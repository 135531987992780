import React, {useCallback, useState, useEffect, useLayoutEffect} from "react";
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';
import Overlay from "../Overlay";
import domain from "../../Domain";
import Select from "react-select";
import {sweetalert} from "../../App";
import SimpleTable from "../tables/SimpleTable";
import {BatchSelect} from "./selects/BatchSelect";
import Batch from "../../types/Batch";
import TestsAPI from "../../network/TestsAPI";
import BatchesAPI from "../../network/BatchesAPI";


type MachineExportProps = {};

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#eeeeee';
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: rgba(236, 236, 236, 0.93);
  outline: none;
  transition: border .24s ease-in-out;
`;

export interface BatchInfo {
    SpecimenID: string
    InternalSpecimenID: string
    WellSlot: string
    ResultID: number
    ResultDescription: string
    PromptRerun: boolean
}

export const MachineExport = ({}: MachineExportProps) => {

    const [fileToUpload, setFileToUpload] = useState<Blob | {}>({})
    const [fileDroppedBool, setFileDroppedBool] = useState(false)
    const [showLoading, setShowLoading] = useState(true)
    const [batch, setBatch] = useState<Batch>(null)
    const [batchInfo, setBatchInfo] = useState<BatchInfo[]>([])
    const [viewClick, setViewClick] = useState(false)
    const [submitClick, setSubmitClick] = useState(false)
    const [tests, setTests] = useState<{ label, value }[]>([]);
    const [selectedTest, setSelectedTest] = useState<{ label, value: number }>('' as unknown as { label, value })


    async function getTests() {
        let tests = await TestsAPI.getAllTestDetails();
        if (!tests.success) {
            return sweetalert.fire({
                title: 'Error',
                text: tests.reason,
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }
        setTests(tests.tests.filter(t => t.ExportParseURL).map(t => {
            return {
                label: t.Name,
                value: t.ID
            }
        }))
    }

    useLayoutEffect(() => {
        getTests();
    }, [])

    const onViewResults = async () => {
        if (!batch) {
            sweetalert.fire({icon: 'error', title: '', text: "Please select batch number"});
        } else if (fileToUpload === {}) {
            sweetalert.fire({icon: 'error', title: '', text: "Please select file to upload"});
        } else if (!selectedTest) {
            sweetalert.fire({icon: 'error', title: '', text: "Please select a test"});
        } else {
            setShowLoading(true)
            let response = await BatchesAPI.postExportForParse(batch.ID, fileToUpload as Blob, selectedTest.value);
            setShowLoading(false);
            if (!response.success) {
                setFileDroppedBool(false)
                setBatchInfo([]);
                sweetalert.fire({icon: 'error', title: '', text: response.reason});
            } else {
                setBatchInfo(response.info ? response.info : []);
                promptReruns(response.info);
            }
        }
    }

    async function promptReruns(data: BatchInfo[]) {
        for (const row of data) {
            let Queue = sweetalert.mixin({})
            if (!row.PromptRerun)
                continue
            await Queue.fire({
                title: 'Specimen is Inconclusive/Invalid',
                text: "Do you want to mark this specimen as a rerun? " + row.InternalSpecimenID,
                showCancelButton: true,
                showLoaderOnConfirm: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, mark as a rerun!',
                cancelButtonText: "No",
                showClass: {backdrop: 'swal2-noanimation'},
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    BatchesAPI.setRerun(batch.ID, row.SpecimenID).then(response => {
                        if (!response.success) {
                            sweetalert.fire({
                                title: 'Error',
                                text: response.reason,
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            })
                        }
                    });
                }
            })
        }
        await sweetalert.fire(
            'Rerun check complete!',
            'This batch does not have anymore inconclusive/invalids',
            'success'
        )
        setViewClick(true);
    }

    const onUpdateResults = async () => {
        if (!batch) {
            sweetalert.fire({icon: 'error', title: '', text: "Please select batch number"});
        } else if (!selectedTest) {
            sweetalert.fire({icon: 'error', title: '', text: "Please select a test"});
        } else if (fileToUpload === {}) {
            sweetalert.fire({icon: 'error', title: '', text: "Please select file to upload"});
        } else if (!batchInfo || batchInfo.length === 0) {
            sweetalert.fire({icon: 'error', title: '', text: "No data to upload"});
        } else {
            setShowLoading(true)
            let response = await BatchesAPI.postMachineResults(batch.ID, batchInfo);
            setShowLoading(false)
            if (!response.success) {
                await sweetalert.fire({
                    title: 'Error',
                    text: response.reason,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            } else {
                setFileDroppedBool(false);
                setBatchInfo([]);
                setSubmitClick(true)
                setBatch(null);
                await sweetalert.fire({
                    title: 'Success',
                    text: 'Results uploaded successfully',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                })
            }
        }
    }

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach(async (file) => {
            setFileToUpload(file)
        })
        setFileDroppedBool(true)
    }, [])


    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles,
        fileRejections,
    } = useDropzone({
        accept: '.csv, application/vnd.ms-excel, text/csv',
        onDrop
    });

    const fileRejection = fileRejections.map(({file, errors}) => (
        <div key={(file as any).path}>
            {(file as any).path} - {file.size} bytes
            <ul>
                {errors.map(e => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </div>
    ));
    
    const filepath = acceptedFiles.map(file => (
        <div key={(file as any).path}>
            {(file as any).path} - {file.size} bytes
        </div>
    ));

    function getFileComponent() {
        if (batchInfo.length > 1 || !batch)
            return null;

        if (fileDroppedBool) {
            return (
                <div className="row">
                    <div className="offset-2 col-8 text-center jumbotron"
                         style={{backgroundColor: 'transparent', border: '2px solid black'}}>
                        <div style={{fontStyle: 'bold', fontSize: '2em', marginBottom: '1em'}}>
                            {filepath.length > 0 ? "File to upload" : "Rejected"}
                        </div>
                        <div>{filepath}</div>
                    </div>
                </div>
            )
        } else {
            return (
                <Container {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
                    <input {...getInputProps()} />
                    <p>Drag and drop a file here or click to select files</p>
                </Container>)
        }
    }

    return (
        <div className="container">
            <Overlay show_loading={showLoading}/>
            <div className="container-fluid  ">
                <div className={"row"}>
                    <div className="col-12 col-md-12 offset-xl-2 col-xl-8 offset-lg-2 col-lg-8  pt-2">
                        <div className="card mb-2">
                            <div className="card-header verlag-bold">
                                <h3>Machine Export</h3>
                            </div>
                            <div className="card-body">
                                <div className="form-group row">
                                    <div className={"col-4"}>
                                        <label style={{fontWeight: 'bold', paddingTop: '0.5em'}}>Test</label>
                                    </div>
                                    <div className={"col-8 pl-0 pr-1"}>
                                        <Select
                                            isSearchable={true}
                                            placeholder={"Please Select..."}
                                            onChange={(e) => setSelectedTest(e)}
                                            value={selectedTest}
                                            className={"state_select"}
                                            options={tests}
                                        />
                                    </div>
                                </div>
                                <BatchSelect disabled={!selectedTest}
                                             onSelected={(e: { label, value: Batch }) => setBatch(e.value)}
                                             filterTests={(b) => b.filter(batch => batch.TestID === selectedTest.value)}
                                finishedLoading={() =>setShowLoading(false)}/>
                                {batchInfo.length > 1 &&
                                <SimpleTable columns={[
                                    {label: "Specimen ID", key: "SpecimenID"},
                                    {label: "Internal Specimen ID", key: "InternalSpecimenID"},
                                    {label: "Well Slot", key: "WellSlot"},
                                    {label: "Result", key: "ResultDescription"}
                                ]} table_data={batchInfo}/>
                                }
                                {getFileComponent()}
                            </div>
                            {fileDroppedBool && filepath.length > 0 &&
                            <div className="card-footer">
                                <React.Fragment>
                                    <button className="btn btn-outline-primary" style={{float: 'left'}}
                                            onClick={() => onViewResults()}>View Results
                                    </button>
                                    {viewClick &&
                                    <button className="btn btn-outline-success" style={{float: 'right'}}
                                            onClick={() => onUpdateResults()}>Update Results
                                    </button>}
                                </React.Fragment>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}