import React,{FC} from "react";
import QRCode from "react-qr-code";
import Overlay from "../Overlay";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import ReactToPrint from "react-to-print";
import domain from "../../Domain";
import PhoneInput from "react-phone-number-input";
import Barcode from "react-barcode";
import {sweetalert} from "../../App";
import SystemAPI from "../../network/SystemAPI";
import Validator, {ValidationEntry} from "../../validation/Validator";
import {Validators} from "../../validation/Validators";
import {calculateAge} from "../../util/ValidationUtil";
import AdminAPI from "../../network/AdminAPI";

interface SpecimenState{
    numberSpecimen: string
    filter: object
    show_loading: boolean
    specimenList: []
    barcodeStyle: boolean
}

export class InternalSpecimen extends React.Component<any,SpecimenState>{
    private componentRef: any;
    constructor(props) {
        super(props);
        this.state = {
            filter: {},
            show_loading: false,
            numberSpecimen: '',
            specimenList: [],
            barcodeStyle: true
        };

    this.onSubmit = this.onSubmit.bind(this);
}

    componentDidMount() {
        this.setState({show_loading: true}, () => {
            AdminAPI.getBarcodeStyle().then(data => {
                this.setState({barcodeStyle: data.isOneD, show_loading: false})
            })
        })
    }

    async onSubmit(e) {

        let formValidation = {
            NumberSpecimen: this.state.filter['numberSpecimen']
        }

        let validator = new Validator<any>()
            .withComposedValidation("NumberSpecimen", new ValidationEntry(Validators.requireNotBlankValidator("Number of Labels")), new ValidationEntry(Validators.requireRange(1,100, "Number of Labels")), new ValidationEntry(Validators.requireIsInt("Number of Labels")))
        let validationResponse = validator.validate(formValidation);
        if(!validationResponse.success) {
            return sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
        }
        this.setState({show_loading: true, numberSpecimen: this.state.filter['numberSpecimen']})

        try{
            let result = await AdminAPI.getSpecimenLabels({count: this.state.filter['numberSpecimen']})
            if(result.success){
                this.setState({specimenList: result.idList, show_loading: false});
            }
        }
        catch (e) {
            console.log(e);
            this.setState({show_loading: false});
        }
    }

render() {
    return(
        <React.Fragment>
            <div className="container">
                <Overlay show_loading={this.state.show_loading}/>
                <div className="container-fluid  ">
                    <div className={"row"}>
                        <div className="col-12 col-md-12 offset-xl-3 col-xl-6 offset-lg-2 col-lg-8  pt-2">
                            <div className="card mb-2">
                                <div className="card-header verlag-bold">
                                    <h3>Print Internal Specimen Labels</h3>
                                </div>
                                <div className="card-body">
                                    <FilterCard
                                        fields={[
                                            {
                                                key: "numberSpecimen",
                                                label: "Number of Labels",
                                                type: FIELD_TYPE.NUMBER,
                                                isMapped: true

                                            },
                                        ]} filterChanged={(filter) => this.setState({filter: filter})}/>
                                </div>

                                <div className="card-footer">
                                    <button
                                        className="btn btn-outline-primary"
                                        onClick={(e) => this.onSubmit(e)}>
                                        View Labels
                                    </button>

                                    {this.state.numberSpecimen ? <ReactToPrint
                                        trigger={() => <button className={"btn btn-outline-success"} style={{float: 'right'}}>Print Labels</button>}
                                        // @ts-ignore
                                        content={() => this.componentRef}
                                    /> : <ReactToPrint
                                        trigger={() => <button className={"btn btn-outline-success"} style={{float: 'right', display: 'none'}}>Print Labels</button>}
                                        // @ts-ignore
                                        content={() => this.componentRef}
                                    />}
                                </div>
                            </div>
                            <div className={"container"} ref={el => (this.componentRef = el)}>
                            <table>

                                    {this.state.specimenList.map((item) =>(
                                        <>
                                            <tr className={'mt-4'}>
                                                {this.state.barcodeStyle ?
                                                <td><b> <Barcode width='4px' height='100px' value={item} /> </b></td>
                                                    :
                                                <td className={'text-center'}>
                                                    <QRCode value={item} size={50}  />
                                                    <div className={'mt-2'}>{item}</div>
                                                </td>
                                                }
                                            </tr>
                                        </>
                                        )
                                    )}

                            </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
        )
    }
}