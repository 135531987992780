import React from "react";
import ReactQuill from "react-quill";
import Select from "react-select";
import {Service} from "../../types/Service";
import {sweetalert} from "../../App";
import {isBlank, isOnlyWhitespace} from "../../util/ValidationUtil";
import {buildRow, getBoolSelectOptions, getBoolSelectVal} from "../../util/FormatUtil";
import {isEmptyObject} from "jquery";

interface ServicesManagementState{
    selectedService:Service
}
interface ServicesManagementProps{
    selectedService
    onSubmit?
}
export class ServicesManagementModal extends React.Component<ServicesManagementProps, ServicesManagementState>{
    public static ID = "servicesmanagement";
    constructor(props) {
        super(props);
        this.state = {
            selectedService: {
                ID:  null,
                Name:  "",
                IsEnabled:getBoolSelectVal(0),
                HtmlTemplate: ""
            } as Service
        } as ServicesManagementState
    }

    componentWillReceiveProps(nextProps: Readonly<ServicesManagementProps>, nextContext: any) {
        if(!isEmptyObject(nextProps.selectedService)){
            let service = nextProps.selectedService;

            this.setState({
                selectedService: {
                    ID: service.ID,
                    Name: service.Name,
                    IsEnabled: getBoolSelectVal(service.IsEnabled),
                    HtmlTemplate: service.HtmlTemplate
                } as Service
            });
        }
    }

    render(){
        return(
            <div className="modal fade form_modal" id={ServicesManagementModal.ID} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="result_modal_label">Service Management</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body m-3 ">
                            {buildRow("Name",
                                <input className={"form-control"}
                                       maxLength={50}
                                       autoComplete={"off"}
                                       type={"search"}
                                       name={"Name"}
                                       onChange={(e)=>{
                                           this.setState( (prevState) => ({
                                               selectedService: {
                                                   ...prevState.selectedService,
                                                   Name: e.target.value
                                               }
                                           }))
                                       }}
                                       value={this.state.selectedService.Name}
                                />
                            )}
                            {buildRow("Is Enabled",
                                <Select
                                    isSearchable={true}
                                    placeholder={"Please Select..."}
                                    noOptionsMessage={() => "No option"}
                                    value={ this.state.selectedService.IsEnabled }
                                    onChange={(e) => this.setState((prevState)=>({
                                        selectedService: {
                                            ...prevState.selectedService,
                                            IsEnabled: e
                                        }
                                    }))}
                                    className={"state_select"}
                                    options={getBoolSelectOptions()}
                                />
                            )}
                            {buildRow("Confirmation Description",
                                <ReactQuill
                                    theme="snow"
                                    value={this.state.selectedService.HtmlTemplate}
                                    onChange={(val) => this.setState((prevState)=>({
                                        selectedService: {
                                            ...prevState.selectedService,
                                            HtmlTemplate: val
                                        }
                                    }))}
                                />
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={() => {
                                if(isBlank(this.state.selectedService.Name) ||
                                    isOnlyWhitespace(this.state.selectedService.Name)
                                ){
                                    return sweetalert.fire("Attention","Name cannot be empty.", "error")
                                }
                                if(!this.state.selectedService.IsEnabled){
                                    return sweetalert.fire("Attention", "Please select a value for Is Enabled", "error")
                                }
                                if(isBlank(this.state.selectedService.HtmlTemplate) || isOnlyWhitespace(this.state.selectedService.HtmlTemplate)){
                                    return sweetalert.fire("Attention", "Confirmation Description cannot be empty", "error")
                                }

                                this.props.onSubmit(this.state.selectedService);
                            }}>
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}