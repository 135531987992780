import React from "react";
import ModalForm from "./ModalForm";
import {isBlank, isOnlyWhitespace} from "../../util/ValidationUtil";
import PhoneInput from 'react-phone-number-input'
import Select from "react-select";
// import domain from "../../Domain";
import {sweetalert} from "../../App";
import {AiOutlineDown, AiOutlineUp} from "react-icons/ai";
import {isEmptyObject} from "jquery";
// import FilterCard, {FIELD_TYPE} from "../FilterCard";
import Validator, {ValidationEntry} from "../../validation/Validator";
import {Validators} from "../../validation/Validators";
import Facility, {FacilityMgmtBody} from "../../types/Facility";
import { showModalNoOutsideClick } from "../../util/FormatUtil";

interface FacilityProps{
    facility
    states
    onSubmit:(facility) => void
    facilityGroups
    isGlobal
}

interface FacilityState{
    facilityID?
    facilityName?
    facilityAbbreviation?
    facilityPhone?
    facilityEmail?
    facilityAddress?
    facilityAddressTwo?
    facilityCity?
    facilityZip?
    facilityState?
    facilityStateLabel?
    facilityCounty?
    facilityGroup?
    facilityGroupLabel?
    providerFirstName?
    providerLastName?
    providerPhone?
    providerEmail?
    providerAddress?
    providerCity?
    providerZip?
    providerState?
    providerStateLabel?
    providerCounty?
    clia?
    reportAddressID?
    reportStreetAddress?
    reportStreetAddressTwo?
    reportCity?
    reportZip?
    reportState?
    reportCounty?
    reportCountry?
    reportStateLabel?
    reportHelpEmail?
    reportDirector?
    reportEntityName?
    oid?
    isSubmitting?
    facilityArrowDown?
    providerArrowDown?
    resultDetailsArrowDown?
    newFacilityGroup?
    facilityGroups?
    CLIA?
    labInfoID?
}
export class ManageFacilityModal extends React.Component<FacilityProps, FacilityState>{
    public formRef: React.RefObject<ModalForm>;
    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false,
            facilityArrowDown: false,
            providerArrowDown: false,
            resultDetailsArrowDown: false,
        };
        this.formRef = React.createRef()
    }


    public static display(){
        console.log("Display called")
        window["$"]("#" + ManageFacilityModal.ID).modal('show');

    }

    getLabel(val, propArray){
        let temp = propArray.find(s => s.value === val || s.label === val)
        if(!temp){
            return null;
        }
        return {label: temp.label, value: val}
    }

    componentWillReceiveProps(nextProps: Readonly<FacilityProps>, nextContext: any) {
        nextProps = JSON.parse(JSON.stringify(nextProps))
        if(!isEmptyObject(nextProps.facility)){
            this.setState({
                facilityID: nextProps.facility && nextProps.facility.ID ? nextProps.facility.ID : '',
                facilityName: nextProps.facility &&  nextProps.facility.FacilityName ? nextProps.facility.FacilityName : '',
                facilityAbbreviation: nextProps.facility && nextProps.facility.FacilityAbbreviation ? nextProps.facility.FacilityAbbreviation : '',
                facilityPhone: nextProps.facility && nextProps.facility.FacilityPhone ? nextProps.facility.FacilityPhone : '',
                facilityEmail: nextProps.facility && nextProps.facility.FacilityEmail ? nextProps.facility.FacilityEmail : '',
                facilityAddress: nextProps.facility && nextProps.facility.FacilityAddress ? nextProps.facility.FacilityAddress : '',
                facilityAddressTwo: nextProps.facility && nextProps.facility.FacilityAddressTwo ? nextProps.facility.FacilityAddressTwo : '',
                facilityCity: nextProps.facility && nextProps.facility.FacilityCity ? nextProps.facility.FacilityCity : '',
                facilityZip: nextProps.facility && nextProps.facility.FacilityZip ? nextProps.facility.FacilityZip : '',
                facilityState: nextProps.facility && nextProps.facility.FacilityState ? nextProps.facility.FacilityState : '',
                facilityCounty: nextProps.facility && nextProps.facility.FacilityCounty ? nextProps.facility.FacilityCounty : '',
                facilityGroup: nextProps.facility && nextProps.facility.FacilityGroup ? nextProps.facility.FacilityGroup : '',
                facilityGroupLabel: nextProps.facility && nextProps.facility.FacilityGroup ? this.getLabel(nextProps.facility.FacilityGroup, this.props.facilityGroups) : '',
                providerFirstName: nextProps.facility && nextProps.facility.ProviderFirstName ? nextProps.facility.ProviderFirstName : '',
                providerLastName: nextProps.facility && nextProps.facility.ProviderLastName ? nextProps.facility.ProviderLastName : '',
                providerPhone: nextProps.facility && nextProps.facility.ProviderPhone ? nextProps.facility.ProviderPhone : '',
                providerEmail: nextProps.facility && nextProps.facility.ProviderEmail ? nextProps.facility.ProviderEmail : '',
                providerAddress: nextProps.facility && nextProps.facility.ProviderAddress ? nextProps.facility.ProviderAddress : '',
                providerCity: nextProps.facility && nextProps.facility.ProviderCity ? nextProps.facility.ProviderCity : '',
                providerZip: nextProps.facility && nextProps.facility.ProviderZip ? nextProps.facility.ProviderZip : '',
                providerState: nextProps.facility && nextProps.facility.ProviderState ? nextProps.facility.ProviderState : '',
                facilityStateLabel: nextProps.facility && nextProps.facility.FacilityState ? this.getLabel(nextProps.facility.FacilityState, this.props.states) : '',
                providerStateLabel: nextProps.facility && nextProps.facility.ProviderState ? this.getLabel(nextProps.facility.ProviderState, this.props.states) : '',
                CLIA: nextProps.facility && nextProps.facility.CLIA ? nextProps.facility.CLIA : '',
                reportAddressID: nextProps.facility && nextProps.facility.ReportAddressID ? nextProps.facility.ReportAddressID : '',
                reportStreetAddress: nextProps.facility && nextProps.facility.StreetAddressOne ? nextProps.facility.StreetAddressOne : '',
                reportStreetAddressTwo: nextProps.facility && nextProps.facility.StreetAddressTwo ? nextProps.facility.StreetAddressTwo : '',
                reportCity: nextProps.facility && nextProps.facility.City ? nextProps.facility.City : '',
                reportZip: nextProps.facility && nextProps.facility.Zipcode ? nextProps.facility.Zipcode : '',
                reportState: nextProps.facility && nextProps.facility.State ? nextProps.facility.State : '',
                reportCounty : nextProps.facility && nextProps.facility.County ? nextProps.facility.County : '',
                reportCountry: nextProps.facility && nextProps.facility.Country ? nextProps.facility.Country : 'United States of America',
                reportStateLabel: nextProps.facility && nextProps.facility.State ? this.getLabel(nextProps.facility.State, this.props.states) : '',
                reportHelpEmail: nextProps.facility && nextProps.facility.ReportHelpEmail ? nextProps.facility.ReportHelpEmail : '',
                reportDirector: nextProps.facility && nextProps.facility.ReportDirector ? nextProps.facility.ReportDirector : '',
                reportEntityName: nextProps.facility && nextProps.facility.ReportEntityName ? nextProps.facility.ReportEntityName : '',
                oid: nextProps.facility && nextProps.facility.LabID ? nextProps.facility.LabID : '',
                facilityGroups: nextProps.facilityGroups ? JSON.parse(JSON.stringify(this.props.facilityGroups)) : null,
                labInfoID: nextProps.facility && nextProps.facility.LabInfoID ? nextProps.facility.LabInfoID : "",
            })
        }
        else{
            this.setState({
                facilityID: '',
                facilityName: '',
                facilityAbbreviation: '',
                facilityPhone: '',
                facilityEmail: '',
                facilityAddress: '',
                facilityAddressTwo: '',
                facilityCity: '',
                facilityZip: '',
                facilityState: '',
                facilityCounty: '',
                facilityGroup: '',
                facilityGroupLabel: '',
                providerFirstName:'',
                providerLastName:'',
                providerPhone:'',
                providerEmail: '',
                providerAddress: '',
                providerCity: '',
                providerZip: '',
                providerState: '',
                facilityStateLabel: '',
                providerStateLabel: '',
                CLIA: '',
                reportAddressID: '',
                reportStreetAddress: '',
                reportStreetAddressTwo: '',
                reportCity: '',
                reportZip: '',
                reportState: '',
                reportCounty: '',
                reportCountry: 'United States of America',
                reportStateLabel: '',
                reportHelpEmail: '',
                reportDirector: '',
                reportEntityName: '',
                oid: '',
                facilityGroups: nextProps.facilityGroups ? JSON.parse(JSON.stringify(this.props.facilityGroups)) : null,
                labInfoID: ''
            })
        }
    }


    getNewFacilityObj(){
        return {
            ID: this.state.facilityID ? this.state.facilityID : null,
            FacilityName:this.state.facilityName ? this.state.facilityName.trim() : null,
            FacilityAbbreviation: this.state.facilityAbbreviation ? this.state.facilityAbbreviation.trim() : null,
            FacilityPhone:this.state.facilityPhone ? this.state.facilityPhone : null,
            FacilityEmail:this.state.facilityEmail ? this.state.facilityEmail.trim() : null,
            FacilityAddress: this.state.facilityAddress ? this.state.facilityAddress.trim() : null,
            FacilityAddressTwo: this.state.facilityAddressTwo ? this.state.facilityAddressTwo.trim() : null,
            FacilityCity: this.state.facilityCity ? this.state.facilityCity.trim() : null,
            FacilityZip: this.state.facilityZip ? this.state.facilityZip : null,
            FacilityState: this.state.facilityStateLabel ? this.state.facilityStateLabel.value : null,
            FacilityCounty: this.state.facilityCounty ? this.state.facilityCounty.trim() : null,
            FacilityGroup: this.state.facilityGroupLabel ? this.state.facilityGroupLabel.value : null,
            ProviderFirstName:  this.state.providerFirstName ? this.state.providerFirstName.trim() : null,
            ProviderLastName: this.state.providerLastName ? this.state.providerLastName.trim() : null,
            ProviderPhone: this.state.providerPhone ? this.state.providerPhone : null,
            ProviderEmail:  this.state.providerEmail ? this.state.providerEmail.trim() : null,
            ProviderAddress: this.state.providerAddress ? this.state.providerAddress.trim() : null,
            ProviderCity: this.state.providerCity ? this.state.providerCity.trim() : null,
            ProviderZip: this.state.providerZip ? this.state.providerZip : null,
            ProviderState: this.state.providerStateLabel ? this.state.providerStateLabel.value : null,
            CLIA: this.state.CLIA ? this.state.CLIA.trim() : null,
            ReportAddressID: this.state.reportAddressID ? this.state.reportAddressID : null,
            ReportStreetAddress: this.state.reportStreetAddress ? this.state.reportStreetAddress.trim() : null,
            ReportStreetAddressTwo: this.state.reportStreetAddressTwo ? this.state.reportStreetAddressTwo.trim() : null,
            ReportCity: this.state.reportCity ? this.state.reportCity.trim() : null,
            ReportZip:  this.state.reportZip ? this.state.reportZip : null,
            ReportCounty: this.state.reportCounty ? this.state.reportCounty : null,
            ReportCountry: this.state.reportCountry ? this.state.reportCountry : null,
            ReportState: this.state.reportStateLabel ? this.state.reportStateLabel.value : null,
            ReportHelpEmail: this.state.reportHelpEmail ? this.state.reportHelpEmail.trim() : null,
            ReportDirector: this.state.reportDirector ? this.state.reportDirector.trim() : null,
            ReportEntityName: this.state.reportEntityName ? this.state.reportEntityName.trim() : null,
            Oid: this.state.oid ? this.state.oid.trim() : null,
            LabInfoID: this.state.labInfoID ? this.state.labInfoID : null
        }
    }

    onSubmit(){

        let validator = new Validator<FacilityMgmtBody>()
            .withComposedValidation("FacilityName", new ValidationEntry(Validators.requireNotBlankValidator("Facility Name")), new ValidationEntry(Validators.requireLength(200, "Facility Name")))
            .withSimpleValidation("FacilityGroup", Validators.requireNonNullValidator("Facility Group"))
            .withComposedValidation("FacilityAbbreviation", new ValidationEntry(Validators.requireNotBlankValidator("Facility Abbreviation")), new ValidationEntry(Validators.requireLength(100, "Facility Abbreviation")))
            .withComposedValidation("FacilityPhone", new ValidationEntry(Validators.requireNonNullValidator("Facility Phone")), new ValidationEntry(Validators.requirePhone("Facility Phone")))
            .withComposedValidation("FacilityEmail", new ValidationEntry(Validators.requireNotBlankValidator("Facility Email")), new ValidationEntry(Validators.requireValidEmail("Facility Email")), new ValidationEntry(Validators.requireLength(200, "Facility Email")))
            .withComposedValidation("FacilityAddress", new ValidationEntry(Validators.requireNotBlankValidator("Facility Address")), new ValidationEntry(Validators.requireLength(100, "Facility Address")))
            if(this.state.facilityAddressTwo){
                validator = validator.withComposedValidation("FacilityAddressTwo", new ValidationEntry(Validators.requireNotBlankValidator("Facility Address cont")), new ValidationEntry(Validators.requireLength(100, "Facility Address cont")))
            }
            validator = validator.withComposedValidation("FacilityCity", new ValidationEntry(Validators.requireNotBlankValidator("Facility City")), new ValidationEntry(Validators.requireLength(100, "Facility City")))
            .withComposedValidation("FacilityZip", new ValidationEntry(Validators.requireNotBlankValidator("Facility Zipcode")), new ValidationEntry(Validators.requireZip("Facility Zipcode")))
            .withSimpleValidation("FacilityState", Validators.requireNonNullValidator("Facility State"))
                .withComposedValidation("FacilityCounty", new ValidationEntry(Validators.requireNotBlankValidator("Facility County")), new ValidationEntry(Validators.requireLength(100, "Facility County")))
                .withComposedValidation("ProviderFirstName", new ValidationEntry(Validators.requireNotBlankValidator("Provider First Name")), new ValidationEntry(Validators.requireLength(50, "Provider First Name")))
                .withComposedValidation("ProviderLastName", new ValidationEntry(Validators.requireNotBlankValidator("Provider Last Name")), new ValidationEntry(Validators.requireLength(50, "Provider Last Name")))
                .withComposedValidation("ProviderPhone", new ValidationEntry(Validators.requireNonNullValidator("Provider Phone")), new ValidationEntry(Validators.requirePhone("Provider Phone")))
                .withComposedValidation("ProviderEmail", new ValidationEntry(Validators.requireNotBlankValidator("Provider Email")), new ValidationEntry(Validators.requireValidEmail("Provider Email")), new ValidationEntry(Validators.requireLength(200, "Provider Email")))
                .withComposedValidation("ProviderAddress", new ValidationEntry(Validators.requireNotBlankValidator("Provider Address")), new ValidationEntry(Validators.requireLength(100, "Provider Address")))
                .withComposedValidation("ProviderCity", new ValidationEntry(Validators.requireNotBlankValidator("Provider City")), new ValidationEntry(Validators.requireLength(100, "Provider City")))
                .withComposedValidation("ProviderZip", new ValidationEntry(Validators.requireNotBlankValidator("Provider Zipcode")), new ValidationEntry(Validators.requireZip("Provider Zipcode")))
                .withSimpleValidation("ProviderState", Validators.requireNonNullValidator("Provider State"))
                .withComposedValidation("CLIA", new ValidationEntry(Validators.requireNotBlankValidator("CLIA #")), new ValidationEntry(Validators.requireLength(45, "CLIA #")))
                .withComposedValidation("Oid", new ValidationEntry(Validators.requireNotBlankValidator("OID")), new ValidationEntry(Validators.requireLength(255, "OID")))
                .withComposedValidation("ReportStreetAddress", new ValidationEntry(Validators.requireNotBlankValidator("Report Street Address")), new ValidationEntry(Validators.requireLength(100, "Report Street Address")))
        if(this.state.reportStreetAddressTwo){
            validator = validator.withComposedValidation("ReportStreetAddressTwo", new ValidationEntry(Validators.requireNotBlankValidator("Report Street Address cont")), new ValidationEntry(Validators.requireLength(100, "Report Street Address cont")))
        }
        validator = validator.withComposedValidation("ReportCity", new ValidationEntry(Validators.requireNotBlankValidator("Report City")), new ValidationEntry(Validators.requireLength(50, "Report City")))
            .withComposedValidation("ReportZip", new ValidationEntry(Validators.requireNotBlankValidator("Report Zipcode")), new ValidationEntry(Validators.requireZip("Report Zipcode")))
            .withSimpleValidation("ReportState", Validators.requireNonNullValidator("Report State"))
            .withComposedValidation("ReportCounty", new ValidationEntry(Validators.requireNotBlankValidator("Report County")), new ValidationEntry(Validators.requireLength(50, "Report County")))
        if(this.state.reportHelpEmail){
            validator = validator.withComposedValidation("ReportHelpEmail", new ValidationEntry(Validators.requireNotBlankValidator("Report Help Email")), new ValidationEntry(Validators.requireValidEmail("Report Email")), new ValidationEntry(Validators.requireLength(200, "Report Help Email")))
        }
        validator = validator.withComposedValidation("ReportDirector", new ValidationEntry(Validators.requireNotBlankValidator("Report Director")), new ValidationEntry(Validators.requireLength(100, "Report Director")))
            .withComposedValidation("ReportEntityName", new ValidationEntry(Validators.requireNotBlankValidator("Report Entity Name")), new ValidationEntry(Validators.requireLength(100, "Report Entity Name")))
        let validationResponse = validator.validate(this.getNewFacilityObj());
        if(!validationResponse.success) {
            return sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
        }

        this.setState({newFacilityGroup: ''})
        this.props.onSubmit(this.getNewFacilityObj())


    }

// fix needed to show sweetalert input on top of modal
// call this before showing SweetAlert:
    fixBootstrapModal() {
        var modalNode = document.querySelector('.modal[tabindex="-1"]');
        if (!modalNode) return;

        modalNode.removeAttribute('tabindex');
        modalNode.classList.add('js-swal-fixed');
    }

// call this before hiding SweetAlert (inside done callback):
    restoreBootstrapModal() {
        var modalNode = document.querySelector('.modal.js-swal-fixed');
        if (!modalNode) return;

        modalNode.setAttribute('tabindex', '-1');
        modalNode.classList.remove('js-swal-fixed');
    }

    async handleAddNewFacility(){
        this.fixBootstrapModal()
        const { value: newFacilityGroup } = await sweetalert.fire({
            title: 'Enter new facility group',
            input: 'text',
            showCancelButton: true,
            confirmButtonText: 'Add',
            inputValidator: (value) => {
                if (isBlank(value)|| isOnlyWhitespace(value)) {
                    return 'New Facility Group cannot be blank'
                }
                else if (value.length > 200) {
                    return 'New Facility Group must be 200 characters or less'
                }
            }
        })
        if(newFacilityGroup){
            let updateFacilityGroups = JSON.parse(JSON.stringify(this.state.facilityGroups));
            let duplicateFacilityGroupName = updateFacilityGroups.find(f => f.label === newFacilityGroup)
            if(duplicateFacilityGroupName){
                return sweetalert.fire({icon: 'error', title: '', text: 'New Facility Group name cannot be an existing facility group name'});
            }
            else{
                updateFacilityGroups.push({label: newFacilityGroup.trim(), value:newFacilityGroup.trim()})
                this.setState({facilityGroups: updateFacilityGroups, facilityGroupLabel: {label: newFacilityGroup.trim(), value: newFacilityGroup.trim()}, facilityGroup: newFacilityGroup})
            }
            this.restoreBootstrapModal()
        }




    }

    getInput(stateToSet, state, label, tooltip, maxLength?, disabled=false, placeholder=''){
        return(
            <div className="form-group row">
                <div className={"col-12 col-md-4"} data-toggle={'tooltip'} data-placement={'top'} title={tooltip}>
                    <label htmlFor={label} style={{fontWeight: 'bold', paddingTop: '0.5em'}}>{label}</label>
                </div>
                <div className={"col-12 col-md-8 pl-md-0 pr-md-1"} id={label}>
                    <div className="row">
                        <div className="col-12">
                            <input type={"search"}
                                   maxLength={maxLength}
                                   placeholder={placeholder}
                                   disabled={disabled}
                                   className={'form-control'}
                                   value={state}
                                   autoComplete="off"
                                   onChange={(e) =>{
                                       this.setState({[stateToSet]: e.target.value} as any)
                                   }}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getSelect(stateToSet, stateLabel, stateLabelToSet, label, tooltip, options, disabled=false){
        if(stateToSet === 'facilityGroup'){
            return(
                <div className={"form-group row"}>
                    <div className={"col-12 col-md-4"} data-toggle={'tooltip'} data-placement={'top'} title={tooltip}>
                        <label htmlFor={label} style={{fontWeight: 'bold', paddingTop: '0.5em'}}>{label}</label>
                    </div>
                    <div className={"col-12 col-md-8 pl-md-0 pr-md-1"} id={label}>
                        <div className="row">
                            <div className={this.props.isGlobal ? "col-9" : "col-12"}>
                                <Select
                                    isDisabled={disabled}
                                    isSearchable={true}
                                    placeholder={"Please select..."}
                                    onChange={(e)=> this.setState({[stateLabelToSet]: e, [stateToSet]: e.value} as any)}
                                    className={"state_select"}
                                    options={options}
                                    value={stateLabel}
                                />
                            </div>
                            <div className={this.props.isGlobal ? "col-3" : "d-none"} data-toggle={"tooltip"} data-placement={"top"} title={"This button will open an input field below to create a new facility group"}>
                                <button className={"btn btn-success"} onClick={() => this.handleAddNewFacility()}>New Facility Group</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return(
            <div className="form-group row">
                <div className={"col-12 col-md-4"} data-toggle={'tooltip'} data-placement={'top'} title={tooltip}>
                    <label htmlFor={label} style={{fontWeight: 'bold', paddingTop: '0.5em'}}>{label}</label>
                </div>
                <div className={"col-12 col-md-8 pl-md-0 pr-md-1"} id={label}>
                    <div className="row">
                        <div className="col-12">
                            <Select
                                isDisabled={disabled}
                                isSearchable={true}
                                placeholder={"Please select..."}
                                onChange={(e)=> this.setState({[stateLabelToSet]: e, [stateToSet]: e.value} as any)}
                                className={"state_select"}
                                options={options}
                                value={stateLabel}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }



    public static readonly ID = "newfacility"
    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <React.Fragment>
                <div className="modal fade form_modal" id={ManageFacilityModal.ID} tabIndex={-1} role="dialog"
                     aria-labelledby="result_modal_label"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-body p-0 m-0">
                                <div className="container-fluid">
                                    <div className={"row"}>
                                        <div className="col-12 pt-2">
                                            <div className="card mb-2">
                                                <div className="card-header verlag-bold">
                                                    <h3>Facility Editing
                                                        <button type="button" className="close pr-4" data-dismiss="modal"
                                                                aria-label="Close">
                                                            <span aria-hidden="true" style={{fontSize: '1.5em', fontWeight: 'bold'}}>&times;</span>
                                                        </button>
                                                    </h3>
                                                </div>
                                                <div className="card-body">
                                                    <div className="card mb-2">
                                                        <div className="card-header verlag-bold" data-toggle="collapse" data-target="#facilityCollapse" onClick={() => this.setState({facilityArrowDown: !this.state.facilityArrowDown})} role="button" aria-expanded="false" aria-controls="facilityCollapse">
                                                            <h3>Facility
                                                                {this.state.facilityArrowDown ? (<AiOutlineUp className={'float-right'} size={30}  data-toggle="collapse" data-target="#facilityCollapse"  role="button" aria-expanded="false" aria-controls="facilityCollapse" />) : (<AiOutlineDown className={'float-right'} size={30} data-toggle="collapse" data-target="#facilityCollapse" role="button" aria-expanded="false" aria-controls="facilityCollapse" />)}
                                                            </h3>
                                                        </div>
                                                        <div className="card-body collapse" id={'facilityCollapse'}>
                                                            {this.getInput('facilityName', this.state.facilityName, 'Facility Name', '', 200)}
                                                            {this.getSelect('facilityGroup', this.state.facilityGroupLabel, 'facilityGroupLabel', 'Facility Group', '', this.state.facilityGroups)}
                                                            {this.getInput('facilityAbbreviation', this.state.facilityAbbreviation, 'Facility Abbreviation', '', 50)}

                                                            <div className="form-group row">
                                                                <div className={"col-4"}>
                                                                    <label htmlFor={'Facility Phone'} style={{fontWeight: 'bold', paddingTop: '0.5em'}}>Facility Phone</label>
                                                                </div>
                                                                <div className={"col-8 pl-0 pr-1"}>

                                                                    <PhoneInput
                                                                        id={'Facility Phone'}
                                                                        placeholder="Enter phone number"
                                                                        value={this.state.facilityPhone}
                                                                        onChange={(e) => this.setState({facilityPhone: e})}
                                                                        defaultCountry="US"
                                                                    />
                                                                </div>
                                                            </div>

                                                            {this.getInput('facilityEmail', this.state.facilityEmail, 'Facility Email', '', 100)}
                                                            {this.getInput('facilityAddress', this.state.facilityAddress, 'Facility Street Address', '', 100)}
                                                            {this.getInput('facilityAddressTwo', this.state.facilityAddressTwo, 'Facility Street Address cont.', '', 100)}
                                                            {this.getInput('facilityCity', this.state.facilityCity, 'Facility City', '', 100)}
                                                            {this.getInput('facilityZip', this.state.facilityZip, 'Facility Zip', '', 50)}
                                                            {this.getSelect('facilityState', this.state.facilityStateLabel, 'facilityStateLabel', 'Facility State', '', this.props.states)}
                                                            {this.getInput('facilityCounty', this.state.facilityCounty, 'Facility County', '', 100)}

                                                        </div>
                                                    </div>

                                                    <div className="card mb-2">
                                                        <div className="card-header verlag-bold" data-toggle="collapse" data-target="#providerCollapse" onClick={() => this.setState({providerArrowDown: !this.state.providerArrowDown})} role="button" aria-expanded="false" aria-controls="providerCollapse">
                                                            <h3>Provider
                                                                {this.state.providerArrowDown ? (<AiOutlineUp className={'float-right'} size={30}  data-toggle="collapse" data-target="#providerCollapse"  role="button" aria-expanded="false" aria-controls="providerCollapse" />) : (<AiOutlineDown className={'float-right'} size={30} data-toggle="collapse" data-target="#providerCollapse" role="button" aria-expanded="false" aria-controls="providerCollapse" />)}
                                                            </h3>
                                                        </div>
                                                        <div className="card-body collapse" id={'providerCollapse'}>
                                                            {this.getInput('providerFirstName', this.state.providerFirstName, 'Provider First Name', '', 50)}
                                                            {this.getInput('providerLastName', this.state.providerLastName, 'Provider Last Name', '', 50)}

                                                            <div className="form-group row">
                                                                <div className={"col-4"}>
                                                                    <label htmlFor={'Provider Phone'} style={{fontWeight: 'bold', paddingTop: '0.5em'}}>Provider Phone</label>
                                                                </div>
                                                                <div className={"col-8 pl-0 pr-1"}>

                                                                    <PhoneInput
                                                                        id={'Provider Phone'}
                                                                        placeholder="Enter phone number"
                                                                        value={this.state.providerPhone}
                                                                        onChange={(e) => this.setState({providerPhone: e})}
                                                                        defaultCountry="US"
                                                                    />
                                                                </div>
                                                            </div>

                                                            {this.getInput('providerEmail', this.state.providerEmail, 'Provider Email', '', 100)}
                                                            {this.getInput('providerAddress', this.state.providerAddress, 'Provider Street Address', '', 100)}
                                                            {this.getInput('providerCity', this.state.providerCity, 'Provider City', '', 50)}
                                                            {this.getInput('providerZip', this.state.providerZip, 'Provider Zip', '', 50)}
                                                            {this.getSelect('providerState', this.state.providerStateLabel, 'providerStateLabel', 'Provider State', '', this.props.states)}
                                                        </div>
                                                    </div>

                                                    <div className="card mb-2">
                                                        <div className="card-header verlag-bold"  data-toggle="collapse" data-target="#resultDetailsCollapse" onClick={() => this.setState({resultDetailsArrowDown: !this.state.resultDetailsArrowDown})} role="button" aria-expanded="false" aria-controls="resultDetailsCollapse">
                                                            <h3>Rapid Test Reporting
                                                                {this.state.resultDetailsArrowDown ? (<AiOutlineUp className={'float-right'} size={30}  data-toggle="collapse" data-target="#resultDetailsCollapse"  role="button" aria-expanded="false" aria-controls="resultDetailsCollapse" />) : (<AiOutlineDown className={'float-right'} size={30} data-toggle="collapse" data-target="#resultDetailsCollapse" role="button" aria-expanded="false" aria-controls="resultDetailsCollapse" />)}
                                                            </h3>
                                                        </div>
                                                        <div className="card-body collapse" id={'resultDetailsCollapse'}>
                                                            {this.getInput('CLIA', this.state.CLIA, 'CLIA', '', 45)}
                                                            {this.getInput('oid', this.state.oid, 'Unique OID', 'Unique OID allocated to this facility', 255)}
                                                            {this.getInput('reportStreetAddress', this.state.reportStreetAddress, 'Report Street Address', '', 100)}
                                                            {this.getInput('reportStreetAddressTwo', this.state.reportStreetAddressTwo, 'Report Street Address cont.', '', 100)}
                                                            {this.getInput('reportCity', this.state.reportCity, 'Report City', '', 50)}
                                                            {this.getInput('reportZip', this.state.reportZip, 'Report Zip', '', 50)}
                                                            {this.getSelect('reportState', this.state.reportStateLabel, 'reportStateLabel', 'Report State', '', this.props.states)}
                                                            {this.getInput('reportCounty', this.state.reportCounty, 'Report County', '', 50)}
                                                            {this.getInput('reportHelpEmail', this.state.reportHelpEmail, 'Report Help Email (Optional)', '', 100, false)}
                                                            {this.getInput('reportDirector', this.state.reportDirector, 'Report Director', '', 100)}
                                                            {this.getInput('reportEntityName', this.state.reportEntityName, 'Report Entity Name', '', 100)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer" style={{backgroundColor: 'transparent', borderTop: 'none'}}>
                                        <button className={"btn btn-primary "} onClick={() => this.onSubmit()} disabled={this.state.isSubmitting}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        )
    }
}

