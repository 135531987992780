import React from "react";

class Home extends React.Component<{}, {}> {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount(): void {
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (<React.Fragment>

            <div className="container  min-vh-100 ">
                <div className="row responsive-p">
                    <div className={"col-12 pb-4"}>
                        <h1 className={"text-center pt-4 verlag-light"}>
                            <img src={"https://storage.googleapis.com/streamline-bucket/FullNoBackground.png"} 
                                className={"homepage-logo"} 
                                alt={"logo"}/>
                        </h1>
                        <h1 className="text-center verlag-bold text-uppercase">High-Speed Testing Service</h1>
                    </div>
                    <div className="col-xl-4 pt-2 pb-2 text-center verlag-light responsive-p">
                        <a href="/appointment">
                        <img className="img-fluid text-center" src="images/outline_event_black_48dp.png"
                             />
                        </a>
                            <h1 className="verlag-black">Make Appointment</h1>

                    </div>
                    <div className="col-xl-4 pt-2 pb-2 text-center">
                        <a href="/appointment">
                        <img className="img-fluid text-center" src="images/outline_speed_black_48dp.png"
                            />
                        </a>
                            <h1 className="verlag-black">Get Tested</h1>
                    </div>
                    <div className="col-xl-4 pt-2 pb-2  text-center">
                        <a href={"/results"}>
                        <img className="img-fluid text-center" src="images/outline_assignment_black_48dp.png"
                             />
                        </a>
                            <h1 className="verlag-black">Get Results Digitally</h1>
                    </div>
                    <div className={"col-12 pb-4 pt-4 text-center responsive-p"}>
                        <p className={"verlag-light "}>Schedule your Covid-19 test today.</p>
                        <a href={"/appointment"}> <button className="btn btn-primary">Make Appointment</button></a>
                    </div>
                </div>
            </div>
        </React.Fragment>)
    }
}
export default Home;