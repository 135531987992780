import NetworkUtil from "./NetworkUtil";
import {Sample} from "../types/Sample";
import {ServerResponse} from "../types/ServerResponse";

export default class SamplesAPI {

    public static async getFilteredSamples(page, filter):Promise<{num_pages:number, totalSamples:number, table_data?:Sample[]}>{
        return NetworkUtil.makePost("/api/admin/sampleSearch?page=" + page, filter);
    }

    public static async getFilteredSamplesForCheckin(page, filter):Promise<{num_pages:number, totalSamples:number, table_data?:Sample[]}>{
        return NetworkUtil.makePost("/api/admin/sampleSearchForCheckin?page=" + page, filter);
    }

    public static async updateSample(sample):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/sampleEdit", sample);
    }

    public static async updateResultsAccessedDate(id):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/sampleResultsAccessedDate", {ID: id});
    }

    public static editSample(editBody:SampleCheckinBody):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/sample/checkin", editBody);
    }

}

export interface SampleCheckinBody{
    ID?
    PatientFirstName:string
    PatientLastName:string,
    PatientDOB
    TestID
    SpecimenSourceID
    SpecimenID
    Lot
}