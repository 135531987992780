import NetworkUtil from "./NetworkUtil";
import {Service} from "../types/Service";
import {ServerResponse} from "../types/ServerResponse";

export default class ServicesAPI {

    public static async getAllServices():Promise<{success:boolean, reason:string, data?:Service[]}>{
        return NetworkUtil.makeGet("/api/admin/services");
    }

    public static async getServicesForAppointmentForm():Promise<{label:string,value:number}[]>{
        return NetworkUtil.makeGet("/api/system/services");
    }

    public static async getServiceNames():Promise<{success:boolean, reason:string, data?:{label:string,value:string}[]}>{
        return NetworkUtil.makeGet("/api/admin/services/names")
    }

    public static async filterServices(filter:{filter:{Name?,IsEnabled?}}):Promise<{success:boolean, reason:string, data?:Service[]}>{
        return NetworkUtil.makePost("/api/admin/services/filter", filter)
    }

    public static async createService(service:Service):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/services/create", service)
    }

    public static async editService(service):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/services/edit", service)
    }

}