import React from "react";
import ModalMessage from "./ModalMessage";

interface InformedConsentProps {
    legalName: string
}

class InformedConsentModal extends React.Component<InformedConsentProps, {}> {

    public static readonly ID = "informed_consent"

    public static display() {
        window['$']("#" + InformedConsentModal.ID).modal('show')
    }

    public static hide() {
        window['$']("#" + InformedConsentModal.ID).modal('hide')
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (<ModalMessage title={"Informed Consent"} ID={InformedConsentModal.ID}
                              onSubmit={() => InformedConsentModal.hide()}
                              message={
                                  (<p>
                                      <ol>
                                          <li>
                                              Authorization and Consent for Covid-19 Diagnostic Testing:
                                              <p>
                                                  I voluntarily consent and authorize {this.props.legalName} to
                                                  conduct collection, testing, and analysis for the purposes of a
                                                  COVID-19 diagnostic test. I acknowledge and understand that my
                                                  COVID-19 diagnostic test will require the collection of an appropriate
                                                  sample by my healthcare provider through a nasopharyngeal swab, oral
                                                  swab, or other recommended collection procedures. I understand that
                                                  there are risks and benefits associated with undergoing a diagnostic
                                                  test for COVID-19 and there may be a potential for false positive or
                                                  false negative test results. I assume complete and full responsibility
                                                  to take appropriate action with regards to my test results. Should I
                                                  have question or concerns regarding my results, or a worsening of my
                                                  condition, I shall promptly seek advice and treatment from an
                                                  appropriate medical provider.
                                              </p>
                                          </li>
                                          <li>Patient Rights and Privacy Practices:
                                              <ol>
                                                  <li>
                                                      Notice of Privacy Practices and Patient
                                                      Rights: {this.props.legalName}’s Notice
                                                      of Privacy Practices describes how it may use and disclose your
                                                      protected health information to carry out treatment, initiate and
                                                      obtain payment, conduct health care operations and for other
                                                      purposes that are permitted or required by law. To review a copy
                                                      of your rights as a patient and {this.props.legalName}’s Notice of
                                                      Privacy
                                                      Practices please click <a target={"_blank"}
                                                                                href={"/documents/osdh_privacy_policies.pdf"}>here</a>.
                                                      I acknowledge that {this.props.legalName} has provided me with a
                                                      copy of {this.props.legalName}’s Notice of Privacy Practices.
                                                  </li>
                                                  <li>
                                                      Disclosure to Government Authorities: I acknowledge and agree
                                                      that {this.props.legalName} may disclose my test results and
                                                      associated information
                                                      to appropriate county, state, or other governmental and regulatory
                                                      entities as may be permitted by law.

                                                  </li>
                                              </ol>
                                          </li>
                                          <li>
                                              Release:
                                              <p>
                                                  To the fullest extent permitted by law, I hereby release, discharge
                                                  and hold harmless, {this.props.legalName}, including, without
                                                  limitation, any its
                                                  respective officers, directors, employees, representatives and agents
                                                  from any and all claims, liability, and damages, of whatever kind or
                                                  nature, arising out of or in connection with any act or omission
                                                  relating to my COVID-19 diagnostic test or the disclosure of my
                                                  COVID-19 test results.
                                              </p>
                                              <p>
                                                  By selecting the ACKNOWLEDGEMENT during the registration process for
                                                  COVID-19 Diagnostic Testing at {this.props.legalName}, I acknowledge
                                                  and agree that
                                                  I have read, understand, and agreed to the statements contained within
                                                  this form. I have been informed about the purpose of the COVID-19
                                                  diagnostic test, procedures to be performed, potential risks and
                                                  benefits, and associated costs. I have been provided an opportunity to
                                                  ask questions before proceeding with a COVID-19 diagnostic test and I
                                                  understand that if I do not wish to continue with the collection,
                                                  testing, or analysis of a COVID-19 diagnostic test, I may decline to
                                                  receive continued services. I have read the contents of this form in
                                                  its entirety and voluntarily consent to undergo diagnostic testing for
                                                  COVID-19.

                                              </p>
                                          </li>
                                      </ol>

                                  </p>)
                              } buttonText={"OK"}/>)
    }
}

export default InformedConsentModal
