import React from "react";
import { showModalNoOutsideClick } from "../../util/FormatUtil";
import ModalForm from "./ModalForm";

interface HintProps{
    header: string
    content: any
}

interface HintState{

}

export class HintModal extends React.Component<HintProps, HintState>{
    public formRef: React.RefObject<ModalForm>;

    constructor(props) {
        super(props);
        this.state = {

        };
        this.formRef = React.createRef()
    }

    public static display(){
        //console.log("Display called")
        window["$"]("#" + HintModal.ID).modal('show');

    }

    public static readonly ID = "hint"
    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        // console.log(this.state)
        return (
            <React.Fragment>

                <div className="modal fade form_modal" id={HintModal.ID} tabIndex={-1} role="dialog"
                     aria-labelledby="result_modal_label"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg " role="document">
                        <div className="modal-content">
                            <div className="modal-body p-0 m-0">
                                <div className="container-fluid  min-vh-10">
                                    <div className={"row"}>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-12 pt-2">
                                            <div className="card mb-2 text-center">
                                                <div className="card-header verlag-bold">
                                                    <h3 style={{fontWeight: 'bold'}}>{this.props.header}
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                    </h3>
                                                </div>
                                                <table className="table mb-0 table-bordered">
                                                    <tbody className="text-center">
                                                    {this.props.content.map((con) => {
                                                        return(
                                                            <tr>
                                                                <td>{con}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                    </tbody>
                                                </table>
                                                    </div>
                                                </div>

                                               </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

            </React.Fragment>
        )
    }
}

