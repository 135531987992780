import React from "react";
import Overlay from "../Overlay";
import SimpleTable from "../tables/SimpleTable";
import {UserPermissionsModal} from "../modals/UserPermissionsModal";
import FilterCard, {FIELD_TYPE, ReactSelect} from "../FilterCard";
import {sweetalert} from '../../App';
import {getPageOptions, slicePages, hideModal, showModalNoOutsideClick} from "../../util/FormatUtil";
import Select from "react-select";
import NetworkUtil from "../../network/NetworkUtil";

import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import UsersAPI from "../../network/UsersAPI";
import PaginationTool from "../PaginationTool";

interface PermState {
  showLoading;
  tableResults: {
    id;
    combinedName;
    email;
    permissions: [];
    status;
    facilityGroupID;
  }[];
  selectedUser: {
    id;
    combinedName;
    email;
    permissions: [];
    status;
    firstName;
    lastName;
    facilityGroup;
  };
  filter?: {
    FirstName;
    LastName;
    Email;
    FacilityGroups;
    Permissions;
    Status;
  };

  page_options: ReactSelect[];
  selected_page?;
  totalUsers?;

  queryResults: {
    combinedName;
    customerID;
    email;
    facilityGroupID;
    id;
    firstName;
    lastName;
    permissions;
    status;
  }[];
  permGroups: { label; value }[]; // Permission Groups from Okta
  oktaGroups: { label; value; oktaID }[]; // Facility and Consent Entity Groups from Okta

  direction?: TableOrder;
}

interface PermProps {}

export class UserPermissioning extends React.Component<PermProps, PermState> {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      queryResults: [] as any,
      tableResults: [] as any,
      selectedUser: { permissions: [] } as any,
      totalUsers: 0,
      page_options: [{ value: "1", label: "1" }],
      selected_page: { label: 1, value: 1 },
      permGroups: [{ label: "", value: -1 }] as any,
      oktaGroups: [{ label: "", value: -1, oktaID: -1 }] as any,
      filter: {
        FirstName: "",
        LastName: "",
        Email: "",
        FacilityGroups: [],
        Permissions: [],
        Status: "",
      },
      direction: "asc",
    };
    this.filterUsers = this.filterUsers.bind(this);
    this.deactivateUserAccount = this.deactivateUserAccount.bind(this);
    this.adjustPermissions = this.adjustPermissions.bind(this);
    this.changePage = this.changePage.bind(this);
  }

  changePage(page: number) {
    let allUsers = this.state.queryResults;
    let returnData = slicePages(allUsers, page, 50);
    this.setState({ tableResults: returnData });
  }

  filterUsers(page: number) {
    this.setState(
      {
        showLoading: true,
        selectedUser: {
          id: -1,
          combinedName: "",
          firstName: "",
          lastName: "",
          email: "",
          permissions: [],
          status: "",
          facilityGroup: {},
        },
      },
      async () => {
        let fields = {
          FirstName: this.state.filter.FirstName,
          LastName: this.state.filter.LastName,
          Email: this.state.filter.Email,
          FacilityGroups: this.state.filter.FacilityGroups,
          Permissions: this.state.filter.Permissions,
          Status: this.state.filter.Status,
        };
        let response = await UsersAPI.filterUsers({ filter: fields });
        if (!response.success || (response.users && !response.users.length)) {
          this.setState({
            showLoading: false,
            tableResults: slicePages(response.users, page, 50),
            queryResults: response.users,
            totalUsers: response.users.length,
            page_options: getPageOptions(response.num_pages),
          });
          return sweetalert.fire({
            icon: "error",
            title: "",
            text: "No results were returned",
          });
        }

        if (
          response.users &&
          response.users.length &&
          response.users.length > 0
        ) {
          response.users = response.users.map((u) => {
            // display DEACTIVATED instead of DEPROVISIONED
            if (u.status == "DEPROVISIONED") {
              u.status = "DEACTIVATED";
            }
            return u;
          });

          this.setState({
            showLoading: false,
            tableResults: slicePages(response.users, page, 50),
            queryResults: response.users,
            totalUsers: response.users.length,
            page_options: getPageOptions(response.num_pages),
          });
        }
      }
    );
  }

  componentDidMount() {
    this.setState({ showLoading: true }, async () => {
      // use promises instead of await for permissions and groups
      UsersAPI.getValidGroups().then((response) => {
        if (!response.success) {
          sweetalert.fire({ icon: "error", title: "", text: response.reason });
        }
        let permissionGroups = response.groups.map((g) => ({
          label: g.description,
          value: g.id,
        }));
        permissionGroups.push({ label: "null", value: "" });
        this.setState({ permGroups: permissionGroups });
      });
      UsersAPI.getOktaCustomerGroups().then((response) => {
        if (!response.success) {
          sweetalert.fire({ icon: "error", title: "", text: response.reason });
        }
        let validGroups = response.oktaGroups;
        let validGroupsWithNull = validGroups.concat([
          {
            label: "null",
            value: "********************",
            oktaID: "********************",
          },
        ]);
        this.setState({ oktaGroups: validGroupsWithNull });
      });
      this.setState({
        showLoading: false,
      });
    });
  }

  createUserAccount = async (user: {
    firstName;
    lastName;
    email;
    selectedPerms;
    status;
    deactivate;
    id;
    facilityGroup: { label; value };
  }) => {
    let userData = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      login: user.email,
      facilityGroup: user.facilityGroup.value,
      groupIds: user.selectedPerms.map((p) => p.value),
    };

    let response = await UsersAPI.createUserAccount(userData);
    if (!response.success) {
      this.setState({ showLoading: false });
      return sweetalert.fire({
        icon: "error",
        title: "",
        text: response.reason,
      });
    }

    this.filterUsers(this.state.selected_page.value);
    return sweetalert.fire({
      icon: "success",
      title: "",
      text: "User Account Created.",
    });
  };

  deactivateUserAccount = async (selectedUser: {
    firstName?: any;
    lastName?: any;
    email?: any;
    selectedPerms?: any;
    status?: any;
    deactivate?: any;
    facilityGroup?: any;
    id?: any;
    combinedName?: any;
  }) => {
    let id = selectedUser.id;

    sweetalert
      .fire({
        title: "Attention",
        text:
          "Are you sure you want to deactivate " +
          selectedUser.combinedName +
          "'s account? This cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Deactivate",
        confirmButtonColor: "#C41230", //immy-red
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          let response = await UsersAPI.deactivateUserAccount(id);
          if (!response.success) {
            this.setState({ showLoading: false });
            return sweetalert.fire({
              icon: "error",
              title: "",
              text: response.reason,
            });
          }
          this.filterUsers(this.state.selected_page.value);
          return sweetalert.fire({
            icon: "success",
            title: "",
            text: "User account deactivated.",
          });
        }
        if (result.isDismissed) {
          this.setState({ showLoading: false });
        }
      });
  };

  adjustPermissions = async (permList: any[]) => {
    let userid = this.state.selectedUser.id;
    let permissions = permList.map((p) => p.value);
    let response = await UsersAPI.adjustUserPermissions(userid, permissions);

    if (!response.success) {
      this.setState({ showLoading: false });
      return sweetalert.fire({
        icon: "error",
        title: "",
        text: response.reason,
      });
    }
    this.setState(
      {
        selectedUser: {
          id: -1,
          combinedName: "",
          firstName: "",
          lastName: "",
          email: "",
          permissions: [],
          status: "",
          facilityGroup: {},
        },
      },
      () => {
        this.filterUsers(this.state.selected_page.value);
        return sweetalert.fire({
          icon: "success",
          title: "",
          text: "Permissions saved.",
        });
      }
    );
  };

  submitUserData(user: {
    firstName;
    lastName;
    email;
    selectedPerms;
    status;
    deactivate;
    id;
    facilityGroup;
  }) {
    let isNewUser = user.id === -1;
    let shouldBeDeactivated = user.deactivate;
    let updatedPermissions = user.selectedPerms;

    this.setState({ showLoading: true }, () => {
      if (isNewUser) {
        return this.createUserAccount(user);
      } else if (shouldBeDeactivated) {
        return this.deactivateUserAccount(user);
      } else {
        return this.adjustPermissions(updatedPermissions);
      }
    });
  }

  renderFilterCardFields() {
    return (
      <FilterCard
        fields={[
          {
            label: "First Name",
            key: "FirstName",
            type: FIELD_TYPE.TEXT,
          },
          {
            label: "Last Name",
            key: "LastName",
            type: FIELD_TYPE.TEXT,
          },
          {
            label: "Email",
            key: "Email",
            type: FIELD_TYPE.TEXT,
          },
          {
            label: "Permissions",
            key: "Permissions",
            type: FIELD_TYPE.SELECT,
            options: this.state.permGroups,
            isMapped: true,
            isMulti: true,
            textType: "text",
          },
          {
            label: "Facility Group",
            key: "FacilityGroups",
            type: FIELD_TYPE.SELECT,
            options: this.state.oktaGroups,
            isMapped: true,
            isMulti: true,
            textType: "text",
          },
          {
            label: "Status",
            key: "Status",
            type: FIELD_TYPE.SELECT,
            options: [
              "STAGED", // - New users created through the API and not activated yet
              "PROVISIONED", // - Admin manually activate the user account. The user is in an active state, but the user has not completed the activation process
              "ACTIVE", // - Active status
              "RECOVERY", // - Existing user, activated previously, is in password reset mode
              "PASSWORD_EXPIRED", // - User password is expired
              "LOCKED_OUT", // - User exceeds the number of login attempts defined in the login policy
              "SUSPENDED", // - User cannot access applications, including the dashboard/admin..
              "DEACTIVATED", // - Deactivated in Okta
            ],
            isMulti: true,
            textType: "text",
          },
        ]}
        filterChanged={(e) => this.setState({ filter: e })}
      />
    );
  }

  handleExportToCSV() {
    this.setState({ showLoading: true }, async () => {
      await NetworkUtil.downloadCSV("/api/admin/user/csvExport", "Users.xlsx", {
        users: this.state.queryResults,
      });
      this.setState({ showLoading: false });
    });
  }

  useSorter(col: Column) {
    let sorter = new Sorter<any>()
      .withRawSorterFunc("permissions", (a, b) => {
        let aPermissions = a.permissions[0]["desc"];
        let bPermissions = b.permissions[0]["desc"];
        return aPermissions.localeCompare(bPermissions);
      })
      .withRawSorterFunc("facilityGroupID", (a, b) => {
        let aGroup: { label; value } = this.state.oktaGroups.find(
          (af) => af.value === a.facilityGroupID
        );
        let bGroup: { label; value } = this.state.oktaGroups.find(
          (af) => af.value === b.facilityGroupID
        );
        if (!aGroup) return -1;
        if (!bGroup) return 1;
        return aGroup && bGroup ? aGroup.label.localeCompare(bGroup.label) : 0;
      });
    this.setState({
      tableResults: sorter.sortByKey(
        this.state.tableResults,
        col.key as keyof any,
        this.state.direction
      ),
      direction: this.state.direction === "asc" ? "desc" : "asc",
    });
  }

  resendActivationEmail = async (selectedUser) => {
    let id = selectedUser.id;
    let response = await UsersAPI.reactivateUserAccount(id);

    if (!response.success) {
      this.setState({ showLoading: false });
      return sweetalert.fire({
        icon: "error",
        title: "",
        text: response.reason,
      });
    }

    this.filterUsers(this.state.selected_page.value);
    return sweetalert.fire({
      icon: "success",
      title: "",
      text: "Activation email sent.",
    });
  };

  resetPasswordEmail = async (selectedUser) => {
    let id = selectedUser.id;
    let response = await UsersAPI.resetUserPassword(id);

    if (!response.success) {
      this.setState({ showLoading: false });
      return sweetalert.fire({
        icon: "error",
        title: "",
        text: response.reason,
      });
    }

    this.filterUsers(this.state.selected_page.value);
    return sweetalert.fire({
      icon: "success",
      title: "",
      text: "Password Reset email sent.",
    });
  };

  render() {
    return (
      <React.Fragment>
        <PaginationTool />
        <Overlay show_loading={this.state.showLoading} />
        <UserPermissionsModal
          selectedUser={this.state.selectedUser}
          facilityGroups={this.state.oktaGroups}
          permissionGroups={this.state.permGroups}
          onSubmit={(user) => {
            hideModal(UserPermissionsModal.ID);
            this.submitUserData(user);
          }}
        />
        <div className="container-fluid  min-vh-100 ">
          <div className={"row"}>
            <div className="col-12 col-md-12 col-lg-8 col-xl-5 pt-2">
              <div className="card mb-2">
                <div className="card-header verlag-bold">
                  <h3>User Management</h3>
                </div>
                <div className="card-body">{this.renderFilterCardFields()}</div>
                <div className="card-footer">
                  <button
                    className="btn btn-outline-primary"
                    onClick={() =>
                      this.setState(
                        { selected_page: { label: 1, value: 1 } },
                        () => this.filterUsers(this.state.selected_page.value)
                      )
                    }
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-outline-primary pull-right"
                    onClick={() => showModalNoOutsideClick(UserPermissionsModal.ID)}
                  >
                    Add User
                  </button>
                </div>
              </div>
            </div>
            {this.state.tableResults && this.state.tableResults.length > 0 && (
              <div className="col-12 col-md-12 col-lg-12 pt-2">
                <div className="card mt-2 mb-5">
                  <div className="card-header verlag-bold stickToTop">
                    <h3>
                      Users
                      <section className="tableHeaderSection float-md-right ">
                        <h3 className={"float-md-right"}>
                          Total: {this.state.totalUsers}
                        </h3>
                        <h4 className="d-inline-block float-right align-middle pr-2 ml-5">
                          Page{" "}
                        </h4>
                        <div className=" align-middle float-right pages ">
                          <Select
                            isSearchable={true}
                            placeholder={"1"}
                            noOptionsMessage={() => "No option"}
                            value={this.state.selected_page}
                            onChange={(e: ReactSelect) =>
                              this.setState({ selected_page: e }, () =>
                                this.changePage(e.value)
                              )
                            }
                            className={"state_select page-num-select"}
                            options={this.state.page_options}
                          />
                        </div>
                      </section>
                      <button
                        className={"btn btn-outline-primary ml-3"}
                        onClick={() => this.handleExportToCSV()}
                      >
                        Export to CSV
                      </button>
                    </h3>
                  </div>
                  <div className="card-body p-0 m-0 table-responsive-md">
                    <SimpleTable
                      columns={[
                        {
                          label: "Name",
                          key: "combinedName",
                          rawFormat: (val) => {
                            return (
                              <a
                                href={"#!"}
                                onClick={() => {
                                  if (
                                    val.permissions.find(
                                      (p) => p.id === "00g1wzk5frC57WLuR5d7"
                                    )
                                  ) {
                                    return sweetalert.fire({
                                      icon: "info",
                                      text:
                                        "Global Admins cannot be modified. Please contact " +
                                        "IMMY Technology Group to remove or add Global Admins.",
                                      title: "Attention",
                                    });
                                  }
                                  this.setState({ selectedUser: val }, () =>
                                  showModalNoOutsideClick(UserPermissionsModal.ID)
                                  );
                                }}
                              >
                                {val.combinedName}
                              </a>
                            );
                          },
                          popoverText: "Click to sort by Last Name",
                        },
                        {
                          label: "Email",
                          key: "email",
                          popoverText: "Click to sort by Email",
                        },
                        {
                          label: "Permissions",
                          key: "permissions",
                          rawFormat: (val: { permissions: { id; desc }[] }) => {
                            let perms: string[] = val.permissions.map(
                              (v) => v.desc + ", "
                            );
                            let lastInd = perms.length - 1;
                            if (lastInd >= 0)
                              perms[lastInd] = perms[lastInd].slice(
                                0,
                                perms[lastInd].length - 2
                              ); // Remove last space
                            return <p>{perms}</p>;
                          },
                          popoverText: "Click to sort by Permissions",
                        },
                        {
                          label: "Facility Group",
                          key: "facilityGroupID",
                          rawFormat: (val: {
                            firstName;
                            lastName;
                            email;
                            status;
                            facilityGroupID;
                            permissions;
                            id;
                          }) => {
                            let facility = this.state.oktaGroups.find(
                              (af) => af.value === val.facilityGroupID
                            );

                            return (
                              <p>
                                {facility && facility.label
                                  ? facility.label
                                  : "null"}
                              </p>
                            );
                          },
                          popoverText: "Click to sort by Facility Group",
                        },
                        {
                          label: "Status",
                          key: "status",
                          popoverText: "Click to sort by Status",
                        },
                        {
                          label: "",
                          key: "action",
                          rawFormat: (val) => {
                            if (val.status === "PROVISIONED") {
                              return (
                                <button
                                  type={"button"}
                                  className={"btn btn-outline-success"}
                                  onClick={() =>
                                    this.resendActivationEmail(val)
                                  }
                                >
                                  Re-send Activation Email
                                </button>
                              );
                            }
                            if (val.status === "PASSWORD_EXPIRED") {
                              return (
                                <button
                                  type={"button"}
                                  className={"btn btn-outline-success"}
                                  onClick={() => this.resetPasswordEmail(val)}
                                >
                                  Send Reset Password Email
                                </button>
                              );
                            }
                            return <div />;
                          },
                        },
                      ]}
                      table_data={this.state.tableResults}
                      columnClickedCallback={(col) => {
                        this.useSorter(col);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
