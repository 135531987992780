import {parseDate, toLocalDateTime} from "../util/FormatUtil";
import React from "react";
import {Sample} from "../types/Sample";
import Parser from 'html-react-parser';
import TestType from "../types/TestType"

export interface ResultInfo{
    TestResultDate
    PatientFirstName
    PatientLastName
    PatientDOB
    PatientGender
    PatientPhone
    PatientAddress
    FacilityID
    SpecimenID
    SpecimenSource
    CollectionDate
    ProviderFirstName
    ProviderLastName
    Result
    Clia
    ReportAddress
    ReportDirector
    ReportHelpEmail
    test:TestType
    ReportEntityName
    HighlightResult
    HighlightColor
}
interface ResultProps{
    result?:ResultInfo
    logo
}
interface ResultState{

}

export default  class TestResult extends React.Component<ResultProps, ResultState>{

    constructor(props) {
        super(props);
        this.state = {}
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let result = this.props.result;
        return(
            <div className="row border  min-vh-100">
                <div className="col-12 text-center verlag-bold pt-2">
                   <img src={this.props.logo}  height={90} className="d-inline-block align-top nav-href pr-2" alt=""/>
                </div>
                <div className="col-12 responsive-p m-3">
                    <table className="table table-borderless">
                        <tbody>
                        {/*<tr>*/}
                        {/*    <div className="row">*/}
                        {/*        <div className="col-3">*/}
                        {/*            <th></th>*/}
                        {/*        </div>*/}
                        {/*        <div className="col-3">*/}
                        {/*            <th style={{textAlign: 'left'}}>Test</th>*/}
                        {/*        </div>*/}
                        {/*        <div className="col-6">*/}
                        {/*            <td style={{textAlign: 'left'}}>{result.test ? result.test.Name : ""}</td>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</tr>*/}

                        <tr>
                            <div className="row">
                                <div className="col-3">
                                    <th></th>
                                </div>
                                <div className="col-3">
                                    <th style={{textAlign: 'left'}}>Test Report Date</th>
                                </div>
                                <div className="col-6">
                                    <td style={{textAlign: 'left'}}>{parseDate(result.TestResultDate)}</td>
                                </div>
                            </div>
                        </tr>

                        <tr>
                            <div className="row">
                                <div className="col-3">
                                    <th></th>
                                </div>
                                <div className="col-3">
                                    <th style={{textAlign: 'left'}}>Patient Name</th>
                                </div>
                                <div className="col-6">
                                    <td style={{textAlign: 'left'}}>{result.PatientLastName.toUpperCase()} , {result.PatientFirstName.toUpperCase()}</td>
                                </div>
                            </div>
                        </tr>
                        <tr>
                            <div className="row">
                                <div className="col-3">
                                    <th></th>
                                </div>
                                <div className="col-3">
                                    <th style={{textAlign: 'left'}}>Patient DOB</th>
                                </div>
                                <div className="col-6">
                                    <td style={{textAlign: 'left'}}>{parseDate(result.PatientDOB)}</td>
                                </div>
                            </div>
                        </tr>
                        <tr>
                            <div className="row">
                                <div className="col-3">
                                    <th></th>
                                </div>
                                <div className="col-3">
                                    <th style={{textAlign: 'left'}}>Gender</th>
                                </div>
                                <div className="col-6">
                                    <td style={{textAlign: 'left'}}>{result.PatientGender}</td>
                                </div>
                            </div>
                        </tr>
                        <tr>
                            <div className="row">
                                <div className="col-3">
                                    <th></th>
                                </div>
                                <div className="col-3">
                                    <th style={{textAlign: 'left'}}>Patient Phone</th>
                                </div>
                                <div className="col-6">
                                    <td style={{textAlign: 'left'}}>{result.PatientPhone}</td>
                                </div>
                            </div>
                        </tr>
                        <tr>
                            <div className="row">
                                <div className="col-3">
                                    <th></th>
                                </div>
                                <div className="col-3">
                                    <th style={{textAlign: 'left'}}>Patient Address</th>
                                </div>
                                <div className="col-6">
                                    <td style={{textAlign: 'left'}}>{result["PatientAddress"]}</td>
                                </div>
                            </div>
                        </tr>
                        <tr>
                            <div className="row">
                                <div className="col-3">
                                    <th></th>
                                </div>
                                <div className="col-3">
                                    <th style={{textAlign: 'left'}}>Ordering Facility</th>
                                </div>
                                <div className="col-6">
                                    <td style={{textAlign: 'left'}}>{result.FacilityID}</td>
                                </div>
                            </div>
                        </tr>
                        <tr>
                            <div className="row">
                                <div className="col-3">
                                    <th></th>
                                </div>
                                <div className="col-3">
                                    <th style={{textAlign: 'left'}}>Specimen ID</th>
                                </div>
                                <div className="col-6">
                                    <td style={{textAlign: 'left'}}>{result.SpecimenID}</td>
                                </div>
                            </div>
                        </tr>
                        <tr>
                            <div className="row">
                                <div className="col-3">
                                    <th></th>
                                </div>
                                <div className="col-3">
                                    <th style={{textAlign: 'left'}}>Source</th>
                                </div>
                                <div className="col-6">
                                    <td style={{textAlign: 'left'}}>{result.SpecimenSource}</td>
                                </div>
                            </div>
                        </tr>
                        <tr>
                            <div className="row">
                                <div className="col-3">
                                    <th></th>
                                </div>
                                <div className="col-3">
                                    <th style={{textAlign: 'left'}}>Collection Date</th>
                                </div>
                                <div className="col-6">
                                    <td style={{textAlign: 'left'}}>{toLocalDateTime(result.CollectionDate)}</td>
                                </div>
                            </div>
                        </tr>
                        <tr>
                            <div className="row">
                                <div className="col-3">
                                    <th></th>
                                </div>
                                <div className="col-3">
                                    <th style={{textAlign: 'left'}}>Ordering Physician</th>
                                </div>
                                <div className="col-6">
                                    <td style={{textAlign: 'left'}}>{result.ProviderFirstName + " " + result.ProviderLastName}</td>
                                </div>
                            </div>
                        </tr>
                        </tbody>
                    </table>

                </div>
                <div className={"col-12 col-md-12  text-center border responsive-p verlag-bold "} >
                    <h2>Result:</h2>
                    <p className={(result.HighlightResult ? result.HighlightColor : null)}>{result.Result}</p>
                    <p className={(result.HighlightResult ? result.HighlightColor : null)}>*****</p>

                </div>
                <div className={"col-12  pt-2"}>
                    {this.props.result && this.props.result.test && this.props.result.test.ReportDescription ? Parser(this.props.result.test.ReportDescription) : ""}
                </div>
                <div className="col-6  pt-2 text-center border border-info responsive-p verlag-bold">
                    <p>{this.props.result.ReportEntityName}</p>
                    <p>{this.props.result.ReportAddress}</p>
                    {this.props.result.ReportHelpEmail ? <React.Fragment>
                        <p>E-Mail:</p>
                        <p>{this.props.result.ReportHelpEmail}</p>
                    </React.Fragment>: null}
                </div>
                {this.props.result.test ?
                    <div className="col-6  pt-2 text-center border border-info responsive-p verlag-bold">
                        <h3>CLIA: {this.props.result.Clia}</h3>
                        {/* <br/> */}
                        {/* <p>Laboratory Director</p><p>
                        {this.props.result.ReportDirector}</p> */}
                    </div>
                    : null}
            </div>
        )
    }
}