import React from 'react';
import '../App.css';
import domain from "../Domain";
import InformedConsentModal from "./modals/InformedConsentModal";
import Overlay from "./Overlay";
import ConfirmationMessage from "./modals/ConfirmationMessage";
import {GuardianForm} from "./GuardianForm";
import {PatientInformation} from "./PatientInformation";
import {AppointmentForm} from "./AppointmentForm";
import {ConsentSummary} from "./ConsentSummary";
import {Sample} from "../types/Sample";
import {getToken} from "../util/CaptchaUtil";
import {calculateAge} from "../util/ValidationUtil";
import ReleaseForm from './Release'
import {sweetalert} from "../App";
import {parseDate} from "../util/FormatUtil";
import SubmissionAPI from "../network/SubmissionAPI";
import Validator, {ValidationEntry} from "../validation/Validator";
import {Validators} from "../validation/Validators";
import NetworkUtil from "../network/NetworkUtil";
import {CustomerAPI} from "../network/CustomerAPI";
import {EventAPI} from "../network/EventAPI";


export interface PatientFormState {
    test?
    location: {
        day?: { label: string, value: { day: string, times: { remaining: number, time: string }[], schedID: number, testType } };
        facility: {
            label: string, value: {
                FacilityName?: string
                Address?: string
                days?: { day: string }[]
            }
        },
        time?: { label: string, value: any }
    },
    patientInformation: Sample
    guardian: { GuardianFirstName: string, GuardianLastName: string }
    schedMap?: any[]
    acknowledged
    showLoading: boolean
    release?:any
    token?: string
    consentEntities: {ID:number,Name:string,FacilityID:number,AccessCode:string}[]
    legalName:string
}

class PatientForm extends React.Component<{ isPrivate?, schedMap?, fetchFacilities? }, PatientFormState> {
    constructor(props) {
        super(props);
        this.state = {
            patientInformation: {
                PatientDOB: "",
                OnsetSymptomsDate: "",
                EmployedHealthcare: "",
                PatientFirstName: "",
                FirstTest: "",
                PatientGenderID: "",
                Hospitalized: "",
                ICU: "",
                PatientLastName: "",
                PatientMiddleName: "",
                PatientAddress: "",
                PatientCounty: "",
                PatientEmail: "",
                PatientEthnicityID: "",
                PatientPhone: "",
                PatientRaceID: "",
                PatientZip: "",
                Pregnant: "",
                ResidentCongregateCare: "",
                Symptomatic: ""
            } as Sample,
            guardian: {GuardianFirstName: "", GuardianLastName: ""},
            location: {facility: null},
            acknowledged: "false",
            showLoading: false,
            test: {label: "", value: 1},
            consentEntities: [],
            legalName:""
        };
        this.submit = this.submit.bind(this);
        this.sendForm = this.sendForm.bind(this);
        this.assignClearState = this.assignClearState.bind(this);
    }

    fetchFacilities() {

        if (this.props.fetchFacilities)
            return this.props.fetchFacilities();

        this.setState({showLoading: true}, () => {
            EventAPI.getEvents().then(data =>{
                this.setState({showLoading: false, schedMap:data.schedMap});
            })

        })
    }

    componentDidMount(): void {
        if (!this.props.isPrivate)
            this.fetchFacilities()
        CustomerAPI.getPolicyName().then(data => {
            this.setState({legalName: data.name})
        })
    }

    async submit() {

        if(!PatientInformation.validatePatient(this.state.patientInformation)) {
            this.setState({showLoading: false});
            return;
        }

        let patientFormValidation = {
            Facility: this.state.location.facility,
            Time: this.state.location.time,
            Day: this.state.location.day,
            PatientDOB: this.state.patientInformation.PatientDOB,
            GuardianFirstName: this.state.guardian.GuardianFirstName,
            GuardianLastName: this.state.guardian.GuardianLastName,
            Acknowledged: this.state.acknowledged
        }

        let validator = new Validator<any>()
            .withSimpleValidation("Facility", Validators.requireNonNullValidator("Collection Site"))
            .withSimpleValidation("Day", Validators.requireNonNullValidator())
            .withSimpleValidation("Time", Validators.requireNonNullValidator())
            .withValidation("PatientDOB", Validators.requireDOB(150, "Patient Date of Birth"))
            if(calculateAge(this.state.patientInformation.PatientDOB) < 18){
                validator = validator.withValidation("GuardianFirstName", Validators.requireNotBlankAndLength(50, "Guardian First Name"))
                    .withValidation("GuardianLastName", Validators.requireNotBlankAndLength(50, "Guardian Last Name"))
            }
            validator = validator.withComposedValidation("Acknowledged", new ValidationEntry(Validators.requireNonNullValidator()), new ValidationEntry<any>((key, attr, obj) => {
                if(obj.Acknowledged === "false" || !obj.Acknowledged){
                    return {success: false, error: "You must acknowledge all policies listed in this form"};
                }
                return {success: true, error: null}
            }))
        let validationResponse = validator.validate(patientFormValidation);
        if(!validationResponse.success) {
            return sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
        }

        ConfirmationMessage.display();
    }

    async sendForm() {
        ConfirmationMessage.hide();
        this.setState({showLoading: true}, async () => {
            let token:string = await getToken();
            let obj: PatientFormState | any = JSON.parse(JSON.stringify(this.state));



            Object.keys(obj["patientInformation"]).forEach(k => {
                obj["patientInformation"][k] = obj["patientInformation"][k].value ? obj["patientInformation"][k].value : obj["patientInformation"][k];
            });
            let serviceID = obj.location.service.value;

            delete obj.location.day.value.times;
            delete obj.location.facility.days;
            obj.location = {
                facility: {id: obj.location.facility.value.id},
                day: obj.location.day.value,
                time: obj.location.time.value
            };
            obj.token = token;
            obj.release = this.state.release;
            obj.test = this.state.test ? this.state.test.value : 1;
            let patient:Sample = obj.patientInformation;

            patient.PatientMiddleName = patient.PatientMiddleName ? patient.PatientMiddleName : null;

            delete obj.schedMap;
            delete obj['confirmEmail'];
            delete obj.schedule;



            if(obj.guardian && obj.guardian.GuardianFirstName){
                patient.GuardianFirstName = obj.guardian.GuardianFirstName;
                patient.GuardianLastName = obj.guardian.GuardianLastName;
            }

            obj.location = {
                facilityID: obj.location.facility.id,
                podID: obj.location.day.schedID,
                time: obj.location.time
            }

            console.log("Post OBJ:");
            console.log(obj);

            try {
                let result = await SubmissionAPI.patientForm({
                    patientInformation:obj.patientInformation,
                    location:obj.location,
                    token:obj.token,
                    release: obj.release
                })
                if (result.success) {
                    let serviceParam = 'service='+ serviceID;
                    sweetalert.fire({icon: 'success', title: '', text: "An email has been sent confirming your appointment"})
                        .then(function () {
                            let codeParam = 'code=' + result.ReqNum;
                            let urlString = '/confirmation?'+codeParam + '&' + serviceParam;
                            console.log(' urlString', urlString)
                            window.location = urlString as any;
                        });
                }else{
                    this.setState({showLoading:false}, () =>
                        sweetalert.fire({icon: 'error', title: '', text: result.reason}));
                    if(result.timeTaken){
                        this.clearAppointmentState();
                        this.fetchFacilities()
                        this.setState({location:{facility:null, time:null, day:null}})
                    }
                }
            }
            catch (e) {
                sweetalert.fire({icon: 'error', title: '', text: "We are unable to process your request at this time. Please try again later. Your appointment was not saved"});
                this.setState({showLoading: false});
                console.log(e);
            }
        })
    }
    clearAppointmentState:() => void = null;

    assignClearState(func){
        this.clearAppointmentState = func;
    }
    render() {
        let schedMap = this.state.schedMap;
        if (this.props.isPrivate)
            schedMap = this.props.schedMap;
        let dob:Date = this.state.patientInformation.PatientDOB;
        return (
            <React.Fragment>
                <Overlay show_loading={this.state.showLoading}/>
                <InformedConsentModal legalName={this.state.legalName} />
                <ConfirmationMessage email={this.state.patientInformation.PatientEmail}
                                     fname={this.state.patientInformation.PatientFirstName}
                                     lname={this.state.patientInformation.PatientLastName}
                                     DOB={parseDate(this.state.patientInformation.PatientDOB)}
                                     phone={this.state.patientInformation.PatientPhone}
                                     onSubmit={this.sendForm}
                />
                <div className="container-fluid  min-vh-100 ">
                    <div className={"row"}>
                        <div className=" col-12 col-sm-12  col-md-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 pt-2">
                            <div className="card mb-2">
                                <div className="card-header verlag-bold">
                                    <h3>Important Information</h3>
                                </div>
                                <div className="card-body verlag-light responsive-p">
                                    <ul>
                                        <li><b>This is for the Covid-19 Test, NOT the vaccine.</b></li>
                                        <li><p>Once you have submitted your appointment, you will receive an email with
                                            a confirmation code. You will need this code to obtain your results later.
                                            If you do not receive a code after making an appointment, please check your
                                            junk and spam.</p></li>
                                        <li><p>Testing is provided free of charge.</p></li>
                                        <li><p>Individuals below the age of 18 must be accompanied by a legal guardian
                                            that will be present during the appointment.</p></li>
                                        <li><p>Correct information is critical for obtaining your results as soon as
                                            possible.</p></li>
                                        <li><p>If you are having trouble submitting your request, please try a different
                                            browser such as Chrome, Firefox, or Microsoft Edge.</p></li>
                                    </ul>
                                    <p><b>Please submit one form per person.</b></p>
                                </div>
                            </div>
                            {/*<Schedule data={this.state.schedule}/>*/}
                            <AppointmentForm passClearStateFunc={this.assignClearState} schedMap={schedMap} onChange={(e) => this.setState({location: e})}/>
                            <PatientInformation onChange={(e) => this.setState({patientInformation: e})}/>
                            <GuardianForm shouldDisplay={dob ? calculateAge(dob) < 18 : false}
                                          age={dob ? calculateAge(dob) : -1}
                                          onChange={(e) => this.setState({guardian: e})}/>
                            <ReleaseForm releaseChanged={(r) => this.setState({release: r})}/>
                            <ConsentSummary legalName={this.state.legalName} onAcknowledge={(e) => this.setState({acknowledged:e})} onSubmit={this.submit}
                                            facility={this.state.location.facility ? this.state.location.facility.value : null}
                                            day={this.state.location.day ? this.state.location.day.value.day : null}
                                            time={this.state.location.time ? this.state.location.time.value : null} test={"Covid-19"}/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default PatientForm;