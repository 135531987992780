import React from "react";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import ModalForm from "./ModalForm";
import domain from "../../Domain";
import Form from "../../types/Form";
import Select from "react-select";
import {FaCameraRetro} from "react-icons/fa";
import BarcodeScannerComponent from "../BarcodeScannerComponent";
import {sweetalert} from "../../App";
import DatePicker from 'react-datepicker'
import moment from "moment";
import TestType from "../../types/TestType";
import SystemAPI from "../../network/SystemAPI";
import { showModalNoOutsideClick } from "../../util/FormatUtil";

export interface ChangeVals{
    FirstName?:string
    LastName?:string
    DOB?:string
    SpecimenID?:string
    SpecimenSourceID?
    Lot?
    TestID?
}

interface CheckinProps extends ChangeVals{
    onChange:(obj:ChangeVals) => void
    onSubmit:() => void
    tests?:TestType[]
    sources?
}
interface CheckinModalState{
    selectedSpecimenSource?
    showScanner?:boolean
    selectedTestID?:{label, value} | any
}
class CheckinPatientModal extends React.Component<CheckinProps, CheckinModalState>{
    public formRef: React.RefObject<ModalForm>;

    constructor(props) {
        super(props);
        this.state ={showScanner:false}
        this.formRef = React.createRef()
        this.handleScan = this.handleScan.bind(this);
        this.getTestFromID = this.getTestFromID.bind(this);
    }

    getTestFromID(id){
        let test:TestType = this.props.tests.find(f => f.ID === id);
        return test ? ({label:test.Name, value:test.ID}) : ""
    }

    componentWillReceiveProps(nextProps: Readonly<CheckinProps>, nextContext: any) {
        this.setState({
            selectedSpecimenSource:nextProps.SpecimenSourceID ? this.props.sources.find(f => f.value === nextProps.SpecimenSourceID) : "",
            selectedTestID: nextProps.TestID ? this.getTestFromID(nextProps.TestID): "",
        })
    }

    public static display(){
        console.log("Display called")
        window["$"]("#" + CheckinPatientModal.ID).modal('show');
        setTimeout(() =>{
            $("#SpecimenID").triggerHandler( "focus" );
        }, 500)
    }

    componentDidMount(): void {
        window["$"]("#" + CheckinPatientModal.ID).on('shown.bs.modal', function () {
         $('#SpecimenID').triggerHandler( "focus" );
        })
    }

    showBarcodeScanner = (e) => {
        this.setState({showScanner: true})
    }

    handleScan(scan) {
        if(!scan){
            this.setState({showScanner: false}); return;
        }
        sweetalert.fire({
            title: "Scan Captured",
            html: "Please verify <b> " + scan.text + " </b>  matches the barcode.",
            icon: "info",
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonText: 'Confirm',
            confirmButtonColor: '#00539B', //immy-blue
            cancelButtonText: 'Cancel',
            cancelButtonColor: '#a7a9ac' // immy-gray
        }).then((result)=>{
            if(result.isConfirmed){
                this.props.onChange({SpecimenID: scan.text})
                this.setState({showScanner: false})
            }
            if(result.isDismissed){
                //keep scanner div open so user can hit Scan
                this.setState({showScanner: true})
            }
        });
    }

    public static readonly ID = "checkin"
    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let specIDString = "Specimen ID";
        let validSources = [];
        if(this.state.selectedTestID){
            let test:TestType = this.props.tests.find(t => t.ID === this.state.selectedTestID.value);
            if(test && !test.IsLabTest){
                specIDString = "Specimen ID (optional)"
            }
            validSources = this.props.sources.filter(s => test.ValidSpecimenSources ? test.ValidSpecimenSources.find(v => v.ID === s.value) : []);
        }
        return (
            <div className="modal fade form_modal" id={CheckinPatientModal.ID} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                        {/*<Modal_Header title={title}/>*/}
                        <div className="modal-header">
                            <h5 className="modal-title" id="result_modal_label">Patient Check-in</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body mr-3">
                            <div className="form-group row">
                                <label htmlFor={"FirstName"} className={"col-sm-4 col-form-label"}>First Name</label>
                                <div className={"col-sm-8 p-0 m-0"} id={"FirstName"}>
                                    <input type={"search"} className="form-control" readOnly={false} id={"FirstName"}
                                           value={this.props.FirstName}
                                           autoComplete="off"
                                           onChange={(e) => this.props.onChange({FirstName:e.target.value})}
                                           maxLength={50}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor={"LastName"} className={"col-sm-4 col-form-label"}>Last Name</label>
                                <div className={"col-sm-8 p-0 m-0"} id={"LastName"}>
                                    <input type={"search"} className="form-control" readOnly={false} id={"LastName"}
                                           value={this.props.LastName}
                                           autoComplete="off"
                                           onChange={(e) => this.props.onChange({LastName:e.target.value})}
                                           maxLength={50}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor={"DOB"} className={"col-sm-4 col-form-label"}>DOB</label>
                                <div className={"col-sm-8 p-0 m-0"} id={"DOB"}>

                                    <DatePicker
                                        minDate={new Date('01-01-1000')}
                                        maxDate={new Date('12-31-9999')}
                                        placeholderText="--/--/----"
                                        selected={this.props.DOB ? Date.parse(moment(this.props.DOB, 'MM-DD-YYYY').toISOString()) : null}
                                        onChange={(e) => this.props.onChange({DOB: e})}
                                    />

                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor={"TestType"} className={ "col-sm-4 col-form-label"}>Test Type</label>
                                <div className={"col-sm-8 p-0 m-0 "} id={"TestType"}>

                                    <Select
                                        placeholder={"Please Select..."}
                                        value={this.state.selectedTestID}
                                        onChange={(e)=>{
                                            this.setState({selectedTestID:e, selectedSpecimenSource:""}, () =>{
                                                this.props.onChange({TestID:e.value, SpecimenSourceID:null})
                                            })
                                        }}
                                        className={"state_select"}
                                        options={this.props.tests.map(t => ({label:t.Name, value:t.ID}))}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor={"SpecimenID"} className={"col-sm-4 col-form-label"}>{specIDString}</label>
                                <div className={"col-sm-8 p-0 m-0 input-group"}>
                                    <button className={"btn btn-primary btn-sm"}
                                            onClick={this.showBarcodeScanner}
                                            disabled={!this.state.selectedTestID}
                                    ><FaCameraRetro/></button>
                                    <input type={"search"} className="form-control" readOnly={false} id={"SpecimenID"}
                                           value={this.props.SpecimenID}
                                           autoComplete="off"
                                           onChange={(e) => this.props.onChange({SpecimenID:e.target.value})}
                                           disabled={!this.state.selectedTestID}
                                           maxLength={50}
                                    />
                                </div>
                            </div>
                            { this.state.showScanner ?
                                <BarcodeScannerComponent
                                    onResult={this.handleScan}
                                    showButtons={true}
                                    started={false}
                                />
                                : null
                            }
                            <div className="form-group row">
                                <label htmlFor={"Lot"} className={"col-sm-4 col-form-label"}>Lot (Optional)</label>
                                <div className={"col-sm-8 p-0 m-0"} id={"Lot"}>
                                    <input type={"search"} className="form-control" readOnly={false} id={"Lot"}
                                           value={this.props.Lot}
                                           autoComplete="off"
                                           onChange={(e) => this.props.onChange({Lot:e.target.value})}
                                           maxLength={45}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor={"Specimen Source"} className={ "col-sm-4 col-form-label"}>Specimen Source</label>
                                <div className={"col-sm-8 p-0 m-0 "} id={"Specimen Source"}>
                                    <Select
                                        placeholder={"Please Select..."}
                                        value={this.state.selectedSpecimenSource}
                                        onChange={(e)=>{
                                            this.setState({selectedSpecimenSource:e}, () =>{
                                                this.props.onChange({SpecimenSourceID:e.value})
                                            })
                                        }}
                                        className={"state_select"}
                                        isDisabled={!this.state.selectedTestID}
                                        options={validSources}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => this.props.onSubmit()}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CheckinPatientModal;