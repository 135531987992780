import React from "react";
import ModalMessage from "./ModalMessage";


interface ConsentProps{
    onConfirmation:(didAccept) => void
    entity:{ID:number,Name:string,FacilityID:number,AccessCode:string}
}
class ReleaseConsent extends React.Component<ConsentProps, any> {

    public static readonly ID = "release_consent"

    constructor(props) {
        super(props);
        this.confirm = this.confirm.bind(this);
    }

    confirm(didAccept){
        this.props.onConfirmation(didAccept);
        window['$']("#" + ReleaseConsent.ID).modal('hide')
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div className="modal fade form_modal" id={ReleaseConsent.ID} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true"
                 data-keyboard="false" data-backdrop="static">
                <div className="modal-dialog modal-lg modal-xl"  role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="result_modal_label">Informed Consent</h5>
                        </div>
                        <div className="modal-body ">
                            <p>
                                <ol>
                                    <li>
                                        AUTHORIZATION TO DISCLOSE PROTECTED HEALTH INFORMATION

                                        <p>
                                            <b> Please read this entire form before acknowledging your decisions relating to the disclosure of protected health information.</b>
                                            Covered entities as that term is defined by HIPAA must obtain a signed authorization from the individual or the individual’s legally authorized representative to disclose that individual’s protected health information. Authorization is not required for disclosures related to treatment, payment, health care operations, performing certain insurance functions, or as may be otherwise authorized by law. Individuals cannot be denied treatment based on a failure to sign this authorization form, and a refusal to sign this form will not affect the payment, enrollment, or eligibility for benefits.
                                        </p>
                                        <p>
                                            I authorize IMMYTech, LLC, 2701 Corporate Centre Drive, Norman, OK 73069 to disclose my Protected Health Information to the following:
                                            <br/>
                                            <b>{this.props.entity.Name}</b>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b>WHAT INFORMATION CAN BE DISCLOSED?  </b>
                                            Lab results related to a positive confirmation of the novel coronavirus (SARS-CoV-2 or COVID-19)
                                        </p>

                                    </li>
                                    <li>
                                        <p>
                                            <b>WHAT IS THE PURPOSE FOR DISCLOSING?   </b>
                                            To notify the School that the Individual attends or works at, in order that the School and the Oklahoma State Department of Health to take measures that may slow the spread of the novel coronavirus (SARS-CoV-2 or COVID-19).
                                        </p>

                                    </li>
                                    <li>
                                        <p>
                                            <b>RIGHT TO REVOKE: </b>
                                            I understand that I can withdraw my permission at any time by giving written notice stating my intent to revoke this authorization to the School identified above.  I understand that prior actions taken in reliance on this authorization by entities that had permission to access my health information will not be affected and that I cannot restrict information that may have already been shared based on this authorization.
                                        </p>

                                    </li>
                                    <li>
                                        <p>
                                            <b>RIGHT TO RECEIVE A COPY:  </b>
                                            I understand that I have the right to receive a copy of this authorization.
                                        </p>

                                    </li>
                                    <li>
                                        <p>
                                            <b>NATURE OF INFORMATION:    </b>
                                            I understand that the medical information may indicate that the patient has a communicable and/or non-communicable disease which may include, but is not limited to diseases such as the novel coronavirus, hepatitis, syphilis, gonorrhea or HIV or AIDS and/or may indicate that I have or have been treated for psychological or psychiatric conditions or substance abuse.
                                        </p>

                                    </li>
                                    <li>
                                        <p>
                                            <b>SIGNATURE AUTHORIZATION:    </b>
                                            I have read this form and agree to the uses and disclosures of the information as described. I understand that refusing to sign this form does not stop disclosure of health information that has occurred prior to revocation or that is otherwise permitted by law without my specific authorization or permission, including disclosures to the Oklahoma State Department of Health or covered entities as provided by Oklahoma State Law and/or 45 C.F.R. § 164.502(a)(1). I understand that information disclosed pursuant to this authorization may be subject to re-disclosure by the recipient and may no longer be protected by federal or state privacy laws.
                                        </p>

                                    </li>
                                    <li>
                                        <p>
                                            <b>IF BEING COMPLETED ON BEHALF OF A MINOR:   </b>
                                            I affirm that I have legal authority, based on my relationship to the minor, to authorize disclosure of the minor’s protected health information.
                                        </p>

                                    </li>

                                </ol>

                            </p>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={() => this.confirm(true)}>I Consent</button>
                            <button type="button" className="btn btn-danger" onClick={() => this.confirm(false)}>I Decline</button>
                        </div>
                    </div>
                </div>
            </div>)
    }
}
export default ReleaseConsent
