import NetworkUtil from "./NetworkUtil";
import {Sample} from "../types/Sample";
import TestType from "../types/TestType"
import {ResultType} from "../types/ResultType";
import {ServerResponse} from "../types/ServerResponse";
import {Service} from "../types/Service";

export default class TestsAPI {

    public static async getAllTestDetails():Promise<{success:boolean, reason?:string, tests?:TestType[]}>{
        return NetworkUtil.makeGet("/api/admin/tests/all");
    }

    public static async getValidSpecimenSources():Promise<{label:string, value:number}[]>{
        return NetworkUtil.makeGet("/api/admin/validSpecimenSource");
    }

    public static async getValidResultTypes():Promise<{label:string, value:number}[]>{
        return NetworkUtil.makeGet("/api/admin/validResultTypes");
    }

    public static async newTest(body):Promise<ServerResponse>{
        return await NetworkUtil.makePost("/api/admin/tests/new", body);
    }

    public static async editTest(body):Promise<ServerResponse>{
        return await NetworkUtil.makePost("/api/admin/tests/edit", body);
    }

    public static async queryTest(body):Promise<{success:boolean, test:TestType}>{
        return await NetworkUtil.makePost("/api/admin/tests/details", body);
    }

}