import React from "react";
import ModalForm from "./ModalForm";
import {Sample, SampleEdit} from "../../types/Sample";
import {isPossiblePhoneNumber} from 'react-phone-number-input'
import * as EmailValidator from "email-validator";
import PhoneInput from 'react-phone-number-input'
import Select from "react-select";
import domain from "../../Domain";
import {parseDate, toLocalDateTime, getDateOrNull, showModalNoOutsideClick} from "../../util/FormatUtil";
import moment from "moment";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import {AiOutlineDown, AiOutlineUp} from "react-icons/ai";
import Overlay from "../Overlay";
import {calculateAge, isBlank} from "../../util/ValidationUtil";
import {sweetalert} from "../../App";
import SamplesAPI, {SampleCheckinBody} from "../../network/SamplesAPI";
import Validator, {ValidationEntry} from "../../validation/Validator";
import {Validators} from "../../validation/Validators";
import TestType from "../../types/TestType";


interface ResultProps{
    result?:Sample
    states?
    countries?
    ethnicity?
    race?
    gender?
    specimen_source?
    validSpecimenSources?
    tests?
    testDetails?
    result_types?
    validResultTypes?
    facilities?
    pods?
    binIDs?
    batches?
    getResultsButtonBoolean?:boolean
    isGlobal?
    isAdmin?
    onSubmit:() => void
}

interface ResultState{
    sampleID?
    reqNum?
    specimenID?
    internalSpecimenID?
    memo?
    patientFirstName?
    patientMiddleName?
    patientLastName?
    patientDOB?
    patientZip?
    patientCity?
    patientState?
    patientCounty?
    patientCountry?
    patientAddress?
    patientEmail?
    patientPhone?
    patientEthnicityID?
    patientRaceID?
    patientGenderID?
    guardianFirstName?
    guardianLastName?
    providerFirstName?
    providerLastName?
    bin?
    binID?
    batchNum?
    batchNums?
    wellSlot?
    collectionDate?
    specimenSourceID?
    testID?
    firstTest?
    employedHealthcare?
    symptomatic?
    onsetSymptomsDate?
    hospitalized?
    icu?
    residentCongregateCare?
    pregnant?
    processedDate?
    receivedBy?
    droppedDate?
    testOrderedDate?
    apptTime?
    podApptID?
    apptCanceled?
    rerunCount?
    rerunFlag?
    consentFlag?
    resultID?
    reason?
    testResultDate?
    reportedDate?
    createdDate?
    facilityID?
    resultsAccessedDate?
    //pod?: number
    facilityName?
    patientEthnicity?
    patientRace?
    patientGender?
    specimenSource?
    test?
    podLabel?
    resultLabel?
    stateLabel?
    countryLabel?
    firstTestLabel?
    employedInHealthcareLabel?
    symptomaticLabel?
    hospitalizedLabel?
    icuLabel?
    residentCongregateCareLabel?
    pregnantLabel?
    apptCanceledLabel?
    rerunFlagLabel?
    consentFlagLabel?
    resultApprovedDate?
    createdBy?
    lastUpdatedBy?
    lastUpdatedDate?
    lot?
    patientArrowDown?
    sampleArrowDown?
    facilityArrowDown?
    additionalArrowDown?
    isLabTest?:boolean
    submitClicked?:boolean
    showLoading?:boolean
    validResultTypes?
    validSpecimenSources?
}

export class SamplesModal extends React.Component<ResultProps, ResultState>{
    public formRef: React.RefObject<ModalForm>;

    constructor(props) {
        super(props);
         this.state = {
             sampleID: null,
             reqNum: '',
             specimenID: '',
             internalSpecimenID: '',
             patientFirstName: '',
             patientMiddleName: '',
             patientLastName: '',
             patientDOB: '',
             patientZip: '',
             patientCity: '',
             patientState: null,
             patientCounty: '',
             patientCountry: '',
             patientAddress: '',
             patientEmail: '',
             patientPhone: null,
             patientEthnicityID: null,
             patientRaceID: null,
             patientGenderID: null,
             guardianFirstName: '',
             guardianLastName: '',
             providerFirstName: '',
             providerLastName: '',
             binID: '',
             bin: null,
             batchNum: '',
             batchNums: null,
             wellSlot: '',
             collectionDate: '',
             specimenSourceID: null,
             testID: null,
             firstTest: '',
             employedHealthcare: '',
             symptomatic: '',
             onsetSymptomsDate: '',
             hospitalized: '',
             icu: '',
             residentCongregateCare: '',
             pregnant: '',
             processedDate: '',
             receivedBy: '',
             droppedDate: '',
             createdDate: '',
             testOrderedDate: '',
             apptTime: '',
             podApptID: null,
             apptCanceled: null,
             rerunCount: null,
             rerunFlag: null,
             consentFlag: null,
             resultID: null,
             reason: '',
             testResultDate: '',
             reportedDate: '',
             facilityID: null,
             resultsAccessedDate: '',
             facilityName: null,
             patientEthnicity: null,
             patientRace: null,
             patientGender: null,
             specimenSource: null,
             test: null,
             podLabel: null,
             resultLabel: null,
             stateLabel: null,
             countryLabel: null,
             firstTestLabel: null,
             employedInHealthcareLabel: null,
             symptomaticLabel: null,
             hospitalizedLabel: null,
             icuLabel: null,
             residentCongregateCareLabel: null,
             pregnantLabel: null,
             apptCanceledLabel: null,
             rerunFlagLabel: null,
             consentFlagLabel: null,
             resultApprovedDate: '',
             createdBy: '',
             lastUpdatedBy: '',
             lastUpdatedDate: '',
             lot: '',
             patientArrowDown: false,
             sampleArrowDown: false,
             facilityArrowDown: false,
             additionalArrowDown: false,
             isLabTest: false,
             submitClicked: false,
             memo: '',
             showLoading: false,
         };
        this.formRef = React.createRef()
        this.isResultDisabled = this.isResultDisabled.bind(this);
    }

    public static display(){
        window["$"]("#" + SamplesModal.ID).modal('show');
    }

    getLabel(id, searchArray){
        let temp = searchArray.find(t => t.value === id)
        if(!temp){
            return null;
        }
        return {label: temp.label, value: id};
    }

    getSpecimenSource(id){
        let ss = this.state.validSpecimenSources.find(s => s.value.SpecimenSourceID === id)
        if(!ss){
            return null;
        }
        return {label: ss.label, value: ss.value.SpecimenSourceID}
    }

    getTest(id){
        let test = this.props.testDetails.find(t => t.ID === id)
        if(!test){
            return null;
        }
        this.setState({isLabTest: test.IsLabTest})
        return {label: test.Name, value: id}
    }

    getResult(id){
        let result = this.state.validResultTypes.find(res => res.value.ResultID === id)
        if(!result){
            return null;
        }
        return {label: result.label, value: result.value.ResultID}
    }

    getBinID(id){
        let binID = this.props.binIDs.find(res => res.value.ID === id)
        if(!binID){
            return null;
        }
        return {label: binID.label, value: binID.value}
    }

    getStateLabel(val){
        let state = this.props.states.find(s => s.value == val)
        if(!state){
            return null;
        }
        return {label: state.label, value: val}
    }

    getBatchNum(id){
        let batchNum = this.props.batches.find(res => res.value.ID === id)
        if(!batchNum){
            return null;
        }
        return {label: batchNum.label, value: batchNum.value}
    }

    getYesNoOptions(){
        return [
            {value: "Yes", label: "Yes"},
            {value: "No", label: "No"},
            {value: "Unknown", label: "Unknown"}
        ];
    }

    getBoolOptions(){
        return [
            {value: 1, label: "Yes"},
            {value: null, label: "No"}
        ];
    }

    componentWillReceiveProps(nextProps: Readonly<ResultProps>, nextContext: any) {

        nextProps = JSON.parse(JSON.stringify(nextProps))
        let apptTime = nextProps.result.ApptTime
        let fakeDate = new Date("1970/01/01 " + apptTime)

        this.setState({
            sampleID: nextProps.result && nextProps.result.ID ? nextProps.result.ID : '',
            reqNum: nextProps.result && nextProps.result.ReqNum ? nextProps.result.ReqNum : '',
            specimenID: nextProps.result && nextProps.result.SpecimenID ? nextProps.result.SpecimenID : '',
            internalSpecimenID: nextProps.result && nextProps.result.InternalSpecimenID ? nextProps.result.InternalSpecimenID : '',
            memo: nextProps.result && nextProps.result.Memo ? nextProps.result.Memo : '',
            patientFirstName: nextProps.result && nextProps.result.PatientFirstName ? nextProps.result.PatientFirstName : '',
            patientMiddleName: nextProps.result && nextProps.result.PatientMiddleName ? nextProps.result.PatientMiddleName : '',
            patientLastName: nextProps.result && nextProps.result.PatientLastName ? nextProps.result.PatientLastName : '',
            patientDOB: nextProps.result && nextProps.result.PatientDOB ? parseDate(nextProps.result.PatientDOB) : null,
            patientZip: nextProps.result && nextProps.result.PatientZip ? nextProps.result.PatientZip : '',
            patientCity: nextProps.result && nextProps.result.PatientCity ? nextProps.result.PatientCity : '',
            patientState: nextProps.result && nextProps.result.PatientState ? nextProps.result.PatientState : '',
            patientCounty: nextProps.result && nextProps.result.PatientCounty ? nextProps.result.PatientCounty : '',
            patientCountry: nextProps.result && nextProps.result.PatientCountry ? nextProps.result.PatientCountry : '',
            patientAddress: nextProps.result && nextProps.result.PatientAddress ? nextProps.result.PatientAddress : '',
            patientEmail: nextProps.result && nextProps.result.PatientEmail ? nextProps.result.PatientEmail : '',
            patientPhone: nextProps.result && nextProps.result.PatientPhone ? nextProps.result.PatientPhone : '',
            patientEthnicityID: nextProps.result && nextProps.result.PatientEthnicityID ? nextProps.result.PatientEthnicityID : '',
            patientRaceID: nextProps.result && nextProps.result.PatientRaceID ? nextProps.result.PatientRaceID : '',
            patientGenderID: nextProps.result && nextProps.result.PatientGenderID ? nextProps.result.PatientGenderID : '',
            guardianFirstName: nextProps.result && nextProps.result.GuardianFirstName ? nextProps.result.GuardianFirstName : '',
            guardianLastName: nextProps.result && nextProps.result.GuardianLastName ? nextProps.result.GuardianLastName : '',
            providerFirstName: nextProps.result && nextProps.result.ProviderFirstName ? nextProps.result.ProviderFirstName : '',
            providerLastName: nextProps.result && nextProps.result.ProviderLastName ? nextProps.result.ProviderLastName : '',
            binID: nextProps.result && nextProps.result.SampleReceivedID ? nextProps.result.SampleReceivedID : '',
            bin: nextProps.result && nextProps.result.SampleReceivedID ? this.getBinID(nextProps.result.SampleReceivedID) : '',
            batchNum: nextProps.result && nextProps.result.BatchID ? nextProps.result.BatchID : '',
            batchNums: nextProps.result && nextProps.result.BatchID ? this.getBatchNum(nextProps.result.BatchID) : '',
            wellSlot: nextProps.result && nextProps.result.WellSlot ? nextProps.result.WellSlot : '',
            collectionDate: nextProps.result && nextProps.result.CollectionDate ? toLocalDateTime(nextProps.result.CollectionDate) : null,
            specimenSourceID: nextProps.result && nextProps.result.SpecimenSourceID ? nextProps.result.SpecimenSourceID : '',
            testID: nextProps.result && nextProps.result.TestID ? nextProps.result.TestID : '',
            firstTest: nextProps.result && nextProps.result.FirstTest ? nextProps.result.FirstTest : '',
            employedHealthcare: nextProps.result && nextProps.result.EmployedHealthcare  ? nextProps.result.EmployedHealthcare : '',
            symptomatic: nextProps.result && nextProps.result.Symptomatic ? nextProps.result.Symptomatic : '',
            onsetSymptomsDate: nextProps.result && nextProps.result.OnsetSymptomsDate ? parseDate(nextProps.result.OnsetSymptomsDate) : null,
            hospitalized: nextProps.result && nextProps.result.Hospitalized ? nextProps.result.Hospitalized : '',
            icu: nextProps.result && nextProps.result.ICU  ? nextProps.result.ICU : '',
            residentCongregateCare: nextProps.result && nextProps.result.ResidentCongregateCare ? nextProps.result.ResidentCongregateCare : '',
            pregnant: nextProps.result && nextProps.result.Pregnant ? nextProps.result.Pregnant : '',
            processedDate: nextProps.result && nextProps.result.ProcessedDate ? toLocalDateTime(nextProps.result.ProcessedDate) : null,
            receivedBy: nextProps.result &&nextProps.result['ReceivedBy'] ? nextProps.result['ReceivedBy'] : '',
            droppedDate: nextProps.result && nextProps.result.DroppedDate ? toLocalDateTime(nextProps.result.DroppedDate) : null,
            testOrderedDate: nextProps.result && nextProps.result.TestOrderedDate ? toLocalDateTime(nextProps.result.TestOrderedDate) : null,
            apptTime: nextProps.result && nextProps.result.ApptTime ? fakeDate : null,
            podApptID: nextProps.result && nextProps.result.PodApptID? nextProps.result.PodApptID : '',
            apptCanceled: nextProps.result && nextProps.result.ApptCanceled? nextProps.result.ApptCanceled : '',
            rerunCount: nextProps.result && nextProps.result.RerunCount ? nextProps.result.RerunCount : '',
            rerunFlag: nextProps.result && nextProps.result.RerunFlag ? nextProps.result.RerunFlag : '',
            consentFlag: nextProps.result && nextProps.result.ConsentFlag ? nextProps.result.ConsentFlag : '',
            resultID: nextProps.result && nextProps.result.ResultID ? nextProps.result.ResultID : '',
            reason: nextProps.result && nextProps.result.Reason ? nextProps.result.Reason : '',
            testResultDate: nextProps.result && nextProps.result.TestResultDate ? toLocalDateTime(nextProps.result.TestResultDate) : null,
            reportedDate: nextProps.result && nextProps.result.ReportedDate ? toLocalDateTime(nextProps.result.ReportedDate) : null,
            createdDate: nextProps.result && nextProps.result.CreatedDate ? toLocalDateTime(nextProps.result.CreatedDate) : null,
            facilityID: nextProps.result && nextProps.result.FacilityID ? nextProps.result.FacilityID : '',
            resultsAccessedDate: nextProps.result && nextProps.result.ResultsAccessedDate ? toLocalDateTime(nextProps.result.ResultsAccessedDate) : null,
            facilityName: nextProps.result && nextProps.result.FacilityID ? this.getLabel(nextProps.result.FacilityID, this.props.facilities) : '',
            patientEthnicity: nextProps.result && nextProps.result.PatientEthnicityID ? this.getLabel(nextProps.result.PatientEthnicityID, this.props.ethnicity) : '',
            patientRace: nextProps.result && nextProps.result.PatientRaceID ? this.getLabel(nextProps.result.PatientRaceID, this.props.race) : '',
            patientGender: nextProps.result && nextProps.result.PatientGenderID ? this.getLabel(nextProps.result.PatientGenderID, this.props.gender) : '',
            specimenSource: nextProps.result && nextProps.result.SpecimenSourceID ? this.getSpecimenSource(nextProps.result.SpecimenSourceID) : '',
            test: nextProps.result && nextProps.result.TestID ? this.getTest(nextProps.result.TestID) : '',
            podLabel: nextProps.result && nextProps.result.PodApptID ? this.getLabel(nextProps.result.PodApptID, this.props.pods) : '',
            resultLabel: nextProps.result && nextProps.result.ResultID ? this.getResult(nextProps.result.ResultID) : '',
            stateLabel: nextProps.result && nextProps.result.PatientState ? this.getLabel(nextProps.result.PatientState, this.props.states) : '',
            countryLabel: nextProps.result && nextProps.result.PatientCountry ? this.getLabel(nextProps.result.PatientCountry, this.props.countries) : '',
            firstTestLabel: nextProps.result && nextProps.result.FirstTest ? this.getLabel(nextProps.result.FirstTest, this.getYesNoOptions()) : '',
            employedInHealthcareLabel: nextProps.result && nextProps.result.EmployedHealthcare ? this.getLabel(nextProps.result.EmployedHealthcare, this.getYesNoOptions()) : '',
            symptomaticLabel: nextProps.result && nextProps.result.Symptomatic ? this.getLabel(nextProps.result.Symptomatic, this.getYesNoOptions()) : '',
            hospitalizedLabel: nextProps.result && nextProps.result.Hospitalized ? this.getLabel(nextProps.result.Hospitalized, this.getYesNoOptions()) : '',
            icuLabel: nextProps.result && nextProps.result.ICU ? this.getLabel(nextProps.result.ICU, this.getYesNoOptions()) : '',
            residentCongregateCareLabel: nextProps.result && nextProps.result.ResidentCongregateCare ? this.getLabel(nextProps.result.ResidentCongregateCare, this.getYesNoOptions()) : '',
            pregnantLabel: nextProps.result && nextProps.result.Pregnant ? this.getLabel(nextProps.result.Pregnant, this.getYesNoOptions()) : '',
            apptCanceledLabel: nextProps.result && nextProps.result.ApptCanceled ? this.getLabel(nextProps.result.ApptCanceled, this.getBoolOptions()) : '',
            consentFlagLabel: nextProps.result && nextProps.result.ConsentFlag ? this.getLabel(nextProps.result.ConsentFlag, this.getBoolOptions()) : '',
            rerunFlagLabel: nextProps.result && nextProps.result.RerunFlag ? this.getLabel(nextProps.result.RerunFlag, this.getBoolOptions()) : '',
            resultApprovedDate: nextProps.result && nextProps.result.ResultApprovedDate ? toLocalDateTime(nextProps.result.ResultApprovedDate): null,
            createdBy: nextProps.result && nextProps.result.CreatedBy ? nextProps.result.CreatedBy : '',
            lastUpdatedBy: nextProps.result && nextProps.result.LastUpdatedBy ? nextProps.result.LastUpdatedBy : '',
            lastUpdatedDate: nextProps.result && nextProps.result.LastUpdatedDate ? toLocalDateTime(nextProps.result.LastUpdatedDate) : null,
            lot: nextProps.result && nextProps.result.Lot ? nextProps.result.Lot : '',
            validResultTypes: nextProps.result && nextProps.result.TestID ? nextProps.validResultTypes.filter(r => r.value.TestID === nextProps.result.TestID) : nextProps.result_types.filter(r => r.label !== '*null' && r.label !== '<>null'),
            validSpecimenSources: nextProps.result && nextProps.result.TestID ? nextProps.validSpecimenSources.filter(r => r.value.TestID === nextProps.result.TestID) : nextProps.specimen_source.filter(r => r.label !== '*null' && r.label !== '<>null'),
        })
    }

    getEdittedObj() {
        return {
            ID: this.state.sampleID,
            ReqNum: this.state.reqNum ? this.state.reqNum.trim() : null,
            SpecimenID: this.state.specimenID ? this.state.specimenID.trim() : null,
            InternalSpecimenID: this.state.internalSpecimenID ? this.state.internalSpecimenID.trim() : null,
            Memo: this.state.memo ? this.state.memo.trim() : null,
            PatientFirstName: this.state.patientFirstName ? this.state.patientFirstName.trim() : null,
            PatientMiddleName: this.state.patientMiddleName ? this.state.patientMiddleName.trim() : null,
            PatientLastName: this.state.patientLastName ? this.state.patientLastName.trim() : null,
            PatientDOB: getDateOrNull(this.state.patientDOB),
            PatientZip: this.state.patientZip ? this.state.patientZip.trim() : null,
            PatientCity: this.state.patientCity ? this.state.patientCity.trim() : null,
            PatientState: this.state.stateLabel ? this.state.stateLabel.value : null,
            PatientCounty: this.state.patientCounty ? this.state.patientCounty.trim() : null,
            PatientCountry: this.state.countryLabel ? this.state.countryLabel.value : null,
            PatientAddress: this.state.patientAddress ? this.state.patientAddress.trim() : null,
            PatientEmail: this.state.patientEmail ? this.state.patientEmail.trim() : null,
            PatientPhone: this.state.patientPhone ? this.state.patientPhone.trim() : null,
            PatientEthnicityID: this.state.patientEthnicity ? this.state.patientEthnicity.value : null,
            PatientRaceID: this.state.patientRaceID ? this.state.patientRace.value : null,
            PatientGenderID: this.state.patientGender ? this.state.patientGender.value : null,
            GuardianFirstName: this.state.guardianFirstName ? this.state.guardianFirstName.trim() : null,
            GuardianLastName: this.state.guardianLastName ? this.state.guardianLastName.trim() : null,
            ProviderFirstName: this.state.providerFirstName ? this.state.providerFirstName.trim() : null,
            ProviderLastName: this.state.providerLastName ? this.state.providerLastName.trim() : null,
            SampleReceivedID: this.state.binID ? this.state.bin.value.ID : null,
            BatchID: this.state.batchNum ? this.state.batchNums.value.ID : null,
            WellSlot: this.state.wellSlot ? this.state.wellSlot.trim() : null,
            CollectionDate: getDateOrNull(this.state.collectionDate),
            SpecimenSourceID: this.state.specimenSourceID ? this.state.specimenSourceID : null,
            TestID: this.state.test ? this.state.test.value : null,
            FirstTest: this.state.firstTestLabel ? this.state.firstTestLabel.value : null,
            EmployedHealthcare: this.state.employedInHealthcareLabel ? this.state.employedInHealthcareLabel.value : null,
            Symptomatic: this.state.symptomaticLabel ? this.state.symptomaticLabel.value : null,
            OnsetSymptomsDate: getDateOrNull(this.state.onsetSymptomsDate),
            Hospitalized: this.state.hospitalizedLabel ? this.state.hospitalizedLabel.value : null,
            ICU: this.state.icuLabel ? this.state.icuLabel.value : null,
            ResidentCongregateCare: this.state.residentCongregateCareLabel ? this.state.residentCongregateCareLabel.value : null,
            Pregnant: this.state.pregnantLabel ? this.state.pregnantLabel.value : null,
            ProcessedDate: getDateOrNull(this.state.processedDate) ,
            TestOrderedDate: getDateOrNull(this.state.testOrderedDate),
            ApptTime: this.state.apptTime ? moment(this.state.apptTime, 'hh:mm a').format('hh:mm a') : null,
            PodApptID: this.state.podLabel ? this.state.podLabel.value : null,
            ApptCanceled: this.state.apptCanceledLabel ? this.state.apptCanceledLabel.value : null,
            RerunCount: this.state.rerunCount ? this.state.rerunCount : null,
            RerunFlag: this.state.rerunFlagLabel ? this.state.rerunFlagLabel.value : null,
            ConsentFlag: this.state.consentFlagLabel ? this.state.consentFlagLabel.value : null,
            ResultID: this.state.resultID ? this.state.resultLabel.value.ResultID : null,
            Reason: this.state.reason ? this.state.reason.trim() : null,
            TestResultDate: getDateOrNull(this.state.testResultDate),
            ReportedDate: getDateOrNull(this.state.reportedDate),
            CreatedDate: getDateOrNull(this.state.createdDate),
            FacilityID: this.state.facilityID ? this.state.facilityName.value : null,
            ResultsAccessedDate: getDateOrNull(this.state.resultsAccessedDate),
            Lot: this.state.lot ? this.state.lot.trim() : null,
        }
    }

    rapidTestTest(propToTest){
        if(propToTest && !this.props.isGlobal && !this.state.resultID){
            sweetalert.fire({icon: 'error', title: '', text: 'Please assign results of rapid tests through the rapid test management page.'});
            return true
        }
        if(!propToTest && !this.props.isGlobal && this.state.resultID){
            sweetalert.fire({icon: 'error', title: '', text: 'Please assign results of rapid tests through the rapid test management page.'});
            return true
        }
    }

    onSubmit(){
        let rapidTestTestResult = this.rapidTestTest(this.props.result.ResultID)
        if(rapidTestTestResult){ return }

        let validator = new Validator<SampleEdit>()
            .withValidation("PatientFirstName", Validators.requireNotBlankAndLength(50, "Patient First Name"))
            if(this.state.patientMiddleName && this.state.patientMiddleName.trim().length > 0){
                    validator = validator.withValidation("PatientMiddleName", Validators.requireNotBlankAndLength(50, "Patient Middle Name"))
            }
            validator = validator.withValidation("PatientLastName", Validators.requireNotBlankAndLength(50, "Patient Last Name"))
            .withValidation("PatientDOB", Validators.requireDOB(150, "Patient Date of Birth"))
            .withComposedValidation("PatientPhone", new ValidationEntry(Validators.requireNonNullValidator("Patient Phone")), new ValidationEntry(Validators.requirePhone("Patient Phone")))
                .withComposedValidation("PatientEmail", new ValidationEntry(Validators.requireValidEmail("Patient Email")), Validators.requireNotBlankAndLength(100, "Patient Email"))
                .withValidation("PatientAddress", Validators.requireNotBlankAndLength(500, "Patient Address"))
                .withValidation("PatientCity", Validators.requireNotBlankAndLength(100, "Patient City"))
            .withComposedValidation("PatientZip", new ValidationEntry(Validators.requireNotBlankValidator("Patient Zipcode")), new ValidationEntry(Validators.requireZip("Patient Zipcode")))
                .withValidation("PatientCounty", Validators.requireNotBlankAndLength(100, "Patient County"))
        if(this.state.guardianFirstName && this.state.guardianFirstName.trim().length > 0){
            validator = validator.withValidation("GuardianFirstName", Validators.requireNotBlankAndLength(50, "Guardian First Name"))
        }
        if(this.state.guardianLastName && this.state.guardianLastName.trim().length > 0){
            validator = validator.withValidation("GuardianLastName", Validators.requireNotBlankAndLength(50, "Guardian Last Name"))
        }
        if(this.state.rerunCount){
            validator = validator.withValidation("RerunCount", new ValidationEntry<SampleEdit>((key, attr, obj) =>{
                if (isNaN(parseInt(attr))) {
                    return {success:false, error:"Rerun Count must be numeric"};
                }
                else if(attr < 1 || attr > 100){
                    return {success:false, error:"Rerun Count must be between 1 and 100"};
                }
                return {success:true, error:null}
            }))
        }
        if(this.state.reason && this.state.reason.trim().length > 0){
            validator = validator.withValidation("Reason", Validators.requireNotBlankAndLength(500, "Result Reason"))
        }
            validator = validator.withSimpleValidation("SpecimenID", Validators.requireNotBlankIfExistsValidator(this.props.result.SpecimenID, "Specimen ID"))
            validator = validator.withSimpleValidation("InternalSpecimenID", Validators.requireNotBlankIfExistsValidator(this.props.result.InternalSpecimenID, "Internal Specimen ID"))
        if(this.state.internalSpecimenID && this.state.internalSpecimenID.trim().length > 0){
            validator = validator.withValidation("InternalSpecimenID", Validators.requireNotBlankAndLength(50, "Internal Specimen ID"))
        }
            if(this.state.collectionDate){
                validator = validator.withComposedValidation("CollectionDate", new ValidationEntry(Validators.requireNotFutureDate("Collection Date")), new ValidationEntry(Validators.requireNotBlankIfExistsValidator(this.props.result.CollectionDate, "Collection Date")), new ValidationEntry(Validators.requireNotBeforePatientDOB(this.state.patientDOB, "Collection Date")))
            }
            if(this.state.testOrderedDate){
                validator = validator.withComposedValidation("TestOrderedDate", new ValidationEntry(Validators.requireNotFutureDate("Test Ordered Date")), new ValidationEntry(Validators.requireNotBlankIfExistsValidator(this.props.result.TestOrderedDate, "Test Ordered Date")), new ValidationEntry(Validators.requireNotBeforePatientDOB(this.state.patientDOB, "Test Ordered Date")))
            }
            if(this.state.processedDate){
                validator = validator.withComposedValidation("ProcessedDate", new ValidationEntry(Validators.requireNotFutureDate("Processed Date")), new ValidationEntry(Validators.requireNotBeforePatientDOB(this.state.patientDOB, "Processed Date")))
            }
            if(this.state.testResultDate){
                validator = validator.withComposedValidation("TestResultDate", new ValidationEntry(Validators.requireNotFutureDate("Test Result Date")), new ValidationEntry(Validators.requireNotBeforePatientDOB(this.state.patientDOB, "Test Result Date")))
            }
            if(this.state.reportedDate){
                validator = validator.withComposedValidation("ReportedDate", new ValidationEntry(Validators.requireNotFutureDate("Reported Date")), new ValidationEntry(Validators.requireNotBeforePatientDOB(this.state.patientDOB, "Reported Date")))
            }
            if(this.state.resultsAccessedDate){
                validator = validator.withComposedValidation("ResultsAccessedDate", new ValidationEntry(Validators.requireNotFutureDate("Results Accessed Date")), new ValidationEntry(Validators.requireNotBeforePatientDOB(this.state.patientDOB, "Results Accessed Date")))
            }
            validator = validator.withSimpleValidation("TestID", Validators.requireNotBlankIfExistsValidator(this.props.result.TestID, "Test ID"))
            .withSimpleValidation("SpecimenSourceID", Validators.requireNotBlankIfExistsValidator(this.props.result.SpecimenSourceID, "Specimen Source ID"))
            .withSimpleValidation("ProviderFirstName", Validators.requireNotBlankIfExistsValidator(this.props.result.ProviderFirstName, "Provider First Name"))
            .withSimpleValidation("ProviderLastName", Validators.requireNotBlankIfExistsValidator(this.props.result.ProviderLastName, "Provider Last Name"))
            .withSimpleValidation("Lot", Validators.requireNotBlankIfExistsValidator(this.props.result.Lot, "Lot"))
                if(this.state.memo && this.state.memo.trim().length > 0){
                    validator = validator.withSimpleValidation("Memo", Validators.requireLength(1000))
                }
            validator = validator.withSimpleValidation("FacilityID", Validators.requireNotBlankIfExistsValidator(this.props.result.FacilityID, "Facility ID"))
                if(this.state.onsetSymptomsDate){
                    validator = validator.withComposedValidation("OnsetSymptomsDate", new ValidationEntry(Validators.requireNotFutureDate("Onset Symptoms Date")), new ValidationEntry(Validators.requireNotBeforePatientDOB(this.state.patientDOB, "Onset Symptoms Date")))
                }
        let validationResponse = validator.validate(this.getEdittedObj());
        if(!validationResponse.success) {
            return sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
        }
        this.setState({submitClicked: true})
        this.editSample()
    }



    editSample(){
        this.setState({showLoading:true}, async () => {

            window['$']("#" + SamplesModal.ID).modal('hide')
            try {
                let result = await SamplesAPI.updateSample({sample: this.getEdittedObj()})
                if(!result.success){
                    sweetalert.fire({icon: 'error', title: '', text: result.reason});
                    this.setState({submitClicked: false, showLoading: false})
                }
                else{
                    sweetalert.fire({icon: 'success', title: '', text: "Sample has been updated."});
                    this.setState({submitClicked: false, showLoading: false})
                    this.props.onSubmit()
                }
            } catch (e) {
                console.log(e);
                sweetalert.fire({icon: 'error', title: '', text: "Server error"});
                this.setState({submitClicked: false, showLoading: false})
            }
        })
    }



    getLinks(){
        if(!this.props.result || !this.props.result.ReqNum){
            return null
        }
        return(
            <div className="card-footer mb-2 mr-2 ml-0" style={{backgroundColor: 'transparent', borderTop: 'none'}}>
                <a className={this.props.getResultsButtonBoolean ? '' : 'd-none'} href={this.props.result ? "/results?fname=" + this.props.result.PatientFirstName.trim() +"&lname=" + this.props.result.PatientLastName.trim()
                    +"&dob=" + this.props.result.PatientDOB + "&conf=" + this.props.result.ReqNum +"&norecord=true"
                    : ""} target={"_blank"}>
                    <button type="button"  className={this.props.getResultsButtonBoolean ? 'btn btn-success mr-3' : 'd-none'}  >Get Results</button></a>
                <button disabled={this.state.submitClicked} className={"btn btn-primary "} style={{float: 'right'}} onClick={() => this.onSubmit()}>Update Sample</button>

            </div>
        )

    }

    getInput(stateToSet, state, label, tooltip, disabled=false, maxLength?){
        if(label === 'Memo'){
            return(
                <div className="form-group row">
                    <div className={"col-12 col-md-4"} data-toggle={'tooltip'} data-placement={'top'} title={tooltip}>
                        <label htmlFor={label} style={{fontWeight: 'bold', paddingTop: '0.5em', fontSize: '0.8em'}}>{label}</label>
                    </div>
                    <div className={"col-12 col-md-8 pl-md-0 pr-md-1"} id={label}>
                        <div className="row">
                            <div className={'col-12'}>
                                <textarea
                                        rows={3}
                                        maxLength={1000}
                                        disabled={disabled}
                                        className={'form-control'}
                                        value={state}
                                        autoComplete="off"
                                        onChange={(e) =>{
                                           this.setState({memo: e.target.value} as any)
                                       }}/>
                            </div>
                        </div>
                        <div className="row pt-1 pr-3 justify-content-end">
                            <div className={this.state.memo.length > 0 ? 'visible' : 'invisible'}>
                                <div style={{fontSize: '0.8em'}}>{this.state.memo.length}/1000</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return(
            <div className="form-group row">
                <div className={"col-12 col-md-4"} data-toggle={'tooltip'} data-placement={'top'} title={tooltip}>
                    <label htmlFor={label} style={{fontWeight: 'bold', paddingTop: '0.5em', fontSize: '0.8em'}}>{label}</label>
                </div>
                <div className={"col-12 col-md-8 pl-md-0 pr-md-1"} id={label}>
                    <div className="row">
                        <div className="col-12">
                            <input type={"search"}
                                   maxLength={maxLength}
                                   disabled={disabled}
                                   className={'form-control'}
                                   value={state}
                                   autoComplete="off"
                                   onChange={(e) =>{
                                       this.setState({[stateToSet]: e.target.value} as any)
                                   }}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    handleDateOnChange(value, state){
        if (value) {
            this.setState({[state]: value });
        }
        else{
            this.setState({[state]: null})
        }
    }

    getDatePicker(stateToSet, state, label, tooltip){
        return(
            <div className="form-group row">
                <div className={"col-12 col-md-4"} data-toggle={'tooltip'} data-placement={'top'} title={tooltip}>
                    <label htmlFor={label} style={{fontWeight: 'bold', paddingTop: '0.5em', fontSize: '0.8em'}}>{label}</label>
                </div>
                <div className={"col-12 col-md-8 pl-md-0 pr-md-1"} id={label}>
                    <div className="row">
                        <div className="col-12">
                            <DatePicker
                                minDate={new Date('01-01-1000')}
                                maxDate={new Date('12-31-9999')}
                                placeholderText="--/--/----"
                                selected={state ? Date.parse(moment(state, 'MM-DD-YYYY').toISOString()) : null}
                                onChange={(e) => this.handleDateOnChange(e, stateToSet)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getDateTimePicker(stateToSet, state, label, tooltip, disabled=false){
        return(
            <div className="form-group row">
                <div className={"col-12 col-md-4"} data-toggle={'tooltip'} data-placement={'top'} title={tooltip}>
                    <label htmlFor={label} style={{fontWeight: 'bold', paddingTop: '0.5em', fontSize: '0.8em'}}>{label}</label>
                </div>
                <div className={"col-12 col-md-8 pl-md-0 pr-md-1"} id={label}>
                    <div className="row">
                        <div className="col-12">
                            <DatePicker
                                placeholderText="--/--/---- --:-- --"
                                minDate={new Date('01-01-1000')}
                                maxDate={new Date('12-31-9999')}
                                disabled={disabled}
                                showTimeSelect
                                selected={state ? Date.parse(moment(state, 'MM-DD-YYYY h:mm aa').toISOString()) : null}
                                dateFormat="MM/dd/yyyy h:mm aa"
                                onChange={(e) => this.handleDateOnChange(e, stateToSet)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getTimePicker(stateToSet, state, label, tooltip, disabled=false){
        return(
            <div className="form-group row">
                <div className={"col-12 col-md-4"} data-toggle={'tooltip'} data-placement={'top'} title={tooltip}>
                    <label htmlFor={label} style={{fontWeight: 'bold', paddingTop: '0.5em', fontSize: '0.8em'}}>{label}</label>
                </div>
                <div className={"col-12 col-md-8 pl-md-0 pr-md-1"} id={label}>
                    <div className="row">
                        <div className="col-12">
                            <DatePicker
                                placeholderText="--:-- --"
                                minDate={new Date('01-01-1000')}
                                maxDate={new Date('12-31-9999')}
                                disabled={disabled}
                                showTimeSelect
                                showTimeSelectOnly
                                selected={state ? Date.parse(moment(state, 'h:mm aa').toISOString()) : null}
                                dateFormat="h:mm aa"
                                onChange={(e) => this.handleDateOnChange(e, stateToSet)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    handleTestID(event, stateLabelToSet, stateToSet) {
        this.getTest(event.value)
        this.setState({
            [stateLabelToSet]: event,
            [stateToSet]: event.value,
            validSpecimenSources: this.props.validSpecimenSources.filter(r => r.value.TestID === event.value),
            validResultTypes: this.props.validResultTypes.filter(r => r.value.TestID === event.value),
            resultID: null,
            resultLabel: null,
            specimenSourceID: null,
            specimenSource: null
        })
    }

    handleBatch(event, stateLabelToSet, stateToSet){
        this.setState({[stateLabelToSet]: event, [stateToSet]: event.value, resultApprovedDate: new Date(event.value.ResultApprovedDate)})
    }

    handleBinID(event, stateLabelToSet, stateToSet){
        this.setState({[stateLabelToSet]: event, [stateToSet]: event.value, receivedBy: event.value.ReceivedBy, droppedDate: new Date(event.value.DroppedDate)})
    }

    getSelect(stateToSet, stateLabel, stateLabelToSet, label, tooltip, options, disabled=false){
        if(stateToSet == 'specimenSourceID'){
            return(
                <div className="form-group row">
                    <div className={"col-12 col-md-4"} data-toggle={'tooltip'} data-placement={'top'} title={tooltip}>
                        <label htmlFor={label} style={{fontWeight: 'bold', paddingTop: '0.5em', fontSize: '0.8em'}}>{label}</label>
                    </div>
                    <div className={"col-12 col-md-8 pl-md-0 pr-md-1"} id={label}>
                        <div className="row">
                            <div className="col-12">
                                <Select
                                    isDisabled={disabled}
                                    isSearchable={true}
                                    placeholder={"Please select..."}
                                    onChange={ (e)=>  this.setState({[stateLabelToSet]: e, [stateToSet]: e.value.SpecimenSourceID} as any)}
                                    className={"state_select"}
                                    options={options}
                                    value={stateLabel}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return(
            <div className="form-group row">
                <div className={"col-12 col-md-4"} data-toggle={'tooltip'} data-placement={'top'} title={tooltip}>
                    <label htmlFor={label} style={{fontWeight: 'bold', paddingTop: '0.5em', fontSize: '0.8em'}}>{label}</label>
                </div>
                <div className={"col-12 col-md-8 pl-md-0 pr-md-1"} id={label}>
                    <div className="row">
                        <div className="col-12">
                                <Select
                                    isDisabled={disabled}
                                    isSearchable={true}
                                    placeholder={"Please select..."}
                                    onChange={stateToSet === 'testID' ? (e) => this.handleTestID(e, stateLabelToSet, stateToSet) : stateToSet === 'batchNum' ? (e) => this.handleBatch(e, stateLabelToSet, stateToSet) : stateToSet === 'binID' ? (e) => this.handleBinID(e, stateLabelToSet, stateToSet) : (e)=> this.setState({[stateLabelToSet]: e, [stateToSet]: e.value})}
                                    className={"state_select"}
                                    options={options}
                                    value={stateLabel}
                                />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    public static readonly ID = "sample"

    isResultDisabled(){
        if(!this.state.collectionDate){
            return true;
        }
        if(this.state.isLabTest && !this.props.isGlobal) {
            return true;
        }
        if(!this.state.isLabTest && this.props.isGlobal) {
            return true;
        }
        return !this.state.testID;
   }

   disableCollectionRelevantFields(field){
        if(!this.props.result[field] && !this.props.isGlobal){
            return true;
        }
        return false;
   }
   
    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let states = this.props.states.filter(f => f.value !== '*null' && f.value !== '<>null')
        let countries = this.props.countries.filter(f => f.value !== '*null' && f.value !== '<>null')
        let ethnicity = this.props.ethnicity.filter(f => f.value !== '*null' && f.value !== '<>null')
        let race = this.props.race.filter(f => f.value !== '*null' && f.value !== '<>null')
        let gender = this.props.gender.filter(f => f.value !== '*null' && f.value !== '<>null')
        let testTypes = this.props.tests.filter(f => f.value !== '*null' && f.value !== '<>null')
        let pods = this.props.pods.filter(f => f.value !== '*null' && f.value !== '<>null')

        // let binIDs = this.props.binIDs.filter(f => f.value !== '*null' && f.value !== '<>null')
        //
        // let batches = this.props.batches.filter(f => f.value !== '*null' && f.value !== '<>null')

        return (
            <React.Fragment>
                <Overlay show_loading={this.state.showLoading}/>
                <div className="modal fade form_modal" id={SamplesModal.ID} tabIndex={-1} role="dialog"
                     data-backdrop="static"
                     data-keyboard="false"
                     aria-labelledby="result_modal_label"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-body p-0 m-0">
                                <div className="container-fluid">
                                    <div className={"row"}>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-12 pt-2">
                                            <div className="card mb-2">
                                                <div className="card-header verlag-bold">
                                                    <h3>Edit Sample
                                                        <button type="button" className="close pr-4" data-dismiss="modal"
                                                                aria-label="Close">
                                                            <span aria-hidden="true" style={{fontSize: '1.5em', fontWeight: 'bold'}}>&times;</span>
                                                        </button>
                                                    </h3>
                                                </div>

                                                {/*------------------------------------begin xl and xxl screens--------------------------------------*/}

                                                <div className="card-body d-none d-xl-block">
                                                    <div className={"row"}>
                                                        <div className="col-xl-12 pt-2">
                                                            <div className="card mb-xl-2">
                                                                <div className="card-header verlag-bold" data-toggle="collapse" data-target="#patientXLCollapse" onClick={() => this.setState({patientArrowDown: !this.state.patientArrowDown})} role="button" aria-expanded="false" aria-controls="patientXLCollapse">
                                                                    <h3>Patient
                                                                        {this.state.patientArrowDown ? (<AiOutlineUp className={'float-right'} size={30}  data-toggle="collapse" data-target="#patientXLCollapse"  role="button" aria-expanded="false" aria-controls="patientXLCollapse" />) : (<AiOutlineDown className={'float-right'} size={30} data-toggle="collapse" data-target="#patientXLCollapse" role="button" aria-expanded="false" aria-controls="patientXLCollapse" />)}
                                                                    </h3>
                                                                </div>
                                                                <div className="card-body collapse" id={"patientXLCollapse"}>
                                                                    <div className="row">
                                                                        <div className="col-xl-6">
                                                                            {this.getInput('patientFirstName', this.state.patientFirstName, 'First Name', 'The first name of the Patient.', false, 50)}
                                                                            {this.getInput('patientMiddleName', this.state.patientMiddleName, 'Middle Name', 'The middle name of the Patient.', false, 50)}
                                                                            {this.getInput('patientLastName', this.state.patientLastName, 'Last Name', 'The last name of the Patient.', false, 50)}
                                                                            {this.getDatePicker('patientDOB', this.state.patientDOB, 'DOB', 'The date of birth of the Patient.')}
                                                                            {this.getInput('patientEmail', this.state.patientEmail, 'Email', 'The email of the Patient.', false, 100)}
                                                                            {this.getSelect('patientEthnicityID', this.state.patientEthnicity, 'patientEthnicity', 'Ethnicity', 'The ethnicity of the Patient.', ethnicity)}
                                                                            {this.getSelect('patientRaceID', this.state.patientRace, 'patientRace', 'Race', 'The race of the Patient.', race)}
                                                                            {this.getSelect('patientGenderID', this.state.patientGender, 'patientGender', 'Gender', 'The gender of the Patient.', gender)}
                                                                        </div>
                                                                        <div className="col-xl-6">
                                                                            <div className="form-group row">
                                                                                <div className={"col-12 col-md-4"}>
                                                                                    <label htmlFor={'Phone'} style={{fontWeight: 'bold', paddingTop: '0.5em', fontSize: '0.8em'}}>Patient Phone</label>
                                                                                </div>
                                                                                <div className={"col-12 col-md-8 pl-md-0 pr-md-1"} id={'Phone'}>
                                                                                    <PhoneInput
                                                                                        placeholder="Enter phone number"
                                                                                        onChange={(e) => this.setState({patientPhone: e})}
                                                                                        defaultCountry="US"
                                                                                        value={this.state.patientPhone}/>
                                                                                </div>
                                                                            </div>
                                                                            {this.getInput('patientAddress', this.state.patientAddress, 'Address', 'The address of the Patient.', false, 500)}
                                                                            {this.getInput('patientCity', this.state.patientCity, 'City', 'The city of the Patient\'s address.', false, 100)}
                                                                            {this.getSelect('patientState', this.state.stateLabel, 'stateLabel', 'State', 'The state of the Patient\'s address.', states)}
                                                                            {this.getInput('patientZip', this.state.patientZip, 'Zip', 'The zip of the Patient\'s address.', false, 50)}
                                                                            {this.getInput('patientCounty', this.state.patientCounty, 'County', 'The county of the Patient\'s address.', false, 100)}
                                                                            {this.getSelect('patientCountry', this.state.countryLabel, 'countryLabel', 'Country', 'The country of the Patient\'s address.', countries)}
                                                                            {this.getInput('guardianFirstName', this.state.guardianFirstName, 'Guardian First Name', 'The first name of the guardian if the patient is under 18.', false, 50)}
                                                                            {this.getInput('guardianLastName', this.state.guardianLastName, 'Guardian Last Name', 'The last name of the guardian if the patient is under 18.', false, 50)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card mb-2">
                                                                <div className="card-header verlag-bold" data-toggle="collapse" data-target="#sampleXLCollapse" onClick={() => this.setState({sampleArrowDown: !this.state.sampleArrowDown})} role="button" aria-expanded="false" aria-controls="sampleXLCollapse">
                                                                    <h3>Sample
                                                                        {this.state.sampleArrowDown ? (<AiOutlineUp className={'float-right'} size={30}  data-toggle="collapse" data-target="#sampleXLCollapse" role="button" aria-expanded="false" aria-controls="sampleXLCollapse" />) : (<AiOutlineDown className={'float-right'} size={30} data-toggle="collapse" data-target="#sampleXLCollapse" role="button" aria-expanded="false" aria-controls="sampleXLCollapse" />)}</h3>
                                                                </div>
                                                                <div className="card-body collapse" id={"sampleXLCollapse"}>
                                                                    <div className="row">
                                                                        <div className="col-xl-6">
                                                                            {this.getInput('reqNum', this.state.reqNum, 'Confirmation #', 'A patient\'s unique code tied to their appointment or walk-in form submission.', true, 50)}
                                                                            {this.getSelect('apptCanceled', this.state.apptCanceledLabel, 'apptCanceledLabel', 'Canceled', 'Appointment status. "Yes" returns canceled appointments, "No" returns active appointments.', this.getBoolOptions())}
                                                                            {this.getSelect('resultID', this.state.resultLabel, 'resultLabel', 'Result', 'The result assigned to a sample.', this.state.validResultTypes, this.isResultDisabled())}
                                                                            <div className="form-group row">
                                                                                <div className={"col-12 col-md-4"}>
                                                                                    <label htmlFor={'Rerun count'} style={{fontWeight: 'bold', paddingTop: '0.5em', fontSize: '0.8em'}}>Rerun count</label>
                                                                                </div>
                                                                                <div className={"col-12 col-md-8 pl-md-0 pr-md-1"} id={'Rerun count'}>
                                                                                    <input type={"text"}
                                                                                           pattern={'[0-9]'}
                                                                                           disabled={!this.props.isGlobal}
                                                                                           className="form-control"
                                                                                           value={this.state.rerunCount}
                                                                                           autoComplete="off"
                                                                                           min={0}
                                                                                           max={100}
                                                                                           onChange={(e) =>  this.setState({rerunCount: e.target.value})}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            {this.getSelect('rerunFlag', this.state.rerunFlagLabel, 'rerunFlagLabel', 'Rerun flag', 'Samples marked for rerun. "Yes" returns samples marked for rerun.', this.getBoolOptions(), !this.props.isGlobal)}
                                                                            {this.getInput('reason', this.state.reason, 'Result Reason', 'Used by lab personnel to make notes about a sample\'s status or results.', !this.props.isGlobal, 500)}
                                                                            {this.getInput('specimenID', this.state.specimenID, 'Specimen ID', 'A unique identifier assigned to a sample.',this.disableCollectionRelevantFields('SpecimenID'), 50 )}
                                                                            {this.getInput('internalSpecimenID', this.state.internalSpecimenID, 'Internal Specimen ID', 'A unique secondary identifier assigned to a sample.', !this.props.isGlobal, 50)}
                                                                            {this.getSelect('binID', this.state.bin, 'bin', 'Bin ID', 'A unique identifier that ties a sample to receiving at the lab.', this.props.binIDs, !this.props.isGlobal)}
                                                                            {this.getTimePicker('apptTime', this.state.apptTime, 'Appointment Time', 'Time of appointment.')}
                                                                            {this.getDateTimePicker('collectionDate', this.state.collectionDate, 'Collection Date', 'Date a test sample was collected.', this.disableCollectionRelevantFields('CollectionDate'))}

                                                                            {this.getDateTimePicker('droppedDate', this.state.droppedDate, 'Received Date', 'The date a sample was received at the lab.', true)}
                                                                            {this.getInput('receivedBy', this.state.receivedBy, 'Received by', 'The name of who received samples at the lab.', true)}



                                                                            {/*stateToSet, state, label, tooltip, disabled(optional)*/}
                                                                            {this.getDateTimePicker('testOrderedDate', this.state.testOrderedDate, 'Test Ordered Date', 'Date the test was ordered.', this.disableCollectionRelevantFields('TestOrderedDate'))}
                                                                        </div>
                                                                        <div className="col-xl-6">
                                                                            {this.getDateTimePicker('processedDate', this.state.processedDate, 'Processed Date', 'Date of sample accession.', !this.props.isGlobal)}

                                                                            {this.getSelect('batchNum', this.state.batchNums, 'batchNums', 'Batch #', 'Assigned when processing a batch of test samples.', this.props.batches, !this.props.isGlobal)}
                                                                            {this.getInput('wellSlot', this.state.wellSlot, 'Well Slot', 'Used by lab personnel to indicate a sample\'s position on a well plate.', !this.props.isGlobal, 50)}
                                                                            {this.getDateTimePicker('testResultDate', this.state.testResultDate, 'Test Result Date', 'Date a result was assigned to a test sample (note: results are not sent to the patient until they\'re approved.', !this.props.isGlobal)}
                                                                            {this.getDateTimePicker('resultApprovedDate', this.state.resultApprovedDate, 'Result Approved Date', 'The date a result is approved and sent to the patient.', true)}
                                                                            {this.getDateTimePicker('reportedDate', this.state.reportedDate, 'Reported Date', 'The date a sample result is reported to SpringML.', true)}
                                                                            {this.getDateTimePicker('resultsAccessedDate', this.state.resultsAccessedDate, 'Results Accessed Date', 'The date a patient first accessed their test results.', (!this.props.isGlobal || !this.state.resultApprovedDate))}
                                                                            {this.getSelect('testID', this.state.test, 'test', 'Test Type', 'The testing method.', testTypes, this.disableCollectionRelevantFields('TestID'))}
                                                                            {this.getSelect('specimenSourceID', this.state.specimenSource, 'specimenSource', 'Specimen Source', 'The type of sample that was collected.', this.state.validSpecimenSources, (this.disableCollectionRelevantFields('SpecimenSourceID') || !this.state.testID))}


                                                                            {/*stateToSet, state, label, tooltip, disabled(optional)*/}
                                                                            {this.getInput('providerFirstName', this.state.providerFirstName, 'Provider First Name', 'The first name of the Provider that is assigned to a sample.', this.disableCollectionRelevantFields('ProviderFirstName'), 50)}
                                                                            {this.getInput('providerLastName', this.state.providerLastName, 'Provider Last Name', 'The last name of the Provider that is assigned to a sample.', this.disableCollectionRelevantFields('ProviderLastName'), 50)}
                                                                            {this.getInput('lot', this.state.lot, 'Lot', 'Test lot number (e.g., rapid test lot number.)', this.disableCollectionRelevantFields('Lot'), 45)}
                                                                            {this.getInput('memo', this.state.memo, 'Memo', 'General sample notes.', false, 1000)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card mb-2">
                                                                <div className="card-header verlag-bold" data-toggle="collapse" data-target="#facilityXLCollapse" onClick={() => this.setState({facilityArrowDown: !this.state.facilityArrowDown})} role="button" aria-expanded="false" aria-controls="facilityXLCollapse">
                                                                    <h3>Facility
                                                                        {this.state.facilityArrowDown ? (<AiOutlineUp className={'float-right'} size={30}   data-toggle="collapse" data-target="#facilityXLCollapse"  role="button" aria-expanded="false" aria-controls="facilityXLCollapse" />) : (<AiOutlineDown className={'float-right'} size={30} data-toggle="collapse" data-target="#facilityXLCollapse"  role="button" aria-expanded="false" aria-controls="facilityXLCollapse" />)}</h3>
                                                                </div>
                                                                <div className="card-body collapse" id={"facilityXLCollapse"}>
                                                                    {this.getSelect('facilityID', this.state.facilityName, 'facilityName', 'Facility', 'Testing locations by name.', this.props.facilities)}
                                                                    {this.getSelect('podApptID', this.state.podLabel, 'podLabel', 'Pod', 'Lists the Pod ID, Facility Name, and Pod Date.', pods)}
                                                                </div>
                                                            </div>

                                                            <div className="card mb-2">
                                                                <div className="card-header verlag-bold" data-toggle="collapse" data-target="#additionalXLCollapse" onClick={() => this.setState({additionalArrowDown: !this.state.additionalArrowDown})} role="button" aria-expanded="false" aria-controls="additionalXLCollapse">
                                                                    <h3>Additional
                                                                        {this.state.additionalArrowDown ? (<AiOutlineUp className={'float-right'} size={30} data-toggle="collapse" data-target="#additionalXLCollapse"  role="button" aria-expanded="false" aria-controls="additionalXLCollapse" />) : (<AiOutlineDown className={'float-right'} size={30}  data-toggle="collapse" data-target="#additionalXLCollapse"  role="button" aria-expanded="false" aria-controls="additionalXLCollapse" />)}</h3>
                                                                </div>
                                                                <div className="card-body collapse" id={"additionalXLCollapse"}>
                                                                    <div className="row">
                                                                        <div className="col-xl-6">
                                                                            {this.getInput('createdBy', this.state.createdBy, 'Created By', 'The username of who generated a record (applies primarily to walk-ins).', true, 100)}
                                                                            {this.getInput('lastUpdatedBy', this.state.lastUpdatedBy, 'Last Updated By', 'The first name of the Provider that is assigned to a sample.', true, 100)}
                                                                            {this.getDateTimePicker('lastUpdatedDate', this.state.lastUpdatedDate, 'Last Updated Date', 'The date the record was last updated.', true)}



                                                                            {/*stateToSet, stateLabel, stateLabelToSet, label, tooltip, options, disabled=false*/}
                                                                            {this.getSelect('firstTest', this.state.firstTestLabel, 'firstTestLabel', 'First Test', 'Is this the patient\'s first test?', this.getYesNoOptions())}
                                                                            {this.getSelect('employedHealthcare', this.state.employedInHealthcareLabel, 'employedInHealthcareLabel', 'Employed in Healthcare', 'Is the patient employed in healthcare?', this.getYesNoOptions())}
                                                                            {this.getSelect('symptomatic', this.state.symptomaticLabel, 'symptomaticLabel', 'Symptomatic', 'Is the patient symptomatic?', this.getYesNoOptions())}
                                                                            {this.getSelect('hospitalized', this.state.hospitalizedLabel, 'hospitalizedLabel', 'Hospitalized', 'Is the patient hospitalized?', this.getYesNoOptions())}
                                                                        </div>
                                                                        <div className="col-xl-6">
                                                                            {this.getSelect('icu', this.state.icuLabel, 'icuLabel', 'ICU', 'Is the patient in the ICU?', this.getYesNoOptions())}
                                                                            {this.getSelect('residentCongregateCare', this.state.residentCongregateCareLabel, 'residentCongregateCareLabel', 'Resident Congregate Care', 'Is the patient in Resident Congregate Care?', this.getYesNoOptions())}
                                                                            {this.getSelect('pregnant', this.state.pregnantLabel, 'pregnantLabel', 'Pregnant', 'Is the patient pregnant?', this.getYesNoOptions())}
                                                                            {this.getSelect('consentFlag', this.state.consentFlagLabel, 'consentFlagLabel', 'Consent Flag', 'Did the patient give consent?', this.getBoolOptions())}
                                                                            {this.getDateTimePicker('createdDate', this.state.createdDate, 'Created Date', 'The date the record was generated.', true)}
                                                                            {this.getDatePicker('onsetSymptomsDate', this.state.onsetSymptomsDate, 'Onset Symptoms Date', 'Date that the patient started experiencing symptoms, if any.')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*-------------------------------------------- end xl and xxl screens---------------------------------*/}

                                                <div className="card-body d-block d-xl-none">
                                                    <div className={"row"}>
                                                        <div className="col-xl-12 col-lg-12 col-md-12 col-12 pt-2">
                                                            <div className="card mb-2">
                                                                <div className="card-header verlag-bold" data-toggle="collapse" data-target="#patientCollapse" onClick={() => this.setState({patientArrowDown: !this.state.patientArrowDown})} role="button" aria-expanded="false" aria-controls="patientCollapse">
                                                                    <h3>Patient
                                                                        {this.state.patientArrowDown ? (<AiOutlineUp className={'float-right'} size={30}  data-toggle="collapse" data-target="#patientCollapse"  role="button" aria-expanded="false" aria-controls="patientCollapse" />) : (<AiOutlineDown className={'float-right'} size={30} data-toggle="collapse" data-target="#patientCollapse" role="button" aria-expanded="false" aria-controls="patientCollapse" />)}
                                                                        </h3>
                                                                </div>
                                                                <div className="card-body collapse" id={"patientCollapse"}>
                                                                    {this.getInput('patientFirstName', this.state.patientFirstName, 'First Name', 'The first name of the Patient.', false, 50)}
                                                                    {this.getInput('patientMiddleName', this.state.patientMiddleName, 'Middle Name', 'The middle name of the Patient.', false, 50)}
                                                                    {this.getInput('patientLastName', this.state.patientLastName, 'Last Name', 'The last name of the Patient.', false, 50)}
                                                                    {this.getDatePicker('patientDOB', this.state.patientDOB, 'DOB', 'The date of birth of the Patient.')}
                                                                    {this.getInput('patientEmail', this.state.patientEmail, 'Email', 'The email of the Patient.', false, 100)}
                                                                    {this.getInput('patientAddress', this.state.patientAddress, 'Address', 'The address of the Patient.', false, 500)}
                                                                    {this.getInput('patientCity', this.state.patientCity, 'City', 'The city of the Patient\'s address.', false, 100)}
                                                                    {this.getSelect('patientState', this.state.stateLabel, 'stateLabel', 'State', 'The state of the Patient\'s address.', states)}
                                                                    {this.getInput('patientZip', this.state.patientZip, 'Zip', 'The zip of the Patient\'s address.', false, 50)}
                                                                    {this.getInput('patientCounty', this.state.patientCounty, 'County', 'The county of the Patient\'s address.', false, 100)}
                                                                    {this.getSelect('patientCountry', this.state.countryLabel, 'countryLabel', 'Country', 'The country of the Patient\'s address.', countries)}
                                                                    <div className="form-group row">
                                                                        <div className={"col-12 col-md-4"}>
                                                                            <label htmlFor={'Phone'} style={{fontWeight: 'bold', paddingTop: '0.5em', fontSize: '0.8em'}}>Phone</label>
                                                                        </div>
                                                                        <div className={"col-12 col-md-8 pl-md-0 pr-md-1"} id={'Phone'}>
                                                                            <PhoneInput
                                                                                className="form-control"
                                                                                placeholder="Enter phone number"
                                                                                onChange={(e) => this.setState({patientPhone: e})}
                                                                                defaultCountry="US"
                                                                                value={this.state.patientPhone}/>
                                                                        </div>
                                                                    </div>
                                                                    {this.getSelect('patientEthnicityID', this.state.patientEthnicity, 'patientEthnicity', 'Ethnicity', 'The ethnicity of the Patient.', ethnicity)}
                                                                    {this.getSelect('patientRaceID', this.state.patientRace, 'patientRace', 'Race', 'The race of the Patient.', race)}
                                                                    {this.getSelect('patientGenderID', this.state.patientGender, 'patientGender', 'Gender', 'The gender of the Patient.', gender)}
                                                                    {this.getInput('guardianFirstName', this.state.guardianFirstName, 'Guardian First Name', 'The first name of the guardian if the patient is under 18.', false, 50)}
                                                                    {this.getInput('guardianLastName', this.state.guardianLastName, 'Guardian Last Name', 'The last name of the guardian if the patient is under 18.', false, 50)}
                                                                </div>
                                                            </div>
                                                            <div className="card mb-2">
                                                                <div className="card-header verlag-bold" data-toggle="collapse" data-target="#sampleCollapse" onClick={() => this.setState({sampleArrowDown: !this.state.sampleArrowDown})} role="button" aria-expanded="false" aria-controls="sampleCollapse">
                                                                    <h3>Sample
                                                                        {this.state.sampleArrowDown ? (<AiOutlineUp className={'float-right'} size={30}  data-toggle="collapse" data-target="#sampleCollapse" role="button" aria-expanded="false" aria-controls="sampleCollapse" />) : (<AiOutlineDown className={'float-right'} size={30} data-toggle="collapse" data-target="#sampleCollapse" role="button" aria-expanded="false" aria-controls="sampleCollapse" />)}</h3>
                                                                </div>
                                                                <div className="card-body collapse" id={"sampleCollapse"}>
                                                                    {this.getInput('reqNum', this.state.reqNum, 'Confirmation #', 'A patient\'s unique code tied to their appointment or walk-in form submission.', true, 50)}
                                                                    {this.getSelect('apptCanceled', this.state.apptCanceledLabel, 'apptCanceledLabel', 'Appointment Canceled', 'Appointment status. "Yes" returns canceled appointments, "No" returns active appointments.', this.getBoolOptions())}
                                                                    {this.getSelect('resultID', this.state.resultLabel, 'resultLabel', 'Result', 'The result assigned to a sample.', this.state.validResultTypes,
                                                                        !((this.state.isLabTest && this.props.isGlobal) || (!this.state.isLabTest && !this.props.isGlobal) || (this.props.isAdmin && this.props.isGlobal)))}

                                                                    <div className="form-group row">
                                                                        <div className={"col-12 col-md-4"}>
                                                                            <label htmlFor={'Rerun count'} style={{fontWeight: 'bold', paddingTop: '0.5em', fontSize: '0.8em'}}>Rerun count</label>
                                                                        </div>
                                                                        <div className={"col-12 col-md-8 pl-md-0 pr-md-1"} id={'Rerun count'}>
                                                                            <input type={"number"}
                                                                                   disabled={!this.props.isGlobal}
                                                                                   className="form-control"
                                                                                   value={this.state.rerunCount}
                                                                                   autoComplete="off"
                                                                                   min={0}
                                                                                   max={100}
                                                                                   onChange={(e) =>  this.setState({rerunCount: e.target.value})}/>
                                                                        </div>
                                                                    </div>
                                                                    {this.getSelect('rerunFlag', this.state.rerunFlagLabel, 'rerunFlagLabel', 'Rerun flag', 'Samples marked for rerun. "Yes" returns samples marked for rerun.', this.getBoolOptions(), !this.props.isGlobal)}
                                                                    {this.getInput('reason', this.state.reason, 'Result Reason', 'Used by lab personnel to make notes about a sample\'s status or results.', !this.props.isGlobal, 500)}
                                                                    {this.getInput('specimenID', this.state.specimenID, 'Specimen ID', 'A unique identifier assigned to a sample.', this.disableCollectionRelevantFields('SpecimenID'), 50)}
                                                                    {this.getInput('internalSpecimenID', this.state.internalSpecimenID, 'Internal Specimen ID', 'A unique secondary identifier assigned to a sample.', !this.props.isGlobal, 50)}

                                                                    {/*stateToSet, stateLabel, stateLabelToSet, label, tooltip, options, disabled=false*/}
                                                                    {this.getSelect('binID', this.state.bin, 'bin', 'Bin ID', 'A unique identifier that ties a sample to receiving at the lab.', this.props.binIDs, !this.props.isGlobal)}
                                                                    {this.getTimePicker('apptTime', this.state.apptTime, 'Appointment Time', 'Time of appointment.')}
                                                                    {this.getDateTimePicker('collectionDate', this.state.collectionDate, 'Collection Date', 'Date a test sample was collected.', this.disableCollectionRelevantFields('CollectionDate'))}
                                                                    {this.getDateTimePicker('droppedDate', this.state.droppedDate, 'Received Date', 'The date a sample was received at the lab.', true)}

                                                                    {/*stateToSet, state, label, tooltip, disabled(optional)*/}
                                                                    {this.getInput('receivedBy', this.state.receivedBy, 'Received by', 'The name of who received samples at the lab.', true)}
                                                                    {this.getDateTimePicker('testOrderedDate', this.state.testOrderedDate, 'Test Ordered Date', 'Date the test was ordered.', this.disableCollectionRelevantFields('TestOrderedDate'))}
                                                                    {this.getDateTimePicker('processedDate', this.state.processedDate, 'Processed Date', 'Date of sample accession.', !this.props.isGlobal)}
                                                                    {this.getSelect('batchNum', this.state.batchNums, 'batchNums', 'Batch #', 'Assigned when processing a batch of test samples.', this.props.batches, !this.props.isGlobal)}
                                                                    {this.getInput('wellSlot', this.state.wellSlot, 'Well Slot', 'Used by lab personnel to indicate a sample\'s position on a well plate.', !this.props.isGlobal)}


                                                                    {/*stateToSet, state, label, tooltip, disabled(optional)*/}
                                                                    {this.getDateTimePicker('testResultDate', this.state.testResultDate, 'Test Result Date', 'Date a result was assigned to a test sample (note: results are not sent to the patient until they\'re approved.')}
                                                                    {this.getDateTimePicker('resultApprovedDate', this.state.resultApprovedDate, 'Result Approved Date', 'The date a result is approved and sent to the patient.', true  )}
                                                                    {this.getDateTimePicker('reportedDate', this.state.reportedDate, 'Reported Date', 'The date a sample result is reported to SpringML.')}
                                                                    {this.getDateTimePicker('resultsAccessedDate', this.state.resultsAccessedDate, 'Results Accessed Date', 'The date a patient first accessed their test results.', !this.props.isGlobal)}
                                                                    {this.getSelect('testID', this.state.test, 'test', 'Test Type', 'The testing method.', testTypes, this.disableCollectionRelevantFields('TestID'))}
                                                                    {this.getSelect('specimenSourceID', this.state.specimenSource, 'specimenSource', 'Specimen Source', 'The type of sample that was collected.', this.state.validSpecimenSources, (this.disableCollectionRelevantFields('SpecimenSourceID')|| !this.state.testID))}


                                                                    {/*stateToSet, state, label, tooltip, disabled(optional)*/}
                                                                    {this.getInput('providerFirstName', this.state.providerFirstName, 'Provider First Name', 'The first name of the Provider that is assigned to a sample.', this.disableCollectionRelevantFields('ProviderFirstName'), 50)}
                                                                    {this.getInput('providerLastName', this.state.providerLastName, 'Provider Last Name', 'The last name of the Provider that is assigned to a sample.', this.disableCollectionRelevantFields('ProviderLastName'), 50)}
                                                                    {this.getInput('lot', this.state.lot, 'Lot', 'Test lot number (e.g., rapid test lot number.)', this.disableCollectionRelevantFields('Lot'), 45)}
                                                                    {this.getInput('memo', this.state.memo, 'Memo', 'General sample notes.', false, 50)}


                                                                </div>
                                                            </div>

                                                            <div className="card mb-2">
                                                                <div className="card-header verlag-bold" data-toggle="collapse" data-target="#facilityCollapse" onClick={() => this.setState({facilityArrowDown: !this.state.facilityArrowDown})} role="button" aria-expanded="false" aria-controls="facilityCollapse">
                                                                    <h3>Facility
                                                                        {this.state.facilityArrowDown ? (<AiOutlineUp className={'float-right'} size={30}   data-toggle="collapse" data-target="#facilityCollapse"  role="button" aria-expanded="false" aria-controls="facilityCollapse" />) : (<AiOutlineDown className={'float-right'} size={30} data-toggle="collapse" data-target="#facilityCollapse"  role="button" aria-expanded="false" aria-controls="facilityCollapse" />)}</h3>
                                                                </div>
                                                                <div className="card-body collapse" id={"facilityCollapse"}>
                                                                    {this.getSelect('facilityID', this.state.facilityName, 'facilityName', 'Facility', 'Testing locations by name.', this.props.facilities)}
                                                                    {this.getSelect('podApptID', this.state.podLabel, 'podLabel', 'Pod', 'Lists the Pod ID, Facility Name, and Pod Date.', pods)}
                                                                </div>
                                                            </div>

                                                            <div className="card mb-2">
                                                                <div className="card-header verlag-bold" data-toggle="collapse" data-target="#additionalCollapse" onClick={() => this.setState({additionalArrowDown: !this.state.additionalArrowDown})} role="button" aria-expanded="false" aria-controls="additionalCollapse">
                                                                    <h3>Additional
                                                                        {this.state.additionalArrowDown ? (<AiOutlineUp className={'float-right'} size={30} data-toggle="collapse" data-target="#additionalCollapse"  role="button" aria-expanded="false" aria-controls="additionalCollapse" />) : (<AiOutlineDown className={'float-right'} size={30}  data-toggle="collapse" data-target="#additionalCollapse"  role="button" aria-expanded="false" aria-controls="additionalCollapse" />)}</h3>
                                                                </div>
                                                                <div className="card-body collapse" id={"additionalCollapse"}>
                                                                    {this.getInput('createdBy', this.state.createdBy, 'Created By', 'The username of who generated a record (applies primarily to walk-ins).', true, 100)}
                                                                    {this.getInput('lastUpdatedBy', this.state.lastUpdatedBy, 'Last Updated By', 'The first name of the Provider that is assigned to a sample.', true, 100)}
                                                                    {this.getDateTimePicker('lastUpdatedDate', this.state.lastUpdatedDate, 'Last Updated Date', 'The date the record was last updated.', true)}

                                                                                                                                        {/*stateToSet, stateLabel, stateLabelToSet, label, tooltip, options, disabled=false*/}
                                                                    {this.getSelect('firstTest', this.state.firstTestLabel, 'firstTestLabel', 'First Test', 'Is this the patient\'s first test?', this.getYesNoOptions())}
                                                                    {this.getSelect('employedHealthcare', this.state.employedInHealthcareLabel, 'employedInHealthcareLabel', 'Employed in Healthcare', 'Is the patient employed in healthcare?', this.getYesNoOptions())}
                                                                    {this.getSelect('symptomatic', this.state.symptomaticLabel, 'symptomaticLabel', 'Symptomatic', 'Is the patient symptomatic?', this.getYesNoOptions())}
                                                                    {this.getSelect('hospitalized', this.state.hospitalizedLabel, 'hospitalizedLabel', 'Hospitalized', 'Is the patient hospitalized?', this.getYesNoOptions())}
                                                                    {this.getSelect('icu', this.state.icuLabel, 'icuLabel', 'ICU', 'Is the patient in the ICU?', this.getYesNoOptions())}
                                                                    {this.getSelect('residentCongregateCare', this.state.residentCongregateCareLabel, 'residentCongregateCareLabel', 'Resident Congregate Care', 'Is the patient in Resident Congregate Care?', this.getYesNoOptions())}
                                                                    {this.getSelect('pregnant', this.state.pregnantLabel, 'pregnantLabel', 'Pregnant', 'Is the patient pregnant?', this.getYesNoOptions())}
                                                                    {this.getSelect('consentFlag', this.state.consentFlagLabel, 'consentFlagLabel', 'Consent Flag', 'Did the patient give consent?', this.getBoolOptions())}
                                                                    {this.getDateTimePicker('createdDate', this.state.createdDate, 'Created Date', 'The date the record was generated.', true)}
                                                                    {this.getDatePicker('onsetSymptomsDate', this.state.onsetSymptomsDate, 'Onset Symptoms Date', 'Date that the patient started experiencing symptoms, if any.')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        </div>
                                    </div>
                                    {this.getLinks()}
                                </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

