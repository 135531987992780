import React, { LegacyRef } from "react";
import domain from "../../Domain";
import FilterCard, { FIELD_TYPE, ReactSelect } from "../FilterCard";
import Form from "../../types/Form";
import {getPageOptions, parseDate, showModalNoOutsideClick} from "../../util/FormatUtil";
import CheckinPatientModal, {ChangeVals} from "../modals/CheckinPatientModal";
import Overlay from "../Overlay";
import PodSelect from "../PodSelect";
import SimpleTable from "../tables/SimpleTable";
import { Sample } from "../../types/Sample";
import { SamplesModal } from "../modals/SamplesModal";
import { RapidTestModal } from "../modals/RapidTestModal";
import { sweetalert } from "../../App";
import TestType from "../../types/TestType";
import PodSched from "../../types/PodSched";
import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import SystemAPI from "../../network/SystemAPI";
import TestsAPI from "../../network/TestsAPI";
import RapidAPI from "../../network/RapidAPI";
import AdminAPI from "../../network/AdminAPI";
import Select from "react-select";
import PaginationTool from "../PaginationTool";

interface CheckinState {
  showLoading: boolean;
  FirstName?: string;
  LastName?: string;
  SpecimenID?: string;
  DOB?: string;
  pod?: any;
  tableData: any[];
  selectedSample?: Sample;
  totalAppts?: number;
  testTypes: TestType[];
  FilterFirstName?;
  FilterLastName?;
  FilterDOB?;
  FilterReqNum?;
  SpecimenSourceID?;
  Lot?;
  TestID?;
  facilities?: { label: string; value: number }[];
  FacilityID?;
  direction: TableOrder;
  pods?: { label: string; value: PodSched }[];
  page_options: ReactSelect[];
  selected_page?;
}

export default class RapidTestManagement extends React.Component<
  any,
  CheckinState
> {
  private checkinRef: React.RefObject<CheckinPatientModal>;

  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      tableData: [],
      selectedSample: {} as Sample,
      testTypes: [],
      totalAppts: 0,
      FacilityID: [],
      direction: "asc",
      page_options: [{ value: "1", label: "1" }],
      selected_page: { value: 1, label: 1 },
    };
    this.assignClearState = this.assignClearState.bind(this);
    this.assignClear2State = this.assignClear2State.bind(this);
    this.clear = this.clear.bind(this);
    this.querySearch = this.querySearch.bind(this);
    this.checkinRef = React.createRef();
  }

  componentDidMount() {
    AdminAPI.getFacilitiesWithAddress().then((data) => {
      this.setState({ facilities: data });
    });
    TestsAPI.getAllTestDetails().then((data) => {
      this.setState({ testTypes: data.tests });
    });

    // pods is not used in this controller
    // AdminAPI.getAllPods(true).then((response) => {
    //   if(!response.success){
    //     console.error(response.reason)
    //     sweetalert.fire("Error Loading Pods.")
    //   } 
    //   this.setState({ pods: response.data as { label: string; value: PodSched }[] });
    // });

    $(".editbtn").on("click", () => {
      $("#SpecimenID").focus();
      $("#SpecimenID").trigger("touchstart");
    });
    $("#SpecimenID").on("touchstart", function () {
      $("#SpecimenID").focus();
      $(this).focus();
    });
  }

  clearFilterState: () => void = null;

  assignClearState(func) {
    console.log("attached");
    this.clearFilterState = func;
  }

  clearState = () => {
    console.log("clear form");
    this.clearFilterState();
  };

  clearFilter2State: () => void = null;

  assignClear2State(func) {
    console.log("attached");
    this.clearFilter2State = func;
  }

  clear2State = () => {
    console.log("clear form");
    this.clearFilter2State();
  };

  querySearch(page) {
    console.log("state in query search", this.state);
    this.setState({ showLoading: true }, async () => {
      try {
        let result = await RapidAPI.rapidSearch(page, {
          filter: {
            PatientFirstName: this.state.FilterFirstName,
            PatientLastName: this.state.FilterLastName,
            PatientDOB: parseDate(this.state.FilterDOB),
            ReqNum: this.state.FilterReqNum,
            PodID: this.state.pod,
            Facilities: this.state.FacilityID ? [this.state.FacilityID] : [],
          },
        });
        if (result.patients.length < 1) {
          sweetalert.fire({
            icon: "error",
            title: "",
            text: "No records found",
          });
          this.setState({ showLoading: false, tableData: [], totalAppts: 0 });
          return;
        }
        result.patients = result.patients.sort(
          (a: Form, b: Form) =>
            a.ApptTime && a.ApptTime.localeCompare(b.ApptTime)
        );
        this.setState({
          tableData: result.table_data,
          totalAppts: result.patients.length,
          page_options: getPageOptions(result.num_pages),
          showLoading: false,
        });
      } catch (e) {
        console.log(e);
        this.setState({ showLoading: false });
        sweetalert.fire({ icon: "error", title: "", text: "Failed" });
      }
    });
  }

  clear() {
    this.clearState();
    this.clear2State();
  }

  onEdit(resultObj: { resultID: number; PatientInformed: boolean }) {
    if (!this.state.selectedSample) return;
    this.setState({ showLoading: true }, async () => {
      try {
        let result = await RapidAPI.rapidEdit({
          PatientID: this.state.selectedSample.ID,
          ResultID: resultObj.resultID,
          PatientInformed: resultObj.PatientInformed,
        });
        if (result.success) {
          sweetalert.fire({ icon: "success", title: "", text: "Results sent" });
          window["$"]("#" + RapidTestModal.ID).modal("hide");
          this.querySearch(1);
          this.clear();
        } else {
          sweetalert.fire({
            icon: "error",
            title: "",
            text: "Failed to edit: " + result.reason,
          });
          this.setState({ showLoading: false });
        }
      } catch (e) {
        console.log(e);
        sweetalert.fire({
          icon: "error",
          title: "",
          text: "Failed to edit, error encountered",
        });
        this.setState({ showLoading: false });
      }
    });
  }

  useSorter(col: Column) {
    let sorter = new Sorter<Sample>()
      .withRawSorterFunc("Name", (a, b) => {
        let aName = a.PatientFirstName + " " + a.PatientLastName;
        let bName = b.PatientFirstName + " " + b.PatientLastName;
        return aName.localeCompare(bName);
      })
      .withRawSorterFunc("Guardian", (a, b) => {
        let aGuardianName = a.GuardianFirstName + " " + a.GuardianLastName;
        let bGuardianName = b.GuardianFirstName + " " + b.GuardianLastName;
        return aGuardianName.localeCompare(bGuardianName);
      })
      .withSorterFunc("TestID", (a: number, b: number) => {
        let aTest = this.state.testTypes.find((t) => t.ID === a);
        let bTest = this.state.testTypes.find((t) => t.ID === b);
        if (!aTest) return -1;
        if (!bTest) return 1;
        return aTest.Name.localeCompare(bTest.Name);
      });

    this.setState({
      tableData: sorter.sortByKey(
        this.state.tableData,
        col.key as keyof Sample,
        this.state.direction
      ),
      direction: this.state.direction === "asc" ? "desc" : "asc",
    });
  }

  render():
    | React.ReactElement
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    console.log(this.state);
    return (
      <React.Fragment>
        <PaginationTool />
        <Overlay show_loading={this.state.showLoading} />
        <RapidTestModal
          tests={this.state.testTypes}
          selectedPatient={this.state.selectedSample}
          onSubmit={(e) => this.onEdit(e)}
        />
        <div className="container-fluid  min-vh-100 ">
          <div className={"row"}>
            <div className="col-12 col-md-12 col-lg-8 col-xl-5 pt-2">
              <div className="card mb-2">
                <div className="card-header verlag-bold">
                  <h3>Rapid Testing Search</h3>
                </div>
                <div className="card-body">
                  <FilterCard
                    passClearStateFunc={this.assignClearState}
                    fields={[
                      {
                        label: "Facility",
                        type: FIELD_TYPE.SELECT,
                        isMapped: true,
                        options: this.state.facilities,
                        key: "FacilityID",
                      },
                      {
                        label: "First Name",
                        key: "FilterFirstName",
                        type: FIELD_TYPE.TEXT,
                      },
                      {
                        label: "Last Name",
                        key: "FilterLastName",
                        type: FIELD_TYPE.TEXT,
                      },
                      {
                        label: "Date of Birth",
                        key: "FilterDOB",
                        type: FIELD_TYPE.DATE,
                      },
                    ]}
                    filterChanged={(e) => this.setState(e)}
                  />
                  <PodSelect
                    testing={true}
                    active={true}
                    multi={true}
                    onChange={(e) => this.setState({ pod: e.pod })}
                  />
                  <FilterCard
                    passClearStateFunc={this.assignClear2State}
                    fields={[
                      {
                        label: "Confirmation Code",
                        key: "FilterReqNum",
                        type: FIELD_TYPE.TEXT,
                        options: [],
                        isMapped: true,
                        onInput: (e) =>
                          (e.target.value = e.target.value.toUpperCase()),
                      },
                    ]}
                    filterChanged={(e) => this.setState(e)}
                  />
                </div>
                <div className="card-footer">
                  <button
                    className="btn btn-outline-primary"
                    onClick={() =>
                      this.setState(
                        {
                          selected_page: {
                            label: 1,
                            value: 1,
                          },
                        },
                        () => this.querySearch(1)
                      )
                    }
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-outline-danger float-right"
                    onClick={this.clear}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>

            {this.state.totalAppts > 0 ? (
              <div className="col-12 col-md-12 col-lg-12 pt-2">
                <div className="card mt-2 mb-5">
                  <div className="card-header verlag-bold stickToTop">
                    <h3>
                      Records
                      <section className="tableHeaderSection float-md-right ">
                        <h3 className={"pr-2"} style={{ float: "right" }}>
                          Total: {this.state.totalAppts}
                        </h3>
                        <h4 className="d-inline-flex pr-2 float-md-right align-items-md-center pr-md-2 ml-md-5">
                          Page
                        </h4>
                        <div className=" align-middle float-right pages">
                          <Select
                            isSearchable={true}
                            placeholder={"1"}
                            noOptionsMessage={() => "No option"}
                            value={this.state.selected_page}
                            onChange={(e: ReactSelect) =>
                              this.setState({ selected_page: e }, () =>
                                this.querySearch(e.value)
                              )
                            }
                            className={"state_select page-num-select"}
                            options={this.state.page_options}
                          />
                        </div>
                      </section>
                    </h3>
                  </div>
                  <div className="card-body p-0 m-0 table-responsive-md">
                    <SimpleTable
                      columns={[
                        {
                          label: "Name",
                          key: "Name",
                          rawFormat: (v) => {
                            return (
                              <a
                                href={"#!"}
                                onClick={() =>
                                  this.setState(
                                    {
                                      selectedSample: JSON.parse(
                                        JSON.stringify(v)
                                      ),
                                    },
                                    () => showModalNoOutsideClick(RapidTestModal.ID)
                                  )
                                }
                              >
                                {v.PatientFirstName + " " + v.PatientLastName}
                              </a>
                            );
                          },
                        },
                        {
                          label: "DOB",
                          key: "PatientDOB",
                          formatFunc: parseDate,
                        },
                        { label: "Specimen ID", key: "SpecimenID" },
                        { label: "Lot", key: "Lot" },
                        {
                          label: "Test",
                          key: "TestID",
                          formatFunc: (val) => {
                            let found: TestType = this.state.testTypes.find(
                              (f) => f.ID === val
                            );
                            return found ? found.Name : "";
                          },
                        },
                        {
                          label: "Guardian",
                          key: "Guardian",
                          rawFormat: (v: Sample) => {
                            return v.GuardianFirstName
                              ? v.GuardianFirstName + " " + v.GuardianLastName
                              : "";
                          },
                        },
                      ]}
                      table_data={this.state.tableData}
                      columnClickedCallback={(col) => {
                        this.useSorter(col);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
