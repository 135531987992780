import React,{FC} from "react";
import QRCode from "react-qr-code";
import {RerunReport} from "./RerunReport";

export type AccessionRecordType = {
    SpecimenID: string,
    FacilityAbbreviation: string,
    InternalSpecimenID: string,
    RerunCount: number
};

interface AccessionRecordProps{
    rowData: AccessionRecordType,
}

export const AccessionRecord: FC<AccessionRecordProps> = ({rowData}) : JSX.Element => {
    // console.log('rowData in AccessionRecord', rowData)
            return (
                <React.Fragment>
                    <div className="col-12" style={rowData.RerunCount > 0 ? { backgroundColor: 'lightblue'} : {backgroundColor: 'white'}}>
                        <div className='row' style={{fontSize: '0.7em', fontWeight: 'bold'}}>
                            <div className="col-12" >
                                <div style={{textAlign: 'center'}}>{rowData.SpecimenID ? rowData.SpecimenID : ''}</div>
                                <div style={{textAlign: 'center'}}>{rowData.FacilityAbbreviation ? rowData.FacilityAbbreviation: '' }</div>
                            </div>
                        </div>
                        {rowData.InternalSpecimenID ?
                        <div className="row" style={{alignItems: 'center'}}>
                            <div className='col-3' style={{padding: 0, writingMode: 'vertical-rl', textOrientation: 'mixed', fontSize: '0.7em', fontWeight: 'bold'}}>
                                {rowData.InternalSpecimenID ? rowData.InternalSpecimenID : '' }
                            </div>
                            <div className="col-9 p-1">
                                <QRCode value={rowData.InternalSpecimenID ? rowData.InternalSpecimenID : ''}
                                        size={50}/>
                            </div>
                        </div>
                            :
                        <div className="row">
                            <div className="col-9 pt-4 pl-3 font-weight-bold">

                            </div>
                        </div>
                            }

                    </div>
                </React.Fragment>
            )

    }

