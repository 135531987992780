import React, {FC} from "react";
import QRCode from "react-qr-code";
import Overlay from "../Overlay";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import ReactToPrint from "react-to-print";
import domain from "../../Domain";
import PhoneInput from "react-phone-number-input";
import moment from "moment";
import Select from "react-select";
import {parseDate} from "../../util/FormatUtil";
import {sweetalert} from "../../App";
import SimpleTable from "../tables/SimpleTable";
import {LabAPI} from "../../network/LabAPI";
import AdminAPI from "../../network/AdminAPI";
import SystemAPI from "../../network/SystemAPI";

interface ReconcileReportState {
    filter: {cStartDate, cEndDate}
    show_loading: boolean
    dataAList: any
    facilities: any
    selectedFacilities:{label,value}[]
}

export class SamplesStatusReport extends React.Component<any, ReconcileReportState> {
    private componentRef: any;

    constructor(props) {
        super(props);
        this.state = {
            filter: {cStartDate: '', cEndDate: ''},
            show_loading: false,
            dataAList: [],
            selectedFacilities: [],
            facilities: []
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.clear = this.clear.bind(this);
        this.assignClearState = this.assignClearState.bind(this);
    }

    componentDidMount(): void {
        this.setState({show_loading: true}, () => {
            AdminAPI.getAllFacilities().then(data => {
                let facilityNamesMapped = data.authorizedFacilities.map(fac => {
                    return {
                        label: fac.FacilityName,
                        value: fac.ID
                    }
                })
                this.setState({facilities: facilityNamesMapped, show_loading: false});
            })
        })
    }

    clear() {
        this.setState({
            filter: {cStartDate: '', cEndDate: ''},
            show_loading: false,
            dataAList: [],
            selectedFacilities: [],
        });
    }

    clearFilterState: () => void = null;

    assignClearState(func) {
        this.clearFilterState = func;
    }

    async onSubmit(e) {
        if (this.state.filter['cStartDate'] === '' || this.state.filter['cEndDate'] === '') {
            return sweetalert.fire({
                icon: 'error',
                title: '',
                text: 'Please input a both Collection Start Date and End Date'
            });
        }
        else {
            this.setState({
                show_loading: true
            });
            let res = await LabAPI.reconcileSamples(this.state.filter.cStartDate, this.state.filter.cEndDate, this.state.selectedFacilities.map(obj => obj.value));
            this.setState({
                show_loading: false
            });
            if(!res.success){
                return sweetalert.fire({
                    icon: 'error',
                    title: '',
                    text: res.reason
                });
            }else if(res.data.length === 0){
                return sweetalert.fire({
                    icon: 'error',
                    title: '',
                    text: 'No data found'
                });
            }
            this.setState({
                dataAList: res.data,
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <Overlay show_loading={this.state.show_loading}/>
                    <div className="container-fluid  ">
                        <div className={"row"}>
                            <div className="col-12 col-md-12 offset-xl-3 col-xl-6 offset-lg-2 col-lg-8  pt-2">
                                <div className="card mb-2">
                                    <div className="card-header verlag-bold">
                                        <h3>Samples Status Report</h3>
                                    </div>
                                    <div className="card-body">
                                        <FilterCard passClearStateFunc={this.assignClearState}
                                                    fields={[
                                                        {
                                                            label: "Collection Start Date",
                                                            "key": "cStartDate",
                                                            type: FIELD_TYPE.DATE,
                                                        },
                                                        {
                                                            label: "Collection End Date",
                                                            "key": "cEndDate",
                                                            type: FIELD_TYPE.DATE,
                                                        },
                                                    ]} filterChanged={(filter) => this.setState({filter: filter})}/>
                                        <div className="form-group row">
                                            <div className={"col-4"}>
                                                <label htmlFor={'Facility'} style={{
                                                    fontWeight: 'bold',
                                                    paddingTop: '0.5em'
                                                }}>Facility</label>
                                            </div>
                                            <div className={"col-8 pl-0 pr-1"}>
                                                <Select
                                                    id={'Facility'}
                                                    isSearchable={true}
                                                    placeholder={"Please Select..."}
                                                    onChange={(e:{label, value}[]) => this.setState({selectedFacilities: e, dataAList: []})}
                                                    className={"state_select"}
                                                    isMulti={true}
                                                    options={this.state.facilities}
                                                    value={this.state.selectedFacilities}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <button
                                            className="btn btn-outline-primary"
                                            onClick={(e) => this.onSubmit(e)}>
                                            Search
                                        </button>
                                        <button className="btn btn-outline-danger ml-2" onClick={() => {
                                            this.clear();
                                            this.clearFilterState();
                                        }}>Clear
                                        </button>
                                        {this.state.filter.cStartDate && this.state.filter.cEndDate && this.state.dataAList.length > 0 &&
                                            <ReactToPrint
                                                trigger={() => <button className={"btn btn-outline-success"}
                                                                       style={{float: 'right'}}>Print Report</button>}
                                                content={() => this.componentRef}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"container-fluid mb-2"} ref={el => (this.componentRef = el)}>
                        {this.state.dataAList.length > 0 &&
                            <div>
                                <p><b>{"Samples Status Report"}</b></p>
                                <p>{"Collection Start Date: " + parseDate(this.state.filter.cStartDate)}</p>
                                <p>{"Collection End Date: " + parseDate(this.state.filter.cEndDate)}</p>
                                <SimpleTable table_style={" pt-1 text-center"} columns={[
                                    {label:"Collection Date",key:"CollectionDate"},
                                    {label:"Collected", key:"CollectCount"},
                                    {label:"Rejected", key:"RejectedCount"},
                                    {label:"Received", key:"ReceivedCount"},
                                    {label:"Collected Not Received", key:"CollNotRecvCount"},
                                    {label:"Processed", key:"ProcessedCount"},
                                    {label:"Approved and Resulted", key:"ApprovedCount"},
                                    {label:"Received Not Resulted", key:"CollNotResCount"},
                                    {label:"Results Accessed by Patient", key:"AccessedCount"},
                                    {label:"Results Not Accessed", key:"ResNotAccessCount"},
                                ]} table_data={this.state.dataAList}/>
                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}