import moment from "moment";
import momentTZ from 'moment-timezone';
import domain from "../Domain";
import PodSched from "../types/PodSched";
import {RapidTestModal} from "../components/modals/RapidTestModal";


export const getBrandingImage = async (name:string) =>{
    let res = await fetch(domain + "/api/system/image", {method:"POST",   headers: {'Content-Type': 'application/json'}, body:JSON.stringify({Name:name})});
    let obj = await res.json();
    return obj.Link;
}

// Takes a DATE 2019-01-30 -> 01-30-2019
export const formatUSDate = (x, splitter?) =>{
    if(!x)
        return "";
    x = x.split(splitter ? splitter : '-');
    return x[1] + "-" + x[2] + "-" + x[0];
};

// Takes a DATE 2019-01-30T00:00:00 -> 2019-01-30
export const parseDate = (x:string | Date, noFormat?:boolean):string =>{


    if(!x)
        return "";
    if(x instanceof Date)
        x = x.toISOString()


    x = x.slice(0, 10);
    return !noFormat ? formatUSDate(x) : x;
};

export const getTwelveHourAMPMTime = (x:string) => {
    if(!x)
        return "";
    return moment(x, "hh:mm").format('LT')
}

export const toLocalDateTime = (x:string):string =>{
    if(!x){
        return "";
    }
    let utcMoment = moment.utc(x).toDate();
    return moment(utcMoment).local().format('MM-DD-YYYY h:mm a');
};


export function getFormattedDBTime(time:string){
    return moment(time, 'HH:mm:ss').format('HH:mm');
}
export const toLocalDate = (x:string):string =>{
    let utcMoment = moment.utc(x, 'YYYY-MM-DD hh:mm:ss.SSSSSSS').format('YYYY-MM-DD HH:mm:ss.SSS');
    return moment.utc(utcMoment).format('YYYY-MM-DD hh:mm a');
};

export const hideModal = (id:string)=>{
    window['$']("#" +  id).modal('hide');
}

export const showModalNoOutsideClick = (id:string) => {
    window["$"]("#"+id).modal({
        backdrop: "static", keyboard:false
    });
}

export const toReactSelectList = (arr:string[]) =>{
    return arr.map(s => ({label:s, value:s}))
};

export const formatMultiselect = (data, label_key, value_key) =>{
    return data.map(d =>{
        return {label:d[label_key], value:d[value_key]}
    })
};

export const getPageOptions = (n:number) =>{
    let options = []
    for (let i = 1; i <= n; i++) {
        options.push({value: i, label: i})
    }
    return options
};

export const getDateOrNull = (val) => {
    return val ? moment(val, ['MM/DD/YYYY', 'MM-DD-YYYY', 'MM/DD/YYYY h:mm a', 'MM-DD-YYYY h:mm a'], true) : null;
}

export const buildRow = (label, content) => {
    return (
        <div className="form-group row">
            <label htmlFor={label} className="col-sm-4 col-form-label">{label}</label>
            <div className="col-sm-8  p-0 m-0" id={label}>
                {content}
            </div>
        </div>
    )
}

export function getNumPages (rows:any[], itemsPerPage) {
    return rows.length % itemsPerPage > 0 ? rows.length / itemsPerPage + 1 : rows.length / itemsPerPage
}

export function slicePages (rows:any[], page, itemsPerPage) {
    page = page ? page : 1
    return rows.slice(itemsPerPage * (page - 1), itemsPerPage * page)
}

export const getBoolSelectVal = (val) => {
    return val === 1 ? {label:"Yes", value:true} : {label:"No", value:false}
}

export const getBoolSelectOptions = () => {
    return [
        { label: "Yes", value: true },
        { label: "No", value: false }
    ]
}

export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}