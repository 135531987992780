import React from "react";
import Overlay from "../Overlay";
import domain from "../../Domain";
import BarcodeReader from 'react-barcode-reader';
import {ToastContainer, toast, ToastOptions} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {sweetalert} from "../../App";
import {BatchSelect} from "./selects/BatchSelect";
import Batch from "../../types/Batch";
import {WellPlateMap} from "./WellPlateMap";
import BatchesAPI from "../../network/BatchesAPI";
import {WellSlot} from "../../types/WellSlot";
import {WellMapData} from "./WellPlateReport";
import Select from "react-select";
import {BinAPI} from "../../network/BinAPI";
import {BinType} from "../../types/BinType";
import {BatchManagementModal} from "../modals/BatchManagementModal";
import {hideModal, showModalNoOutsideClick} from "../../util/FormatUtil";
import ReactToPrint from "react-to-print";
import AdminAPI from "../../network/AdminAPI";

let audioSuccess = new Audio('/sound/Success.wav');
let audioError = new Audio('/sound/Beep.wav');


interface AccessionState {
    showLoading?
    specimenUpdate: boolean
    internalSpUpdate: boolean
    isHorizontal: boolean
    selectedBatch: Batch,
    plateData: WellMapData[],
    slotMap: WellSlot[],
    selectedBin: { label, value: BinType }
    bins: BinType[]
    scannedSpecimenID: string
    scannedInternalID: string,
    batches:Batch[]
    disabledScan?:boolean
    collectionDateWarning?:string
}

export class AccessionApp extends React.Component<{}, AccessionState> {
    trigger: boolean
    private componentRef: any;
    toastOptions: ToastOptions = {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }

    constructor(props) {
        super(props);
        this.state = {
            showLoading: true,
            specimenUpdate: false,
            internalSpUpdate: true,
            isHorizontal: true,
            selectedBatch: null,
            plateData: [],
            slotMap: [],
            bins: [],
            selectedBin: null,
            scannedSpecimenID: '',
            scannedInternalID: '',
            batches:[],
            collectionDateWarning:''
        };
        this.handleScan = this.handleScan.bind(this);
        this.clear = this.clear.bind(this);
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            AdminAPI.getTablePlatingDirection().then(data => {
                this.setState({isHorizontal: data.isHorizontal})
            })
            BinAPI.getAllBins().then(data => {
                this.setState({bins: data})
            })
            BatchesAPI.getAllBatches().then(data => {
                this.setState({batches: data, showLoading: false})
            })
            BatchesAPI.getCollectionDateWarningSetting().then(data => {
                this.setState({collectionDateWarning: data.warningMessage})
            })
        })
    }


    async handleScan(scan) {
        // if error on scan, close popup when we scan again
        if(sweetalert.isVisible()){ sweetalert.close(); }

        console.log('scan in handleScan', scan)
        if (this.trigger || this.state.disabledScan) {
            audioError.play();
            return sweetalert.fire({
                icon: 'error',
                title: '',
                text: "Please wait for the server the process your request"
            });
        }
        if (!this.state.selectedBatch) {
            return sweetalert.fire({
                icon: 'error',
                title: '',
                text: "Please select or create a new batch."
            });
        }
        if (!this.state.selectedBin && !scan.startsWith("BC")) {
            return sweetalert.fire({
                icon: 'error',
                title: '',
                text: "Please select or scan the Bin that this specimen came from."
            });
        }
        if(scan.startsWith("BC")){
            let bin = this.state.bins.find(b => b.BinID === scan);
            if(bin){
                this.setState({selectedBin: {label: bin.BinID, value: bin}})
                audioSuccess.play();
                toast.success("Bin Set", this.toastOptions);
                return
            }
            audioError.play();
            toast.error("No bin found", this.toastOptions);
            return;
        }

        // If this is an internal specimen ID
        if (scan.startsWith("A") && scan.length === 9) {
            if (!this.state.scannedSpecimenID) {
                audioError.play();
                return sweetalert.fire({
                    icon: 'error',
                    title: '',
                    text: "You cannot scan Internal Specimen ID without scanning Specimen ID"
                });
            }
            this.trigger = true;
            this.setState({showLoading: true, scannedInternalID: scan}, async () => {
                let res = await BatchesAPI.accessionSample({
                    specimenID: this.state.scannedSpecimenID,
                    internalSpecimenID: this.state.scannedInternalID,
                    sampleReceivingID: this.state.selectedBin.value.ID,
                    batchID: this.state.selectedBatch.ID,
                    isHorizontalPlate: this.state.isHorizontal,
                });
                this.setState({showLoading: false, scannedSpecimenID: '', scannedInternalID: ''});
                this.trigger = false;
                if (!res.success) {
                    audioError.play();
                    return sweetalert.fire({icon: 'error', title: '', text: res.reason});
                } else {
                    audioSuccess.play();
                    toast.success("Sample Accessioned", this.toastOptions);
                    this.retrieve();
                }
            });
        } else {
            if (this.state.scannedSpecimenID) {
                audioError.play();
                return sweetalert.fire({
                    icon: 'error',
                    title: '',
                    text: "You cannot scan Specimen ID again. Please scan Internal Specimen."
                });
            }
            this.trigger = true;
            this.setState({showLoading: true, disabledScan:true})
            let checkResponse = await BatchesAPI.precheckSpecimen(scan, this.state.selectedBatch.ID);
            this.trigger = false;
            this.setState({showLoading: false, disabledScan:false});
            if (!checkResponse.success) {
                sweetalert.fire({icon: 'error', title: '', text: checkResponse.reason});
                return;
            }
            if (checkResponse.isOldSample) {
                audioError.play();

                let result = await sweetalert.fire({
                    icon: 'error',
                    title: '',
                    text: this.state.collectionDateWarning,
                    confirmButtonText: "Continue",
                    cancelButtonText: "Cancel",
                    allowOutsideClick: false,
                    showCancelButton: true,
                });
                if (!result.isConfirmed) {
                    return;
                }
            }
            if (checkResponse.isRerun) {
                audioError.play();
                await sweetalert.fire({icon: 'error', title: '', text: "This Specimen is a Rerun"});
            }
            audioSuccess.play();
            this.setState({scannedSpecimenID: scan})
        }
    }

    getStatus() {
        if (!this.state.selectedBatch) {
            return "Please select or create a new batch."
        } else if (!this.state.selectedBin) {
            return "Please select a bin."
        } else if (!this.state.scannedSpecimenID) {
            return "Please scan a specimen ID.";
        } else if (!this.state.scannedInternalID) {
            return "Please scan an internal specimen ID.";
        }
        return "";
    }

    async retrieve() {
        this.setState({showLoading: true});
        let response = await BatchesAPI.getBatchMap(this.state.selectedBatch.ID);
        if (!response.success) {
            sweetalert.fire({icon: 'error', title: '', text: response.reason});
            this.setState({
                plateData: []
            });
        } else {
            this.setState({
                plateData: response.data,
                slotMap: response.wellSlots,
            })
        }
        this.setState({showLoading: false});
    }

    createNewBatch(batch){
        hideModal(BatchManagementModal.ID);
        this.setState({showLoading: true}, async() => {
            let response = await BatchesAPI.createBatch(batch);
            if(!response.success){
                sweetalert.fire({title: '', text: response.reason, icon: 'error'})
            }else{
                this.setState({selectedBatch:null});
                sweetalert.fire({title: '', text: 'Batch saved', icon: 'success'})
            }
            let batches = await BatchesAPI.getAllBatches();
            this.setState({showLoading:false, batches: batches, selectedBatch: batches.find(b => b.BatchNum === batch.BatchNum)}, () =>{
                this.retrieve();
            });
        });
    }

    clear() {
        this.setState({
            showLoading: false,
            specimenUpdate: false,
            internalSpUpdate: true,
            scannedSpecimenID: '',
            scannedInternalID: ''
        });
    }

    render() {
        return <React.Fragment>
            <Overlay show_loading={this.state.showLoading}/>
            <ToastContainer/>
            <BarcodeReader
                onError={(err) => console.error(err)}
                onScan={this.handleScan}
            />
            <BatchManagementModal selectedBatch={null} onSubmit={(batch) => this.createNewBatch(batch)}/>
            <div className="container-fluid  min-vh-100 ">
                <div className={"row"}>
                    <div className="offset-xl-4 col-xl-4 col-lg-6 col-md-6 col-12 pt-2">
                        <div className="card">
                            <div className="card-header verlag-bold">
                                <h3>Accessioning App</h3>
                            </div>
                            <div className="card-body">
                                <BatchSelect
                                    batches={this.state.batches}
                                    selectedBatch={this.state.selectedBatch}
                                    onSelected={(batch: { label, value: Batch }) => this.setState({selectedBatch: batch.value}, () => {
                                        this.retrieve()
                                    })}/>
                                <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'}
                                     title={'Lists the bin IDs'}>
                                    <label id={'Bin'} htmlFor={"Bin"} className="col-sm-4 col-form-label">Scanning from
                                        Bin:</label>
                                    <div className="col-sm-8 p-0 m-0">
                                        <Select
                                            id={"Bin"}
                                            isSearchable={true}
                                            placeholder={"Please Select..."}
                                            noOptionsMessage={() => "No option"}
                                            isDisabled={!this.state.selectedBatch}
                                            value={this.state.selectedBin}
                                            onChange={(e) => this.setState({selectedBin: e})}
                                            className={"state_select"}
                                            options={this.state.bins.map(b => ({label: b.BinID, value: b}))}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'}
                                     title={'Scanned ID'}>
                                    <label id={'BarcodeScan'} htmlFor={"BarcodeScan"}
                                           className="col-sm-4 col-form-label">Scanned Specimen ID:</label>
                                    <div className="col-sm-8  p-0 m-0">
                                        <input
                                            id={"BarcodeScan"}
                                            type="search"
                                            className={'form-control'}
                                            value={this.state.scannedSpecimenID}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'}
                                     title={'Scanned Internal ID'}>
                                    <label id={'InternalIDScan'} htmlFor={"InternalIDScan"}
                                           className="col-sm-4 col-form-label">Scanned Internal ID:</label>
                                    <div className="col-sm-8  p-0 m-0">
                                        <input
                                            id={"InternalIDScan"}
                                            type="search"
                                            className={'form-control'}
                                            value={this.state.scannedInternalID}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <p>Status: {this.getStatus()} </p>
                            </div>
                            <div className={"card-footer"}>
                                <button className={"btn btn-outline-primary"}
                                        onClick={() => {
                                            showModalNoOutsideClick(BatchManagementModal.ID)
                                        }}>Create New Batch
                                </button>
                                {this.state.selectedBatch &&
                                    <ReactToPrint
                                        trigger={() =><button className={"btn btn-outline-success float-right"}>Print</button>}
                                        content={() => this.componentRef}/>
                                }
                            </div>
                        </div>
                    </div>
                    {this.state.selectedBatch &&
                    <WellPlateMap batch={this.state.selectedBatch}  ref={el => (this.componentRef = el)} batchNum={this.state.selectedBatch ? this.state.selectedBatch.BatchNum : ""}
                                  plateData={this.state.plateData} slotMap={this.state.slotMap}/>}
                </div>
            </div>
        </React.Fragment>;
    }
}