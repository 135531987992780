import moment from "moment";

export type TableOrder = "asc" | "desc";

export class Sorter<T>{

    sortFunctions = new Map<keyof T, (a:T[keyof T], b:T[keyof T]) => number>();
    rawSorterFunctions = new Map<string, (a, b) => number>() // Used for sorting special formatted types that are not part of a Typed object, like Name.

    public withSorterFunc(key:keyof T, sortFunc:(a:T[keyof T], b:T[keyof T]) => number):Sorter<T>{
        this.sortFunctions.set(key, sortFunc);
        return this;
    }

    public withRawSorterFunc(key:string, sortFunc:(a, b) => number):Sorter<T>{
        this.rawSorterFunctions.set(key, sortFunc);
        return this;
    }

    public defaultSort(a:T[keyof T], b:T[keyof T]){
        if(!a)
            return -1;
        if(!b)
            return 1;

        // sort strings
        if(typeof a === 'string' && typeof b === 'string'){
            return a.localeCompare(b);
        }
        // sort numbers
        if(typeof a === 'number' && typeof b === 'number'){
            return a - b;
        }

        //sort dates
        if(a instanceof Date && b instanceof Date){
            return a.getTime() - b.getTime();
        }
        //sort moment objects
        if(moment.isMoment(a) && moment.isMoment(b)){
            return a.diff(b);
        }

        if(a > b){
            return 1;
        }
        if(a < b){
            return -1;
        }
        return 0;
    }

    /**
     * Sorts an array by the given key with the given sort function
     * If InPlace is false, the array will be copied and the original array will not be changed
     * If no sort function is given, the default sort function will be used.
     */
    public sortByKey(sorterData:T[], key:keyof T, dataOrder:TableOrder, inPlace?:boolean):T[]{
        let data:T[] = inPlace ? sorterData : JSON.parse(JSON.stringify(sorterData));
        if(this.sortFunctions.has(key)){
            data.sort((a, b) => {
                return this.sortFunctions.get(key)(a[key], b[key]);
            });
        }else if(this.rawSorterFunctions.has(key as string)){
            data.sort(this.rawSorterFunctions.get(key as string));

        } else{
            data.sort((a, b) => this.defaultSort(a[key], b[key]));
        }
        return dataOrder === "desc" ? data.reverse() : data;
    }
}