import React from "react";
import AdminNav from "./AdminNav";
import domain from "../../Domain";
import Alert from "./Alert";
import SamplesReport from "./SamplesReport";
import WalkinForm from "./WalkinForm";
import {WellPlateReport} from "./WellPlateReport";
import {BinReceiving} from "./BinReceiving";
import {InternalSpecimen} from "./InternalSpecimen";
import {MachineExport} from "./MachineExport";
import {Facility} from "./Facility";
import {RerunReport} from "./RerunReport";
import {BatchReporting} from "./BatchReporting";
import {SamplesStatusReport} from "./SamplesStatusReport";
import {AccessionApp} from "./AccessionApp";

import IdleTimer from "react-idle-timer";
import {sweetalert} from "../../App";
import AdminAPI from "../../network/AdminAPI";


interface AdminPanelState {
    selectedPage?: string
    showLoading: boolean
    canRender
    isExpiredAlready
}

class AdminPanel extends React.Component<any, AdminPanelState> {

    idleTimer;

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            canRender: false,
            isExpiredAlready:false
        }
        this.idleTimer = null
        this.handleOnAction = this.handleOnAction.bind(this)
        this.handleOnActive = this.handleOnActive.bind(this)
        this.handleOnIdle = this.handleOnIdle.bind(this)
        this.checkAuth = this.checkAuth.bind(this);
    }

    async checkIsLoggedIn(){
        this.setState({showLoading: true})
        try {
            let result = await AdminAPI.getAuthenticated({})
            if (!result.success) {
                await sweetalert.fire({
                    title: "Error",
                    text: result.reason,
                    icon: "error",
                    confirmButtonText: "OK"
                });
                window['location'] = "/" as unknown as Location
                return;
            }
            this.setState({showLoading: false, canRender: true})
        }catch (e) {
            this.setState({showLoading: false})
            // @ts-ignore
            window["location"] = "/login"
        }

    }

    async checkAuth(){
        console.log("validate still logged in")
        this.setState({showLoading: true})
        try{
            let result = await AdminAPI.getAuthenticated({})
        }catch (e) {
            if(!this.state.isExpiredAlready){
                this.setState({isExpiredAlready:true}, () => {
                    // @ts-ignore
                    document.getElementById("logoutForm").submit();
                });
            }
        }
    }

    handleOnAction (event) {}

    handleOnActive (event) {}

    handleOnIdle (event) {
        if(!this.state.isExpiredAlready) {
            this.setState({isExpiredAlready:true}, () =>{
                // @ts-ignore
                document.getElementById("logoutForm").submit();
            })
        }
    }

    componentDidMount() {
        this.checkIsLoggedIn();
        setInterval( this.checkAuth, 60000 * 20) // 20 min
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined{

        return (
            <React.Fragment>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    timeout={1000 * 60 * 16}
                    onActive={this.handleOnActive}
                    onIdle={this.handleOnIdle}
                    onAction={this.handleOnAction}
                    debounce={250}
                />
                <div id={"main"}>
                    <AdminNav onChange={(str => this.setState({selectedPage: str}))} />
                </div>

            </React.Fragment>
        )
    }
}

export default AdminPanel