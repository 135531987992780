import domain from "../Domain";
export default class NetworkUtil {

    public static async downloadCSV(route:string, fileName:string, body:any):Promise<boolean>{
        return new Promise((resolve, reject) =>{
            let xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = () => {
                if (xhttp.readyState === 4 && xhttp.status === 200) {
                    // make downloadable link
                    let a = document.createElement('a')
                    a.href = window.URL.createObjectURL(xhttp.response)
                    a.download = fileName; // give the download a filename
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                    resolve(true);
                }
            };
            xhttp.open("POST",  route)
            xhttp.setRequestHeader("Content-Type", "application/json");
            xhttp.responseType = 'blob';
            xhttp.send(JSON.stringify(body));
        })
    }

    public static buildURI(route: string): string {
        if(route[0] !== "/") {
            route = "/" + route;
        }
        return domain + route;
    }

    public static async makeGet<T>(route:string):Promise<T>{
        let res = await fetch(NetworkUtil.buildURI(route));
        let json = await res.json();
        return json as T;
    }

    public static async makePost<T>(route: string, body: any): Promise<T> {
        let res = await fetch(NetworkUtil.buildURI(route), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
        let json = await res.json();
        return json as T;
    }

    /**
     * Does not stringify the body or sent application/json headers.
     * Should only be used when posting FORM data, not data serialized as JSON.
     */
    public static async postForm<T>(route: string, body: any): Promise<T> {
        let res = await fetch(NetworkUtil.buildURI(route), {
            method: 'POST',
            body: body
        });
        let json = await res.json();
        return json as T;
    }
}