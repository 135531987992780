
import React from "react";
import Overlay from "../Overlay";
import Select from "react-select";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import moment from "moment";
import {PatientInformation} from "../PatientInformation";
import {Sample} from "../../types/Sample";
import {GuardianForm} from "../GuardianForm";
import {getDateOrNull} from "../../util/FormatUtil";
import {calculateAge, dateInFuture, isBlank} from "../../util/ValidationUtil";
import TestType from "../../types/TestType";
import {FaCameraRetro} from "react-icons/fa";
import {sweetalert} from "../../App";
import BarcodeScannerComponent from "../BarcodeScannerComponent";
import DatePicker from 'react-datepicker'
import SystemAPI from "../../network/SystemAPI";
import TestsAPI from "../../network/TestsAPI";
import WalkinAPI from "../../network/WalkinAPI";
import Validator, {ValidationEntry} from "../../validation/Validator";
import {Validators} from "../../validation/Validators";
import AdminAPI from "../../network/AdminAPI";


interface FacilityFormState{
    showLoading:boolean
    patientInformation:Sample
    guardian: { GuardianFirstName: string, GuardianLastName: string }
    sampleInformation?:{
        SpecimenID
        FacilityID
        CollectionDate
        TestOrderedDate
    }
    SpecimenSourceID?
    FacilityID?
    Memo?
    SpecimenID?
    facilities?
    tests:TestType[]
    Lot?
    selectedTest?:{value, label} | any
    collectionDate?
    testOrderedDate?
    isSubmitting
    showScanner:boolean
    validSpecimenSources?
    sources?
}

interface FacilityFormProps{}

//TODO: Break out logic
export default class WalkinForm extends React.Component<FacilityFormProps, FacilityFormState>{

    constructor(props) {
        super(props);
        this.state = {
            patientInformation:{} as Sample,
            guardian: {GuardianFirstName: "", GuardianLastName: ""},
            collectionDate:new Date(),
            testOrderedDate:new Date(),
            validSpecimenSources:[],
            sources: [],
            showLoading:false,
            isSubmitting: false,
            tests:[],
            showScanner:false
        };
        this.submit = this.submit.bind(this);
        this.assignClearForm = this.assignClearForm.bind(this);
        this.handleScan = this.handleScan.bind(this);
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            TestsAPI.getValidSpecimenSources().then(data => {
                this.setState({validSpecimenSources: data})
            })
            AdminAPI.getFacilitiesWithAddress().then(data => {
                this.setState({facilities: data})
            })
            TestsAPI.getAllTestDetails().then(data => {
                this.setState({tests: data.tests, showLoading: false})
            })
        })
    }

    submit(){
        if(!PatientInformation.validatePatient(JSON.parse(JSON.stringify(this.state.patientInformation))))
            return;


        console.log('select test on submit', this.state.selectedTest)

        let formValidation = {
            PatientDOB: this.state.patientInformation.PatientDOB,
            GuardianFirstName: this.state.guardian.GuardianFirstName,
            GuardianLastName: this.state.guardian.GuardianLastName,
            SelectedTest: this.state.selectedTest,
            TestOrderedDate: this.state.testOrderedDate,
            CollectionDate: this.state.collectionDate,
            SpecimenID: this.state.SpecimenID,
            SpecimenSourceID: this.state.SpecimenSourceID,
            FacilityID: this.state.FacilityID
        }

        let validator = new Validator<any>()
            .withValidation("PatientDOB", Validators.requireDOB(150, "Patient Date of Birth"))
        if(calculateAge(this.state.patientInformation.PatientDOB) < 18){
            validator = validator.withValidation("GuardianFirstName", Validators.requireNotBlankAndLength(50, "Guardian First Name"))
                .withValidation("GuardianLastName", Validators.requireNotBlankAndLength(50, "Guardian Last Name"))
        }
            validator = validator.withSimpleValidation("SelectedTest", Validators.requireNonNullValidator("Test Type"))
                .withSimpleValidation("FacilityID", Validators.requireNonNullValidator("Facility"))
                .withComposedValidation("CollectionDate", new ValidationEntry(Validators.requireNotFutureDate("Collection Date")), new ValidationEntry(Validators.requireNonNullValidator("Collection Date")))
                .withComposedValidation("TestOrderedDate", new ValidationEntry(Validators.requireNotFutureDate("Test Ordered Date")), new ValidationEntry(Validators.requireNonNullValidator("Test Ordered Date")))
                .withSimpleValidation("SpecimenSourceID", Validators.requireNonNullValidator("Specimen Source"))

        if(this.state.selectedTest){
            let test = this.state.tests.find(t => t.ID === this.state.selectedTest.value);
            if(test.IsLabTest){
                validator = validator.withSimpleValidation("SpecimenID", Validators.requireNotBlankValidator("Specimen ID"))
            }
        }
        let validationResponse = validator.validate(formValidation);
        if(!validationResponse.success) {
            return sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
        }

        let patientInfo:Sample = JSON.parse(JSON.stringify(this.state.patientInformation));

        if(this.state.guardian && this.state.guardian.GuardianFirstName){
            patientInfo.GuardianFirstName = this.state.guardian.GuardianFirstName.trim();
            patientInfo.GuardianLastName = this.state.guardian.GuardianLastName.trim();
        }

        let stateCopy = JSON.parse(JSON.stringify(this.state))
        delete patientInfo['confirmEmail'];
        patientInfo.FacilityID = stateCopy.FacilityID ? stateCopy.FacilityID.value : null;
        patientInfo.CollectionDate = getDateOrNull(this.state.collectionDate);
        patientInfo.TestOrderedDate = getDateOrNull(this.state.testOrderedDate);
        patientInfo.SpecimenID = stateCopy.SpecimenID ? stateCopy.SpecimenID.trim() : null;
        patientInfo.SpecimenSourceID = stateCopy.SpecimenSourceID ? stateCopy.SpecimenSourceID.value.SpecimenSourceID : null;
        patientInfo.Memo = stateCopy.Memo ? stateCopy.Memo.trim() : null;
        patientInfo.TestID = stateCopy.selectedTest ? this.state.selectedTest.value : null;
        patientInfo.Lot = stateCopy.Lot ? stateCopy.Lot.trim() : null;
        patientInfo.PatientCountry = 'United States of America'
        patientInfo.PatientCounty = patientInfo.PatientCounty ? patientInfo.PatientCounty.trim() : null;
        patientInfo.PatientMiddleName = patientInfo.PatientMiddleName ? patientInfo.PatientMiddleName.trim() : null;
        this.setState({showLoading:true, isSubmitting: true}, async ()=>{
            try {
                let result = await WalkinAPI.walkinForm({patient: patientInfo})
                if(result.success){
                    sweetalert.fire({icon: 'success', title: '', text: "Form submitted successfully"});
                    this.setState({SpecimenID:"", Memo:"", SpecimenSourceID:"", Lot:"", selectedTest:"", isSubmitting: false},() =>this.clearPatientForm());
                }else{
                    sweetalert.fire({icon: 'error', title: '', text: "Failed to submit: " + result.reason});
                }
                this.setState({showLoading:false, isSubmitting: false})
            }
            catch (e) {
                console.error(e);
                sweetalert.fire({icon: 'error', title: '', text: "Failed to submit"});
                this.setState({showLoading:false, isSubmitting: false})
            }
        })
    }

    clearPatientForm = null;

    assignClearForm(func){
        this.clearPatientForm = func;
    }

    showBarcodeScanner = (e) => {
        this.setState({showScanner: true})
    }

    handleScan(scan) {
        if (!scan) {
            this.setState({showScanner: false});
            return;
        }
        sweetalert.fire({
            title: "Scan Captured",
            html: "Please verify <b> " + scan.text + " </b>  matches the barcode.",
            icon: "info",
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonText: 'Confirm',
            confirmButtonColor: '#00539B', //immy-blue
            cancelButtonText: 'Cancel',
            cancelButtonColor: '#a7a9ac' // immy-gray
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({
                    showScanner: false,
                    SpecimenID: scan.text
                })
            }
            if (result.isDismissed) {
                //keep scanner div open so user can hit Scan
                this.setState({showScanner: true})
            }
        });
    }

    handleDateOnChange(value, state){
        if (value) {
            this.setState({[state]: value } as any);
        }
        else{
            this.setState({[state]: null} as any)
        }
    }


    handleTestChange(event){
        console.log('event', event)
        console.log('validSpecimenSources', this.state.validSpecimenSources)
        this.setState({selectedTest:event, SpecimenSourceID: null, sources: this.state.validSpecimenSources.filter(r => r.value.TestID === event.value)})
    }


    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        console.log('walkin form state', this.state)
        let specID = "Specimen ID";
        if(this.state.selectedTest){
            let test:TestType = this.state.tests.find(t => t.ID === this.state.selectedTest.value);
            if(test && !test.IsLabTest){
                specID = "Specimen ID (optional)"
            }
        }
        let dob:Date = this.state.patientInformation.PatientDOB;
        return (
            <React.Fragment>
                <Overlay show_loading={this.state.showLoading}/>
                <div className="container-fluid  min-vh-100 ">
                    <div className={"row"}>
                        <div className=" col-12 col-sm-12  col-md-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 pt-2">
                            <PatientInformation attachClearStateFunc={this.assignClearForm} onChange={(e) => this.setState({patientInformation: e})}/>
                            <GuardianForm shouldDisplay={dob ? calculateAge(dob) < 18 : false}
                                          age={dob ? calculateAge(dob) : -1}
                                          onChange={(e) => this.setState({guardian: e})}/>
                            <div className="card mt-2">
                                <div className="card-header verlag-bold">
                                    <h3>Sample Information</h3>
                                </div>
                                <div className="card-body">
                                    <div className="form-group row">
                                        <label  className="col-sm-4 col-form-label">Test Type</label>
                                        <div className="col-sm-8  p-0 m-0">
                                            <Select
                                                isSearchable={ true}
                                                placeholder={"Please Select..."}
                                                noOptionsMessage={()=>  "Select a test."}
                                                className={"state_select"}
                                                options={this.state.tests.map(s => ({label:s.Name, value:s.ID}))}
                                                // onChange={(e) => this.setState({selectedTest:e})}
                                                onChange={(e) => this.handleTestChange(e)}
                                                value={this.state.selectedTest}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor={"SpecimenID"} className={"col-sm-4 col-form-label"}>{specID}</label>
                                        <div className={"col-sm-8 p-0 m-0 input-group"}>
                                            <button className={"btn btn-primary btn-sm"}
                                                    onClick={this.showBarcodeScanner}
                                                    disabled={!this.state.selectedTest}
                                            ><FaCameraRetro/></button>
                                            <input type={"search"} className="form-control" id={"SpecimenID"}
                                                   value={this.state.SpecimenID}
                                                   autoComplete="off"
                                                   onChange={(e) => this.setState({SpecimenID:e.target.value})}
                                                   disabled={!this.state.selectedTest}
                                                   maxLength={50}
                                            />
                                        </div>
                                    </div>
                                    { this.state.showScanner ?
                                        <BarcodeScannerComponent
                                            onResult={this.handleScan}
                                            showButtons={true}
                                            started={false}
                                        />
                                        : null
                                    }
                                    <div className="form-group row">
                                        <label htmlFor={"Lot"} className={"col-sm-4 col-form-label"}>Lot (Optional)</label>
                                        <div className={"col-sm-8 p-0 m-0 "}>
                                            <input type={"search"} className="form-control" id={"Lot"}
                                                   value={this.state.Lot}
                                                   autoComplete="off"
                                                   onChange={(e) => this.setState({Lot:e.target.value})}
                                                   maxLength={45}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor={'Facility'} className="col-sm-4 col-form-label">Facility</label>
                                        <div className="col-sm-8  p-0 m-0" id={'Facility'}>
                                            <Select
                                                isSearchable={ true}
                                                placeholder={"Please Select..."}
                                                noOptionsMessage={()=>  "Select a test."}
                                                className={"state_select"}
                                                options={this.state.facilities}
                                                onChange={(e) => this.setState({FacilityID:e})}
                                                value={this.state.FacilityID}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className={"col-12 col-md-4 pl-3 pl-md-3"}>
                                            <label htmlFor={'Collection Date'} style={{fontWeight: 'bold', paddingTop: '0.5em'}}>Collection Date</label>
                                        </div>
                                        <div className={"d-none d-md-block col-md-8 pl-md-0"} id={'Collection Date'}>
                                            <DatePicker
                                                placeholderText="--/--/---- --:-- --"
                                                minDate={new Date('01-01-1000')}
                                                maxDate={new Date('12-31-9999')}
                                                showTimeSelect
                                                selected={this.state.collectionDate ? Date.parse(moment(this.state.collectionDate, 'MM-DD-YYYY h:mm aa').toISOString()) : null}
                                                dateFormat="MM/dd/yyyy h:mm aa"
                                                onChange={(e) => this.handleDateOnChange(e, 'collectionDate')}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className={"d-block d-md-none pl-3"}>
                                                <DatePicker
                                                    placeholderText="--/--/---- --:-- --"
                                                    minDate={new Date('01-01-1000')}
                                                    maxDate={new Date('12-31-9999')}
                                                    showTimeSelect
                                                    selected={this.state.collectionDate ? Date.parse(moment(this.state.collectionDate, 'MM-DD-YYYY h:mm aa').toISOString()) : null}
                                                    dateFormat="MM/dd/yyyy h:mm aa"
                                                    onChange={(e) => this.handleDateOnChange(e, 'collectionDate')}
                                                />

                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group row">
                                        <div className={"col-12 col-md-4 pl-3 pl-md-3"}>
                                            <label htmlFor={'Date Test Ordered'} style={{fontWeight: 'bold', paddingTop: '0.5em'}}>Date Test Ordered</label>
                                        </div>
                                        <div className={"d-none d-md-block col-md-8 pl-md-0"} id={'Date Test Ordered'}>
                                            <DatePicker
                                                placeholderText="--/--/---- --:-- --"
                                                minDate={new Date('01-01-1000')}
                                                maxDate={new Date('12-31-9999')}
                                                showTimeSelect
                                                selected={this.state.testOrderedDate ? Date.parse(moment(this.state.testOrderedDate, 'MM-DD-YYYY h:mm aa').toISOString()) : null}
                                                dateFormat="MM/dd/yyyy h:mm aa"
                                                onChange={(e) => this.handleDateOnChange(e, 'testOrderedDate')}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className={"d-block d-md-none pl-3"}>
                                                <DatePicker
                                                    placeholderText="--/--/---- --:-- --"
                                                    minDate={new Date('01-01-1000')}
                                                    maxDate={new Date('12-31-9999')}
                                                    showTimeSelect
                                                    selected={this.state.testOrderedDate ? Date.parse(moment(this.state.testOrderedDate, 'MM-DD-YYYY h:mm aa').toISOString()) : null}
                                                    dateFormat="MM/dd/yyyy h:mm aa"
                                                    onChange={(e) => this.handleDateOnChange(e, 'testOrderedDate')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor={"Memo"} className={"col-sm-4 col-form-label"}>Memo (Optional)</label>
                                        <div className={"col-sm-8 p-0 m-0 "}>
                                            <textarea
                                                id={"Memo"}
                                                rows={3}
                                                maxLength={1000}
                                                className={'form-control'}
                                                value={this.state.Memo}
                                                autoComplete="off"
                                                onChange={(e) =>{
                                                    this.setState({Memo: e.target.value})
                                                }}
                                            />
                                            <div className={this.state.Memo && this.state.Memo.length > 0 ? 'visible' : 'invisible'}>
                                                <div style={{fontSize: '0.8em'}}>{this.state.Memo && this.state.Memo.length}/1000</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor={'Specimen Source'} className="col-sm-4 col-form-label">Specimen Source</label>
                                        <div className="col-sm-8  p-0 m-0" id={'Specimen Source'}>
                                            <Select
                                                isSearchable={ true}
                                                placeholder={"Please Select..."}
                                                noOptionsMessage={()=>  "Select a test."}
                                                className={"state_select"}
                                                options={this.state.sources}
                                                onChange={(e) => this.setState({SpecimenSourceID:e})}
                                                value={this.state.SpecimenSourceID}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={"card-footer"}>
                                    <button className={"btn btn-outline-primary "}  onClick={() => {
                                        this.submit();
                                    }} disabled={this.state.isSubmitting}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}