import React from "react";
import SimpleTable from "../tables/SimpleTable";
import {WellSlot} from "../../types/WellSlot";
import {AccessionRecord, AccessionRecordType} from "./AccessionRecord";
import {parseDate} from "../../util/FormatUtil";
import {WellMapData} from "./WellPlateReport";
import Batch from "../../types/Batch";


interface WellPlateMapProps {
    printRef?
    batchNum?:string
    plateData: WellMapData[],
    slotMap: WellSlot[],
    batch?:Batch
}
interface WellPlateMapState {
    processedDate?:string
    facilitiesCount: {name, count}[]
    twelveArray: number[]
    letterArr: string[]
}

export class WellPlateMap extends React.Component<WellPlateMapProps, WellPlateMapState>{

    constructor(props) {
        super(props);
        this.state = {
            facilitiesCount: [],
            letterArr: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
            twelveArray: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        }
    }

    componentWillReceiveProps(nextProps: Readonly<WellPlateMapProps>, nextContext: any) {
        if(nextProps.plateData.length > 0)
            this.getFacilityCounts(nextProps.plateData);
    }

    getFacilityCounts(data) {
        let facilityCountArr:{name, count}[] = [];
        for(const samp of data){
            let match = facilityCountArr.find(f => f.name === samp.FacilityAbbreviation);
            if(!match){
                facilityCountArr.push({name: samp.FacilityAbbreviation, count: 1})
            } else {
                match.count++;
            }
        }
        this.setState({
            facilitiesCount: facilityCountArr,
            processedDate: parseDate(data[0].ProcessedDate),
        })
    }

    getTableRow = (letter: string) => {
        return (<tr>
            <td className="col px-2 text-center font-weight-bold"
                style={{paddingTop: '2em', backgroundColor: 'pink', width: '7.7%'}}>{letter}</td>
            {this.state.twelveArray.map((num, index) => {
                    let slot = this.props.slotMap.find(s => s.WellSlot === (letter + num))
                    let r = this.props.plateData.find(r => r.WellSlot === (letter + num));
                    r = r ? r : {
                        SpecimenID: null,
                        FacilityAbbreviation: null,
                        InternalSpecimenID: null,
                        RerunCount: 0,
                        WellSlot: "",
                        ProcessedDate: null,
                        BatchNum: ""
                    }
                    if (slot && slot.IsBlocked) {

                        let color = slot.BackgroundClass;
                        return (
                            <td className={"col p-0 text-center font-weight-bold " + color}
                                style={{width: '7.7%', height: '6em'}}>{slot.BlockedText}
                            </td>
                        )
                    } else {
                        return (
                            <td className="col p-0"
                                style={{width: '7.7%', height: '6em'}}>
                                <AccessionRecord rowData={r && r as AccessionRecordType}/>
                            </td>)
                    }
                }
            )}
        </tr>)
    }

    render() {
        return(
            <div>
                <div className={"container-fluid"}>
                    <div className="row mt-5" style={{height: '50px'}}>
                        <div className="col">
                            <img src={'https://storage.googleapis.com/streamline-bucket/FullLogo.png'} style={{height: '100px'}}
                                 alt={"logo"}/>
                        </div>
                        <div className="col">
                            <div style={{fontWeight: 'bold'}}>Batch #: {this.props.batchNum ? this.props.batchNum : ""} </div>
                        </div>
                        <div className="col">
                            <div style={{fontWeight: 'bold'}}>Processed Date: {this.state.processedDate ? this.state.processedDate : ""}</div>
                        </div>
                    </div>

                    <div className="row mt-5" style={{height: '20px'}}>
                        <div className="offset-2 col-5">
                            <div style={{fontWeight: 'bold', fontStyle: 'italic', fontSize: '0.7em'}}>Aliquoting
                                Personnel: _____________________________________________________
                            </div>
                        </div>
                        <div className="col-5">
                            <div style={{fontWeight: 'bold', fontStyle: 'italic', fontSize: '0.7em'}}>Extraction
                                Personnel: _____________________________________________________
                            </div>
                        </div>
                    </div>
                    {/*testing table*/}
                    <div className="row mt-2">
                        <div className="col-12">
                            <div className={"table-responsive-sm"}>
                                <table className="printable-table table-print table-sm table-bordered">
                                    <thead>
                                    <tr>
                                        <th className={'text-center p-0'}
                                            style={{width: '15em', height: '0.5em', backgroundColor: 'pink'}}/>
                                        {this.state.twelveArray.map(m => (
                                            <th className={'text-center'}
                                                style={{
                                                    width: '15em',
                                                    height: '0.5em',
                                                    backgroundColor: 'pink'
                                                }}>{m}</th>))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.letterArr.map(l => {
                                        return this.getTableRow(l)
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="offset-2 col-8 mb-3">
                            <p>Notes: {this.props.batch ? this.props.batch.Notes : ""}</p>
                            <SimpleTable table_style={"text-center"} columns={[
                                {label:"Facility", key:"name"},
                                {label:"Specimen Count", key:"count"}
                            ]} appendRows={(
                                <tr>
                                    <td><b>Total</b></td>
                                    <td>{this.props.plateData.length}</td>
                                </tr>)} table_data={this.state.facilitiesCount}/>
                        </div>
                    </div>
                </div>
            </div>)
    }
}