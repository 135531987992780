import React, {Fragment} from "react";
import Parser from "html-react-parser";

export interface ConfirmationState {
    code: string
    service: string
    htmlTemplateForService: any
}

class Confirmation extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        const query = new URLSearchParams(window.location.search);

        this.setState({ code: query.get('code'), service: query.get('service') }, ()=>{
            fetch("/api/system/confirmationTemplate?service=" + this.state.service)
                .then(res => res.json() )
                .then( data => {
                    this.setState({ htmlTemplateForService: Parser(data.HTMLTemplate) })
                })
                .catch((err)=>{console.error(err);})
        })
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return(
            <React.Fragment>
                <div className="container-fluid  min-vh-100 ">
                    <div className={"row"}>
                        <div className="col-12 col-sm-12  col-md-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 pt-2">
                            <div className="card mb-2">
                                <div className="card-header verlag-bold">
                                    <h3>Confirmation</h3>
                                </div>
                                <div className="card-body">
                                    <h1 className={"verlag-bold"}>Your code: {this.state.code}</h1>

                                    <p><b>Please verify</b> that you have received an email from <b>no-reply@immytech.com</b> containing your appointment details and confirmation number. <b>You are required to bring
                                        this confirmation code to your appointment.</b></p>
                                    <p>If you do not receive an email containing this information within 15 minutes, please resubmit your appointment request.</p>
                                    <h1 className="verlag-bold">What to expect</h1>

                                    {this.state.htmlTemplateForService}

                                    <h1 className="verlag-bold">After testing</h1>
                                    <p>A text and email will be sent to you once your test results are ready with instructions on how to securely obtain your results. Most test results will be available within 3 business days (excluding holidays and weekends) after your scheduled appointment date. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Confirmation;