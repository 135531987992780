import React from "react";
import domain from "../../Domain";
import FilterCard, { FIELD_TYPE, ReactSelect } from "../FilterCard";
import Form from "../../types/Form";
import {getDateOrNull, getPageOptions, parseDate, showModalNoOutsideClick} from "../../util/FormatUtil";
import CheckinPatientModal, {ChangeVals} from "../modals/CheckinPatientModal";
import Overlay from "../Overlay";
import moment from "moment";
import PodSelect from "../PodSelect";
import SimpleTable from "../tables/SimpleTable";
import { Sample } from "../../types/Sample";
import { calculateAge } from "../../util/ValidationUtil";
import { sweetalert } from "../../App";
import Select from "react-select";
import TestType from "../../types/TestType";
import PodSched,{PodSchedForSelect} from "../../types/PodSched";
import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import ServicesAPI from "../../network/ServicesAPI";
import TestsAPI from "../../network/TestsAPI";
import SystemAPI from "../../network/SystemAPI";
import SamplesAPI, { SampleCheckinBody } from "../../network/SamplesAPI";
import Validator, { ValidationEntry } from "../../validation/Validator";
import { Validators } from "../../validation/Validators";
import AdminAPI from "../../network/AdminAPI";
import PaginationTool from "../PaginationTool";

interface CheckinState {
  showLoading: boolean;
  FirstName?: string;
  LastName?: string;
  SpecimenID?: string;
  DOB?: string;
  pod?: any;
  selectedSample?: Sample;
  totalAppts?: number;
  testTypes?: TestType[];
  FilterFirstName?;
  FilterLastName?;
  FilterDOB?;
  FilterReqNum?;
  FilterFacility?;
  SpecimenSourceID?;
  Lot?;
  TestID?;
  direction?: TableOrder;
  services: { Name; ID }[];
  facilities:{label:string, value:number}[]
  page_options: ReactSelect[];
  selected_page?;
  table_data?;
  pods: { label: string; value: PodSchedForSelect}[];
  specimenSources?;
}

export default class CheckIn extends React.Component<any, CheckinState> {
  private checkinRef: React.RefObject<CheckinPatientModal>;

  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      testTypes: [],
      totalAppts: 0,
      direction: "asc",
      page_options: [{ value: "1", label: "1" }],
      selected_page: { value: 1, label: 1 },
      services: [],
      pods: [],
      specimenSources: [],
      facilities:[]
    };
    this.assignClearState = this.assignClearState.bind(this);
    this.assignClear2State = this.assignClear2State.bind(this);
    this.clear = this.clear.bind(this);
    this.submit = this.submit.bind(this);
    this.checkinRef = React.createRef();
    this.assignClearStatePod = this.assignClearStatePod.bind(this);
  }

  componentDidMount() {
    ServicesAPI.getAllServices().then((data) => {
      this.setState({ services: data.data });
    });
    TestsAPI.getAllTestDetails().then((data) => {
      this.setState({ testTypes: data.tests });
    });
    AdminAPI.getAllPods(true).then((response) => {
      if (!response.success) {
        console.error(response.reason)
        sweetalert.fire("Error loading Pods")
      }

      this.setState({ pods: response.data as { label: string; value: PodSchedForSelect}[] });
    });
    SystemAPI.getAllSpecimenSources().then((data) => {
      this.setState({ specimenSources: data });
    });
    AdminAPI.getFacilitiesWithAddress().then(data => {
        this.setState({facilities: data})
    });

    $(".editbtn").on("click", () => {
      $("#SpecimenID").triggerHandler("focus");
      $("#SpecimenID").trigger("touchstart");
    });
    $("#SpecimenID").on("touchstart", function () {
      $("#SpecimenID").triggerHandler("focus");
      $(this).triggerHandler("focus");
    });
  }

  clearFilterStatePod: () => void = null;

  assignClearStatePod(func) {
    this.clearFilterStatePod = func;
  }

  clearFilterState: () => void = null;

  assignClearState(func) {
    this.clearFilterState = func;
  }

  clearState = () => {
    this.clearFilterState();
  };

  clearFilter2State: () => void = null;

  assignClear2State(func) {
    this.clearFilter2State = func;
  }

  clear2State = () => {
    this.clearFilter2State();
  };

    submit(page) {
      console.log(' submit() this.state is? ', this.state)
        if (!this.state.FilterDOB &&
            !this.state.FilterFirstName &&
            !this.state.FilterLastName &&
            !this.state.FilterReqNum  &&
            !this.state.FilterFacility &&
            !this.state.pod ) {
            return sweetalert.fire({icon: 'error', title: '', text: "Please select a filter"});
        }

        this.setState({showLoading: true}, async () => {
            try{
                let result = await SamplesAPI.getFilteredSamplesForCheckin(page, {filter: {
                        PatientFirstName: this.state.FilterFirstName,
                        PatientLastName: this.state.FilterLastName,
                        PatientDOB: parseDate(this.state.FilterDOB),
                        ReqNum: this.state.FilterReqNum,
                        PodID:this.state.pod,
                        Facilities:this.state.FilterFacility
                    }})
                if(result.totalSamples < 1 ){
                    sweetalert.fire({icon: 'error', title: '', text: 'No appointments were returned'});
                    this.setState({showLoading: false, table_data: [], totalAppts: 0})
                    return
                }
                this.setState({
                    totalAppts: result.totalSamples,
                    showLoading: false,
                    page_options: getPageOptions(result.num_pages),
                    table_data: result.table_data.sort((a,b)=>a.ApptTime && moment(a.ApptTime, 'hh:mm a').diff(moment(b.ApptTime, 'hh:mm a')))
                })
            }
            catch (e) {
                console.log(e);
                sweetalert.fire({icon: 'error', title: '', text: 'Search failed. Please try again.'});
                this.setState({table_data: [], showLoading:false})
            }
        })
    }

  clear() {
    this.clearState();
    this.clear2State();
  }


    async onEdit(){

        let samp:Sample = JSON.parse(JSON.stringify(this.state.selectedSample));
        let checkinBody = {
            ID:samp.ID,
            SpecimenID: this.state.SpecimenID ? this.state.SpecimenID.trim() : null,
            PatientFirstName: this.state.FirstName.trim(),
            PatientLastName: this.state.LastName.trim(),
            PatientDOB: this.state.DOB ? getDateOrNull(this.state.DOB) : null,
            SpecimenSourceID: this.state.SpecimenSourceID,
            Lot: this.state.Lot ? this.state.Lot.trim() : null,
            TestID: this.state.TestID
        }
        let validator = new Validator<SampleCheckinBody>()
            .withSimpleValidation("TestID", Validators.requireNonNullValidator("Test Type"))
            .withValidation("SpecimenID", new ValidationEntry<SampleCheckinBody>((key, attr, obj) =>{
                let test:TestType = this.state.testTypes.find(t => t.ID === obj.TestID);
                // console.log(test);
                if (!obj.SpecimenID && test.IsLabTest) {
                    return {success:false, error:"Please enter Specimen ID"};
                }
                return {success:true, error:null}
            })).withComposedValidation("PatientDOB", new ValidationEntry(Validators.requireNonNullValidator("Date of birth"))
                , new ValidationEntry(Validators.requireNotFutureDate("Date of birth")))
            .withSimpleValidation("SpecimenSourceID", Validators.requireNonNullValidator("Specimen Source"))
            .withSimpleValidation("PatientFirstName", Validators.requireNotBlankValidator("First Name"))
            .withSimpleValidation("PatientLastName", Validators.requireNotBlankValidator("Last Name"))
        let validationResponse = validator.validate(checkinBody);
    if (!validationResponse.success) {
      return sweetalert.fire({
        icon: "error",
        title: "",
        text: validationResponse.error,
      });
    }
    this.setState({ showLoading: true });
    window["$"]("#" + CheckinPatientModal.ID).modal("hide");
    let response = await SamplesAPI.editSample(checkinBody);
    this.setState({ showLoading: false });
    if (response.success) {
      sweetalert.fire({ icon: "success", title: "", text: "Checked In" });
      this.clear();
      this.setState({totalAppts: 0})
    } else {
      sweetalert.fire({
        icon: "error",
        title: "",
        text: `Failed to edit: ${response.reason}`,
      });
    }
  }

  useSorter(col: Column) {
    let sorter = new Sorter<any>()
      .withSorterFunc("ApptTime", (a, b) => {
        return moment(a, "hh:mm a").diff(moment(b, "hh:mm a"));
      })
      .withRawSorterFunc("Name", (a, b) => {
        let aName = a.PatientFirstName + " " + a.PatientLastName;
        let bName = b.PatientFirstName + " " + b.PatientLastName;
        return aName.localeCompare(bName);
      })
      .withRawSorterFunc("Service", (a: Sample, b: Sample) => {
        let aPod: { label: string; value: PodSchedForSelect} = this.state.pods.find(
          (p) => a.PodApptID === p.value.ID
        );
        let bPod: { label: string; value: PodSchedForSelect} = this.state.pods.find(
          (p) => b.PodApptID === p.value.ID
        );
        if (!aPod) return -1;
        if (!bPod) return 1;
        let aService = this.state.services.find(
          (s) => s.ID === aPod.value.ServiceID
        );
        let bService = this.state.services.find(
          (s) => s.ID === bPod.value.ServiceID
        );
        return aService && bService
          ? aService.Name.localeCompare(bService.Name)
          : 0;
      })
      .withSorterFunc("TestID", (a: number, b: number) => {
        let aTest = this.state.testTypes.find((t) => t.ID === a);
        let bTest = this.state.testTypes.find((t) => t.ID === b);
        if (!aTest) return -1;
        if (!bTest) return 1;
        return aTest.Name.localeCompare(bTest.Name);
      });

    this.setState({
      table_data: sorter.sortByKey(
        this.state.table_data,
        col.key as keyof any,
        this.state.direction
      ),
      direction: this.state.direction === "asc" ? "desc" : "asc",
    });
  }

  render():
    | React.ReactElement
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    return (
      <React.Fragment>
        <PaginationTool />
        <Overlay show_loading={this.state.showLoading} />
        <CheckinPatientModal
          onSubmit={() => this.onEdit()}
          onChange={(e) => this.setState(e)}
          FirstName={this.state.FirstName}
          LastName={this.state.LastName}
          DOB={this.state.DOB}
          SpecimenID={this.state.SpecimenID}
          TestID={this.state.TestID}
          tests={this.state.testTypes}
          SpecimenSourceID={this.state.SpecimenSourceID}
          Lot={this.state.Lot}
          sources={this.state.specimenSources}
        />

            <div className="container-fluid  min-vh-100 ">
                <div className={"row"}>
                    <div className="col-12 col-md-12 col-lg-8 col-xl-5 pt-2">
                        <div className="card mb-2">
                            <div className="card-header verlag-bold">
                                <h3>Testing Check In</h3>
                            </div>
                            <div className="card-body">
                                <FilterCard passClearStateFunc={this.assignClearState}
                                            fields={[{
                                                label: "First Name",
                                                "key": "FilterFirstName",
                                                type: FIELD_TYPE.TEXT
                                            },
                                                {label: "Last Name", "key": "FilterLastName", type: FIELD_TYPE.TEXT,},
                                                {
                                                    label: "Date of Birth",
                                                    "key": "FilterDOB",
                                                    type: FIELD_TYPE.DATE,
                                                },
                                                {
                                                    label: "Facility", 
                                                    "key": "FilterFacility", 
                                                    type: FIELD_TYPE.SELECT,
                                                    options: this.state.facilities,
                                                    isMapped:true, isMulti:true, textType: 'text'
                                                }
                                            ]} filterChanged={(e) => this.setState(e)}/>
                                <PodSelect passClearStateFunc={this.assignClearStatePod} testing={true} active={true}
                                           multi={true} onChange={(e) => this.setState({pod: e.pod})}/>
                                <FilterCard passClearStateFunc={this.assignClear2State} fields={[{
                                    label: "Confirmation Code",
                                    "key": "FilterReqNum",
                                    type: FIELD_TYPE.TEXT,
                                    options: [],
                                    isMapped: true,
                                    onInput: (e) => e.target.value = e.target.value.toUpperCase()
                                },
                                ]} filterChanged={(e) => this.setState(e)}/>
                            </div>
                            <div className="card-footer">
                                <button className="btn btn-outline-primary" onClick={() => this.setState({
                                    selected_page: {
                                        label: 1,
                                        value: 1
                                    }
                                }, () => this.submit(1))}>Search
                                </button>
                                <button className="btn btn-outline-danger float-right" onClick={() => {
                                    this.clear();
                                    this.clearFilterStatePod();
                                    this.setState({pod: null})
                                }}>Clear Filter
                                </button>
                            </div>
                        </div>
                    </div>

            {this.state.totalAppts > 0 && (
              <div className="col-12 col-md-12 col-lg-12 pt-2">
                <div className="card mt-2 mb-5">
                  <div className="card-header verlag-bold stickToTop">
                    <h3>
                      Appointments
                      <section className="tableHeaderSection float-md-right ">
                        <h3 style={{ float: "right" }}>
                          Total: {this.state.totalAppts}
                        </h3>
                        <h4 className="d-inline-block float-right align-middle pr-2 ml-5">
                          Page{" "}
                        </h4>
                        <div className=" align-middle float-right pages">
                          <Select
                            isSearchable={true}
                            placeholder={"1"}
                            noOptionsMessage={() => "No option"}
                            value={this.state.selected_page}
                            onChange={(e: ReactSelect) =>
                              this.setState({ selected_page: e }, () =>
                                this.submit(e.value)
                              )
                            }
                            className={"state_select page-num-select"}
                            options={this.state.page_options}
                          />
                        </div>
                      </section>
                    </h3>
                  </div>
                  <div className="card-body p-0 m-0 table-responsive-md">
                    <SimpleTable
                      columns={[
                        {
                          label: "Name",
                          key: "Name",
                          rawFormat: (v) => {
                            // console.log('v in table', v)
                            return (
                              <a
                                href={"#!"}
                                onClick={() =>
                                  this.setState(
                                    {
                                      selectedSample: JSON.parse(
                                        JSON.stringify(v)
                                      ),
                                      FirstName: v.PatientFirstName,
                                      LastName: v.PatientLastName,
                                      DOB: parseDate(v.PatientDOB),
                                      SpecimenID: v.SpecimenID
                                        ? v.SpecimenID
                                        : "",
                                      SpecimenSourceID: v.SpecimenSourceID
                                        ? v.SpecimenSourceID
                                        : "",
                                      TestID: v.TestID ? v.TestID : 0,
                                      Lot: v.Lot ? v.Lot : "",
                                    },
                                    () => showModalNoOutsideClick(CheckinPatientModal.ID)
                                  )
                                }
                              >
                                {v.PatientFirstName + " " + v.PatientLastName}
                              </a>
                            );
                          },
                        },
                        {
                          label: "DOB",
                          key: "PatientDOB",
                          formatFunc: parseDate,
                        },
                        {
                          label: "Specimen ID",
                          key: "SpecimenID",
                        },
                        {
                          label: "Selected Service",
                          key: "Service",
                          rawFormat: (v: Sample) => {
                            // console.log(this.state.pods)
                            let pod: { label: string; value: PodSchedForSelect } =
                              this.state.pods.find(
                                (p) => v.PodApptID === p.value.ID
                              );
                            if (!pod || !pod.value) return "";
                            let service = this.state.services.find(
                              (s) => s.ID === pod.value.ServiceID
                            );
                            return service ? service.Name : "";
                          },
                        },
                        {
                          label: "Test",
                          key: "TestID",
                          formatFunc: (val) => {
                            let found: TestType = this.state.testTypes.find(
                              (f) => f.ID === val
                            );
                            return found ? found.Name : "";
                          },
                        },
                        {
                          label: "Guardian",
                          key: "Guardian",
                          rawFormat: (v: Sample) => {
                            return v.GuardianFirstName
                              ? v.GuardianFirstName + " " + v.GuardianLastName
                              : "";
                          },
                        },
                        {
                          label: "Appt. Time",
                          key: "ApptTime",
                          rawFormat: (v: Sample) => {
                            return v.ApptTime === "Invalid date"
                              ? ""
                              : v.ApptTime;
                          },
                        },
                      ]}
                      table_data={this.state.table_data}
                      columnClickedCallback={(col) => {
                        this.useSorter(col);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
