import React from "react";
import ReactQuill, {Quill} from "react-quill";
import "react-quill/dist/quill.snow.css";
import {getBrandingImage} from "../util/FormatUtil";
import {ImageType} from "../types/Branding";

function insertName(){
    const cursorPosition = this.quill.getSelection().index;
    let name = "#{Name}";
    this.quill.insertText(cursorPosition, name);
    this.quill.setSelection(cursorPosition + name.length);
}

function insertReqNum(){
    const cursorPosition = this.quill.getSelection().index;
    let name = "#{ReqNum}";
    this.quill.insertText(cursorPosition, name);
    this.quill.setSelection(cursorPosition + name.length);
}

function insertLocation(){
    const cursorPosition = this.quill.getSelection().index;
    let name = "#{Location}";
    this.quill.insertText(cursorPosition, name);
    this.quill.setSelection(cursorPosition + name.length);
}

function insertAddress(){
    const cursorPosition = this.quill.getSelection().index;
    let name = "#{Address}";
    this.quill.insertText(cursorPosition, name);
    this.quill.setSelection(cursorPosition + name.length);
}

function insertDate(){
    const cursorPosition = this.quill.getSelection().index;
    let name = "#{Date}";
    this.quill.insertText(cursorPosition, name);
    this.quill.setSelection(cursorPosition + name.length);
}

function insertTime(){
    const cursorPosition = this.quill.getSelection().index;
    let name = "#{Time}";
    this.quill.insertText(cursorPosition, name);
    this.quill.setSelection(cursorPosition + name.length);
}

function insertLogo(){
    const cursorPosition = this.quill.getSelection().index;
    getBrandingImage(ImageType.LOGO).then(url => {
        this.quill.insertEmbed(cursorPosition, 'image', url, 'user')
        this.quill.setSelection(cursorPosition + 2);
    });
}

// ensure logo inserts with width: 400px;
let Image = Quill.import('formats/image');
Image.className = "customtoolbar-logo-width";
Quill.register(Image, true);

function insertText(){
    const cursorPosition = this.quill.getSelection().index;
    let name = "#{Text}";
    this.quill.insertText(cursorPosition, name);
    this.quill.setSelection(cursorPosition + name.length);
}

const CustomToolbar = (props) => (
    <div id="toolbar" className={"ql-toolbar ql-snow"}
         style={{border: props.formats ? '1px solid #ccc' : 'none'}}
    >
        <div id={"toolbar-buttons"} style={{
            visibility: props.formats ? 'visible' : 'hidden',
            display: props.formats ? 'block' : 'none'
        }}>
            <span className={"ql-formats"}>
                <select className="ql-header">
                    <option value="1"/>
                    <option value="2"/>
                    <option value="3"/>
                    <option selected/>
                </select>
            </span>
            <span className={"ql-formats"}>
                <button className="ql-bold"/>
                <button className="ql-italic"/>
                <button className="ql-underline"/>
                <button className="ql-link"/>
            </span>
                    <span className={"ql-formats"}>
                <button type="button" className="ql-list" value="ordered"/>
                <button type="button" className="ql-list" value="bullet"/>
            </span>
                    <span className={"ql-formats"}>
                <button className={"ql-clean"}/>
            </span>
        </div>

        <div id={"customtoolbar"}>
            {   props.templateFields &&
                props.templateFields[props.templateName].map(f => {
                    let Field = () => <span>{f}</span>;
                    let className = "ql-insert"+f
                    return (<button className={className}><Field /></button>)
                })
            }
        </div>
    </div>
)

interface EditorProps {
    value:string,
    onChange?: (value:string) => void
    templateName:string
    templateFields?:{}
    formats:boolean
}

interface EditorState {
    editorHtml:string
}

export class CustomQuillEditor extends React.Component<EditorProps, EditorState> {
    constructor (props) {
        super(props);

        this.state = {
            editorHtml: props.value ? props.value : ""
        }
    }

    componentWillReceiveProps(nextProps: Readonly<EditorProps>, nextContext: any) {
        if(nextProps.value){
            this.setState({editorHtml: nextProps.value})
        }
    }

    static emailModules = {
        toolbar: {
            container: "#toolbar",
            handlers: {
                insertName: insertName,
                insertReqNum: insertReqNum,
                insertLocation: insertLocation,
                insertAddress: insertAddress,
                insertDate: insertDate,
                insertTime: insertTime,
                insertLogo: insertLogo,
                insertText: insertText
            }
        }
    };

    static emailFormats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color",
        'alt', 'width', 'height', 'style' // for logo img
    ];

    render (){
        console.log(" render this.props",this.props)
        return (
            <div className={"text-editor"}>
                <div>
                    <CustomToolbar
                        templateName={this.props.templateName}
                        templateFields={this.props.templateFields}
                        formats={this.props.formats}
                    />
                    <ReactQuill
                        theme={"snow"}
                        defaultValue={this.state.editorHtml}
                        onChange={(val) => this.props.onChange(val) }
                        modules={CustomQuillEditor.emailModules}
                        formats={CustomQuillEditor.emailFormats}
                    />
                </div>
            </div>
        )
    }
}