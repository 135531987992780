import NetworkUtil from "./NetworkUtil";
import {Settings} from "../types/Settings";
import LabInformation from "../types/LabInfo";

export default class SettingsAPI{

    public static async getAllSettings():Promise<{success:boolean, reason:string, data?:Settings[]}>{
        return NetworkUtil.makeGet("/api/admin/settings");
    }

    public static async getDefaultLabInfo():Promise<{success:boolean, reason:string, data?:LabInformation}>{
        return NetworkUtil.makeGet("/api/admin/settings/labinfo");
    }

    public static async updateDefaultLabInfo(labinfo):Promise<{success:boolean,reason:string, data?:LabInformation}>{
        return NetworkUtil.makePost("/api/admin/settings/updateDefaultLabInfo",  labinfo);
    }

    public static async getAllLabInfo():Promise<{success:boolean,reason:string,data:LabInformation[]}>{
        return NetworkUtil.makeGet("/api/admin/settings/getAllLabInfo");
    }
}