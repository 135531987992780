import NetworkUtil from "./NetworkUtil";
import {CommunicationTemplate} from "../types/CommunicationTemplate";
import {ServerResponse} from "../types/ServerResponse";
import Facility from "../types/Facility";

export default class CommTemplatesAPI {

    public static async getEditableTemplates():Promise<{success:boolean,reason:string,data:CommunicationTemplate[]}>{
        return NetworkUtil.makeGet("/api/admin/communications/editable");
    }

    public static async filterTemplates(filter:{filter:{Name?,Subject?}}):Promise<{success:boolean, reason:string, data?:CommunicationTemplate[]}>{
        return NetworkUtil.makePost("/api/admin/communications/filter", filter);
    }

    public static async createTemplate(template:CommunicationTemplate):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/communications/create", template);
    }

    public static async editTemplate(template):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/communications/edit", template);
    }

    public static async getUserFacilityGroup():Promise<{success:boolean,reason:string,data?:Facility}>{
        return NetworkUtil.makeGet("/api/admin/communications/getUserFacilityGroup");
    }
}