import NetworkUtil from "./NetworkUtil";
import {ServerResponse} from "../types/ServerResponse";

export default class PodAPI{

    public static async searchPods(filter, page):Promise<{table_data:[], total:number, num_pages:number}>{
        return NetworkUtil.makePost( "/api/admin/pods/podSchedSearch?page=" + page, filter);
    }

    public static async podUpsert(pod):Promise<ServerResponse>{
        return NetworkUtil.makePost( "/api/admin/pods/upsert", pod);
    }

    public static async searchPodsForHomePage():Promise<{table_data, total}>{
        return NetworkUtil.makePost("/api/admin/pods/podSearchHomePage", {});
    }

}