import React from "react";
import QRCode from "react-qr-code";
import Overlay from "../Overlay";
import ReactToPrint from "react-to-print";
import {sweetalert} from "../../App";
import {BatchSelect} from "./selects/BatchSelect";
import Batch from "../../types/Batch";
import BatchesAPI from "../../network/BatchesAPI";
import SimpleTable from "../tables/SimpleTable";
import {Column} from "../tables/TableBase";

interface RerunState {
    BatchNum: string
    filter: object
    show_loading: boolean
    printList: any[]
    batchNumList: [],
    selectedBatch: { label, value: Batch }
}

export class RerunReport extends React.Component<any, RerunState> {
    private componentRef: any;

    constructor(props) {
        super(props);
        this.state = {
            filter: {},
            show_loading: true,
            BatchNum: '',
            printList: [],
            batchNumList: [],
            selectedBatch: {label: '', value: null}
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    async onSubmit() {
        if (!this.state.selectedBatch || !this.state.selectedBatch.value) {
            return sweetalert.fire({icon: 'error', title: '', text: "Please select a batch number"});
        } else {
            this.setState({show_loading: true}, async () => {
                let response = await BatchesAPI.getReruns(this.state.selectedBatch.value.ID);
                if (response.success) {
                    this.setState(({show_loading: false, printList: response.data}));
                    if(response.data.length === 0) {
                        return sweetalert.fire({icon: 'error', title: '', text: "No reruns found for this batch"});
                    }
                } else {
                    this.setState({show_loading: false});
                    return sweetalert.fire({icon: 'error', title: '', text: response.reason});
                }
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <Overlay show_loading={this.state.show_loading}/>
                    <div className="container-fluid  ">
                        <div className={"row"}>
                            <div className="col-12 col-md-12 offset-xl-3 col-xl-6 offset-lg-2 col-lg-8  pt-2">
                                <div className="card mb-2">
                                    <div className="card-header verlag-bold">
                                        <h3>Print Rerun Report</h3>
                                    </div>
                                    <div className="card-body">
                                        <BatchSelect finishedLoading={() => this.setState({show_loading: false})}
                                                     onSelected={(e: { label, value: Batch }) => this.setState({selectedBatch: e}, () => this.onSubmit())}/>
                                    </div>
                                    {this.state.printList.length > 0 &&
                                        <div className="card-footer">
                                            <ReactToPrint
                                                trigger={() => <button className={"btn btn-outline-success"}
                                                                       style={{float: 'right'}}>Print Report</button>}
                                                content={() => this.componentRef}/>
                                        </div>
                                    }
                                </div>
                                {this.state.printList.length > 0 &&
                                    <div className={"container"} ref={el => (this.componentRef = el)}>
                                        <p><b>Rerun Report</b></p>
                                        <p>Batch
                                            Num: {this.state.selectedBatch ? this.state.selectedBatch.value.BatchNum : ""}</p>
                                        <SimpleTable columns={[
                                            {label:"Bin ID", key:"BinID"},
                                            {label: "Specimen ID", key: "SpecimenID"},
                                            {label: "Internal Specimen ID", key: "InternalSpecimenID"},
                                        ]} table_data={this.state.printList} table_style={"text-center"}
                                                     rowBuilder={(data, columns) => {
                                                         return (data ? data.map((val: any) => {
                                                             return (<tr>
                                                                 {columns.map((col: Column) => {
                                                                     let rowData = val[col.key];
                                                                     if (!rowData)
                                                                         return <td/>;
                                                                     return (
                                                                         <td className={"align-content-center text-center"}>
                                                                             <QRCode value={rowData ? rowData : ""}
                                                                                     size={50}/>
                                                                             <p>{rowData}</p>
                                                                         </td>)
                                                                 })}
                                                             </tr>);
                                                         }) : null);
                                                     }
                                                     }/>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}