import React from "react";
import domain from "../../Domain";
import Overlay from "../Overlay";
import SimpleTable from "../tables/SimpleTable";
import {sweetalert} from "../../App";
import PodSelect from "../PodSelect";
import NetworkUtil from "../../network/NetworkUtil";
import AuditLogsAPI from "../../network/AuditLogsAPI";
import {AlertAudit} from "../../types/AlertAudit";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import { capitalize, formatUSDate, getBoolSelectVal, getTwelveHourAMPMTime, parseDate } from "../../util/FormatUtil";
import Parse from "html-react-parser";
import PaginationTool from "../PaginationTool";

interface AuditLogState{
    show_loading: boolean
    filter: object
    AuditNew: AlertAudit[]
    pod?: any
    searched: any
}

interface AuditLogProps{
    auditLogType?
    batches?
    tests?
    templates?
    facilities?
    services?
}

export class AuditLog extends React.Component<AuditLogProps,AuditLogState>{
    private componentRef: any;
    constructor(props) {
        super(props);
        this.state = {
            show_loading: false,
            filter: {},
            AuditNew: [],
            pod: [],
            searched: []
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.assignClearState = this.assignClearState.bind(this);
        this.handleExportToCSV = this.handleExportToCSV.bind(this);
    }

    clearFilterState:() => void = null;

    assignClearState(func){
        this.clearFilterState = func;
    }
    

    async onSubmit() {

        let selection = this.props.auditLogType;
        
        this.setState({show_loading: true})


        // validations
        if(
            (selection === 'sample' && !this.state.filter['ReqNum']) || 
            (selection === 'facility' && !this.state.filter['FacilityID']) || 
            (selection === 'batch' && !this.state.filter['BatchNum']) || 
            ((selection === 'service' || selection === 'commTemplate') && !this.state.filter['Name']) || 
            ((selection === 'alert' || selection === 'pod') && (!this.state.pod || this.state.pod.length< 1)) ||
            ((selection === 'labInformation' && (!this.state.filter['IsDefault'] && !this.state.filter['Facility'])))
        ){
            this.setState({show_loading: false})
            return sweetalert.fire({icon: 'error', title: '', text: "Please complete form"});
        }

        // data fetching
        try {
            let results = 
                selection === 'sample' ? await AuditLogsAPI.sampleAuditLogs({filter:this.state.filter["ReqNum"]}) : 
                selection === 'facility' ? await AuditLogsAPI.facilityAuditLogs({filter:this.state.filter["FacilityID"]}) : 
                selection === 'pod' ? await AuditLogsAPI.podAuditLogs({filter:this.state.pod}) :
                selection === 'batch' ? await AuditLogsAPI.batchAuditLogs({filter:this.state.filter['BatchNum']}) : 
                selection === 'alert' ? await AuditLogsAPI.alertAuditLogs({filter:this.state.pod}) :
                selection === 'service' ? await AuditLogsAPI.serviceAuditLogs({filter:this.state.filter["Name"]}) :
                selection === 'commTemplate' ? await AuditLogsAPI.commTemplateAuditLogs({filter:this.state.filter["Name"]}) : 
                selection === 'labInformation' ? await AuditLogsAPI.labInfoAuditLogs({filter:this.state.filter}) :
                null;

            if(!results || results.data.length === 0){
                this.setState({show_loading: false, AuditNew: []})
                return sweetalert.fire({icon: 'error', title: '', text: 'No results returned'});
            }

            if(results.data.length > 0){
                this.setState({
                    show_loading: false, 
                    AuditNew: results.data as any, 
                    searched: 
                        selection === 'sample' ?  this.state.filter["ReqNum"] : 
                        selection === 'facility' ? this.state.filter['FacilityID'] : 
                        (selection === 'pod' || selection === 'alert') ? this.state.pod :
                        selection === 'batch' ? this.state.filter['BatchNum'] :
                        (selection === 'service' || selection === 'commTemplate') ? this.state.filter["Name"] : 
                        selection === 'labInformation' ? this.state.filter :
                        null
                })
            }
        }
        catch (e) {
            this.setState({show_loading: false})
            sweetalert.fire({icon: 'error', title: '', text: e});
        }

        
    }

    handleExportToCSV() {
        this.setState({show_loading:true}, async () =>{
            await NetworkUtil.downloadCSV(`/api/admin/audit/${this.props.auditLogType}AuditLogs/csv`,
                `${capitalize(this.props.auditLogType)} Audit Logs.xlsx`,{filter: this.state.searched});
            this.setState({show_loading:false});
        });
    }

    getRenderJSX(){
        let jsx;
        switch (this.props.auditLogType) {
            case 'sample':
                jsx = 
                    <FilterCard
                        passClearStateFunc={this.assignClearState}
                        fields={[
                            {
                                label: "Confirmation Code",
                                "key": "ReqNum",
                                type: FIELD_TYPE.TEXT,
                                placeholder: "Enter Confirmation Code",
                            }
                        ]} filterChanged={(filter) => this.setState({filter: filter})}
                    />
                break;
                case 'facility':
                jsx = 
                    <FilterCard
                        passClearStateFunc={this.assignClearState}
                        fields={[
                            {
                                label: "Facility",
                                "key": "FacilityID",
                                type: FIELD_TYPE.SELECT,
                                isMulti: false,
                                isMapped: true,
                                options: this.props.facilities,
                            },
                        ]} 
                        filterChanged={(filter) => this.setState({filter: filter})}
                    />
                break;
                case 'batch':
                jsx = 
                    <FilterCard
                        passClearStateFunc={this.assignClearState}
                        fields={[
                            {
                                label: "Batch #",
                                "key": "BatchNum",
                                type: FIELD_TYPE.SELECT,
                                isMulti: false,
                                options: this.props.batches,
                            },
                        ]} filterChanged={(filter) => this.setState({filter: filter})}
                    />
                break;
                case 'service':
                jsx = 
                    <FilterCard
                        passClearStateFunc={this.assignClearState}
                        fields={[
                            {
                                label: "Name",
                                "key": "Name",
                                type: FIELD_TYPE.SELECT,
                                isMulti: false,
                                options: this.props.services,
                            },
                        ]} 
                        filterChanged={(filter) => this.setState({filter: filter})}
                    />
                break;
                case 'commTemplate':
                jsx = 
                    <FilterCard
                        passClearStateFunc={this.assignClearState}
                        fields={[
                            {
                                label: "Name",
                                "key": "Name",
                                type: FIELD_TYPE.SELECT,
                                isMulti: false,
                                options: this.props.templates,
                            },
                        ]} filterChanged={(filter) => this.setState({filter: filter})}
                    />
                break;
                case 'pod':
                jsx = 
                    <PodSelect 
                        passClearStateFunc={this.assignClearState} 
                        onChange={(e) => this.setState({pod: e.pod})}
                    />
                break;
                case 'alert':
                jsx = 
                    <PodSelect 
                        passClearStateFunc={this.assignClearState} 
                        onChange={(e) => this.setState({pod: e.pod})}
                    />
                break;
                case 'labInformation':
                    jsx = <FilterCard
                    passClearStateFunc={this.assignClearState}
                    fields={[
                        {
                            label: "Is Default",
                            "key": "IsDefault",
                            type: FIELD_TYPE.SELECT,
                            isMulti: false,
                            options: [ 
                                'Yes'
                            ],
                            disable: this.state.filter['Facility'] !== ""
                        },
                        {
                            label: "Facility",
                            "key": "Facility",
                            type: FIELD_TYPE.SELECT,
                            isMulti: false,
                            options: this.props.facilities.map(f => f.label),
                            disable: this.state.filter["IsDefault"] !== ""
                        },
                    ]} filterChanged={(filter) => this.setState({ filter: filter })}
                />
                break;
            default:
                jsx = <div className="invisible"></div>
                break;
        }
        return jsx;
    }

    getTable(){
        let jsxForTable;
        switch (this.props.auditLogType) {
            case 'sample':
                jsxForTable = 
                    <SimpleTable table_style=''
                        ref={el => (this.componentRef = el)} 
                        columns={[
                            {label:"First Name", key:"PatientFirstName"},
                            {label:"Last Name", key:"PatientLastName"},
                            {label:"DOB", key:"PatientDOB", formatFunc:parseDate},
                            {label:"Confirmation #", key:"ReqNum"},
                            {label:"Email", key:"PatientEmail"},
                            {label:"Phone", key:"PatientPhone"},
                            {label:"Result", key:"ResultLabel.Value"},
                            {label:"Results Accessed", key:"ResultsAccessedDate", formatFunc:parseDate},
                            {label:"Specimen ID", key:"SpecimenID"},
                            {label:"Internal Specimen ID", key:"InternalSpecimenID"},
                            {label:"Batch ID", key:"BatchID"},
                        ]} 
                        table_data={this.state.AuditNew}
                    />
                break;
            case 'facility':
                jsxForTable = 
                    <SimpleTable table_style=''
                        ref={el => (this.componentRef = el)} 
                        columns={[
                            {label: "Name", key: "FacilityName", },
                            {label: "Facility Group", key: "FacilityGroup"},
                            {label: "Facility Seq", key: "FacilitySeq"},
                            {label: "Abbreviation", key: "FacilityAbbreviation"},
                        ]} 
                        table_data={this.state.AuditNew}
                    />
                break;
            case 'pod':
                jsxForTable = 
                    <SimpleTable table_style=''
                        ref={el => (this.componentRef = el)} 
                        columns={[
                            {label:"FacilityID", key:"FacilityID"},
                            {label:"AccessCode", key:"AccessCode"},
                            {label:"TestDate", key:"TestDate", formatFunc: formatUSDate},
                            {label:"StartTime", key:"StartTime", formatFunc: getTwelveHourAMPMTime},
                            {label:"EndTime", key:"EndTime", formatFunc: getTwelveHourAMPMTime},
                        ]} 
                        table_data={this.state.AuditNew}
                    />
                break;
            case 'batch':
                jsxForTable = 
                    <SimpleTable table_style=''
                        ref={el => (this.componentRef = el)} 
                        columns={[
                            {label: "Batch #", key: "BatchNum", },
                            {label: "Test", key: "TestLabel.Name"},
                            {label: "Approved By", key: "ResultApprovedBy"},
                            {label: "Approved Date", key: "ResultApprovedDate", formatFunc:parseDate}
                        ]} 
                        table_data={this.state.AuditNew}
                    />
                break;
            case 'alert':
                jsxForTable = 
                    <SimpleTable table_style=''
                        ref={el => (this.componentRef = el)} 
                        columns={[
                            {label: "Pod", key: "PodID", },
                            {label: "Content", key: "Content"},
                            {label: "Receiving Email", key: "TestEmail"},
                            {label: "Receiving Phone", key: "TestPhone"},
                            {label: "Sent by", key: "SentBy"},
                        ]} 
                        table_data={this.state.AuditNew}
                    />
                break;
            case 'service':
                jsxForTable = 
                    <SimpleTable table_style=''
                        ref={el => (this.componentRef = el)} 
                        columns={[
                            {label: "Name", key: "Name", },
                            {label: "Is Enabled", key: "IsEnabled",
                                rawFormat: (val) => {
                                    let string = getBoolSelectVal(val.IsEnabled)
                                    return <p>{string.label}</p>
                                }},
                            {label: "HtmlTemplate", key: "HtmlTemplate",
                                rawFormat: (val)=>{
                                let parsed = Parse(val.HtmlTemplate);
                                return <p>{parsed}</p>
                                }
                            },
                        ]} 
                        table_data={this.state.AuditNew}
                    />
                break;
            case 'commTemplate':
                jsxForTable = 
                    <SimpleTable
                        ref={el => (this.componentRef = el)} 
                        columns={[
                            {label: "Name", key: "Name"},
                            {label: "Subject", key: "Subject"},
                            {label: "Last Updated By", key: "LastUpdatedBy"},
                            {label: "Last Updated", key: "LastUpdatedDate"},
                            {label: "EditableTemplate", key: "EditableTemplate",
                                rawFormat: (val)=>{
                                let template = val.EditableTemplate ? val.EditableTemplate : val.Template;
                                    let parsed = Parse(template);
                                    return <p>{parsed}</p>
                                }
                            },
                        ]} 
                        table_data={this.state.AuditNew}
                    />
                break;
            case 'labInformation': 
                jsxForTable = <SimpleTable table_style='tableFixHead'
                    ref={el => (this.componentRef = el)} columns={[
                        { label: "Entity Name", key: "EntityName" },
                        {
                            label: "Is Default", key: "IsDefault", rawFormat: (val) => {
                                let string = getBoolSelectVal(val.IsDefault)
                                return <p>{string.label}</p>
                            }
                        },
                        { label: "Director", key: "Director" },
                        {
                            label: "Facility Name", key: "FacilityID", rawFormat: (val) => {
                                
                                return <p>{val.EntityName} </p>
                            }
                        },{
                            label: "CLIA", key: "CLIA"
                        },{
                            label: "Lab ID", key: "LabID",
                        },{
                            label: "Help Email", key: "HelpEmail"
                        },{
                            label: "Last Modified", key: "LastUpdatedDate",formatFunc:parseDate
                        }
                    ]} table_data={this.state.AuditNew}
                />
                break;
            default:
                jsxForTable = <div className={'invisible'}></div>
                break;
        }
        return jsxForTable;
    }

    render() {
        return (
            <React.Fragment>
                <Overlay show_loading={this.state.show_loading}/>
                <PaginationTool />
                {this.props.auditLogType &&
                <div className={"row"}>
                    <div className="col-6 col-md-6 col-lg-6 col-xl-6">
                        <div className="card border-top-0">
                            <div className="card-body">
                                {this.getRenderJSX()}                            
                            </div>
                            <div className="card-footer" style={{height: '4em'}}>
                                <button className={"btn btn-outline-primary verlag-bold"} onClick={this.onSubmit}>Search</button>
                                <button className="btn btn-outline-danger float-right" onClick={() => {this.clearFilterState(); this.setState({pod:null, AuditNew: [], searched: []})}}>Clear</button>
                            </div>
                        </div>
                    </div>
                </div>
                }

                {this.state.AuditNew.length > 0 &&
                <div className="col-12 col-md-12 col-lg-12 pt-2 pl-0">
                    <div className="card mt-2 mb-5">
                        <div className="card-header verlag-bold stickToTop">
                            <h3>Logs
                                <button className={this.state.AuditNew.length < 1 ? 'd-none': "btn btn-outline-primary d-none d-md-none d-lg-inline-block d-xxl-inline-block ml-2 float-right"} onClick={() => this.handleExportToCSV()}>Export to CSV</button>
                            </h3>
                        </div>
                        <div className="card-body p-0 m-0">
                            {this.getTable()}
                        </div>
                    </div>
                </div>
                }
            </React.Fragment>
        )
    }
}