import React from "react";
import Select from "react-select";
import {buildRow} from "../../util/FormatUtil";
import {isBlank, isOnlyWhitespace, isValidEmail} from "../../util/ValidationUtil";
import {sweetalert} from "../../App";
import {isEmptyObject} from "jquery";
import moment from "moment";
import JSONPretty from 'react-json-pretty';
import Batch from "../../types/Batch";
import BatchesAPI from "../../network/BatchesAPI";
import TestsAPI from "../../network/TestsAPI";

interface BatchManagementState {
    selectedBatch:Batch
    TestType?
    testTypeOptions:{label, value:number}[]
}

interface BatchManagementModalProps {
    onSubmit:(batch) => void
    selectedBatch:Batch
}

export class BatchManagementModal extends React.Component<BatchManagementModalProps, BatchManagementState>{
    public static ID = "batchmanagement";

    constructor(props) {
        super(props);
        this.state = {
            selectedBatch: {
                ID: null,
                BatchNum:"",
                TestID:null,
                ResultApprovedDate:null,
                ResultApprovedBy:"",
                ExportUploadedBy:"",
                MachineData:null,
                Notes:""
            } as Batch,
            TestType: {label: "", value: null},
            testTypeOptions: []
        }
    }

    componentWillReceiveProps(nextProps: Readonly<BatchManagementModalProps>, nextContext: any) {
        if(!isEmptyObject(nextProps.selectedBatch)){
            let batch = nextProps.selectedBatch;
            this.setState({
                selectedBatch: {
                    ID: batch.ID,
                    BatchNum: batch.BatchNum,
                    TestID: batch.TestID,
                    ResultApprovedDate: batch.ResultApprovedDate ? new Date(batch.ResultApprovedDate) : '',
                    ResultApprovedBy: batch.ResultApprovedBy,
                    Notes: batch.Notes,
                    ExportUploadedBy: batch.ExportUploadedBy,
                    MachineData: batch.MachineData,
                } as Batch,
                TestType: this.state.testTypeOptions.filter( o => o.value === batch.TestID)
            });
        }else{
            this.setState({
               selectedBatch: {
                   ID: null,
                   BatchNum:"",
                   TestID:null,
                   ResultApprovedDate:null,
                   ResultApprovedBy:"",
                   ExportUploadedBy:"",
                   MachineData:null,
                   Notes:""
               } as Batch,
                TestType: {label: "", value: null},
            })
        }
    }

    public static display(){
        window["$"]("#" + BatchManagementModal.ID).modal({
            backdrop: 'static', keyboard: false
        });
    }

    async componentDidMount() {
        let testResponse = await TestsAPI.getAllTestDetails();
        this.setState({
            testTypeOptions: testResponse.tests.filter(t => t.IsLabTest).map(test => {
                return ({label: test.Name, value: test.ID})
            }),
        })
    }

    render(){
        return (
            <div className="modal fade form_modal" id={BatchManagementModal.ID}
                 tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="result_modal_label">Batch Management</h5>
                            <button type="button" className="close" data-dismiss="modal"
                                    aria-label="Close" onClick={()=>
                                this.setState({
                                    selectedBatch:{
                                        ID: null,
                                        BatchNum: "",
                                        TestID: null,
                                        ResultApprovedDate: null,
                                        ResultApprovedBy: "",
                                        Notes: "",
                                        ExportUploadedBy: "",
                                        MachineData: null,
                                    } as Batch,
                                    TestType: {label: "", value: null}
                                })
                            }>
                                <span aria-hidden="true">&times;</span>
                            </button>

                        </div>
                        <div className="modal-body m-3">
                            { buildRow("Batch Number",
                                <input className={"form-control"}
                                       maxLength={50}
                                       autoComplete={"off"}
                                       type={"search"}
                                       name={"BatchNum"}
                                       onChange={(e)=>{
                                           this.setState( (prevState) => ({
                                               selectedBatch: {
                                                   ...prevState.selectedBatch,
                                                   BatchNum: e.target.value
                                               }
                                           }))
                                       }}
                                       value={this.state.selectedBatch.BatchNum}
                                />
                            )}
                            { buildRow("Test Type",
                                <Select
                                    placeholder={"Please Select..."}
                                    className={"state_select"}
                                    name={"TestType"}
                                    options={this.state.testTypeOptions}
                                    onChange={(e)=>{
                                        this.setState( (prevState) => ({
                                            selectedBatch: {
                                                ...prevState.selectedBatch,
                                                TestID: e.value
                                            },
                                            TestType: e
                                        }))
                                    }}
                                    value={this.state.TestType}
                                />
                            )}
                            { this.state.selectedBatch.ID != null ?
                                buildRow("Result Approved Date",
                                    <input className={"form-control"}
                                           maxLength={50}
                                           autoComplete={"off"}
                                           type={"text"}
                                           name={"ResultApprovedDate"}
                                           readOnly={true}
                                           value={this.state.selectedBatch.ResultApprovedDate ? moment(this.state.selectedBatch.ResultApprovedDate, "MM-DD-YYYY").format('MM-DD-YYYY') : ''}
                                    />
                                ): <React.Fragment />
                            }
                            { this.state.selectedBatch.ID != null ?
                                buildRow("Export Uploaded By",
                                    <input className={"form-control"}
                                           maxLength={1000}
                                           autoComplete={"off"}
                                           type={"search"}
                                           name={"ExportUploadedBy"}
                                           readOnly={true}
                                           value={this.state.selectedBatch.ExportUploadedBy}
                                    />
                                ): <React.Fragment />
                            }
                            { buildRow("Notes",
                                <textarea className={"form-control"}
                                          maxLength={1000}
                                          autoComplete={"off"}
                                          name={"Notes"}
                                          onChange={(e)=>{
                                              this.setState( (prevState) => ({
                                                  selectedBatch: {
                                                      ...prevState.selectedBatch,
                                                      Notes: e.target.value
                                                  }
                                              }))
                                          }}
                                          value={this.state.selectedBatch.Notes}
                                />
                            )}
                            {/** Display Machine Data for Batches that exist **/}
                            { this.state.selectedBatch.ID != null ?
                                buildRow('Machine Data',
                                    <JSONPretty id='json-pretty'
                                                mainStyle='padding:1em'
                                                data={this.state.selectedBatch.MachineData}
                                    />
                                ) :
                                <React.Fragment />
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary"
                                    onClick={() => {
                                        if( !this.state.selectedBatch.BatchNum ||
                                            isBlank(this.state.selectedBatch.BatchNum) ||
                                            isOnlyWhitespace(this.state.selectedBatch.BatchNum)
                                        ){
                                            return sweetalert.fire("Attention", "Please enter a Batch Number.", "error")
                                        }
                                        if( !this.state.selectedBatch.TestID ){
                                            return sweetalert.fire("Attention", "Please select a Test Type.","error")
                                        }
                                        this.props.onSubmit(this.state.selectedBatch)
                                    }}>
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}