import NetworkUtil from "./NetworkUtil";
import {ServerResponse} from "../types/ServerResponse";
import {ResultType} from "../types/ResultType";
import Banner from "../types/Banner";
import TestType from "../types/TestType";

export default class SystemAPI {

    public static async getBanner():Promise<{show:boolean, banner:Banner}>{
        return NetworkUtil.makeGet("/api/system/banner");
    }

    public static async getAllStates():Promise<{label:string, value:string}[]>{
        return NetworkUtil.makeGet("/api/system/states");
    }

    public static async getAllCountries():Promise<{countries}>{
        return NetworkUtil.makeGet("/api/system/countries");
    }

    public static async getAllRaces():Promise<{label:string, value:number}[]>{
        return NetworkUtil.makeGet("/api/system/race");
    }

    public static async getAllGenders():Promise<{label:string, value:number}[]>{
        return NetworkUtil.makeGet("/api/system/gender");
    }

    public static async getAllEthnicities():Promise<{label:string, value:number}[]>{
        return NetworkUtil.makeGet("/api/system/ethnicity");
    }

    public static async getAllSpecimenSources():Promise<{label:string, value:number}[]>{
        return NetworkUtil.makeGet("/api/system/specimensource");
    }

    public static async getAllTests():Promise<TestType[]>{
        return NetworkUtil.makeGet("/api/system/tests");
    }

    public static async getConsentEntities():Promise<{ID:number,Name:string,FacilityID:number,AccessCode:string}[]>{
        return NetworkUtil.makeGet("/api/system/consentEntities");
    }

    public static async getBarcodeStyle():Promise<any>{
        return NetworkUtil.makeGet("/api/admin/settings/tableBarcodeStyle");
    }

    public static async getAllPods(includeData:boolean=false):Promise<any>{
        return NetworkUtil.makePost("/api/admin/pods/filter", {includedata: includeData})
    }

    public static async getSpecimenLabels(body):Promise<{success:boolean, idList:any}>{
        return NetworkUtil.makePost("/api/admin/specimen", body)
    }

}