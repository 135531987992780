import React from "react";
import Overlay from "../Overlay";
import SimpleTable from "../tables/SimpleTable";
import FilterCard, { FIELD_TYPE, ReactSelect } from "../FilterCard";
import {
  getBoolSelectOptions,
  getBoolSelectVal,
  getNumPages,
  getPageOptions,
  hideModal,
  showModalNoOutsideClick,
  slicePages,
} from "../../util/FormatUtil";
import { sweetalert } from "../../App";
import domain from "../../Domain";
import Select from "react-select";
import Parse from "html-react-parser";
import { Service } from "../../types/Service";
import { ServicesManagementModal } from "../modals/ServicesManagementModal";
import NetworkUtil from "../../network/NetworkUtil";
import ServicesAPI from "../../network/ServicesAPI";
import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import { Sample } from "../../types/Sample";
import moment from "moment";
import PodSched from "../../types/PodSched";
import PaginationTool from "../PaginationTool";

const ITEMS_PER_PAGE = 25;

interface ServicesManagementState {
  showLoading: boolean;
  selectedService: Service;
  serviceQueryResults: Service[];
  tableData: {}[];
  filter?: {
    Name;
    IsEnabled;
  };
  direction?: TableOrder;
  page_options: ReactSelect[];
  selected_page?: { label; value };
  serviceNames: { label; value }[];
}

export class ServicesManagement extends React.Component<
  any,
  ServicesManagementState
> {
  constructor(props) {
    super(props);

    this.state = {
      showLoading: false,
      selectedService: {} as Service,
      tableData: [] as any,
      serviceQueryResults: [] as any,
      filter: { Name: [], IsEnabled: [] },
      direction: "asc",
      page_options: [{ label: "1", value: "1" }],
      selected_page: { label: "1", value: 1 },
      serviceNames: [],
    };

    this.createOrModifyService = this.createOrModifyService.bind(this);
  }

  componentDidMount() {
    this.loadServiceNames();
  }

  loadServiceNames() {
    this.setState({ showLoading: true }, async () => {
      ServicesAPI.getServiceNames()
        .then((data) => {
          this.setState({ serviceNames: data.data, showLoading: false });
        })
        .catch((e) => {
          return sweetalert.fire({
            icon: "info",
            title: "",
            text: "No Services found.",
          });
        });
    });
  }

  queryServicesData(page: number) {
    this.setState({ showLoading: true }, async () => {
      let results = await ServicesAPI.filterServices({
        filter: {
          Name: this.state.filter.Name,
          IsEnabled: this.state.filter.IsEnabled,
        },
      });
      if (!results.success) {
        return sweetalert.fire({
          title: "",
          text: "Unable to filter Services data at this time",
          icon: "error",
        });
      }

      let names = await ServicesAPI.getServiceNames();
      if (!names.success) {
        return sweetalert.fire({
          icon: "info",
          title: "",
          text: "No Services found.",
        });
      }

      this.setState({
        tableData: slicePages(results.data, page, ITEMS_PER_PAGE),
        serviceQueryResults: results.data,
        page_options: getPageOptions(getNumPages(results.data, ITEMS_PER_PAGE)),
        showLoading: false,
        serviceNames: names.data,
      });
    });
  }

  createOrModifyService(service: Service) {
    this.setState({ showLoading: true }, async () => {
      if (service.ID === null) {
        let response = await ServicesAPI.createService(service);
        if (!response.success) {
          let msg = "Unable to create Service at this time.";
          if (response.reason) {
            msg = response.reason;
          }
          return sweetalert.fire({
            icon: "error",
            title: "Attention",
            text: msg,
          });
        }
      } else {
        let response = await ServicesAPI.editService(service);
        if (!response.success) {
          let msg = "Unable to edit Service at this time.";
          if (response.reason) {
            msg = response.reason;
          }
          return sweetalert.fire({
            icon: "error",
            title: "Attention",
            text: msg,
          });
        }
      }

      sweetalert
        .fire({ icon: "success", title: "", text: "Service saved" })
        .then(() => {
          this.setState(
            {
              selectedService: {
                ID: null,
                Name: "",
                IsEnabled: getBoolSelectVal(0),
                HtmlTemplate: "",
              } as Service,
            },
            () => {
              this.loadServiceNames();
              this.queryServicesData(this.state.selected_page.value);
            }
          );
        });
    });
  }

  handleExportToCSV() {
    this.setState({ showLoading: true }, async () => {
      await NetworkUtil.downloadCSV(
        "/api/admin/services/csv",
        "ServicesManagementData.xlsx",
        { filter: this.state.filter }
      );
      this.setState({ showLoading: false });
    });
  }

  changePage(page: number) {
    let allServices = this.state.tableData;
    let returnData = slicePages(allServices, page, ITEMS_PER_PAGE);
    this.setState({ tableData: returnData });
  }

  renderServicesFilterFields() {
    return (
      <FilterCard
        fields={[
          {
            label: "Name",
            key: "Name",
            type: FIELD_TYPE.SELECT,
            options: this.state.serviceNames,
            isMapped: true,
            isMulti: true,
            textType: "text",
          },
          {
            label: "Is Enabled",
            key: "IsEnabled",
            type: FIELD_TYPE.SELECT,
            options: getBoolSelectOptions(),
            isMapped: true,
            isMulti: true,
            textType: "text",
          },
        ]}
        filterChanged={(e) => this.setState({ filter: e })}
      />
    );
  }

  useSorter(col: Column) {
    let sorter = new Sorter<any>();

    this.setState({
      tableData: sorter.sortByKey(
        this.state.tableData,
        col.key as keyof any,
        this.state.direction
      ),
      direction: this.state.direction === "asc" ? "desc" : "asc",
    });
  }

  render() {
    return (
      <React.Fragment>
        <PaginationTool />
        <Overlay show_loading={this.state.showLoading} />
        <ServicesManagementModal
          selectedService={this.state.selectedService}
          onSubmit={(service) => {
            hideModal(ServicesManagementModal.ID);
            this.createOrModifyService(service);
          }}
        />

        <div className="container-fluid ">
          <div className={"row"}>
            <div className="col-12 col-md-12 col-lg-8 col-xl-5 pt-2">
              <div className="card mb-2">
                <div className="card-header verlag-bold">
                  <h3>Services Management</h3>
                </div>
                <div className="card-body">
                  {this.renderServicesFilterFields()}
                </div>
                <div className="card-footer">
                  <button
                    className={"btn btn-outline-primary "}
                    onClick={() => {
                      this.setState(
                        {
                          selected_page: { label: 1, value: 1 },
                        },
                        () => {
                          this.queryServicesData(
                            this.state.selected_page.value
                          );
                        }
                      );
                    }}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-outline-success float-right"
                    onClick={() =>
                      this.setState(
                        {
                          selectedService: {
                            ID: null,
                            Name: "",
                            IsEnabled: getBoolSelectVal(0),
                            HtmlTemplate: "",
                          } as Service,
                        },
                        () => {
                          showModalNoOutsideClick(ServicesManagementModal.ID);
                        }
                      )
                    }
                  >
                    Create New
                  </button>
                </div>
              </div>
            </div>
            {this.state.tableData && this.state.tableData.length > 0 && (
              <div className="col-12 col-md-12 col-lg-12 pt-2">
                <div className="card mt-2 mb-5">
                  <div className="card-header verlag-bold stickToTop">
                    <h3>
                      Services
                      <section className="tableHeaderSection float-md-right ">
                        <h3 className={"float-md-right"}>
                          Total: {this.state.tableData.length}
                        </h3>
                        <h4 className="d-inline-block float-right align-middle pr-2 ml-5">
                          Page{" "}
                        </h4>
                        <div className=" align-middle float-right pages ">
                          <Select
                            isSearchable={true}
                            placeholder={"1"}
                            noOptionsMessage={() => "No option"}
                            value={this.state.selected_page}
                            onChange={(e: ReactSelect) =>
                              this.setState({ selected_page: e }, () =>
                                this.changePage(e.value)
                              )
                            }
                            className={"state_select"}
                            options={this.state.page_options}
                          />
                        </div>
                      </section>
                      <button
                        className={"btn btn-outline-primary ml-3"}
                        onClick={() => this.handleExportToCSV()}
                      >
                        Export to CSV
                      </button>
                    </h3>
                  </div>
                  <div className="card-body p-0 m-0 table-responsive-md">
                    <SimpleTable
                      columns={[
                        {
                          label: "Name",
                          key: "Name",
                          rawFormat: (val) => {
                            return (
                              <a
                                href={"#!"}
                                onClick={() => {
                                  this.setState({ selectedService: val }, () =>
                                    showModalNoOutsideClick(
                                      ServicesManagementModal.ID
                                    )
                                  );
                                }}
                              >
                                {val.Name}
                              </a>
                            );
                          },
                          popoverText: "Click to sort by Name",
                        },
                        {
                          label: "Enabled",
                          key: "IsEnabled",
                          popoverText: "Click to sort by Enabled",
                          rawFormat: (val) => {
                            let string = getBoolSelectVal(val.IsEnabled);
                            return <p>{string.label}</p>;
                          },
                        },
                        {
                          label: "Confirmation Description",
                          key: "HtmlTemplate",
                          popoverText:
                            "Click to sort by Confirmation Description",
                          rawFormat: (val) => {
                            let parsed = Parse(val.HtmlTemplate);
                            return <p>{parsed}</p>;
                          },
                        },
                      ]}
                      table_data={this.state.tableData}
                      columnClickedCallback={(col) => {
                        this.useSorter(col);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
