import React, {useRef} from "react";
import FilterCard, {FIELD_TYPE} from "./FilterCard";
import domain from "../Domain";
import {getBrandingImage, parseDate} from "../util/FormatUtil";
import ReactToPrint from "react-to-print";
import TestResult from "./TestResult";
import Overlay from "./Overlay";
import moment from "moment";
import ConsentingModal from "./modals/ConsentingModal";
import {getToken} from "../util/CaptchaUtil";
import {ImageType} from "../types/Branding";
import {Sample} from "../types/Sample";
import ReleaseForm from "./Release";
import {load} from "recaptcha-v3";
import {CAPTCHA, sweetalert} from "../App";
import SystemAPI from "../network/SystemAPI";
import SubmissionAPI from "../network/SubmissionAPI";
import Validator, {ValidationEntry} from "../validation/Validator";
import {Validators} from "../validation/Validators";
import {calculateAge} from "../util/ValidationUtil";

interface ResultsState {
    hasResult: boolean
    showLoading: boolean
    result: any | Sample
    FirstName?: string
    LastName?: string
    DOB?: Date
    ReqNum?: string
    token?: string
    NoRecord?: string
    release?
    consenting: {ID:number,Name:string,FacilityID:number,AccessCode:string}[]
    gender?
    specimen_source?
    logo
    test?
}

export default class ResultsForm extends React.Component<any, ResultsState> {
    private componentRef: any;

    constructor(props) {
        super(props);
        this.state = {result: {}, showLoading: false, hasResult: false, consenting: [], logo:""}
        this.submit = this.submit.bind(this);
        this.openConsent = this.openConsent.bind(this);
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            SystemAPI.getAllGenders().then(data => {
                this.setState({gender: data})
            })
            SystemAPI.getAllSpecimenSources().then(data => {
                this.setState({specimen_source: data})
            })
            SystemAPI.getConsentEntities().then(data => {
                this.setState({consenting: data})
            })
        })

        getBrandingImage(ImageType.LOGO).then(r =>{
            this.setState({logo:r, showLoading:false})
        });

        const query = new URLSearchParams(window.location.search);
        if (query.get('fname') && query.get('fname') !== "") {
            console.log(query.get("dob"))
            let parsedDate = moment(query.get("dob"), ["MM/DD/YYYY", "MM-DD-YYYY", "YYYY-MM-DD"], false)
            this.setState({
                FirstName: query.get('fname'),
                LastName: query.get("lname"),
                DOB: parsedDate.toDate(),
                ReqNum: query.get("conf"),
                NoRecord: query.get("norecord")
            }, () => this.submit())
        }
    }

    getPatientGender(id){
        let gender = this.state.gender.find(g => g.value === id)
        if(!gender){
            return null;
        }
        return gender.label
    }

    getSpecimenSource(id){
        let ss = this.state.specimen_source.find(s => s.value === id)
        if(!ss){
            return null;
        }
        return ss.label
    }

    openConsent() {
        ConsentingModal.display();
    }

    private async submit() {
        console.log('result state', this.state)
        let formValidation = {
            FirstName: this.state.FirstName,
            LastName: this.state.LastName,
            ReqNum: this.state.ReqNum,
            DOB: this.state.DOB
        }

        let validator = new Validator<any>()
            .withSimpleValidation("FirstName", Validators.requireNotBlankValidator("First Name"))
            .withSimpleValidation("LastName", Validators.requireNotBlankValidator("Last Name"))
            .withSimpleValidation("DOB", Validators.requireNonNullValidator("Date of Birth"))
            .withSimpleValidation("ReqNum", Validators.requireNotBlankValidator("Confirmation Number"))
        let validationResponse = validator.validate(formValidation);
        if(!validationResponse.success) {
            return sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
        }
        const filterObj = {
            FirstName: this.state.FirstName,
            LastName: this.state.LastName,
            ReqNum: this.state.ReqNum,
            DOB:  parseDate(this.state.DOB)
        }
        let token:string = await getToken();

        this.setState({showLoading: true})
        let result = await SubmissionAPI.submitResults(filterObj, token);
        try {
            if (result.success) {
                this.setState({
                    hasResult: true,
                    result: result.result
                })
            } else {
                sweetalert.fire({icon: 'error', title: '', text: result.reason});
            }
            this.setState({showLoading: false});
        }
        catch (e) {
            console.log(e)
            sweetalert.fire({icon: 'error', title: '', text: "We have encountered an error obtaining your results. Check your connection and try again, or please check back in a few hours"});
            this.setState({showLoading: false})
        }
    }


    getForm() {
        return (
            <React.Fragment>
                <Overlay show_loading={this.state.showLoading}/>
                <div className="container-fluid  ">
                    <div className={"row"}>
                        <div
                            className="col-12 col-md-12 offset-xl-3 col-xl-6 offset-lg-2 col-lg-8  pt-2 text-center responsive-p">
                            <p>A text and email will be sent to you once your test results are ready with instructions on how to securely obtain your results. Most test results will be available within 3 business days (excluding holidays and weekends) after your scheduled appointment date. </p>
                        </div>
                        <div className="col-12 col-md-12 offset-xl-3 col-xl-6 offset-lg-2 col-lg-8  pt-2">
                            <div className="card mb-2">
                                <div className="card-header verlag-bold">
                                    <h3>Find My Results</h3>
                                </div>
                                <div className="card-body">
                                    <FilterCard
                                        fields={[{label: "First Name", "key": "FirstName", type: FIELD_TYPE.TEXT},
                                            {label: "Last Name", "key": "LastName", type: FIELD_TYPE.TEXT},
                                            // {label:"Email", "key":"PatientEmail", type:FIELD_TYPE.TEXT },
                                            {
                                                label: "Date of Birth",
                                                "key": "DOB",
                                                type: FIELD_TYPE.DATE,
                                                dateWarning:true
                                            },
                                            {
                                                label: "Confirmation Code",
                                                "key": "ReqNum",
                                                type: FIELD_TYPE.TEXT,
                                                onInput: (e) => e.target.value = e.target.value.toUpperCase()
                                            },
                                        ]} filterChanged={(e) => this.setState(e)}/>
                                </div>
                                <div className="card-footer">
                                    <button className="btn btn-outline-primary" onClick={this.submit}>Get My Results
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    getPCRResult(result) {
        return (
            <React.Fragment>
                {/*<ConsentingModal consenting={this.state.consenting} result={this.state}/>*/}
                <div className="container  min-vh-100 ">
                    <div className={"row"}>
                        <ReleaseForm consenting={result.ConsentEntity} releaseChanged={(r) => this.setState({release: r},
                            ()=> this.sendConsent())}
                        />
                        <div className={"col-12 text-center pt-2 verlag-bold responsive-p"}>
                            <p>
                                For further questions regarding your result, please consult your doctor or primary care
                                physician. You can also learn more at the <a target="_blank"
                                                                             href={"https://www.cdc.gov/coronavirus/2019-ncov/testing/diagnostic-testing.html"}>Centers
                                for Disease Control and Prevention website</a>.
                            </p>
                            <ReactToPrint
                                trigger={() => <button className={"btn btn-outline-primary"}>Print Results</button>}
                                // @ts-ignore
                                content={() => this.componentRef}
                            />
                        </div>
                    </div>
                    <TestResult logo={this.state.logo} result={result} ref={el => (this.componentRef = el)}/>

                </div>
            </React.Fragment>
        )
    }

    async sendConsent(){
        if(!this.state.release  || !this.state.ReqNum)
            return;
        let recaptcha = await load(CAPTCHA);
        let token = await recaptcha.execute('login');

        let result = await SubmissionAPI.release(this.state.release, this.state.ReqNum, token);
        try{
            if(result.success)
                return sweetalert.fire("Consent saved.")
            if(!result.success)
                sweetalert.fire("Could not save this consent. ")
        }catch (e) {
            console.log(e)
        }
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        if (!this.state.hasResult)
            return this.getForm();
        let result = this.state.result;
        return this.getPCRResult(result);
    }

}