import React from "react";
import Select from "react-select";
import {sweetalert} from "../../App";
import {isEmptyObject} from "jquery";
import {buildRow} from "../../util/FormatUtil";
import {isBlank, isOnlyWhitespace, isValidEmail} from "../../util/ValidationUtil";

interface PermsState{
    selectedUser: {
        id?
        firstName?
        lastName?
        combinedName?
        email?
        selectedPerms
        status: string
        deactivate: boolean
        facilityGroup:{ label, value }
    },
    permGroups: { label, value }[],
    facilityGroups: { label, value }[]
}

interface PermsProps{
    selectedUser
    facilityGroups
    permissionGroups
    onSubmit
}

export class UserPermissionsModal extends React.Component<PermsProps, PermsState>{
    public static readonly ID = "userManagement";

    constructor(props) {
        super(props);
        this.state = {
            selectedUser: {
                id: -1,
                combinedName: '',
                firstName:'',
                lastName:'',
                email:'',
                selectedPerms:[],
                status: '',
                deactivate: false,
                facilityGroup: {label: "", value: -1}
            },
            facilityGroups:[],
            permGroups:[]
        } as PermsState;
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps(nextProps: Readonly<PermsProps>, nextContext: any) {
        if(nextProps.facilityGroups){
            this.setState({facilityGroups: nextProps.facilityGroups})
        }

        if(nextProps.permissionGroups){
            this.setState({permGroups: nextProps.permissionGroups})
        }

        if (!isEmptyObject(nextProps.selectedUser)) {
            let user = nextProps.selectedUser;
            if (user.id) {

                this.setState({
                    selectedUser: {
                        combinedName: user.combinedName,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                        selectedPerms: this.state.permGroups.filter(f =>
                            user.permissions.find(selected => selected.id === f.value)
                        ),
                        status: user.status,
                        deactivate: user.status === 'DEACTIVATE',
                        id: user.id,
                        facilityGroup: this.state.facilityGroups.find(a =>
                            user &&
                            user.facilityGroupID === a.value ? a : ""
                        )

                    }
                })
            } else {
                this.setState({
                    selectedUser: {
                        combinedName: "",
                        firstName: "",
                        lastName: "",
                        email: "",
                        selectedPerms: [],
                        status: "ACTIVE",
                        deactivate: false,
                        facilityGroup: {label: "", value: -1},
                        id: -1  // -1 means no user, create one
                    }
                });
            }
        }
    }

    public static display(){
        window["$"]("#" + UserPermissionsModal.ID).modal({
            backdrop: 'static', // prevent outside click
            keyboard: false // prevent esc close
        })
    }

    getRoleDesc(){
        let fac = (<div style={{fontSize: "small"}}>
            <p>Facility permission groups only have access to patients and facilities related to their assigned facility group.</p>
            <dl className={"row"}>
                <dt className={"col-md-3"}>Facility Admin</dt>
                <dd className={"col-md-9"}>Edit and read access to Testing Check-in, Walk-In Form, Rapid Test Management, Pod Management and Stats, Send Alert, Samples, and Facility.</dd>
                <dt className={"col-md-3"}>Facility Customer Service</dt>
                <dd className={"col-md-9"}>Edit and read access to Samples. Read-only access to Pod Management and Stats page and Samples Status.</dd>
                <dt className={"col-md-3"}>Facility Read Only</dt>
                <dd className={"col-md-9"}>Read-only access for Pod Management and Stats, Samples, and Samples Status.</dd>
                <dt className={"col-md-3"}>Facility Sample Collection</dt>
                <dd className={"col-md-9"}>Edit and read access for Testing Check-In, Walk-In Form, and Rapid Test Management. Read-only access for Pod Management and Stats, Samples, and Samples Status.</dd>
            </dl>
        </div>);
        let global = (<div style={{fontSize: "small"}}>
            <p>Global permission groups have access to all OSDH patients and facilities, regardless of their assigned facility group. They have permission to download a CSV export from Samples page with Patient Identifiers, and can access Audit Logs.</p>
            <dl className={"row"}>
                {/*<dt className={"col-md-3"}>Global Admin</dt>*/}
                {/*<dd className={"col-md-9"}>Access to all Facility Admin pages as well as all lab related pages and records (Must be added by IMMY Technology Group)</dd>*/}
                <dt className={"col-md-3"}>Global Customer Service</dt>
                <dd className={"col-md-9"}>Edit and read the Samples page. Read-only access for Pod Management and Stats.</dd>
                <dt className={"col-md-3"}>Global Lab</dt>
                <dd className={"col-md-9"}>Edit and Read all pages, excluding Setup pages, including Facility, User Management, and Test Management.</dd>
                <dt className={"col-md-3"}>Global Read Only</dt>
                <dd className={"col-md-9"}>Read-only access for Pod Management and Stats, Samples, and Samples Status.</dd>
            </dl>
        </div>);
        let isGlob = this.state.permGroups.find(f => f.value === "00g1wzl9ax0oP5Pkr5d7");

        return (
            <div className={"col-md-12"}>
                <h5>Permission Group Descriptions</h5>
                <div className={"col-md-12"}>
                    {fac}
                    {isGlob ? global : null}
                </div>
                <hr />
            </div>
        )
    }

    handleChange(event) {
        this.setState((prevState) => ({
            selectedUser:{
                ...prevState.selectedUser,
                deactivate: event.target.checked
            }
        }))
    }

    getUserAccountStatusInput(){
        let userAccountIsActive = this.state.selectedUser.status === 'ACTIVE' &&
            this.state.selectedUser.id != -1;

        let showAccountStatusAndCheckbox = (
            <div className={"col-sm-8 p-0 m-0 input-group"}>
                <input type={"search"} className={"form-control"}
                       readOnly={true} id={"Status"} value={this.state.selectedUser.status} />
                <div className={"input-group-append"}>
                    <span className={"input-group-text deactivate-check-label"}>
                        Deactivate Account
                        <input className={"input-group-addon deactivate-check-input"}
                               type={"checkbox"}
                               onChange={this.handleChange}
                               checked={this.state.selectedUser.deactivate}
                               readOnly={this.state.selectedUser.id != -1}
                        />
                    </span>
                </div>
            </div>
        );

        let showAccountStatus = (
            <div className={"col-sm-8 p-0 m-0"}>
                <input type={"search"} className={"form-control"}
                       readOnly={true} id={"Status"} value={this.state.selectedUser.status} />
            </div>
        );

        return (
            <div className={"form-group row"}>
                <label htmlFor={"Status"} className={"col-sm-4 col-form-label"}>
                    Account Status
                </label>
                { userAccountIsActive ?
                    showAccountStatusAndCheckbox :
                    showAccountStatus
                }
            </div>
        )
    }
    render() {
        console.log(' render this.state', this.state)
        let isNewUser = this.state.selectedUser.id === -1;

        return  <div className="modal fade form_modal" id={UserPermissionsModal.ID} tabIndex={-1} role="dialog"
                     aria-labelledby="result_modal_label"
                     aria-hidden="true">
            <div className="modal-dialog modal-lg modal-xl" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="result_modal_label">User Management</h5>
                        <button type="button" className="close" data-dismiss="modal"
                                aria-label="Close" onClick={()=>
                            this.setState({
                                selectedUser:{
                                    firstName: "",
                                    lastName: "",
                                    email: "",
                                    selectedPerms: [],
                                    status: "ACTIVE",
                                    deactivate: false,
                                    facilityGroup: {label: "", value: -1},
                                    id: -1  // -1 means no user, create one
                                }
                            })
                        }>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body mr-3">
                        <div className={"row"}>
                            {this.getRoleDesc()}
                        </div>

                        { isNewUser ? buildRow("First Name",
                            <input className="form-control"
                                   maxLength={50}
                                   autoComplete="off"
                                   type={"search"}
                                   name={"First Name"}
                                   onChange={(e) => this.setState((prevState)=>({
                                       selectedUser:{
                                           ...prevState.selectedUser,
                                           firstName: e.target.value
                                       }})
                                   )}
                                   readOnly={this.state.selectedUser.id != -1}
                                   value={this.state.selectedUser.firstName}
                            />
                        ) : buildRow("Name",
                            <input className="form-control"
                                   maxLength={100}
                                   autoComplete="off"
                                   type={"search"}
                                   readOnly={true}
                                   value={this.state.selectedUser.combinedName}
                            />
                        )}

                        { isNewUser ? buildRow("Last Name",
                            <input  className="form-control"
                                    maxLength={50}
                                    autoComplete="off"
                                    type={"search"}
                                    name={"Last Name"}
                                    onChange={(e) => this.setState((prevState) =>({
                                        selectedUser:{
                                            ...prevState.selectedUser,
                                            lastName: e.target.value
                                        }})
                                    )}
                                    readOnly={this.state.selectedUser.id != -1}
                                    value={this.state.selectedUser.lastName}
                            />
                        ) : <React.Fragment />
                        }

                        { buildRow("Email/Username",
                            <input className="form-control"
                                   maxLength={100}
                                   autoComplete="off"
                                   type={"search"}
                                   name={"Email"}
                                   onChange={(e) => this.setState((prevState) => ({
                                       selectedUser:{
                                           ...prevState.selectedUser,
                                           email: e.target.value
                                       }})
                                   )}
                                   readOnly={this.state.selectedUser.id != -1}
                                   value={this.state.selectedUser.email}
                            />
                        )}

                        { buildRow("Permission Groups",
                            <Select
                                placeholder={"Please Select..."}
                                value={this.state.selectedUser.selectedPerms}
                                name={"Permissions"}
                                onChange={(e) => this.setState((prevState) => ({
                                    selectedUser: {
                                        ...prevState.selectedUser,
                                        selectedPerms: e
                                    }})
                                )}
                                className={"state_select"}
                                options={this.state.permGroups}
                                isMulti={true}
                            />
                        )}

                        { buildRow("Facility Group",
                            <Select
                                placeholder={"Please Select..."}
                                value={this.state.selectedUser.facilityGroup}
                                name={"Facility Group"}
                                onChange={(e) => this.setState((prevState)=>({
                                    selectedUser: {
                                        ...prevState.selectedUser,
                                        facilityGroup: e
                                    }})
                                )}
                                options={this.state.facilityGroups}
                                className={"state_select"}
                                isMulti={false}
                                readOnly={this.state.selectedUser.id != -1}
                                isDisabled={this.state.selectedUser.id != -1}
                            />
                        )}

                        { this.getUserAccountStatusInput() }

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary"
                                    onClick={()=>{
                                        //form validations
                                        if(isBlank(this.state.selectedUser.firstName) ||
                                            isOnlyWhitespace(this.state.selectedUser.firstName)){
                                            return sweetalert.fire({icon: 'error', title: '', text: 'First Name is required.'})
                                        }
                                        if(isBlank(this.state.selectedUser.lastName) ||
                                            isOnlyWhitespace(this.state.selectedUser.lastName)){
                                            return sweetalert.fire({icon: 'error', title: '', text: 'Last Name is required.'})
                                        }
                                        if(isBlank(this.state.selectedUser.email) ||
                                            isOnlyWhitespace(this.state.selectedUser.email)){
                                            return sweetalert.fire({icon: 'error', title: '', text: 'Email is required.'})
                                        }
                                        if(!isValidEmail(this.state.selectedUser.email)){
                                            return sweetalert.fire({icon: 'error', title: '', text: 'Email format is invalid.'})
                                        }
                                        if(!this.state.selectedUser.selectedPerms ||
                                            this.state.selectedUser.selectedPerms.length == 0){
                                            return sweetalert.fire({icon: 'error', title: '', text: 'At least one Permission Group is required.'})
                                        }
                                        if(this.state.selectedUser.facilityGroup.value == -1){
                                            return sweetalert.fire({icon: 'error', title: '', text: 'Facility Group is required. Select "null" to leave Facility Group unassigned'})
                                        }
                                        this.props.onSubmit(this.state.selectedUser)
                                    }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}