import React from "react";
import {parseDate} from "../util/FormatUtil";
import moment from "moment";
import Select from "react-select";
import domain from "../Domain";
import ServicesAPI from "../network/ServicesAPI";
import {sweetalert} from "../App";
interface AppointmentProps{
    schedMap:any[]
    onChange:(e) => void
    passClearStateFunc?
}

interface AppointmentState{
    location:{
        service?:{label:string, value:number},
        day?: {label:string, value:{day:string, times:{remaining:number, time:string}[], schedID:number}};
        facility:{label:string, value:{
                FacilityName?: string
                Address?:string
                days?:{day:string}[]}}
        ,
        time?:{label:string, value:any}
    }
    schedule
    services
}
export class AppointmentForm extends React.Component<AppointmentProps, AppointmentState>{

    constructor(props) {
        super(props);
        this.state = {
            location:{
                facility:null,
                service:null
            },
            schedule:[],
            services:[]
        }
        this.clearState = this.clearState.bind(this);
    }

    componentDidMount() {
        ServicesAPI.getServicesForAppointmentForm().then(response => {
                this.setState({services: response});
            });
    }

    shouldComponentUpdate(nextProps: Readonly<AppointmentProps>, nextState: Readonly<AppointmentState>, nextContext: any): boolean {
        if(this.props.passClearStateFunc) {
            this.props.passClearStateFunc(this.clearState);
        }
        return true;
    }

    updateParent(){
        this.props.onChange(JSON.parse(JSON.stringify(this.state.location)))
    }

    clearState = () =>{
        this.setState({location:{facility:null, service:null, day:null, time:null}})
    }


    render() {
        let schedMap = this.props.schedMap;
        let facilities = [];
        let days = [];
        let times = [];

        let mapValues = schedMap ? Object.values(schedMap) : [];
        mapValues = mapValues.sort((a:any,b:any) => a.FacilityName ?  a.FacilityName.localeCompare(b.FacilityName) : 0);
        if(schedMap && this.state.location.service){
            facilities = mapValues.map(v => ({label:v.FacilityName + " - " + v.Address, value:v}));
            facilities = facilities.filter(f =>{
                return f.value.days.find(d => d.service === this.state.location.service.value);
            })
        }

        if(this.state.location.facility &&  this.state.location.facility.value && this.state.location.facility.value.days){
            days  = this.state.location.facility.value.days.map(d => ({label:parseDate(d.day), value:d}));
            days = days.filter(d => d.value.service === this.state.location.service.value)
            console.log(facilities);
        }

        let timeMessage = "Please select a day.";

        if(this.state.location.day){
            times = this.state.location.day.value.times.map(t => ({label: t.time + " " + t.remaining + " slots remaining", value:t.time}));
            if(times.length === 0){
                timeMessage = "No more appointments are available.";
            }
        }

        return(
        <div className="card mb-2">
            <div className="card-header verlag-bold">
                <h3>Appointment</h3>
            </div>
            <div className="card-body">
                <div className="form-group row">
                    <label  className="col-sm-4 col-form-label">Service</label>
                    <div className="col-sm-8  p-0 m-0">

                        <Select
                            isSearchable={ true}
                            placeholder={"Please Select..."}
                            noOptionsMessage={()=>  "No option"}
                            className={"state_select"}
                            options={this.state.services}
                            onChange={(e) => {this.setState({location:{service: e, facility:null, time:null, day:null}})}}
                            value={this.state.location.service}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label  className="col-sm-4 col-form-label">Collection Site</label>
                    <div className="col-sm-8  p-0 m-0">
                        <Select
                            isSearchable={ true}
                            isDisabled={!(this.state.location && this.state.location.service)}
                            placeholder={"Select site or search by city, county, or zip..."}
                            noOptionsMessage={()=>  "No appointments available."}
                            className={"state_select"}
                            options={facilities}
                            onChange={(e) => this.setState({location:{service: this.state.location.service, facility:e, time:null, day:null}}, () => this.updateParent())}
                            value={this.state.location.facility}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label  className="col-sm-4 col-form-label">Day</label>
                    <div className="col-sm-8  p-0 m-0">

                        <Select
                            isSearchable={ true}
                            placeholder={"Please Select..."}
                            isDisabled={!(this.state.location && this.state.location.facility)}
                            noOptionsMessage={()=>  "Please select a collection site."}
                            className={"state_select"}
                            options={days}
                            onChange={(e) => this.setState({location:{service:this.state.location.service, facility:this.state.location.facility, day:e, time:null}}, () => this.updateParent())}
                            value={this.state.location.day}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label  className="col-sm-4 col-form-label">Time</label>
                    <div className="col-sm-8  p-0 m-0">

                        <Select
                            isSearchable={ true}
                            isDisabled={!(this.state.location && this.state.location.day)}
                            placeholder={"Please Select..."}
                            noOptionsMessage={()=>  timeMessage}
                            className={"state_select"}
                            options={times}
                            onChange={(e) => this.setState({location:{service:this.state.location.service, facility:this.state.location.facility, day:this.state.location.day, time:e}}, () => this.updateParent())}
                            value={this.state.location.time}
                        />
                    </div>
                </div>
            </div>
        </div>);
    }
}