import React from "react"
interface ModalMessageState{

}

interface ModalMessageProps{
    title:string
    ID:string
    onSubmit:(any) => void
    message:any
    buttonText:string
    cancelButton?:boolean
    isSubmitting?:boolean
}

class ModalMessage extends React.Component<ModalMessageProps, ModalMessageState>{

    constructor(props) {
        super(props);
        this.state = {};
        this.onSubmit = this.onSubmit.bind(this);
        this.getCancel = this.getCancel.bind(this);
    }

    onSubmit(e){
        this.props.onSubmit(e);
    }
    getCancel(){
        if(this.props.cancelButton)
            return (<button type="button" className="btn btn-danger" onClick={() =>
                // @ts-ignore
                window.$("#" + this.props.ID).modal('hide')}>Go Back</button>)

    }

    render() {
        return (
            <div className="modal fade form_modal" id={this.props.ID} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="result_modal_label">{this.props.title}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body ">
                            {this.props.message}

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={this.onSubmit} disabled={this.props.isSubmitting}>{this.props.buttonText}</button>
                            {this.getCancel()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalMessage;