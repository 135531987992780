import React from "react";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import Overlay from "../Overlay";
import {AuditLog} from "./AuditLog";
import ServicesAPI from "../../network/ServicesAPI";
import CommTemplatesAPI from "../../network/CommTemplatesAPI";
import TestsAPI from "../../network/TestsAPI";
import BatchesAPI from "../../network/BatchesAPI";
import AdminAPI from "../../network/AdminAPI";
import { isEmptyObject } from "jquery";

interface AuditLogsState{
    show_loading: boolean
    filter: object
    AuditTypeList: any
    facilities: any
    testTypes: any
    batches: any
    services: any
    templates: any
    labinfo: any
}

export class AuditLogs extends React.Component<any,AuditLogsState>{
    constructor(props) {
        super(props);
        this.state = {
            show_loading: false,
            filter: {},
            AuditTypeList:[
                { label: "Samples", value: "sample" },
                { label: "Facility", value: "facility" },
                { label: "Pod", value: "pod" },
                { label: "Batch", value: "batch" },
                { label: "Alert", value: "alert" },
                { label: "Service", value: "service"},
                { label: "Communication Templates", value: "commTemplate"},
                { label: "Lab Information", value: "labInformation"}
            ],
            facilities: [],
            testTypes: [],
            batches: [],
            services: [],
            templates: [],
            labinfo: []
        };
    }

    componentDidMount() {
        this.setState({show_loading: true}, () => {
            TestsAPI.getAllTestDetails().then(data => {
                this.setState({testTypes: data.tests, show_loading: false})
            })
            BatchesAPI.getBatchesForSamples().then(data => {
                this.setState({batches: data.map(obj => {return obj.label})})
            })
            AdminAPI.getAllFacilities().then(data => {
                let facilityNamesMapped = data.authorizedFacilities.map(fac => {
                    return {
                        label: fac.FacilityName,
                        value: fac.ID
                    }
                })
                this.setState({facilities: facilityNamesMapped})
            })
            ServicesAPI.getAllServices().then(data => {
                this.setState({services: data.data.map(obj => { return obj.Name})})
            })
            CommTemplatesAPI.getEditableTemplates().then(data => {
                this.setState({templates: data.data.map(obj => {return obj.Name})})
            })
        })
    }

    render() {
        return(
            <React.Fragment>
                <Overlay show_loading={this.state.show_loading}/>
                <div className="container-fluid  min-vh-100 ">
                        <div className={"row"}>
                            <div className="col-6 col-md-6 col-lg-6 col-xl-6 pt-2">
                                <div className={this.state.filter['AuditType'] ? "card mt-2 border-bottom-0" : "card mt-2"}>
                                    <div className="card-header verlag-bold">
                                        <h3>Audit Logs</h3>
                                    </div>
                                    <div className="card-body">
                                        <FilterCard fields={
                                            [
                                                {
                                                    label: "Audit Type",
                                                    "key": "AuditType",
                                                    type: FIELD_TYPE.SELECT,
                                                    options: this.state.AuditTypeList,
                                                    isMapped: true,
                                                    isMulti: false,
                                                    textType: "text"
                                                },
                                            ]}
                                                    filterChanged={(filter) => this.setState({filter: filter})}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                        {!isEmptyObject(this.state.filter) &&
                            <AuditLog key={this.state.filter['AuditType']} auditLogType={this.state.filter["AuditType"]} batches={this.state.batches} tests={this.state.testTypes} templates={this.state.templates} facilities={this.state.facilities} services={this.state.services} />
                        }

                </div>
            </React.Fragment>
        )
    }
}
