import React, { LegacyRef } from "react";
import domain from "../../Domain";
import FilterCard, { FIELD_TYPE, ReactSelect } from "../FilterCard";
import Form from "../../types/Form";
import {getPageOptions, getTwelveHourAMPMTime, hideModal, parseDate, showModalNoOutsideClick} from "../../util/FormatUtil";
import Overlay from "../Overlay";
import SimpleTable from "../tables/SimpleTable";
import { PodManagementModal } from "../modals/PodManagementModal";
import PodSched, { PodBreak } from "../../types/PodSched";
import { RapidTestModal } from "../modals/RapidTestModal";
import Select from "react-select";
import { sweetalert } from "../../App";
import PodSelect from "../PodSelect";
import Swal from "sweetalert2";
import NetworkUtil from "../../network/NetworkUtil";
import ServicesAPI from "../../network/ServicesAPI";
import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import { Sample } from "../../types/Sample";
import moment from "moment";
import PodAPI from "../../network/PodAPI";
import SystemAPI from "../../network/SystemAPI";
import AdminAPI from "../../network/AdminAPI";
import PaginationTool from "../PaginationTool";

interface PodScheduleState {
  showLoading: boolean;
  tableData: any[];
  totalPods?: number;
  pod?: any;
  selectedPod?;
  active?;
  facilities?;
  apptsBooked?;
  apptsAvailable?;
  totalAppts?;
  filter?: {
    PodID;
    TestDate;
    FacilityID;
    FacilityGroup;
    Active;
    Service;
  };
  facilityGroups;
  PodDate?;
  FilterID?;
  ActiveLabel?;
  services?;
  direction?: TableOrder;

  page_options: ReactSelect[];
  selected_page?;
  searched?;
}

export default class PodStats extends React.Component<any, PodScheduleState> {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      tableData: [],
      totalPods: 0,
      selectedPod: {},
      facilities: [],
      facilityGroups: [],
      services: [],
      direction: "asc",
      page_options: [{ value: "1", label: "1" }],
      selected_page: { label: 1, value: 1 },
    };
    this.clear = this.clear.bind(this);
    this.search = this.search.bind(this);
    this.assignClearState = this.assignClearState.bind(this);
    this.handleExportToCSV = this.handleExportToCSV.bind(this);
    this.assignClearStatePod = this.assignClearStatePod.bind(this);
  }

  componentDidMount() {
    console.log(" PodStats CDM() ")
    this.setState({ showLoading: true }, () => {
      AdminAPI.getFacilitiesWithAddress().then((data) => {
        this.setState({ facilities: data });
      });
      ServicesAPI.getAllServices().then((data) => {
        this.setState({
          services: data.data.map((s) => ({ label: s.Name, value: s.ID })),
        });
      });
      AdminAPI.getFacilitiesGroups().then((data) => {
        this.setState({ facilityGroups: data, showLoading: false }, ()=>{ console.log("PodStats done loading")});
      });
    });
  }

  getActiveOptions() {
    return [
      { value: 1, label: "Active" },
      { value: 0, label: "Inactive" },
    ];
  }

  getFilterObj() {
    if (!this.state.filter) return {};

        return {
            PodDate: this.state.filter.TestDate ? parseDate(this.state.filter.TestDate) : null,
            Facilities: this.state.filter.FacilityID ? this.state.filter.FacilityID : null,
            Active: this.state.filter.Active ? this.state.filter.Active : null,
            PodID: this.state.pod && this.state.pod.length > 0 ? this.state.pod : null,
            FacilityGroup: this.state.filter.FacilityGroup ? this.state.filter.FacilityGroup : null,
            Service: this.state.filter.Service ? this.state.filter.Service : null
        }
    }


  search(page) {
    this.setState({ showLoading: true }, async () => {
      let response = await PodAPI.searchPods(this.getFilterObj(), page);
      if (response.table_data.length === 0) {
        sweetalert.fire({
          icon: "error",
          title: "",
          text: "No pods were returned",
        });
      }
      this.setState({
        showLoading: false,
        tableData: response.table_data,
        totalPods: response.total,
        page_options: getPageOptions(response.num_pages),
        searched: this.getFilterObj(),
      });
    });
  }

  clear() {
    this.clearFilter();
  }

  clearFilterState: () => void = null;

  assignClearState(func) {
    this.clearFilterState = func;
  }

  clearFilter = () => {
    this.clearFilterState();
  };

  clearFilterStatePod: () => void = null;

  assignClearStatePod(func) {
    this.clearFilterStatePod = func;
  }

  onSubmit(pod: PodSched, podBreaks: PodBreak[]) {
    this.setState({ showLoading: true }, async () => {
      try {
        let result = await PodAPI.podUpsert({ pod: pod, podBreaks: podBreaks });
        if (!result.success) {
          sweetalert.fire({ icon: "error", title: "", text: result.reason });
        } else {
          sweetalert
            .fire({
              icon: "success",
              title: "",
              text: "Pod saved successfully",
            })
            .then((data) => {
              if (data.isConfirmed) {
                this.setState({ showLoading: false });
                window.location.reload();
              }
            });
        }
      } catch (e) {
        console.log(e);
        this.setState({ showLoading: false });
        sweetalert.fire({ icon: "error", title: "", text: "Failed" });
      }
    });
  }

  handleExportToCSV() {
    console.log("searched", this.state.searched);
    this.setState({ showLoading: true }, async () => {
      await NetworkUtil.downloadCSV("/api/admin/pods/export", "Pods.xlsx", {
        filter: this.state.searched,
      });
      this.setState({ showLoading: false });
    });
  }

  useSorter(col: Column) {
    let sorter = new Sorter<PodSched>()
      .withRawSorterFunc("Facility.FacilityName", (a, b) => {
        let aFacility = a.Facility.FacilityName;
        let bFacility = b.Facility.FacilityName;
        return aFacility.localeCompare(bFacility);
      })
      .withRawSorterFunc("BreakTimes", (a, b) => {
        let aBreakTimes = a.PodBreaks.length > 0 ? 1 : 0;
        let bBreakTimes = b.PodBreaks.length > 0 ? 1 : 0;
        return aBreakTimes - bBreakTimes;
      })
      .withRawSorterFunc("Time", (a, b) => {
        return moment(a.StartTime, "hh:mm a").diff(
          moment(b.StartTime, "hh:mm a")
        );
      });

    this.setState({
      tableData: sorter.sortByKey(
        this.state.tableData,
        col.key as keyof PodSched,
        this.state.direction
      ),
      direction: this.state.direction === "asc" ? "desc" : "asc",
    });
  }

  render():
    | React.ReactElement
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    return (
      <React.Fragment>
        <PaginationTool />
        <Overlay show_loading={this.state.showLoading} />
        <PodManagementModal
          facilties={this.state.facilities}
          services={this.state.services}
          selectedPod={this.state.selectedPod}
          onSubmit={(pod: PodSched, podBreaks: PodBreak[]) => {
            hideModal(PodManagementModal.ID);
            this.onSubmit(pod, podBreaks);
          }}
        />
        <div className="container-fluid  min-vh-100 ">
          <a id={"top"}>{}</a>
          <div className={"row"}>
            <div className="col-12 col-md-12 col-lg-8 col-xl-5 pt-2">
              <div className="card mb-2 ">
                <div className="card-header verlag-bold">
                  <h3>Pod Search</h3>
                </div>
                <div className="card-body">
                  <PodSelect
                    passClearStateFunc={this.assignClearStatePod}
                    multi={true}
                    onChange={(e) => this.setState({ pod: e.pod })}
                  />
                  <FilterCard
                    passClearStateFunc={this.assignClearState}
                    fields={[
                      // {label:"Pod ID", "key":"PodID", type:FIELD_TYPE.TEXT},
                      { label: "Date", key: "TestDate", type: FIELD_TYPE.DATE },
                      {
                        label: "Facility",
                        key: "FacilityID",
                        type: FIELD_TYPE.SELECT,
                        options: this.state.facilities,
                        isMapped: true,
                        isMulti: true,
                        textType: "text",
                      },
                      {
                        label: "Facility Group",
                        key: "FacilityGroup",
                        type: FIELD_TYPE.SELECT,
                        options: this.state.facilityGroups,
                        isMapped: true,
                        isMulti: true,
                        textType: "text",
                      },
                      {
                        label: "Active",
                        key: "Active",
                        type: FIELD_TYPE.SELECT,
                        options: this.getActiveOptions(),
                        isMapped: true,
                        isMulti: true,
                        textType: "text",
                      },
                      {
                        label: "Service",
                        key: "Service",
                        type: FIELD_TYPE.SELECT,
                        options: this.state.services,
                        isMapped: true,
                        isMulti: true,
                        textType: "text",
                      },
                    ]}
                    filterChanged={(e) => this.setState({ filter: e })}
                  />
                </div>
                <div className="card-footer">
                  <button
                    className="btn btn-outline-primary"
                    onClick={() =>
                      this.setState(
                        { selected_page: { label: 1, value: 1 } },
                        () => this.search(1)
                      )
                    }
                  >
                    Search
                  </button>
                  <button
                    className={"btn btn-outline-primary ml-2 float-right"}
                    onClick={() =>
                      this.setState({ selectedPod: null }, () =>
                      showModalNoOutsideClick(PodManagementModal.ID)
                      )
                    }
                  >
                    Create Pod
                  </button>
                </div>
              </div>
            </div>

            {this.state.totalPods > 0 ? (
              <div className="col-12 col-md-12 col-lg-12 pt-2">
                <div className="card mt-2 mb-5">
                  <div className="card-header verlag-bold stickToTop">
                    <h3>
                      Pods
                      <button
                        className={"ml-2 btn btn-outline-primary"}
                        onClick={() => this.handleExportToCSV()}
                      >
                        Export to CSV
                      </button>
                      <section className="tableHeaderSection float-md-right ">
                        <h3 style={{ float: "right" }}>
                          Total: {this.state.totalPods}
                        </h3>
                        <h4 className="d-inline-block float-right align-middle pr-2 ml-5">
                          Page{" "}
                        </h4>
                        <div className=" align-middle float-right pages ">
                          <Select
                            isSearchable={true}
                            placeholder={"1"}
                            noOptionsMessage={() => "No option"}
                            value={this.state.selected_page}
                            onChange={(e: ReactSelect) =>
                              this.setState({ selected_page: e }, () =>
                                this.search(e.value)
                              )
                            }
                            className={"state_select page-num-select"}
                            options={this.state.page_options}
                          />
                        </div>
                      </section>
                    </h3>
                  </div>

                  {/*mobile-portrait*/}
                  <div className="card-body p-0 m-0 text-center d-md-none table-responsive-md">
                    <SimpleTable
                      table_data={this.state.tableData}
                       columns={[
                        {
                          label: "Pod #",
                          key: "ID",
                          rawFormat: (val) => {
                            return (
                              <a
                                href={"#top"}
                                onClick={() => {
                                  this.setState({ selectedPod: val }, () => {
                                    showModalNoOutsideClick(PodManagementModal.ID);
                                  });
                                }}
                              >
                                {val.ID}
                              </a>
                            );
                          },
                        },
                        {
                          label: "Date",
                          key: "TestDate",
                          formatFunc: parseDate,
                        },
                        { label: "Facility", key: "Facility.FacilityName" },
                        {
                          label: "Available Appts",
                          key: "Available",
                          rawFormat: (val) => {
                            return (
                              <div>
                                {val.Available} / {val.Total}
                              </div>
                            );
                          },
                        },
                      ]}
                      columnClickedCallback={(col) => {
                        this.useSorter(col);
                      }}
                    />
                  </div>

                  <div className="card-body p-0 m-0 text-center d-none d-md-block table-responsive-md">
                    <SimpleTable
                      table_data={this.state.tableData}
                      columns={[
                        {
                          label: "Pod #",
                          key: "ID",
                          rawFormat: (val) => {
                            return (
                              <a
                                href={"#top"}
                                onClick={() => {
                                  this.setState({ selectedPod: val }, () => {
                                    showModalNoOutsideClick(PodManagementModal.ID)
                                })}}
                              >
                                {val.ID}
                              </a>
                            );
                          },
                        },
                        {
                          label: "Date",
                          key: "TestDate",
                          formatFunc: parseDate,
                        },
                        {
                          label: "Time",
                          key: "Time",
                          rawFormat: (val) => {
                            return (
                              <div>
                                {getTwelveHourAMPMTime(val.StartTime)} -{" "}
                                {getTwelveHourAMPMTime(val.EndTime)}
                              </div>
                            );
                          },
                        },
                        { label: "Service", key: "Service" },
                        { label: "Facility", key: "Facility.FacilityName" },
                        {
                          label: "Available Appts",
                          key: "Available",
                          rawFormat: (val) => {
                            return (
                              <div>
                                {val.Available} / {val.Total}
                              </div>
                            );
                          },
                        },
                        { label: "Checked In", key: "CheckedIn" },
                        { label: "Factor", key: "Factor" },
                        {
                          label: "Break Times",
                          key: "BreakTimes",
                          rawFormat: (val) => {
                            return val.PodBreaks.map((p) => {
                              let start = getTwelveHourAMPMTime(p.StartTime);
                              let end = getTwelveHourAMPMTime(p.EndTime);
                              return (
                                <div>
                                  {start} - {end}
                                </div>
                              );
                            });
                          },
                        },
                      ]}
                      columnClickedCallback={(col) => {
                        this.useSorter(col);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
