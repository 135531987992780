import NetworkUtil from "./NetworkUtil";

export class LabAPI{

    public static async reconcileSamples(startDate:Date, endDate:Date, facility:number[]):Promise<{success:boolean, reason:string, data:ReconcileSampleEntry[]}>{
        return NetworkUtil.makePost("/api/admin/lab/reconcileSamples", {startDate:startDate, endDate: endDate, facility: facility});
    }
}

export interface ReconcileSampleEntry{
    CollectionDate
    CollectCount
    RejectedCount
    ReceivedCount
    CollNotRecvCount
    ProcessedCount
    ApprovedCount
    CollNotResCount
    AccessedCount
    ResNotAccessCount
}