import NetworkUtil from "./NetworkUtil";
import Batch from "../types/Batch";
import {ServerResponse} from "../types/ServerResponse";
import {WellSlot} from "../types/WellSlot";
import {BatchInfo} from "../components/admin/MachineExport";
import {WellMapData} from "../components/admin/WellPlateReport";

export default class BatchesAPI {

    public static async getAllBatches():Promise<Batch[]>{
        return NetworkUtil.makePost('/api/admin/lab/batch/all', {})
    }

    public static async getBatchesForSamples():Promise<{label:string, value:object}[]>{
        return NetworkUtil.makeGet('/api/admin/getBatches')
    }

    public static async getSamplesByBatchID(batchID:number):Promise<{success:boolean, reason:string, samples:SampleBatchInfo[]}>{
        return NetworkUtil.makePost("/api/admin/lab/getBatchInfo", {batchID:batchID})
    }

    public static async approveBatch(batchID:number):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/lab/batch/approve", {batchID:batchID})
    }

    public static async getReruns(batchID:number):Promise<{success, reason, data:[]}>{
        return NetworkUtil.makePost("/api/admin/lab/batch/rerun", {batchID:batchID})
    }

    public static async getBatchMap(batchID:number):Promise<{success, reason, data:WellMapData[], wellSlots:WellSlot[]}>{
        return NetworkUtil.makePost("/api/admin/lab/batch/map", {batchID:batchID})
    }

    public static async setRerun(batchID:number, specimenID:string):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/lab/batch/setRerun", {batchID:batchID, specimenID:specimenID})
    }

    public static async postMachineResults(batchID:number, results:BatchInfo[]):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/lab/batch/exportResults", {batchID:batchID, results:results})
    }

    public static async precheckSpecimen(specimenID:string, batchID:number):Promise<{ success: boolean, reason: string, isRerun: boolean, isOldSample: boolean }> {
        return NetworkUtil.makePost("/api/admin/lab/batch/precheckSpecimen", {specimenID:specimenID, batchID:batchID})
    }

    public static async accessionSample(accessionData:AccessionData):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/lab/batch/accessionSpecimen", accessionData)
    }

    public static async postExportForParse(batchID, file:Blob, testID):Promise<ParsedFileResult>{
        let data = new FormData()
        data.set('file', file)
        data.set("batchID", batchID);
        data.set("test", testID);
        return NetworkUtil.postForm("/api/admin/lab/batch/parsefile", data)
    }

    public static async getFilteredBatches(filter:{ filter:{BatchNum?, TestType?, ResultApprovedDate? }}):Promise<{success:boolean, reason:string, response?:Batch[]}>{
        return NetworkUtil.makePost("/api/admin/lab/batch/filter", filter);
    }

    public static async createBatch(batch:Batch):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/lab/batch/create", batch);
    }

    public static editBatch(batch):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/lab/batch/edit", batch);
    }

    public static async getReconciliationReport(receivedStart, receivedEnd, facilityID:number):Promise<ReconciliationReport>{
        return NetworkUtil.makePost("/api/admin/lab/batch/reconcileLab", {receivedStart:receivedStart, receivedEnd:receivedEnd, facilityID:facilityID})
    }

    public static async getCollectionDateWarningSetting():Promise<{success:boolean,reason:string,warningMessage:string}>{
        return NetworkUtil.makeGet("/api/admin/settings/collectionDateWarning")
    }
}

export interface ReconciliationReport extends ServerResponse{
    data:ReconciliationReportEntry[]
}

export interface ReconciliationReportEntry{
    BatchNum
    ReceivedCount
    ProcessedCount
    ResultCount
    RerunCount
    SpringMLCount
    ApprovedCount
    RecdNotResCount
}

interface ParsedFileResult extends ServerResponse{
    info:BatchInfo[]
}

interface AccessionData{
    specimenID:string,
    internalSpecimenID:string,
    sampleReceivingID:number,
    isHorizontalPlate:boolean
    batchID:number
}

export interface SampleBatchInfo{
    SpecimenID,
    InternalSpecimenID,
    WellSlot,
    ResultDescription,
    ResultID
}