import React from "react";
import FilterCard, { FIELD_TYPE, ReactSelect } from "../FilterCard";
import domain from "../../Domain";
import {parseDate, toLocalDateTime, getPageOptions, getBrandingImage, hideModal, showModalNoOutsideClick} from "../../util/FormatUtil";
import Overlay from "../Overlay";
import ReactToPrint from "react-to-print";
import { Sample } from "../../types/Sample";
import SimpleTable from "../tables/SimpleTable";
import { SamplesModal } from "../modals/SamplesModal";
import { HintModal } from "../modals/HintModal";
import TestResult, { ResultInfo } from "../TestResult";
import { ImageType } from "../../types/Branding";
import Select from "react-select";
import { BsCheck } from "react-icons/bs";
import PhoneInput from "react-phone-number-input";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import TestType from "../../types/TestType";
import moment from "moment";
import DatePicker from "react-datepicker";
import { sweetalert } from "../../App";
import NetworkUtil from "../../network/NetworkUtil";
import TestsAPI from "../../network/TestsAPI";
import SystemAPI from "../../network/SystemAPI";
import SamplesAPI from "../../network/SamplesAPI";
import BatchesAPI from "../../network/BatchesAPI";
import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import PodSched from "../../types/PodSched";
import AdminAPI from "../../network/AdminAPI";
import { BinAPI } from "../../network/BinAPI";
import { unstable_renderSubtreeIntoContainer } from "react-dom";
import { FaInfoCircle } from "react-icons/fa";
import PaginationTool from "../PaginationTool";

interface ResultsReportState extends Sample {
  states: any;
  countries: any;
  gender: any;
  ethnicity: any;
  race: any;
  specimen_source: any;
  tests: any;
  testDetails: TestType[];
  pods: { label: string; value: number }[];
  selectedResult?: Sample;
  samples?: Sample[];
  totalSamples?: number;
  podsForModal: {label:string,value:number}[];

  showLoading: boolean;
  facilities?;
  facilityGroups?;
  resultTypes?;

  selectedPod?;
  Facilities?;
  FacilityGroup?;

  hintHeader?;
  hintContent?;

  getResultsButtonEnabled?: boolean;
  isGlobal?: boolean;
  isAdmin?: boolean;
  logo?;

  direction: TableOrder;

  page_options: ReactSelect[];
  selected_page?;
  tableData?;
  pdfData?;

  PodID?;

  nullPatientDOB?;

  nullPatientPhone?;

  beginCollectionDate?;
  endCollectionDate?;
  nullCollectionDate?;

  beginCreatedDate?;
  endCreatedDate?;
  nullCreatedDate?;

  beginReceivedDate?;
  endReceivedDate?;
  nullReceivedDate?;

  beginResultDate?;
  endResultDate?;
  nullResultDate?;

  beginResultsApprovedDate?;
  endResultsApprovedDate?;
  nullResultsApprovedDate?;

  beginResultsAccessedDate?;
  endResultsAccessedDate?;
  nullResultsAccessedDate?;

  beginReportedDate?;
  endReportedDate?;
  nullReportedDate?;

  sampleArrowDown?;
  patientArrowDown?;

  validSpecimenSources?;
  validResultTypes?;

  batchesForFilter?;
  batchesForModal?;
  BatchNum;

  binIDsForFilter?;
  binIDsForModal?;
  BinID;

  isGlobalAdminorLab?;
  searched?;
}

export default class SamplesReport extends React.Component<
  any,
  ResultsReportState
> {
  private componentRef: any;
  private componentRefBR: any;
  constructor(props) {
    super(props);
    this.state = {
      selected_page: { label: 1, value: 1 },
      sampleArrowDown: false,
      patientArrowDown: false,
      ApptCanceled: [{ label: "No", value: "*null" }],
      RerunFlag: [{ label: "No", value: "*null" }],
      logo: "",
      states: [],
      countries: [],
      gender: [],
      ethnicity: [],
      race: [],
      specimen_source: [],
      tests: [],
      binIDs: [],
      batches: [],
      showLoading: false,
      pods: [],
      facilities: [],
      facilityGroups: [],
      samples: [],
      totalSamples: 0,
      resultTypes: [],
      podsForModal: [],
      direction: "asc",
      sampleModalOpen: false,
      page_options: [{ value: "1", label: "1" }],
      hintHeader: "Samples Search Hints",
      hintContent: [
        "Click on table headers to sort",
        "Place cursor over search labels for field description",
        "Blue check next to search field indicates there is value present",
        "Use *null to search for records WITH null values",
        "Use <>null to search for records WITHOUT null values",
        "To get records for a single day, put the same day in both boxes",
      ],
    } as any;
    this.submit = this.submit.bind(this);
    this.clear = this.clear.bind(this);
    this.handleExportToCSV = this.handleExportToCSV.bind(this);
  }

  addNullSelection(data) {
    return data.concat([
      { label: "*null", value: "*null" },
      { label: "<>null", value: "<>null" },
    ]);
  }

  async componentDidMount() {
    this.setState({ showLoading: true }, () => {
      getBrandingImage(ImageType.LOGO).then((data) => {
        this.setState({ logo: data });
      });
      AdminAPI.getPermissions().then((data) => {
        this.setState({
          isGlobalAdminorLab: data.isGlobalAdminorLab,
          isGlobal: data.isGlobal,
          isAdmin: data.isAdmin,
        });
      });
      SystemAPI.getAllStates().then((data) => {
        this.setState({ states: this.addNullSelection(data) });
      });
      SystemAPI.getAllCountries().then((data) => {
        this.setState({ countries: this.addNullSelection(data.countries) });
      });
      SystemAPI.getAllRaces().then((data) => {
        this.setState({ race: this.addNullSelection(data) });
      });
      SystemAPI.getAllGenders().then((data) => {
        this.setState({ gender: this.addNullSelection(data) });
      });
      SystemAPI.getAllEthnicities().then((data) => {
        this.setState({ ethnicity: this.addNullSelection(data) });
      });
      SystemAPI.getAllSpecimenSources().then((data) => {
        this.setState({ specimen_source: this.addNullSelection(data) });
      });
      TestsAPI.getValidSpecimenSources().then((data) => {
        this.setState({ validSpecimenSources: data });
      });
      SystemAPI.getAllTests().then((data) => {
        this.setState({ tests: this.addNullSelection(data) });
      });
      TestsAPI.getAllTestDetails().then((data) => {
        this.setState({ testDetails: data.tests });
      });
      AdminAPI.getAllResultTypes().then((data) => {
        this.setState({ resultTypes: this.addNullSelection(data) });
      });
      TestsAPI.getValidResultTypes().then((data) => {
        this.setState({ validResultTypes: data });
      });
      AdminAPI.getAllPods().then((response) => {
        if(!response.success){
          console.error(response.reason)
          sweetalert.fire("Error loading Pods.")
        }
        this.setState({ podsForModal: this.addNullSelection(response.data) });
      });
      BatchesAPI.getBatchesForSamples().then((data) => {
        console.log("batchesForModal", data);
        this.setState({ batchesForModal: data });
      });
      BatchesAPI.getAllBatches().then((data) => {
        let batchesMapped = data.map((b) => {
          return {
            label: b.BatchNum,
            value: b.ID,
          };
        });
        this.setState({
          batchesForFilter: this.addNullSelection(batchesMapped),
        });
      });
      BinAPI.getBinsForSamples().then((data) => {
        console.log("binsForModal", data);
        this.setState({ binIDsForModal: data });
      });
      BinAPI.getAllBins().then((data) => {
        let binsMapped = data.map((b) => {
          return {
            label: b.BinID,
            value: b.ID,
          };
        });
        this.setState({ binIDsForFilter: this.addNullSelection(binsMapped) });
      });
      AdminAPI.getAllFacilities().then((data) => {
        let facilityNamesMapped = data.authorizedFacilities.map((fac) => {
          return {
            label: fac.FacilityName,
            value: fac.ID,
          };
        });

        let facilityGroupsMapped = data.authorizedFacilities.filter((f) => {
          let facilityIsConsentEntity = f.FacilitySeq != 1 && f.OktaID != null;
          if (facilityIsConsentEntity || f.FacilitySeq === 1) return f;
        });

        facilityGroupsMapped = facilityGroupsMapped.filter(
          (f) => f.FacilitySeq === 1
        );
        let facilityGroupsMappedNew = facilityGroupsMapped.map((fac) => {
          return {
            label: fac.FacilityGroup,
            value: fac.FacilityGroup,
          };
        });
        facilityGroupsMappedNew = facilityGroupsMappedNew.sort((a, b) => {
          let firstIndex = a.value.indexOf(" ");
          let first = a.value.slice(firstIndex);
          let secondIndex = b.value.indexOf(" ");
          let second = b.value.slice(secondIndex);
          return first - second;
        });
        this.setState({
          facilities: facilityNamesMapped,
          facilityGroups: facilityGroupsMappedNew,
          showLoading: false,
        });
      });
    });
  }

  clear() {
    this.setState({
      PodID: null,
      FacilityID: null,
      FacilityGroup: null,
      PatientFirstName: "",
      PatientMiddleName: "",
      PatientLastName: "",
      PatientDOB: null,
      nullPatientDOB: null,
      PatientEthnicityID: null,
      PatientRaceID: null,
      PatientGenderID: null,
      PatientEmail: "",
      PatientPhone: "",
      nullPatientPhone: null,
      PatientCity: "",
      PatientZip: "",
      PatientState: null,
      PatientCounty: "",
      PatientCountry: null,
      ReqNum: "",
      BatchNum: "",
      SpecimenID: "",
      InternalSpecimenID: "",
      BinID: "",
      Lot: "",
      beginCreatedDate: null,
      endCreatedDate: null,
      nullCreatedDate: null,
      beginCollectionDate: null,
      endCollectionDate: null,
      nullCollectionDate: null,
      beginReceivedDate: null,
      endReceivedDate: null,
      nullReceivedDate: null,
      beginResultDate: null,
      endResultDate: null,
      nullResultDate: null,
      beginResultsApprovedDate: null,
      endResultsApprovedDate: null,
      nullResultsApprovedDate: null,
      beginResultsAccessedDate: null,
      endResultsAccessedDate: null,
      nullResultsAccessedDate: null,
      beginReportedDate: null,
      endReportedDate: null,
      nullReportedDate: null,
      ResultID: "",
      SpecimenSourceID: "",
      TestID: "",
      RerunFlag: "",
      ApptCanceled: "",
      ProviderFirstName: "",
      ProviderLastName: "",
    });
  }

  getFilterObj() {
    return {
      PodID: this.state.PodID
        ? this.prepareMultiSelect(this.state.PodID)
        : null,
      Facilities: this.state.FacilityID
        ? this.prepareMultiSelect(this.state.FacilityID)
        : null,
      FacilityGroup: this.state.FacilityGroup
        ? this.prepareMultiSelect(this.state.FacilityGroup)
        : null,
      PatientFirstName: this.state.PatientFirstName
        ? this.state.PatientFirstName
        : null,
      PatientMiddleName: this.state.PatientMiddleName
        ? this.state.PatientMiddleName
        : null,
      PatientLastName: this.state.PatientLastName
        ? this.state.PatientLastName
        : null,

      PatientDOB: this.state.nullPatientDOB
        ? null
        : this.state.PatientDOB
        ? parseDate(new Date(this.state.PatientDOB))
        : null,
      NullPatientDOB: this.state.nullPatientDOB
        ? this.state.nullPatientDOB
        : null,

      PatientEthnicityID: this.state.PatientEthnicityID
        ? this.prepareMultiSelect(this.state.PatientEthnicityID)
        : null,
      PatientRaceID: this.state.PatientRaceID
        ? this.prepareMultiSelect(this.state.PatientRaceID)
        : null,
      PatientGenderID: this.state.PatientGenderID
        ? this.prepareMultiSelect(this.state.PatientGenderID)
        : null,
      PatientEmail: this.state.PatientEmail ? this.state.PatientEmail : null,

      PatientPhone: this.state.PatientPhone ? this.state.PatientPhone : null,
      NullPatientPhone: this.state.nullPatientPhone
        ? this.state.nullPatientPhone
        : null,

      PatientCity: this.state.PatientCity ? this.state.PatientCity : null,
      PatientZip: this.state.PatientZip ? this.state.PatientZip : null,
      PatientState: this.state.PatientState
        ? this.prepareMultiSelect(this.state.PatientState)
        : null,
      PatientCounty: this.state.PatientCounty ? this.state.PatientCounty : null,
      PatientCountry: this.state.PatientCountry
        ? this.prepareMultiSelect(this.state.PatientCountry)
        : null,
      ReqNum: this.state.ReqNum ? this.state.ReqNum : null,
      BatchNum: this.state.BatchNum
        ? this.prepareMultiSelect(this.state.BatchNum)
        : null,
      SpecimenID: this.state.SpecimenID ? this.state.SpecimenID : null,
      InternalSpecimenID: this.state.InternalSpecimenID
        ? this.state.InternalSpecimenID
        : null,
      BinID: this.state.BinID
        ? this.prepareMultiSelect(this.state.BinID)
        : null,
      Lot: this.state.Lot ? this.state.Lot : null,

      BeginCreatedDate: this.state.nullCreatedDate
        ? null
        : this.state.beginCreatedDate
        ? this.state.beginCreatedDate
        : null,
      EndCreatedDate: this.state.nullCreatedDate
        ? null
        : this.state.endCreatedDate
        ? this.state.endCreatedDate
        : null,
      NullCreatedDate: this.state.nullCreatedDate
        ? this.state.nullCreatedDate
        : null,

      BeginCollectionDate: this.state.nullCollectionDate
        ? null
        : this.state.beginCollectionDate
        ? this.state.beginCollectionDate
        : null,
      EndCollectionDate: this.state.nullCollectionDate
        ? null
        : this.state.endCollectionDate
        ? this.state.endCollectionDate
        : null,
      NullCollectionDate: this.state.nullCollectionDate
        ? this.state.nullCollectionDate
        : null,

      BeginReceivedDate: this.state.nullReceivedDate
        ? null
        : this.state.beginReceivedDate
        ? this.state.beginReceivedDate
        : null,
      EndReceivedDate: this.state.nullReceivedDate
        ? null
        : this.state.endReceivedDate
        ? this.state.endReceivedDate
        : null,
      NullReceivedDate: this.state.nullReceivedDate
        ? this.state.nullReceivedDate
        : null,

      BeginResultDate: this.state.nullResultDate
        ? null
        : this.state.beginResultDate
        ? this.state.beginResultDate
        : null,
      EndResultDate: this.state.nullResultDate
        ? null
        : this.state.endResultDate
        ? this.state.endResultDate
        : null,
      NullResultDate: this.state.nullResultDate
        ? this.state.nullResultDate
        : null,

      BeginResultsApprovedDate: this.state.nullResultsApprovedDate
        ? null
        : this.state.beginResultsApprovedDate
        ? this.state.beginResultsApprovedDate
        : null,
      EndResultsApprovedDate: this.state.nullResultsApprovedDate
        ? null
        : this.state.endResultsApprovedDate
        ? this.state.endResultsApprovedDate
        : null,
      NullResultsApprovedDate: this.state.nullResultsApprovedDate
        ? this.state.nullResultsApprovedDate
        : null,

      BeginResultsAccessedDate: this.state.nullResultsAccessedDate
        ? null
        : this.state.beginResultsAccessedDate
        ? this.state.beginResultsAccessedDate
        : null,
      EndResultsAccessedDate: this.state.nullResultsAccessedDate
        ? null
        : this.state.endResultsAccessedDate
        ? this.state.endResultsAccessedDate
        : null,
      NullResultsAccessedDate: this.state.nullResultsAccessedDate
        ? this.state.nullResultsAccessedDate
        : null,

      BeginReportedDate: this.state.nullReportedDate
        ? null
        : this.state.beginReportedDate
        ? this.state.beginReportedDate
        : null,
      EndReportedDate: this.state.nullReportedDate
        ? null
        : this.state.endReportedDate
        ? this.state.endReportedDate
        : null,
      NullReportedDate: this.state.nullReportedDate
        ? this.state.nullReportedDate
        : null,

      ResultID: this.state.ResultID
        ? this.prepareMultiSelect(this.state.ResultID)
        : null,
      SpecimenSourceID: this.state.SpecimenSourceID
        ? this.prepareMultiSelect(this.state.SpecimenSourceID)
        : null,
      TestID: this.state.TestID
        ? this.prepareMultiSelect(this.state.TestID)
        : null,

      //passing empty array if greater than 1 because if both 1 and null are selected then that is all the records.  same for ApptCanceled
      RerunFlag: this.state.RerunFlag
        ? this.state.RerunFlag.length > 1
          ? []
          : this.prepareMultiSelect(this.state.RerunFlag)
        : null,
      ApptCanceled: this.state.ApptCanceled
        ? this.state.ApptCanceled.length > 1
          ? []
          : this.prepareMultiSelect(this.state.ApptCanceled)
        : null,
      ProviderFirstName: this.state.ProviderFirstName
        ? this.state.ProviderFirstName
        : null,
      ProviderLastName: this.state.ProviderLastName
        ? this.state.ProviderLastName
        : null,
    };
  }

  dateCheck(beginDateState, endDateState, beginLabel, endLabel, label) {
    if (
      (beginDateState && !endDateState) ||
      (!beginDateState && endDateState)
    ) {
      sweetalert.fire({
        icon: "error",
        title: "",
        text: `Please enter a valid ${beginLabel} Date and an ${endLabel} Date`,
      });
      return true;
    }

    if (beginDateState > endDateState) {
      sweetalert.fire({
        icon: "error",
        title: "",
        text: `${beginLabel} Date cannot be after ${endLabel} Date`,
      });
      return true;
    }

    if (beginDateState > new Date() || endDateState > new Date()) {
      sweetalert.fire({
        icon: "error",
        title: "",
        text: `Cannot search for ${label} Date in the future`,
      });
      return true;
    }
  }

  submit(page) {
    console.log(this.getFilterObj());
    let filteredObjArray = this.getFilterObj();
    let keys = Object.keys(filteredObjArray);
    let hasValue = keys.find((k) => filteredObjArray[k]);
    if (!hasValue) {
      return sweetalert.fire({
        icon: "error",
        title: "",
        text: "Please choose a value to search",
      });
    }

    let dateInvalid = false;
    if (this.state.beginCreatedDate || this.state.endCreatedDate) {
      dateInvalid = this.dateCheck(
        this.state.beginCreatedDate,
        this.state.endCreatedDate,
        "Beginning Created",
        "Ending Created",
        "Created"
      );
    }
    if (this.state.beginCollectionDate || this.state.endCollectionDate) {
      dateInvalid = this.dateCheck(
        this.state.beginCollectionDate,
        this.state.endCollectionDate,
        "Beginning Collection",
        "Ending Collection",
        "Collection"
      );
    }
    if (this.state.beginReceivedDate || this.state.endReceivedDate) {
      dateInvalid = this.dateCheck(
        this.state.beginReceivedDate,
        this.state.endReceivedDate,
        "Beginning Received",
        "Ending Received",
        "Received"
      );
    }
    if (this.state.beginResultDate || this.state.endResultDate) {
      dateInvalid = this.dateCheck(
        this.state.beginResultDate,
        this.state.endResultDate,
        "Beginning Result",
        "Ending Result",
        "Result"
      );
    }
    if (
      this.state.beginResultsApprovedDate ||
      this.state.endResultsApprovedDate
    ) {
      dateInvalid = this.dateCheck(
        this.state.beginResultsApprovedDate,
        this.state.endResultsApprovedDate,
        "Beginning Results Approved",
        "Ending Results Approved",
        "Results Approved"
      );
    }
    if (
      this.state.beginResultsAccessedDate ||
      this.state.endResultsAccessedDate
    ) {
      dateInvalid = this.dateCheck(
        this.state.beginResultsAccessedDate,
        this.state.endResultsAccessedDate,
        "Beginning Results Accessed",
        "Ending Results Accessed",
        "Results Accessed"
      );
    }
    if (this.state.beginReportedDate || this.state.endReportedDate) {
      dateInvalid = this.dateCheck(
        this.state.beginReportedDate,
        this.state.endReportedDate,
        "Beginning Reported",
        "Ending Reported",
        "Reported"
      );
    }
    if (dateInvalid) {
      return;
    }

    this.setState({ showLoading: true }, async () => {
      hideModal(SamplesModal.ID);
      try {
        let result = await SamplesAPI.getFilteredSamples(page, {
          filter: this.getFilterObj(),
        });

        console.log("result", result);

        if (result.totalSamples < 1) {
          sweetalert.fire({
            icon: "error",
            title: "",
            text: "No samples found",
          });
          this.setState({ showLoading: false, tableData: [], totalSamples: 0 });
          return;
        }
        window["$"]("#" + SamplesModal.ID).modal("hide");
        this.setState({
          totalSamples: result.totalSamples,
          showLoading: false,
          page_options: getPageOptions(result.num_pages),
          tableData: result.table_data,
          searched: this.getFilterObj(),
        });
      } catch (e) {
        console.log(e);
        sweetalert.fire({
          icon: "error",
          title: "",
          text: "Search failed. Please try again.",
        });
        this.setState({ tableData: [], showLoading: false });
      }
    });
  }

  handleExportToCSV() {
    let filter = this.state.searched;
    if (this.state.isGlobalAdminorLab) {
      sweetalert
        .fire({
          title: "Should your export include Patient Identifiers?",
          showCloseButton: true,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Include",
          denyButtonText: `Don't include`,
        })
        .then((result) => {
          if (result.isConfirmed || result.isDenied) {
            let modifiedfilter = Object.assign(filter, {
              IncludePHI: result.isConfirmed ? true : false,
            });
            this.setState({ showLoading: true }, async () => {
              await NetworkUtil.downloadCSV(
                "/api/admin/samples/results/csv",
                "Sample Results.xlsx",
                { filter: modifiedfilter }
              );
              this.setState({ showLoading: false });
            });
          }
        });
    } else {
      this.setState({ showLoading: true }, async () => {
        await NetworkUtil.downloadCSV(
          "/api/admin/samples/results/csv",
          "Sample Results.xlsx",
          { filter: filter, IncludePHI: false }
        );
        this.setState({ showLoading: false });
      });
    }
  }

  getLabel(id, searchArray) {
    let temp = searchArray.find((t) => t.value === id);
    if (!temp) {
      return null;
    }
    return temp.label;
  }

  getBoolOptions() {
    return [
      { value: 1, label: "Yes" },
      { value: "*null", label: "No" },
    ];
  }

  useSorter(col: Column) {
    let sorter = new Sorter<Sample>().withRawSorterFunc("Name", (a, b) => {
      let aName = a.PatientFirstName + " " + a.PatientLastName;
      let bName = b.PatientFirstName + " " + b.PatientLastName;
      return aName.localeCompare(bName);
    });
    this.setState({
      tableData: sorter.sortByKey(
        this.state.tableData,
        col.key as keyof Sample,
        this.state.direction
      ),
      direction: this.state.direction === "asc" ? "desc" : "asc",
    });
  }

  getNullOptions() {
    return [
      { value: "*null", label: "*null" },
      { value: "<>null", label: "<>null" },
    ];
  }

  prepareMultiSelect(data) {
    let tempArr = [];
    data.map((f) => {
      tempArr.push(f.value);
    });
    return tempArr;
  }

  getInput(stateToSet, state, label, tooltip) {
    if (
      label === "First Name" ||
      label === "Last Name" ||
      label === "Middle Name" ||
      label === "Email"
    ) {
      return (
        <>
          <div className={"d-none d-xxl-block"}>
            <div className="form-group row pt-xxl-0">
              <div
                className={"col-10 col-md-4 col-lg-4 pt-xxl-0"}
                data-toggle={"tooltip"}
                data-placement={"top"}
                title={tooltip}
              >
                <label
                  htmlFor={label}
                  style={{ fontSize: "0.8em" }}
                  className={"pt-1 pt-xxl-0 font-weight-bold"}
                >
                  {label}
                </label>
              </div>
              <div className="col-2 col-md-1 col-lg-1 pt-2">
                {state ? (
                  <BsCheck
                    className={"float-md-right"}
                    color={"blue"}
                    size={20}
                  />
                ) : null}
              </div>

              <div className={"col-md-7 col-lg-7 pl-0 pr-1"} id={label}>
                <div className="row" style={{ width: "13.7em" }}>
                  <div className="col-12">
                    <input
                      type={"search"}
                      className={"form-control"}
                      value={state}
                      autoComplete="off"
                      onChange={(e) => {
                        this.setState({ [stateToSet]: e.target.value } as any);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={"d-block d-xxl-none"}>
            <div className="form-group row pt-xxl-0">
              <div
                className={"col-10 col-md-4 col-lg-4 pt-xxl-0"}
                data-toggle={"tooltip"}
                data-placement={"top"}
                title={tooltip}
              >
                <label
                  htmlFor={label}
                  style={{ fontSize: "0.8em" }}
                  className={"pt-1 pt-xxl-0 font-weight-bold"}
                >
                  {label}
                </label>
              </div>
              <div className="col-2 col-md-1 col-lg-1 pt-2">
                {state ? (
                  <BsCheck
                    className={"float-md-right"}
                    color={"blue"}
                    size={20}
                  />
                ) : null}
              </div>

              <div className={"col-md-7 col-lg-7 pl-0 pr-1"} id={label}>
                <div className="row">
                  <div className="col-12">
                    <input
                      type={"search"}
                      className={"form-control"}
                      value={state}
                      autoComplete="off"
                      onChange={(e) => {
                        this.setState({ [stateToSet]: e.target.value } as any);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    return (
      <div className="form-group row pt-xxl-0">
        <div
          className={"col-10 col-md-4 col-lg-4 pt-xxl-0"}
          data-toggle={"tooltip"}
          data-placement={"top"}
          title={tooltip}
        >
          <label
            htmlFor={label}
            style={{ fontSize: "0.8em" }}
            className={"pt-1 pt-xxl-0 font-weight-bold"}
          >
            {label}
          </label>
        </div>
        <div className="col-2 col-md-1 col-lg-1 pt-2">
          {state ? (
            <BsCheck className={"float-md-right"} color={"blue"} size={20} />
          ) : null}
        </div>

        <div className={"col-md-7 col-lg-7 pl-0 pr-1"} id={label}>
          <div className="row">
            <div className="col-12">
              <input
                type={"search"}
                className={"form-control"}
                value={state}
                autoComplete="off"
                onChange={(e) => {
                  this.setState({ [stateToSet]: e.target.value } as any);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  getSelect(stateToSet, state, label, tooltip, options, disabled = false) {
    return (
      <div className="form-group row pt-xxl-0">
        <div
          className={"col-10 col-md-4 col-lg-4 pt-xxl-0"}
          data-toggle={"tooltip"}
          data-placement={"top"}
          title={tooltip}
        >
          <label
            htmlFor={label}
            style={{ fontSize: "0.8em" }}
            className={"pt-1 pt-xxl-0 font-weight-bold"}
          >
            {label}
          </label>
        </div>
        <div className="col-2 col-md-1 col-lg-1 pt-2">
          {state ? (
            <BsCheck className={"float-md-right"} color={"blue"} size={20} />
          ) : null}
        </div>

        <div className={"col-md-7 col-lg-7 pl-0 pr-1"} id={label}>
          <div className="row">
            <div className="col-12">
              <Select
                isDisabled={disabled}
                isMulti={true}
                isSearchable={true}
                placeholder={"Select..."}
                onChange={(e) => this.setState({ [stateToSet]: e } as any)}
                className={"state_select"}
                options={options}
                value={state}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleDateOnChange(value, nullState, state) {
    if (value) {
      this.setState({ [nullState]: null, [state]: value } as any);
    } else {
      this.setState({ [nullState]: null, [state]: null } as any);
    }
  }

  getDateRange(
    beginStateToSet,
    beginState,
    endStateToSet,
    endState,
    nullStateToSet,
    nullState,
    label,
    tooltip,
    options
  ) {
    return (
      <div className="form-group row pt-md-2 pt-lg-1 pt-xxl-0">
        <div
          className={"col-10 col-md-4 col-lg-4 pt-xxl-0"}
          data-toggle={"tooltip"}
          data-placement={"top"}
          title={tooltip}
        >
          <label
            htmlFor={label}
            style={{ fontSize: "0.8em" }}
            className={"pt-1 pt-xxl-0 font-weight-bold"}
          >
            {label}
          </label>
        </div>

        <div className=" col-2 col-md-1 col-lg-1 pt-2">
          {" "}
          {beginState || endState || nullState ? (
            <BsCheck className={"float-right"} color={"blue"} size={20} />
          ) : null}
        </div>

        <div className={"col-md-7 pl-md-0 pr-md-1 col-lg-7 pl-lg-0 pr-lg-1"}>
          <div className="row">
            <div className={"col-3"}>
              <label>Begin: </label>
            </div>
            <div
              className={"col-9 pl-xl-5 pl-xxl-0 justify-content-end"}
              id={label}
            >
              <DatePicker
                minDate={new Date("01-01-1000")}
                maxDate={new Date("12-31-9999")}
                placeholderText="--/--/----"
                selected={
                  beginState
                    ? Date.parse(moment(beginState, "MM-DD-YYYY").toISOString())
                    : null
                }
                onChange={(e) =>
                  this.handleDateOnChange(e, nullStateToSet, beginStateToSet)
                }
              />
            </div>
          </div>

          <div className={"row"}>
            <div className={"col-3"}>
              <label>End: </label>
            </div>

            <div className={"col-9 pl-xl-5 pl-xxl-0"} id={label}>
              <DatePicker
                minDate={new Date("01-01-1000")}
                maxDate={new Date("12-31-9999")}
                placeholderText="--/--/----"
                selected={
                  endStateToSet
                    ? Date.parse(moment(endState, "MM-DD-YYYY").toISOString())
                    : null
                }
                onChange={(e) =>
                  this.handleDateOnChange(e, nullStateToSet, endStateToSet)
                }
              />
            </div>
          </div>

          <div className={"row"}>
            <div className="col-3"></div>
            <div className="col-9 pr-md-9 pr-lg-3 pl-xl-5 pl-xxl-0">
              <div>
                <Select
                  isMulti={true}
                  isSearchable={true}
                  placeholder={"Select..."}
                  onChange={(e) =>
                    this.setState({
                      [beginStateToSet]: null,
                      [endStateToSet]: null,
                      [nullStateToSet]: e,
                    } as any)
                  }
                  className={"state_select"}
                  options={options}
                  value={nullState}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleSetResultsAccessed(val) {
    this.setState({ showLoading: true }, async () => {
      try {
        let result = await SamplesAPI.updateResultsAccessedDate(val.ID);
        if (!result.success) {
          sweetalert.fire({ icon: "error", title: "", text: result.reason });
          this.setState({ showLoading: false });
          return;
        } else {
          sweetalert.fire({
            icon: "success",
            title: "",
            text: "Sample has been updated",
          });
          this.setState({ showLoading: false });
          await this.submit(1);
        }
      } catch (e) {
        console.log(e);
        sweetalert.fire({ icon: "error", title: "", text: "Server error" });
        this.setState({ showLoading: false });
      }
    });
  }

  render():
    | React.ReactElement
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    this.state.tableData &&
      this.state.tableData.map((samp) => {
        samp.ResultDesc = this.getLabel(samp.ResultID, this.state.resultTypes);
        samp.Test = this.getLabel(samp.TestID, this.state.tests);
      });
    console.log("sample report state", this.state);
    return (
      <React.Fragment>
        <PaginationTool />
        <div className="container-fluid  min-vh-100 ">
          <a id={"top"}>{}</a>
          <Overlay show_loading={this.state.showLoading} />

          <SamplesModal
            onSubmit={() => this.submit(1)}
            isAdmin={this.state.isAdmin}
            isGlobal={this.state.isGlobal}
            getResultsButtonBoolean={this.state.getResultsButtonEnabled}
            result={
              this.state.selectedResult
                ? JSON.parse(JSON.stringify(this.state.selectedResult))
                : {}
            }
            states={this.state.states}
            countries={this.state.countries}
            ethnicity={this.state.ethnicity}
            race={this.state.race}
            gender={this.state.gender}
            specimen_source={this.state.specimen_source}
            validSpecimenSources={this.state.validSpecimenSources}
            batches={this.state.batchesForModal}
            binIDs={this.state.binIDsForModal}
            testDetails={this.state.testDetails}
            tests={this.state.tests}
            result_types={this.state.resultTypes}
            validResultTypes={this.state.validResultTypes}
            facilities={this.state.facilities}
            pods={this.state.podsForModal}
          />

          <HintModal
            header={this.state.hintHeader}
            content={this.state.hintContent}
          />
          <div className="container-fluid  min-vh-100 ">
            <div className="card m-2">
              <div
                className="card-header verlag-bold"
                style={{ height: "4em" }}
              >
                <h3>
                  Samples{" "}
                  <FaInfoCircle
                    tabIndex={5}
                    className={"ml-1"}
                    size={25}
                    color={"#00539B"}
                    onClick={() => showModalNoOutsideClick(HintModal.ID)}
                  />
                </h3>
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6 pr-lg-0 col-xxl-6">
                  <div className="card m-2">
                    <div
                      className="card-header verlag-bold"
                      style={{ height: "4em" }}
                    >
                      <h3 className={"d-none d-md-inline-block"}>
                        Sample Information{" "}
                      </h3>
                      <h3
                        className={"d-inline-block d-md-none"}
                        style={{ fontSize: "1.5em" }}
                      >
                        Sample Information{" "}
                      </h3>
                    </div>

                    {/*xxl screen*/}
                    <div className="card-body d-none d-xxl-block pb-xxl-0 pr-xxl-5">
                      <div className="row">
                        <div className="col-xxl-6">
                          {this.getInput(
                            "ReqNum",
                            this.state.ReqNum,
                            "Confirmation #",
                            "A patient's unique code tied to their appointment or walk-in form submission."
                          )}
                          {this.getSelect(
                            "ApptCanceled",
                            this.state.ApptCanceled,
                            "Appointment Canceled",
                            'Appointment status. "Yes" returns canceled appointments, "No" returns active appointments.',
                            this.getBoolOptions()
                          )}
                          {this.getSelect(
                            "ResultID",
                            this.state.ResultID,
                            "Result",
                            "The result assigned to a sample.",
                            this.state.resultTypes
                          )}
                          {this.getInput(
                            "SpecimenID",
                            this.state.SpecimenID,
                            "Specimen ID",
                            "A unique identifier assigned to a sample."
                          )}
                          {this.getInput(
                            "InternalSpecimenID",
                            this.state.InternalSpecimenID,
                            "Internal Specimen ID",
                            "A unique secondary identifier assigned to a sample."
                          )}
                          {this.getSelect(
                            "BatchNum",
                            this.state.BatchNum,
                            "Batch #",
                            "Assigned when processing a batch of test samples.",
                            this.state.batchesForFilter
                          )}
                          {this.getSelect(
                            "BinID",
                            this.state.BinID,
                            "Bin ID",
                            "A unique identifier that ties a sample to receiving at the lab.",
                            this.state.binIDsForFilter
                          )}
                          {this.getSelect(
                            "SpecimenSourceID",
                            this.state.SpecimenSourceID,
                            "Specimen Source",
                            "The type of sample that was collected.",
                            this.state.specimen_source
                          )}
                          {this.getSelect(
                            "TestID",
                            this.state.TestID,
                            "Test Type",
                            "The testing method.",
                            this.state.tests
                          )}
                          {this.getSelect(
                            "RerunFlag",
                            this.state.RerunFlag,
                            "Rerun Flag",
                            'Samples marked for rerun. "Yes" returns samples marked for rerun.',
                            this.getBoolOptions()
                          )}
                          {this.getInput(
                            "ProviderFirstName",
                            this.state.ProviderFirstName,
                            "Provider First Name",
                            "The first name of the Provider that is assigned to a sample."
                          )}
                          {this.getInput(
                            "ProviderLastName",
                            this.state.ProviderLastName,
                            "Provider Last Name",
                            "The last name of the Provider that is assigned to a sample."
                          )}
                          {this.getInput(
                            "Lot",
                            this.state.Lot,
                            "Lot",
                            "Test lot number (e.g., rapid test lot number.)"
                          )}
                        </div>
                        <div className="col-xxl-6">
                          {this.getDateRange(
                            "beginCollectionDate",
                            this.state.beginCollectionDate,
                            "endCollectionDate",
                            this.state.endCollectionDate,
                            "nullCollectionDate",
                            this.state.nullCollectionDate,
                            "Collection Date",
                            "Date a test sample was collected.",
                            this.getNullOptions()
                          )}
                          {this.getDateRange(
                            "beginReceivedDate",
                            this.state.beginReceivedDate,
                            "endReceivedDate",
                            this.state.endReceivedDate,
                            "nullReceivedDate",
                            this.state.nullReceivedDate,
                            "Received Date",
                            "The date a sample was received at the lab.",
                            this.getNullOptions()
                          )}
                          {this.getDateRange(
                            "beginResultDate",
                            this.state.beginResultDate,
                            "endResultDate",
                            this.state.endResultDate,
                            "nullResultDate",
                            this.state.nullResultDate,
                            "Result Date",
                            "Date a result was assigned to a test sample (note: results are not sent to the patient until they're approved.",
                            this.getNullOptions()
                          )}
                          {this.getDateRange(
                            "beginResultsApprovedDate",
                            this.state.beginResultsApprovedDate,
                            "endResultsApprovedDate",
                            this.state.endResultsApprovedDate,
                            "nullResultsApprovedDate",
                            this.state.nullResultsApprovedDate,
                            "Approved Date",
                            "The date a result is approved and sent to the patient.",
                            this.getNullOptions()
                          )}
                          {this.getDateRange(
                            "beginResultsAccessedDate",
                            this.state.beginResultsAccessedDate,
                            "endResultsAccessedDate",
                            this.state.endResultsAccessedDate,
                            "nullResultsAccessedDate",
                            this.state.nullResultsAccessedDate,
                            "Accessed Date",
                            "The date a patient first accessed their test results.",
                            this.getNullOptions()
                          )}
                          {this.getDateRange(
                            "beginCreatedDate",
                            this.state.beginCreatedDate,
                            "endCreatedDate",
                            this.state.endCreatedDate,
                            "nullCreatedDate",
                            this.state.nullCreatedDate,
                            "Created Date",
                            "The date that a patient record was created using the Appointment or Walk-In form.",
                            this.getNullOptions()
                          )}
                          {this.getDateRange(
                            "beginReportedDate",
                            this.state.beginReportedDate,
                            "endReportedDate",
                            this.state.endReportedDate,
                            "nullReportedDate",
                            this.state.nullReportedDate,
                            "Reported Date",
                            "The date that the sample has been reported to SpringML.",
                            this.getNullOptions()
                          )}
                        </div>
                      </div>
                    </div>
                    {/*xxl screen end*/}

                    {/*xl screen and below*/}
                    <div className="card-body d-block d-xxl-none pr-lg-7 pr-xl-6">
                      {this.getInput(
                        "ReqNum",
                        this.state.ReqNum,
                        "Confirmation #",
                        "A patient's unique code tied to their appointment or walk-in form submission."
                      )}
                      {this.getSelect(
                        "ApptCanceled",
                        this.state.ApptCanceled,
                        "Appointment Canceled",
                        'Appointment status. "Yes" returns canceled appointments, "No" returns active appointments.',
                        this.getBoolOptions()
                      )}
                      {this.getSelect(
                        "ResultID",
                        this.state.ResultID,
                        "Result",
                        "The result assigned to a sample.",
                        this.state.resultTypes
                      )}
                      {this.getInput(
                        "SpecimenID",
                        this.state.SpecimenID,
                        "Specimen ID",
                        "A unique identifier assigned to a sample."
                      )}
                      {this.getInput(
                        "InternalSpecimenID",
                        this.state.InternalSpecimenID,
                        "Internal Specimen ID",
                        "A unique secondary identifier assigned to a sample."
                      )}
                      {this.getSelect(
                        "BatchNum",
                        this.state.BatchNum,
                        "Batch #",
                        "Assigned when processing a batch of test samples.",
                        this.state.batchesForFilter
                      )}
                      {this.getSelect(
                        "BinID",
                        this.state.BinID,
                        "Bin ID",
                        "A unique identifier that ties a sample to receiving at the lab.",
                        this.state.binIDsForFilter
                      )}
                      {this.getDateRange(
                        "beginCollectionDate",
                        this.state.beginCollectionDate,
                        "endCollectionDate",
                        this.state.endCollectionDate,
                        "nullCollectionDate",
                        this.state.nullCollectionDate,
                        "Collection Date",
                        "Date a test sample was collected.",
                        this.getNullOptions()
                      )}
                      {this.getDateRange(
                        "beginReceivedDate",
                        this.state.beginReceivedDate,
                        "endReceivedDate",
                        this.state.endReceivedDate,
                        "nullReceivedDate",
                        this.state.nullReceivedDate,
                        "Received Date",
                        "The date a sample was received at the lab.",
                        this.getNullOptions()
                      )}
                      {this.getDateRange(
                        "beginResultDate",
                        this.state.beginResultDate,
                        "endResultDate",
                        this.state.endResultDate,
                        "nullResultDate",
                        this.state.nullResultDate,
                        "Result Date",
                        "Date a result was assigned to a test sample (note: results are not sent to the patient until they're approved.",
                        this.getNullOptions()
                      )}
                      {this.getDateRange(
                        "beginResultsApprovedDate",
                        this.state.beginResultsApprovedDate,
                        "endResultsApprovedDate",
                        this.state.endResultsApprovedDate,
                        "nullResultsApprovedDate",
                        this.state.nullResultsApprovedDate,
                        "Result Approved Date",
                        "The date a result is approved and sent to the patient.",
                        this.getNullOptions()
                      )}
                      {this.getDateRange(
                        "beginResultsAccessedDate",
                        this.state.beginResultsAccessedDate,
                        "endResultsAccessedDate",
                        this.state.endResultsAccessedDate,
                        "nullResultsAccessedDate",
                        this.state.nullResultsAccessedDate,
                        "Result Accessed Date",
                        "The date a patient first accessed their test results.",
                        this.getNullOptions()
                      )}
                      {this.getDateRange(
                        "beginReportedDate",
                        this.state.beginReportedDate,
                        "endReportedDate",
                        this.state.endReportedDate,
                        "nullReportedDate",
                        this.state.nullReportedDate,
                        "Reported Date",
                        "The date that the sample has been reported to SpringML.",
                        this.getNullOptions()
                      )}
                      {this.getSelect(
                        "SpecimenSourceID",
                        this.state.SpecimenSourceID,
                        "Specimen Source",
                        "The type of sample that was collected.",
                        this.state.specimen_source
                      )}
                      {this.getSelect(
                        "TestID",
                        this.state.TestID,
                        "Test Type",
                        "The testing method.",
                        this.state.tests
                      )}
                      {this.getSelect(
                        "RerunFlag",
                        this.state.RerunFlag,
                        "Rerun Flag",
                        'Samples marked for rerun. "Yes" returns samples marked for rerun.',
                        this.getBoolOptions()
                      )}
                    </div>
                  </div>

                  {/*xl screen and below*/}
                  <div className="card m-2 d-block d-xxl-none">
                    <div
                      className="card-header verlag-bold"
                      onClick={() =>
                        this.setState({
                          sampleArrowDown: !this.state.sampleArrowDown,
                        })
                      }
                      style={{ height: "4em" }}
                      data-toggle="collapse"
                      data-target="#moreSampleFilterCollapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="moreSampleFilterCollapse"
                    >
                      <h3 className={"d-none d-md-block"}>
                        More Sample Filters{" "}
                        {this.state.sampleArrowDown ? (
                          <AiOutlineUp
                            className={"float-right"}
                            size={30}
                            data-toggle="collapse"
                            data-target="#moreSampleFilterCollapse"
                            role="button"
                            aria-expanded="false"
                            aria-controls="moreSampleFilterCollapse"
                          />
                        ) : (
                          <AiOutlineDown
                            className={"float-right"}
                            size={30}
                            data-toggle="collapse"
                            data-target="#moreSampleFilterCollapse"
                            role="button"
                            aria-expanded="false"
                            aria-controls="moreSampleFilterCollapse"
                          />
                        )}
                      </h3>

                      <h3
                        className={"d-block d-md-none"}
                        style={{ fontSize: "1.25em" }}
                      >
                        More Sample Filters{" "}
                        {this.state.sampleArrowDown ? (
                          <AiOutlineUp
                            className={"float-right"}
                            size={30}
                            data-toggle="collapse"
                            data-target="#moreSampleFilterCollapse"
                            role="button"
                            aria-expanded="false"
                            aria-controls="moreSampleFilterCollapse"
                          />
                        ) : (
                          <AiOutlineDown
                            className={"float-right"}
                            size={30}
                            data-toggle="collapse"
                            data-target="#moreSampleFilterCollapse"
                            role="button"
                            aria-expanded="false"
                            aria-controls="moreSampleFilterCollapse"
                          />
                        )}
                      </h3>
                    </div>

                    <div
                      className="card-body collapse pr-lg-7 pr-xl-6"
                      id={"moreSampleFilterCollapse"}
                    >
                      {this.getInput(
                        "ProviderFirstName",
                        this.state.ProviderFirstName,
                        "Provider First Name",
                        "The first name of the Provider that is assigned to a sample."
                      )}
                      {this.getInput(
                        "ProviderLastName",
                        this.state.ProviderLastName,
                        "Provider Last Name",
                        "The last name of the Provider that is assigned to a sample."
                      )}
                      {this.getInput(
                        "Lot",
                        this.state.Lot,
                        "Lot",
                        "Test lot number (e.g., rapid test lot number.)"
                      )}
                      {this.getDateRange(
                        "beginCreatedDate",
                        this.state.beginCreatedDate,
                        "endCreatedDate",
                        this.state.endCreatedDate,
                        "nullCreatedDate",
                        this.state.nullCreatedDate,
                        "Created Date",
                        "The date that a patient record was created using the Appointment or Walk-In form.",
                        this.getNullOptions()
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-6 pl-lg-0 col-xxl-6">
                  {/*lg screen and below*/}
                  <div className="card mx-2 mb-2 mt-0 mt-lg-2 d-block">
                    <div
                      className="card-header verlag-bold"
                      style={{ height: "4em" }}
                    >
                      <h3 className={"d-none d-md-inline-block"}>
                        Facility Information{" "}
                      </h3>
                      <h3
                        className={"d-inline-block d-md-none"}
                        style={{ fontSize: "1.5em" }}
                      >
                        Facility Information{" "}
                      </h3>
                    </div>
                    <div className="card-body">
                      {this.getSelect(
                        "PodID",
                        this.state.PodID,
                        "Pod",
                        "Lists the Pod ID, Facility Name, and Pod Date.",
                        this.state.podsForModal,
                        this.state.FacilityGroup || this.state.FacilityID
                      )}
                      {this.getSelect(
                        "FacilityGroup",
                        this.state.FacilityGroup,
                        "Facility Group",
                        "Group facility is assigned to.",
                        this.state.facilityGroups,
                        this.state.FacilityID || this.state.PodID
                      )}
                      {this.getSelect(
                        "FacilityID",
                        this.state.FacilityID,
                        "Facility",
                        "Testing locations by name.",
                        this.state.facilities,
                        this.state.FacilityGroup || this.state.PodID
                      )}
                    </div>
                  </div>

                  <div className="card m-2 d-block d-xxl-none">
                    <div
                      className="card-header verlag-bold"
                      style={{ height: "4.15em" }}
                    >
                      <h3 className={"d-none d-md-inline-block"}>
                        Patient Information{" "}
                      </h3>
                      <h3
                        className={"d-inline-block d-md-none"}
                        style={{ fontSize: "1.5em" }}
                      >
                        Patient Information{" "}
                      </h3>
                    </div>
                    <div className="card-body">
                      {this.getInput(
                        "PatientFirstName",
                        this.state.PatientFirstName,
                        "First Name",
                        "The first name of the Patient."
                      )}
                      {this.getInput(
                        "PatientMiddleName",
                        this.state.PatientMiddleName,
                        "Middle Name",
                        "The middle name of the Patient."
                      )}
                      {this.getInput(
                        "PatientLastName",
                        this.state.PatientLastName,
                        "Last Name",
                        "The last name of the Patient."
                      )}
                      {this.getInput(
                        "PatientEmail",
                        this.state.PatientEmail,
                        "Email",
                        "The email of the Patient."
                      )}

                      <div className="form-group row">
                        <div
                          className={"col-md-4"}
                          data-toggle={"tooltip"}
                          data-placement={"top"}
                          title={"The date of birth of the Patient."}
                        >
                          <label
                            htmlFor={"DOB"}
                            style={{ fontSize: "0.8em" }}
                            className={"pt-1 font-weight-bold"}
                          >
                            DOB
                          </label>
                        </div>
                        <div className="col-md-1 pt-2">
                          {this.state.PatientDOB ||
                          this.state.nullPatientDOB ? (
                            <BsCheck
                              className={"float-right"}
                              color={"blue"}
                              size={20}
                            />
                          ) : null}
                        </div>
                        <div
                          className={
                            "col-md-7 pl-md-0 pr-md-1 col-lg-7 pl-lg-0 pr-lg-1"
                          }
                        >
                          <div className="row">
                            <div
                              className="col-12 px-0 px-md-3 pl-lg-3 pr-lg-0"
                              id={"DOB"}
                            >
                              <DatePicker
                                minDate={new Date("01-01-1000")}
                                maxDate={new Date("12-31-9999")}
                                placeholderText="--/--/----"
                                selected={
                                  this.state.PatientDOB
                                    ? Date.parse(
                                        moment(
                                          this.state.PatientDOB,
                                          "MM-DD-YYYY"
                                        ).toISOString()
                                      )
                                    : null
                                }
                                onChange={(e) =>
                                  this.handleDateOnChange(
                                    e,
                                    "nullPatientDOB",
                                    "PatientDOB"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="row pt-1"
                            style={{ width: "13.8em" }}
                            id={"DOB"}
                          >
                            <div className="col-12 px-0 px-md-3">
                              <Select
                                isMulti={true}
                                isSearchable={true}
                                placeholder={"Select..."}
                                onChange={(e) =>
                                  this.setState({
                                    PatientDOB: null,
                                    nullPatientDOB: e,
                                  })
                                }
                                className={"state_select"}
                                options={this.getNullOptions()}
                                value={this.state.nullPatientDOB}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div
                          className={"col-md-4 col-lg-4"}
                          data-toggle={"tooltip"}
                          data-placement={"top"}
                          title={"The phone number of the Patient."}
                        >
                          <label
                            htmlFor={"Phone"}
                            style={{ fontSize: "0.8em" }}
                            className={"pt-1 font-weight-bold"}
                          >
                            Phone #
                          </label>
                        </div>

                        <div className="col-md-1 col-lg-1 pt-2">
                          {this.state.PatientPhone ||
                          this.state.nullPatientPhone ? (
                            <BsCheck
                              className={"float-right"}
                              color={"blue"}
                              size={20}
                            />
                          ) : null}
                        </div>

                        <div className={"col-md-7 col-lg-7 pl-0 pr-1"}>
                          <div className="row" style={{ width: "13.8em" }}>
                            <div className="col-md-12 col-lg-12" id={"Phone"}>
                              <PhoneInput
                                placeholder="Enter phone #"
                                onChange={(e) =>
                                  this.setState({
                                    nullPatientPhone: null,
                                    PatientPhone: e,
                                  })
                                }
                                defaultCountry="US"
                                value={this.state.PatientPhone}
                              />
                            </div>
                          </div>

                          <div
                            className="row pt-1"
                            style={{ width: "13.8em" }}
                            id={"Phone"}
                          >
                            <div className="col-md-12">
                              <Select
                                isMulti={true}
                                isSearchable={true}
                                placeholder={"Select..."}
                                onChange={(e) =>
                                  this.setState({
                                    PatientPhone: "",
                                    nullPatientPhone: e,
                                  })
                                }
                                className={"state_select"}
                                options={this.getNullOptions()}
                                value={this.state.nullPatientPhone}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*xxl screens*/}
                  <div className="card m-2 d-none d-xxl-block">
                    <div
                      className="card-header verlag-bold"
                      style={{ height: "4.15em" }}
                    >
                      <h3 className={"d-none d-md-inline-block"}>
                        Patient Information{" "}
                      </h3>
                    </div>
                    <div className="card-body pb-xxl-4">
                      <div className="row">
                        <div className="col-xxl-6">
                          {this.getInput(
                            "PatientFirstName",
                            this.state.PatientFirstName,
                            "First Name",
                            "The first name of the Patient."
                          )}
                          {this.getInput(
                            "PatientMiddleName",
                            this.state.PatientMiddleName,
                            "Middle Name",
                            "The middle name of the Patient."
                          )}
                          {this.getInput(
                            "PatientLastName",
                            this.state.PatientLastName,
                            "Last Name",
                            "The last name of the Patient."
                          )}
                          {this.getInput(
                            "PatientEmail",
                            this.state.PatientEmail,
                            "Email",
                            "The email of the Patient."
                          )}

                          <div className="form-group row">
                            <div
                              className={"col-xxl-4"}
                              data-toggle={"tooltip"}
                              data-placement={"top"}
                              title={"The date of birth of the Patient."}
                            >
                              <label
                                htmlFor={"DOB"}
                                style={{ fontSize: "0.8em" }}
                                className={"pt-1 pt-xxl-0 font-weight-bold"}
                              >
                                Date of Birth
                              </label>
                            </div>

                            <div className="col-xxl-1 pt-2">
                              {this.state.PatientDOB ||
                              this.state.nullPatientDOB ? (
                                <BsCheck
                                  className={"float-right"}
                                  color={"blue"}
                                  size={20}
                                />
                              ) : null}
                            </div>
                            <div className={"col-xxl-7 pr-xxl-1"}>
                              <div className="row">
                                <div
                                  className="col-xxl-12 pt-xxl-1 px-0"
                                  id={"DOB"}
                                >
                                  <DatePicker
                                    minDate={new Date("01-01-1000")}
                                    maxDate={new Date("12-31-9999")}
                                    placeholderText="--/--/----"
                                    selected={
                                      this.state.PatientDOB
                                        ? Date.parse(
                                            moment(
                                              this.state.PatientDOB,
                                              "MM-DD-YYYY"
                                            ).toISOString()
                                          )
                                        : null
                                    }
                                    onChange={(e) =>
                                      this.handleDateOnChange(
                                        e,
                                        "nullPatientDOB",
                                        "PatientDOB"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="row" style={{ width: "12.8em" }}>
                                <div
                                  className="col-xxl-12 pl-0 pt-1"
                                  id={"DOB"}
                                >
                                  <Select
                                    isMulti={true}
                                    isSearchable={true}
                                    placeholder={"Select..."}
                                    onChange={(e) =>
                                      this.setState({
                                        PatientDOB: null,
                                        nullPatientDOB: e,
                                      })
                                    }
                                    className={"state_select"}
                                    options={this.getNullOptions()}
                                    value={this.state.nullPatientDOB}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-group row">
                            <div
                              className={"col-xxl-4"}
                              data-toggle={"tooltip"}
                              data-placement={"top"}
                              title={"The phone number of the Patient."}
                            >
                              <label
                                htmlFor={"Phone"}
                                style={{ fontSize: "0.8em" }}
                                className={"pt-1 pt-xxl-0 font-weight-bold"}
                              >
                                Phone #
                              </label>
                            </div>
                            <div className="col-xxl-1 pt-2">
                              {this.state.PatientPhone ||
                              this.state.nullPatientPhone ? (
                                <BsCheck
                                  className={"float-right"}
                                  color={"blue"}
                                  size={20}
                                />
                              ) : null}
                            </div>
                            <div className={"col-xxl-7 pl-0 pr-1"}>
                              <div className="row">
                                <div
                                  className="col-xxl-12 pt-xxl-1 pr-xxl-7"
                                  id={"Phone"}
                                >
                                  <PhoneInput
                                    placeholder="Enter phone #"
                                    onChange={(e) =>
                                      this.setState({
                                        nullPatientPhone: null,
                                        PatientPhone: e,
                                      })
                                    }
                                    defaultCountry="US"
                                    value={this.state.PatientPhone}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div
                                  className="col-xxl-12 pt-1 pr-xxl-7"
                                  id={"Phone"}
                                >
                                  <Select
                                    isMulti={true}
                                    isSearchable={true}
                                    placeholder={"Select..."}
                                    onChange={(e) =>
                                      this.setState({
                                        PatientPhone: "",
                                        nullPatientPhone: e,
                                      })
                                    }
                                    className={"state_select"}
                                    options={this.getNullOptions()}
                                    value={this.state.nullPatientPhone}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-6">
                          {this.getSelect(
                            "PatientEthnicityID",
                            this.state.PatientEthnicityID,
                            "Ethnicity",
                            "The ethnicity of the Patient.",
                            this.state.ethnicity
                          )}
                          {this.getSelect(
                            "PatientRaceID",
                            this.state.PatientRaceID,
                            "Race",
                            "The race of the Patient.",
                            this.state.race
                          )}
                          {this.getSelect(
                            "PatientGenderID",
                            this.state.PatientGenderID,
                            "Gender",
                            "The gender of the Patient.",
                            this.state.gender
                          )}
                          {this.getInput(
                            "PatientCity",
                            this.state.PatientCity,
                            "City",
                            "The city of the Patient's address."
                          )}
                          {this.getInput(
                            "PatientZip",
                            this.state.PatientZip,
                            "Zip",
                            "The zip of the Patient's address."
                          )}
                          {this.getSelect(
                            "PatientState",
                            this.state.PatientState,
                            "State",
                            "The state of the Patient's address.",
                            this.state.states
                          )}
                          {this.getInput(
                            "PatientCounty",
                            this.state.PatientCounty,
                            "County",
                            "The county of the Patient's address."
                          )}
                          {this.getSelect(
                            "PatientCountry",
                            this.state.PatientCountry,
                            "Country",
                            "The country of the Patient's address.",
                            this.state.countries
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*xxl screen end*/}

                  {/*xl screen and below*/}
                  <div className="card m-2 d-block d-xxl-none">
                    <div
                      className="card-header verlag-bold"
                      onClick={() =>
                        this.setState({
                          patientArrowDown: !this.state.patientArrowDown,
                        })
                      }
                      style={{ height: "4em" }}
                      data-toggle="collapse"
                      data-target="#morePatientFilterCollapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="morePatientFilterCollapse"
                    >
                      <h3 className={"d-none d-md-block"}>
                        More Patient Filters{" "}
                        {this.state.patientArrowDown ? (
                          <AiOutlineUp
                            className={"float-right"}
                            size={30}
                            data-toggle="collapse"
                            data-target="#morePatientFilterCollapse"
                            role="button"
                            aria-expanded="false"
                            aria-controls="morePatientFilterCollapse"
                          />
                        ) : (
                          <AiOutlineDown
                            className={"float-right"}
                            size={30}
                            data-toggle="collapse"
                            data-target="#morePatientFilterCollapse"
                            role="button"
                            aria-expanded="false"
                            aria-controls="morePatientFilterCollapse"
                          />
                        )}
                      </h3>

                      <h3
                        className={"d-block d-md-none"}
                        style={{ fontSize: "1.25em" }}
                      >
                        More Patient Filters{" "}
                        {this.state.patientArrowDown ? (
                          <AiOutlineUp
                            className={"float-right"}
                            size={30}
                            data-toggle="collapse"
                            data-target="#morePatientFilterCollapse"
                            role="button"
                            aria-expanded="false"
                            aria-controls="morePatientFilterCollapse"
                          />
                        ) : (
                          <AiOutlineDown
                            className={"float-right"}
                            size={30}
                            data-toggle="collapse"
                            data-target="#morePatientFilterCollapse"
                            role="button"
                            aria-expanded="false"
                            aria-controls="morePatientFilterCollapse"
                          />
                        )}
                      </h3>
                    </div>
                    <div
                      className="card-body collapse"
                      id={"morePatientFilterCollapse"}
                    >
                      {this.getSelect(
                        "PatientEthnicityID",
                        this.state.PatientEthnicityID,
                        "Ethnicity",
                        "The ethnicity of the Patient.",
                        this.state.ethnicity
                      )}
                      {this.getSelect(
                        "PatientRaceID",
                        this.state.PatientRaceID,
                        "Race",
                        "The race of the Patient.",
                        this.state.race
                      )}
                      {this.getSelect(
                        "PatientGenderID",
                        this.state.PatientGenderID,
                        "Gender",
                        "The gender of the Patient.",
                        this.state.gender
                      )}
                      {this.getInput(
                        "PatientCity",
                        this.state.PatientCity,
                        "City",
                        "The city of the Patient's address."
                      )}
                      {this.getInput(
                        "PatientZip",
                        this.state.PatientZip,
                        "Zip",
                        "The zip of the Patient's address."
                      )}
                      {this.getSelect(
                        "PatientState",
                        this.state.PatientState,
                        "State",
                        "The state of the Patient's address.",
                        this.state.states
                      )}
                      {this.getInput(
                        "PatientCounty",
                        this.state.PatientCounty,
                        "County",
                        "The county of the Patient's address."
                      )}
                      {this.getSelect(
                        "PatientCountry",
                        this.state.PatientCountry,
                        "Country",
                        "The country of the Patient's address.",
                        this.state.countries
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer" style={{ height: "4em" }}>
                <button
                  className="btn btn-outline-danger mr-5 float-right verlag-bold"
                  onClick={() => {
                    this.clear();
                  }}
                >
                  Clear Filters
                </button>
                <button
                  className={"btn btn-outline-primary verlag-bold"}
                  onClick={() =>
                    this.setState(
                      { selected_page: { label: 1, value: 1 } },
                      () => this.submit(1)
                    )
                  }
                >
                  Search
                </button>
              </div>
            </div>

            {this.state.totalSamples > 0 ? (
              <div className="col-12 col-md-12 col-lg-12 col-xxl-12 pt-2 pr-0 pl-0">
                <div className="card m-2 mb-5">
                  <div className="card-header verlag-bold stickToTop">
                    <h3>
                      Samples
                      <button
                        className={
                          this.state.totalSamples < 1
                            ? "d-none"
                            : "btn btn-outline-primary d-none d-md-none d-lg-inline-block d-xxl-inline-block ml-2"
                        }
                        onClick={() => this.handleExportToCSV()}
                      >
                        Export to CSV
                      </button>
                      <ReactToPrint
                        trigger={() => (
                          <button
                            className={
                              this.state.totalSamples < 1
                                ? "d-none"
                                : "btn btn-outline-success ml-2 d-none d-md-none d-lg-inline-block d-xxl-inline-block"
                            }
                          >
                            Print Grid to PDF
                          </button>
                        )}
                        // @ts-ignore
                        content={() => this.componentRef}
                      />
                      <ReactToPrint
                        trigger={() => (
                          <button
                            className={
                              this.state.totalSamples < 1
                                ? "d-none"
                                : "btn btn-outline-success ml-2 d-none d-md-none d-lg-inline-block d-xxl-inline-block"
                            }
                          >
                            Print Results to PDF
                          </button>
                        )}
                        // @ts-ignore
                        content={() => this.componentRefBR}
                        onBeforeGetContent={() => {
                          if (
                            !this.state.tableData ||
                            this.state.tableData.length === 0
                          ) {
                            sweetalert.fire({
                              icon: "error",
                              title: "",
                              text: "No samples found",
                            });
                            return;
                          }
                          return new Promise<void>((resolve, reject) => {
                            this.setState({ showLoading: true }, () => {
                              fetch(
                                domain +
                                  "/api/admin/sampleReport?page=" +
                                  this.state.selected_page.value,
                                {
                                  method: "POST",
                                  body: JSON.stringify({
                                    filter: this.getFilterObj(),
                                  }),
                                  headers: {
                                    "Content-Type": "application/json",
                                  },
                                }
                              )
                                .then((res) => res.json())
                                .then((data) => {
                                  this.setState({
                                    pdfData: data.pdfData,
                                    showLoading: false,
                                  });
                                  resolve();
                                })
                                .catch((e) => {
                                  console.log(e);
                                  sweetalert.fire({
                                    icon: "error",
                                    title: "",
                                    text: "Export failed. Please try again.",
                                  });
                                  this.setState({
                                    pdfData: [],
                                    showLoading: false,
                                  });
                                  resolve();
                                });
                            });
                          });
                        }}
                      />
                      <section className="tableHeaderSection float-md-right ">
                        <h3 className={"float-right"}>
                          Total: {this.state.totalSamples}
                        </h3>
                        <h4 className="d-inline-flex pr-2 float-md-right align-items-md-center pr-md-2 ml-md-5">
                          Page
                        </h4>
                        <div className="align-items-md-center float-right pages">
                          <Select
                            isSearchable={true}
                            placeholder={"1"}
                            noOptionsMessage={() => "No option"}
                            value={this.state.selected_page}
                            onChange={(e: ReactSelect) =>
                              this.setState({ selected_page: e }, () =>
                                this.submit(e.value)
                              )
                            }
                            className={"state_select page-num-select"}
                            options={this.state.page_options}
                          />
                        </div>
                      </section>
                    </h3>
                  </div>

                  {/*phone-portrait*/}
                  <div className="card-body p-0 d-md-none table-responsive">
                    <SimpleTable
                      ref={(el) => (this.componentRef = el)}
                      columns={[
                        {
                          label: "Name",
                          key: "Name",
                          rawFormat: (val: Sample) => {
                            return (
                              <a
                                href={"#top"}
                                onClick={() =>
                                  this.setState(
                                    {
                                      getResultsButtonEnabled: !!val.ResultID,
                                      selectedResult: JSON.parse(
                                        JSON.stringify(val)
                                      ),
                                    },
                                    () => showModalNoOutsideClick(SamplesModal.ID)
                                  )
                                }
                              >
                                {val.PatientFirstName +
                                  " " +
                                  val.PatientLastName}
                              </a>
                            );
                          },
                        },
                        {
                          label: "DOB",
                          key: "PatientDOB",
                          formatFunc: parseDate,
                        },
                        { label: "Confirmation #", key: "ReqNum" },
                        {
                          label: "Results Accessed",
                          key: "ResultsAccessedDate",
                          rawFormat: (val: Sample) =>
                            val.ResultsAccessedDate ? (
                              <div>
                                {toLocalDateTime(val.ResultsAccessedDate)}
                              </div>
                            ) : (
                              <button
                                type={"button"}
                                className={
                                  val.ResultID
                                    ? "btn btn-outline-success"
                                    : "d-none"
                                }
                                onClick={() =>
                                  this.handleSetResultsAccessed(val)
                                }
                              >
                                Mark as Accessed
                              </button>
                            ),
                        },
                      ]}
                      table_data={this.state.tableData}
                      columnClickedCallback={(col) => {
                        this.useSorter(col);
                      }}
                    />
                  </div>

                  {/*tablet-portrait and phone-landscape*/}
                  <div className="card-body p-0 d-none d-md-block d-lg-none table-responsive">
                    <SimpleTable
                      ref={(el) => (this.componentRef = el)}
                      columns={[
                        {
                          label: "Name",
                          key: "Name",
                          rawFormat: (val: Sample) => {
                            return (
                              <a
                                href={"#top"}
                                onClick={() =>
                                  this.setState(
                                    {
                                      getResultsButtonEnabled: !!val.ResultID,
                                      selectedResult: JSON.parse(
                                        JSON.stringify(val)
                                      ),
                                    },
                                    () => SamplesModal.display()
                                  )
                                }
                              >
                                {val.PatientFirstName +
                                  " " +
                                  val.PatientLastName}
                              </a>
                            );
                          },
                        },
                        {
                          label: "DOB",
                          key: "PatientDOB",
                          formatFunc: parseDate,
                        },
                        { label: "Confirmation #", key: "ReqNum" },
                        { label: "Specimen ID", key: "SpecimenID" },
                        { label: "Result", key: "ResultDesc" },
                        {
                          label: "Results Accessed",
                          key: "ResultsAccessedDate",
                          rawFormat: (val: Sample) =>
                            val.ResultsAccessedDate ? (
                              <div>
                                {toLocalDateTime(val.ResultsAccessedDate)}
                              </div>
                            ) : (
                              <button
                                type={"button"}
                                className={
                                  val.ResultID
                                    ? "btn btn-outline-success"
                                    : "d-none"
                                }
                                onClick={() =>
                                  this.handleSetResultsAccessed(val)
                                }
                              >
                                Mark as Accessed
                              </button>
                            ),
                        },
                      ]}
                      table_data={this.state.tableData}
                      columnClickedCallback={(col) => {
                        this.useSorter(col);
                      }}
                    />
                  </div>

                            {/*tablet-landscape and small desktop*/}
                            <div className="card-body p-0 d-none d-lg-block d-xxl-none">
                                <SimpleTable table_style='' ref={el => (this.componentRef = el)} columns={[
                                    {label:"Name", key:"Name", rawFormat:(val:Sample) => {
                                            return <a href={"#top"}
                                                      onClick={() => this.setState({getResultsButtonEnabled: !!val.ResultID , selectedResult:JSON.parse(JSON.stringify(val))}, () => showModalNoOutsideClick(SamplesModal.ID))}>{val.PatientFirstName + " " + val.PatientLastName}</a>}},
                                    {label:"DOB", key:"PatientDOB", formatFunc:parseDate},
                                    {label:"Confirmation #", key:"ReqNum"},
                                    {label:"Collection Date", key:"CollectionDate", formatFunc:toLocalDateTime},
                                    {label:"Specimen ID", key:"SpecimenID"},
                                    {label:"Test", key:"Test"},
                                    {label:"Result", key:"ResultDesc"},
                                    {label:"Results Accessed", key:"ResultsAccessedDate", rawFormat: (val:Sample) => (val.ResultsAccessedDate ? <div>{toLocalDateTime(val.ResultsAccessedDate)}</div> : <button type={'button'} className={val.ResultID ? 'btn btn-outline-success' : "d-none"} onClick={() => this.handleSetResultsAccessed(val)}>Mark as Accessed</button> )},
                                ]} table_data={this.state.tableData} columnClickedCallback={(col =>{
                                    this.useSorter(col);
                                })} />
                            </div>

                  {/*desktop*/}
                  <div className="card-body p-0 d-none d-xxl-block">
                    <SimpleTable
                      ref={(el) => (this.componentRef = el)}
                      columns={[
                        {
                          label: "Name",
                          key: "Name",
                          rawFormat: (val: Sample) => {
                            return (
                              <a
                                href={"#top"}
                                onClick={() =>
                                  this.setState(
                                    {
                                      getResultsButtonEnabled: !!val.ResultID,
                                      selectedResult: JSON.parse(
                                        JSON.stringify(val)
                                      ),
                                    },
                                    () => showModalNoOutsideClick(SamplesModal.ID)
                                  )
                                }
                              >
                                {val.PatientFirstName +
                                  " " +
                                  val.PatientLastName}
                              </a>
                            );
                          },
                        },
                        {
                          label: "DOB",
                          key: "PatientDOB",
                          formatFunc: parseDate,
                        },
                        { label: "Confirmation #", key: "ReqNum" },
                        {
                          label: "Collection Date",
                          key: "CollectionDate",
                          formatFunc: toLocalDateTime,
                        },
                        { label: "Phone", key: "PatientPhone" },
                        { label: "Specimen ID", key: "SpecimenID" },
                        {
                          label: "Internal Specimen ID",
                          key: "InternalSpecimenID",
                        },
                        { label: "Batch #", key: "BatchNum" },
                        { label: "Test", key: "Test" },
                        { label: "Result", key: "ResultDesc" },
                        {
                          label: "Results Accessed",
                          key: "ResultsAccessedDate",
                          rawFormat: (val: Sample) =>
                            val.ResultsAccessedDate ? (
                              <div>
                                {toLocalDateTime(val.ResultsAccessedDate)}
                              </div>
                            ) : (
                              <button
                                type={"button"}
                                className={
                                  val.ResultID
                                    ? "btn btn-outline-success"
                                    : "d-none"
                                }
                                onClick={() =>
                                  this.handleSetResultsAccessed(val)
                                }
                              >
                                Mark as Accessed
                              </button>
                            ),
                        },
                      ]}
                      table_data={this.state.tableData}
                      columnClickedCallback={(col) => {
                        this.useSorter(col);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div style={{ display: "none" }}>
          <React.Fragment>
            <div
              className="print-container"
              ref={(el) => (this.componentRefBR = el)}
              style={{ margin: "0", padding: "0" }}
            >
              {this.state.pdfData
                ? this.state.pdfData.map((result: ResultInfo) => {
                    return (
                      <React.Fragment>
                        <TestResult logo={this.state.logo} result={result} />
                        <div className={"page-break"} />
                      </React.Fragment>
                    );
                  })
                : null}
            </div>
          </React.Fragment>
        </div>
      </React.Fragment>
    );
  }
}
