import React from "react";
import Overlay from "../Overlay";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import ReactToPrint from "react-to-print";
import Select from "react-select";
import {parseDate} from "../../util/FormatUtil";
import {sweetalert} from "../../App";
import SimpleTable from "../tables/SimpleTable";
import BatchesAPI from "../../network/BatchesAPI";
import AdminAPI from "../../network/AdminAPI";

interface ReconcileLabState {
    filter: {rStartDate, rEndDate}
    show_loading: boolean
    dataAList: any
    facilities: { label:string, value:number }[]
    selectedFacility: {label:string, value:number}
}

export class ReconcileLab extends React.Component<any, ReconcileLabState> {
    private componentRef: any;

    constructor(props) {
        super(props);
        this.state = {
            filter: {rStartDate:'', rEndDate:''},
            show_loading: false,
            dataAList: [],
            selectedFacility: null,
            facilities: []
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.clear = this.clear.bind(this);
        this.assignClearState = this.assignClearState.bind(this);
    }

    componentDidMount(): void {
        this.setState({show_loading: true}, async () => {
            AdminAPI.getFacilitiesWithAddress().then(data => {
                this.setState({facilities:  data, show_loading: false})
            })

        })
    }

    clear() {
        this.setState({
            filter: {rStartDate: '', rEndDate: ''},
            show_loading: false,
            dataAList: [],
            selectedFacility: {label: '', value: -1}
        });
    }

    clearFilterState: () => void = null;

    assignClearState(func) {
        this.clearFilterState = func;
    }


    async onSubmit(e) {
        if (this.state.filter['rStartDate'] === '' || this.state.filter['rEndDate'] === '') {
            return sweetalert.fire({
                icon: 'error',
                title: '',
                text: 'Please input a both Received Start Date and End Date'
            });
        }
        else {
            this.setState({
                show_loading: true
            })

            let reconciliationReport = await BatchesAPI.getReconciliationReport(this.state.filter['rStartDate'], this.state.filter['rEndDate'],
                this.state.selectedFacility ? this.state.selectedFacility.value : null);
            console.log(reconciliationReport);
            this.setState({show_loading:false})
            if(!reconciliationReport.success){
                return sweetalert.fire({
                    icon: 'error',
                    title: '',
                    text: reconciliationReport.reason
                });
            }
            if(reconciliationReport.data.length > 0){
                this.setState({
                    dataAList: reconciliationReport.data,
                    show_loading: false
                })
            }
            else{
                return sweetalert.fire({
                    icon: 'error',
                    title: '',
                    text: 'No data found'
                });
            }
        }

    }

    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <Overlay show_loading={this.state.show_loading}/>
                    <div className="container-fluid  ">
                        <div className={"row"}>
                            <div className="col-12 col-md-12 offset-xl-3 col-xl-6 offset-lg-2 col-lg-8  pt-2">
                                <div className="card mb-2">
                                    <div className="card-header verlag-bold">
                                        <h3>Reconciliation Lab Report</h3>
                                    </div>
                                    <div className="card-body">
                                        <FilterCard passClearStateFunc={this.assignClearState}
                                                    fields={[
                                                        {
                                                            label: "Received Start Date",
                                                            "key": "rStartDate",
                                                            type: FIELD_TYPE.DATE,
                                                        },
                                                        {
                                                            label: "Received End Date",
                                                            "key": "rEndDate",
                                                            type: FIELD_TYPE.DATE,
                                                        },
                                                    ]} filterChanged={(filter) => this.setState({filter: filter})}/>
                                        <div className="form-group row">
                                            <div className={"col-4"}>
                                                <label style={{fontWeight: 'bold', paddingTop: '0.5em'}} htmlFor={'Facility'}>Facility</label>
                                            </div>
                                            <div className={"col-8 pl-0 pr-1"}>
                                                <Select
                                                    id={'Facility'}
                                                    isSearchable={true}
                                                    placeholder={"Please Select..."}
                                                    onChange={(e) => this.setState({selectedFacility: e, dataAList: []})}
                                                    className={"state_select"}
                                                    options={this.state.facilities}
                                                    value={this.state.selectedFacility}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <button
                                            className="btn btn-outline-primary float-left"
                                            onClick={(e) => this.onSubmit(e)}>
                                            Search
                                        </button>

                                        <button className="btn btn-outline-danger ml-2" onClick={() => {
                                            this.clear();
                                            this.clearFilterState();
                                        }}>Clear
                                        </button>
                                        {this.state.dataAList.length > 0 &&
                                        <ReactToPrint
                                            trigger={() => <button className={"btn btn-outline-success float-right"}
                                                                   style={{float: 'right'}}>Print Report</button>}
                                            content={() => this.componentRef}
                                        />}

                                    </div>
                                </div>
                            </div>
                            <div className={"container-fluid"} ref={el => (this.componentRef = el)}>
                                {this.state.dataAList.length > 0 &&
                                <div>
                                    <b><p>{"Reconciliation Lab Report"}</p></b>
                                    <p>{"Received Start Date: " + parseDate(this.state.filter.rStartDate)} </p>
                                    <p>{"Received End Date: " + parseDate(this.state.filter.rEndDate)}</p>
                                    <p>{this.state.selectedFacility ? `Facility: ${this.state.selectedFacility.label}` : null}</p>
                                    <SimpleTable table_style={"pt-1 text-center mb-2"} columns={[
                                        {
                                            label: "Received Date",
                                            key: "ReceivedDate",
                                            formatFunc: (value) => parseDate(value)
                                        },
                                        {label: "Batch Number", key: "BatchNum"},
                                        {label: "Received", key: "ReceivedCount"},
                                        {label: "Processed", key: "ProcessedCount"},
                                        {label: "Results", key: "ResultCount"},
                                        {label: "Reruns", key: "RerunCount"},
                                        {label: "Reported SpringML", key: "SpringMLCount"},
                                        {label: "Approved", key: "ApprovedCount"},
                                        {label: "Received Not Complete", key: "RecdNotResCount"},
                                    ]} table_data={this.state.dataAList}/>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}