import React from "react";
import domain from "../Domain";
import {parseDate} from "../util/FormatUtil";
let Barcode = require('react-barcode');

export default  class PatientCheckIn extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state={code:""}
    }
    componentDidMount(): void {
        const query = new URLSearchParams(window.location.search);
        if(query.get('code') && query.get('code') !== "")
            this.setState({code:query.get('code') ? query.get('code') : ""})
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (<React.Fragment>
            <Barcode
                value={this.state.code ? this.state.code : ""}
                options={
                    {width:2,
                        height:100,
                        displayValue:true,
                        format:"CODE128"

                    }
                }
            />

        </React.Fragment>)
    }
}