import React from "react";
import InformedConsentModal from "./modals/InformedConsentModal";
import InformedConsent from "./modals/InformedConsentModal";
import {parseDate} from "../util/FormatUtil";

interface ConsentProps {
    onAcknowledge: (e) => void
    onSubmit: () => void
    facility: {
        FacilityName?: string
        Address?: string
        days?: { day: string }[]
    },
    day:string
    time:string
    test:string
    legalName:string
}

interface ConsentState {
    acknowledged
}

export class ConsentSummary extends React.Component<ConsentProps, ConsentState> {
    constructor(props) {
        super(props);
        this.state = {acknowledged:""};
    }

    private buildSummary(): any {
        if (!this.props.facility || !this.props.day || !this.props.time)
            return <p>Please select a location, date, and time.</p>;
        return (
            <p>You are scheduling a {this.props.test} test at <b>{this.props.facility.FacilityName}</b> located
                at <b>{this.props.facility.Address}</b> on <b>{parseDate(this.props.day)}</b> at <b>{this.props.time}</b>.
            </p>
        )
    }

    render() {
        return (
            <div className="card mb-2  mt-2">
                <div className="card-header verlag-bold">
                    <h3>Summary</h3>
                </div>
                <div className="card-body">
                    {this.buildSummary()}
                    <br/>
                    <h3><a href={"#!"} onClick={() => InformedConsentModal.display()}>Informed Consent</a></h3>
                    <p>
                        By selecting the ACKNOWLEDGEMENT during the registration process for COVID-19
                        Diagnostic Testing at {this.props.legalName}, I acknowledge and agree that I have read,
                        understand, and agreed to the statements contained within the
                        <a href={"#!"} onClick={() => InformedConsent.display()}> informed consent</a>.
                        I have been informed about the purpose of the COVID-19 diagnostic
                        test, procedures to be performed, potential risks and benefits, and associated
                        costs. I have been provided an opportunity to ask questions before proceeding
                        with a COVID-19 diagnostic test and I understand that if I do not wish to
                        continue with the collection, testing, or analysis of a COVID-19 diagnostic
                        test, I may decline to receive continued services. I have read the contents of
                        this form in its entirety and voluntarily consent to undergo diagnostic testing
                        for COVID-19.
                    </p>
                    <p>IF BEING COMPLETED ON BEHALF OF A MINOR: I affirm that I have legal authority,
                        based on my relationship to the minor, to consent to a test sample being taken
                        from my child or legal dependent for COVID-19 Diagnostic Testing. </p>
                    <p>I would like to receive healthcare information (i.e. appointment reminders,
                        notifications that lab results are ready to view, etc.) via text message. NOTE:
                        {this.props.legalName} will never send marketing or other non-healthcare related information
                        via text message. You may opt-out at any time.</p>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="acknowledge"
                               value={this.state.acknowledged}
                               onChange={(e) => this.setState({acknowledged: e.target.checked}, () => this.props.onAcknowledge(e.target.checked))}/>
                        <label className="form-check-label" htmlFor="acknowledge">
                            I acknowledge and agree that I have read, understand, and agreed to the
                            statements contained within this form.
                        </label>
                    </div>
                </div>
                <div className={"card-footer"}>
                    <button className={"btn btn-outline-primary "} onClick={this.props.onSubmit}>Next</button>
                    <p><b>* This is not a final submission. You will need to hit 'Submit Appointment' on
                        the next page before your actual appointment is scheduled. You will receive an
                        email when your appointment has been scheduled.</b></p>
                </div>
            </div>);
    }
}