import React from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import Select from "react-select";
import TestType from "../../types/TestType";
import domain from "../../Domain";
import Overlay from "../Overlay";
import {buildRow, showModalNoOutsideClick} from "../../util/FormatUtil";
import {TestManagementModal} from "../modals/TestManagementModal";
import Parser from "html-react-parser";
import {sweetalert} from '../../App';
import Validator from "../../validation/Validator";
import {Validators} from "../../validation/Validators";
import TestsAPI from "../../network/TestsAPI";
import SystemAPI from "../../network/SystemAPI";
import AdminAPI from "../../network/AdminAPI";

interface TestManagementState {
    selectedTest?;
    tests: TestType[]
    testDescription?
    name?
    shortName?
    isLabTest?:{label, value}
    showLoading?
    testOrderedCode?
    testOrderedDescription?
    testPerformedCode?
    testPerformedDescription?
    resultTypes:{label, value}[]
    allowedResults:{label, value}[]
    specimenSources:{label, value}[]
    allowedSources:{label, value}[]
}
export interface PostedTest extends TestType{
    ResultIDs:number[]
    SpecimenIDs:number[]
}

export class TestManagement extends React.Component<any, TestManagementState> {

    constructor(props) {
        super(props);
        this.state = {testDescription: "", selectedTest: {} as TestType, tests: [], resultTypes:[], allowedResults:[], specimenSources:[], allowedSources:[]}
        this.edit = this.edit.bind(this);
        this.getBoolSelect = this.getBoolSelect.bind(this);
    }


    componentDidMount() {
        this.setState({showLoading: true}, () => {
            SystemAPI.getAllTests().then(data => {
                this.setState({tests: data})
            })
            AdminAPI.getAllResultTypes().then(data => {
                this.setState({resultTypes: data} as any)
            })
            SystemAPI.getAllSpecimenSources().then(data => {
                this.setState({specimenSources: data, showLoading: false})
            })
        })
    }


    submitNewTest(test:TestType){
        this.setState({showLoading:true}, async () =>{
            try {
                let result = await TestsAPI.newTest(test);
                if(result.success){
                    sweetalert.fire({icon: 'success', title: '', text: 'Test saved'});
                    let tests = await SystemAPI.getAllTests();
                    this.setState({tests: tests, showLoading: false})
                }
                else{
                    sweetalert.fire({icon: 'error', title: '', text: result.reason});
                    this.setState({showLoading: false})
                }
            }catch (e) {
                console.log(e)
                this.setState({showLoading: false})
            }

        })
    }

    edit(){
        let validator = new Validator<TestManagementState>();
        validator.withSimpleValidation("isLabTest", Validators.requireNonNullValidator("Is a Lab Test"))
            .withValidation("name", Validators.requireNotBlankAndLength(500, "Name"))
            .withValidation("shortName", Validators.requireNotBlankAndLength(45, "Short Name"))
            .withValidation("testOrderedCode", Validators.requireNotBlankAndLength(500, "Test Ordered Code"))
            .withValidation("testOrderedDescription", Validators.requireNotBlankAndLength(500, "Test Ordered Description"))
            .withValidation("testPerformedCode", Validators.requireNotBlankAndLength(500, "Test Performed Code"))
            .withValidation("testPerformedDescription", Validators.requireNotBlankAndLength(500, "Test Performed Description"))
        let response = validator.validate(this.state);
        if(!response.success)
            return;
        this.setState({showLoading:true}, async () =>{
            try{
                let result = await TestsAPI.editTest({
                    ID: this.state.selectedTest.value,
                    Name:this.state.name,
                    ShortName:this.state.shortName,
                    IsLabTest:this.state.isLabTest.value,
                    ReportDescription:this.state.testDescription,
                    TestOrderedCode:this.state.testOrderedCode,
                    TestOrderedDescription:this.state.testOrderedDescription,
                    TestPerformedCode: this.state.testPerformedCode,
                    TestPerformedDescription: this.state.testPerformedDescription,
                    ResultIDs: this.state.allowedResults ?  this.state.allowedResults.map(a => a.value) : [],
                    SpecimenIDs:this.state.allowedSources ? this.state.allowedSources.map(a => a.value) : []
                } as PostedTest)
                if(result.success){
                    sweetalert.fire({icon: 'success', title: '', text: 'Test saved'});
                    this.setState({showLoading:false})
                }else{
                    sweetalert.fire({icon: 'error', title: '', text: result.reason});
                    this.setState({showLoading:false})
                }
            }
            catch (e) {
                console.log(e)
                this.setState({showLoading:false})
            }

        })
    }
    getBoolSelect(val){
        return val === 1 ? {label:"Yes", value:true} : {label:"No", value:false}
    }

    queryTest() {
        this.setState({showLoading:true}, async () => {
            try {
                let result = await TestsAPI.queryTest({ID: this.state.selectedTest.value})
                if (result.success) {
                    let test = result.test
                    this.setState({
                        name: test.Name ? test.Name : "",
                        shortName: test.ShortName ? test.ShortName : "",
                        testDescription: test.ReportDescription ? test.ReportDescription : "",
                        isLabTest: this.getBoolSelect(test.IsLabTest),
                        testPerformedCode: test.TestPerformedCode ? test.TestPerformedCode : "",
                        testPerformedDescription: test.TestPerformedDescription ? test.TestPerformedDescription : "",
                        testOrderedCode: test.TestOrderedCode ? test.TestOrderedCode : "",
                        testOrderedDescription: test.TestOrderedDescription ? test.TestOrderedDescription : "",
                        allowedSources: test.ValidSpecimenSources ? this.state.specimenSources.filter(s => test.ValidSpecimenSources.map(validSource => validSource.ID).includes(s.value)) : [],
                        allowedResults: test.ValidResults ? this.state.resultTypes.filter(s => test.ValidResults.map(validSource => validSource.ID).includes(s.value)) : [],
                        showLoading: false})
                }
            } catch (e) {
                console.log(e)
                this.setState({showLoading: false})
            }
        })
    }

    render() {
        console.log(this.state);
        return (<React.Fragment>
            <Overlay show_loading={this.state.showLoading}/>
            <TestManagementModal resultTypes={this.state.resultTypes} specimenSources={this.state.specimenSources} onSubmit={(test:TestType) => this.submitNewTest(test)}/>
            <div className="container-fluid ">
                <div className={"row"}>
                    <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10  col-12 pt-2">
                        <div className="card mb-2">
                            <div className="card-header verlag-bold">
                                <h3>Test Management</h3>
                            </div>
                            <div className="card-body">
                                {buildRow("Selected Test",
                                    <Select
                                        isSearchable={true}
                                        placeholder={"Please Select..."}
                                        noOptionsMessage={() => "No option"}
                                        value={this.state.selectedTest}
                                        onChange={(e) => this.setState({selectedTest: e}, () => this.queryTest())}
                                        className={"state_select"}
                                        options={this.state.tests}
                                    />)}
                                {buildRow("Name",
                                    <input className="form-control"
                                           maxLength={500}
                                           autoComplete="off"
                                           onChange={(e) => this.setState({name: e.target.value})}
                                           value={this.state.name}
                                    />)}
                                {buildRow("Short Name",
                                    <input className="form-control"
                                           maxLength={45}
                                           autoComplete="off"
                                           onChange={(e) => this.setState({shortName: e.target.value})}
                                           value={this.state.shortName}
                                    />)}
                                {buildRow("Is Lab Test?",
                                    <Select
                                        isSearchable={true}
                                        placeholder={"Please Select..."}
                                        noOptionsMessage={() => "No option"}
                                        value={this.state.isLabTest}
                                        onChange={(e) => this.setState({isLabTest: e})}
                                        className={"state_select"}
                                        options={[{label: "Yes", value: true}, {label: "No", value: false}]}
                                    />)}
                                {buildRow("Test Ordered Code (LOINC)",
                                    <input className="form-control"
                                           maxLength={500}
                                           autoComplete="off"
                                           onChange={(e) => this.setState({testOrderedCode: e.target.value})}
                                           value={this.state.testOrderedCode}
                                    />)}
                                {buildRow("Test Ordered Description (LOINC)",
                                    <input className="form-control"
                                           maxLength={500}
                                           autoComplete="off"
                                           onChange={(e) => this.setState({testOrderedDescription: e.target.value})}
                                           value={this.state.testOrderedDescription}
                                    />)}
                                {buildRow("Test Performed Code (LOINC)",
                                    <input className="form-control"
                                           maxLength={500}
                                           autoComplete="off"
                                           onChange={(e) => this.setState({testPerformedCode: e.target.value})}
                                           value={this.state.testPerformedCode}
                                    />)}
                                {buildRow("Test Performed Description (LOINC)",
                                    <input className="form-control"
                                           maxLength={500}
                                           autoComplete="off"
                                           onChange={(e) => this.setState({testPerformedDescription: e.target.value})}
                                           value={this.state.testPerformedDescription}
                                    />)}
                                {buildRow("Allowed Results",
                                    <Select
                                        isSearchable={true}
                                        placeholder={"Please Select..."}
                                        noOptionsMessage={() => "No option"}
                                        isMulti={true}
                                        value={this.state.allowedResults}
                                        onChange={(e) =>{
                                            console.log(e);
                                            this.setState({allowedResults: e as any})
                                        }}
                                        className={"state_select"}
                                        options={this.state.resultTypes}
                                    />)}
                                {buildRow("Allowed Specimen Source",
                                    <Select
                                        isSearchable={true}
                                        placeholder={"Please Select..."}
                                        noOptionsMessage={() => "No option"}
                                        isMulti={true}
                                        value={this.state.allowedSources}
                                        onChange={(e) =>{
                                            console.log(e);
                                            this.setState({allowedSources: e as any})
                                        }}
                                        className={"state_select"}
                                        options={this.state.specimenSources}
                                    />)}
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">Test Result Description</label>
                                    <div className="col-sm-12  p-0 m-0">
                                        <ReactQuill theme="snow" value={this.state.testDescription}
                                                    onChange={(val) => this.setState({testDescription: val})}/>
                                    </div>
                                </div>

                            </div>
                            <div className="card-footer">
                                <button className={"btn btn-outline-primary "} onClick={this.edit}>Save</button>
                                <button className="btn btn-outline-success float-right" onClick={() => showModalNoOutsideClick(TestManagementModal.ID)}>Create New</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"container border"}>
                <div className={"row"}>
                    <div className="col-12 text-center verlag-bold pt-2">
                        <h1>Sample Report</h1>
                    </div>
                    <div className={"col-12  pt-2"}>
                        {this.state.testDescription ? Parser(this.state.testDescription) : ""}
                    </div>
                    <div className="col-6  pt-2 text-center border border-info responsive-p verlag-bold">
                        <h3>My Facility</h3>
                        <p>1234 W. Fake St, Fake City, OK 74075</p>
                        <p>E-Mail:</p>
                        <p>help@mydomain.com</p>
                    </div>
                    <div className="col-6  pt-2 text-center border border-info responsive-p verlag-bold">
                        <h3>CLIA: 123456</h3>
                        <br/>
                        <p>Laboratory Director</p><p>
                        Dr. Little Stockings</p>
                    </div>
                </div>
            </div>
        </React.Fragment>)
    }
}