import React from "react";
import Select from "react-select";
import ReactQuill from "react-quill";
import {buildRow, showModalNoOutsideClick} from "../../util/FormatUtil";
import TestType from "../../types/TestType";
import {calculateAge, isBlank} from "../../util/ValidationUtil";
import {PostedTest} from "../admin/TestManagement";
import {sweetalert} from '../../App';
import Validator, {ValidationEntry} from "../../validation/Validator";
import {Validators} from "../../validation/Validators";

interface TestManagementState {
    testDescription?
    name?
    shortName?
    isLabTest?
    showLoading?
    testOrderedCode?
    testOrderedDescription?
    testPerformedCode?
    testPerformedDescription?
    allowedResults:{label, value}[]
    allowedSources:{label, value}[]
}

interface TestManagmentModalProps{
    onSubmit?:(test:PostedTest) => void
    specimenSources:{label, value}[]
    resultTypes:{label, value}[]
}
export class TestManagementModal extends React.Component<TestManagmentModalProps, TestManagementState> {
    public static ID = "testmanagement"

    constructor(props) {
        super(props);
        this.state = {
            testDescription:"",
            allowedResults:[],
            allowedSources:[]
        }
        this.clearFields = this.clearFields.bind(this)
    }

    public static display(){
        window["$"]("#" + TestManagementModal.ID).modal('show');
    }

    clearFields(){
        console.log("clearing")
        this.setState({
            testDescription: "",
            name: "",
            shortName: "",
            isLabTest: "",
            testOrderedCode: "",
            testOrderedDescription: "",
            testPerformedCode: "",
            testPerformedDescription: "",
            allowedResults:[],
            allowedSources:[]
        })
    }


    render() {
        return(
            <div className="modal fade form_modal" id={TestManagementModal.ID} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                        {/*<Modal_Header title={title}/>*/}
                        <div className="modal-header">
                            <h5 className="modal-title" id="result_modal_label">Test Management</h5>
                            <button type="button" className="close" data-dismiss="modal" onClick={() => this.clearFields()} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body ">

                            {buildRow("Name",
                                <input className="form-control"
                                       autoComplete="off"
                                       onChange={(e) => this.setState({name: e.target.value})}
                                       value={this.state.name}
                                />)}
                            {buildRow("Short Name",
                                <input className="form-control"
                                       autoComplete="off"
                                       onChange={(e) => this.setState({shortName: e.target.value})}
                                       value={this.state.shortName}
                                />)}
                            {buildRow("Is Lab Test?",
                                <Select
                                    isSearchable={true}
                                    placeholder={"Please Select..."}
                                    noOptionsMessage={() => "No option"}
                                    value={this.state.isLabTest}
                                    onChange={(e) => this.setState({isLabTest: e})}
                                    className={"state_select"}
                                    options={[{label: "Yes", value: true}, {label: "No", value: false}]}
                                />)}
                            {buildRow("Test Ordered Code (LOINC)",
                                <input className="form-control"
                                       autoComplete="off"
                                       onChange={(e) => this.setState({testOrderedCode: e.target.value})}
                                       value={this.state.testOrderedCode}
                                />)}
                            {buildRow("Test Ordered Description (LOINC)",
                                <input className="form-control"
                                       autoComplete="off"
                                       onChange={(e) => this.setState({testOrderedDescription: e.target.value})}
                                       value={this.state.testOrderedDescription}
                                />)}
                            {buildRow("Test Performed Code (LOINC)",
                                <input className="form-control"
                                       autoComplete="off"
                                       onChange={(e) => this.setState({testPerformedCode: e.target.value})}
                                       value={this.state.testPerformedCode}
                                />)}
                            {buildRow("Test Performed Description (LOINC)",
                                <input className="form-control"
                                       autoComplete="off"
                                       onChange={(e) => this.setState({testPerformedDescription: e.target.value})}
                                       value={this.state.testPerformedDescription}
                                />)}
                            {buildRow("Allowed Results",
                                <Select
                                    isSearchable={true}
                                    placeholder={"Please Select..."}
                                    noOptionsMessage={() => "No option"}
                                    isMulti={true}
                                    value={this.state.allowedResults}
                                    onChange={(e) =>{
                                        this.setState({allowedResults: e as any})
                                    }}
                                    className={"state_select"}
                                    options={this.props.resultTypes}
                                />)}
                            {buildRow("Allowed Specimen Source",
                                <Select
                                    isSearchable={true}
                                    placeholder={"Please Select..."}
                                    noOptionsMessage={() => "No option"}
                                    isMulti={true}
                                    value={this.state.allowedSources}
                                    onChange={(e) =>{
                                        this.setState({allowedSources: e as any})
                                    }}
                                    className={"state_select"}
                                    options={this.props.specimenSources}
                                />)}
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">Test Result Description</label>
                                <div className="col-sm-12  p-0 m-0">
                                    <ReactQuill theme="snow" value={this.state.testDescription}
                                                onChange={(val) => this.setState({testDescription: val})}/>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => {
                                    let formValidation = {
                                        Name: this.state.name,
                                        ShortName: this.state.shortName,
                                        IsLabTest: this.state.isLabTest,
                                        TestOrderedCode: this.state.testOrderedCode,
                                        TestOrderedDescription: this.state.testOrderedDescription,
                                        TestPerformedCode: this.state.testPerformedCode,
                                        TestPerformedDescription: this.state.testPerformedDescription,
                                    }

                                    let validator = new Validator<any>()
                                        .withValidation("Name", Validators.requireNotBlankAndLength(500))
                                        .withValidation("ShortName", Validators.requireNotBlankAndLength(45, "Short Name"))
                                        .withSimpleValidation("IsLabTest", Validators.requireNonNullValidator("Is Lab Test"))
                                        .withValidation("TestOrderedCode", Validators.requireNotBlankAndLength(500, "Test Ordered Code"))
                                        .withValidation("TestOrderedDescription", Validators.requireNotBlankAndLength(500, "Test Ordered Description"))
                                        .withValidation("TestPerformedCode", Validators.requireNotBlankAndLength(500, "Test Performed Code"))
                                        .withValidation("TestPerformedDescription", Validators.requireNotBlankAndLength(500, "Test Performed Description"))
                                    let validationResponse = validator.validate(formValidation);
                                    if(!validationResponse.success) {
                                        return sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
                                    }

                                    window['$']("#" + TestManagementModal.ID).modal('hide');
                                    this.props.onSubmit(
                                        {
                                            Name:this.state.name,
                                            ShortName:this.state.shortName,
                                            ReportDescription:this.state.testDescription,
                                            IsLabTest:this.state.isLabTest.value,
                                            TestOrderedCode:this.state.testOrderedCode,
                                            TestOrderedDescription:this.state.testOrderedDescription,
                                            TestPerformedCode: this.state.testPerformedCode,
                                            TestPerformedDescription: this.state.testPerformedDescription,
                                            ResultIDs:this.state.allowedResults ? this.state.allowedResults.map(a => a.value) : [],
                                            SpecimenIDs:this.state.allowedSources ? this.state.allowedSources.map(a => a.value) : []
                                        } as PostedTest)
                                    this.setState({testDescription:"", isLabTest:{label:"No", value:false}, name:"", shortName:"",
                                        testOrderedDescription:"", testOrderedCode:"", testPerformedDescription:"", testPerformedCode:"",
                                        allowedSources:[], allowedResults:[]
                                    })
                                }}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}