import React from "react";
import { sweetalert } from "../../App";
import { BatchManagementModal } from "../modals/BatchManagementModal";
import Overlay from "../Overlay";
import FilterCard, { FIELD_TYPE, ReactSelect } from "../FilterCard";
import SimpleTable from "../tables/SimpleTable";
import {hideModal, parseDate, getPageOptions, slicePages, getNumPages, showModalNoOutsideClick} from "../../util/FormatUtil";
import Select from "react-select";
import Batch from "../../types/Batch";
import { BatchSelect } from "./selects/BatchSelect";
import TestsAPI from "../../network/TestsAPI";
import BatchesAPI from "../../network/BatchesAPI";
import NetworkUtil from "../../network/NetworkUtil";
import PaginationTool from "../PaginationTool";

interface BatchManagementState {
  showLoading: boolean;
  // selected for viewing in Modal
  selectedBatch: Batch;
  // Query Results
  batchQueryResults: Batch[];
  // Table Data
  tableData: {
    BatchNum: string;
    TestType: any;
    ResultApprovedDate: Date;
    Notes: string;
    ExportUploadedBy: string;
    MachineData: any;
  }[];
  // FilterCard fields
  filter?: {
    TestType: string;
    ResultApprovedDate: Date;
  };
  // Table sorting
  direction?: string;
  // Pagination
  page_options: ReactSelect[];
  selected_page?;
  // Dropdown options
  testTypeOptions: { label: string; value: number }[];
  filteredBatch: { label; value: Batch }[];
}

export class BatchManagement extends React.Component<
  any,
  BatchManagementState
> {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: true,
      selectedBatch: null,
      batchQueryResults: [] as any,
      tableData: [] as any,
      filter: {
        TestType: "",
        ResultApprovedDate: null,
      },
      filteredBatch: [],
      direction: "asc",
      page_options: [{ label: "1", value: "1" }],
      selected_page: { label: 1, value: 1 },
      testTypeOptions: [{ label: "", value: -1 }],
    };

    this.queryBatchData = this.queryBatchData.bind(this);
    this.createOrModifyBatch = this.createOrModifyBatch.bind(this);
    this.handleExportToCSV = this.handleExportToCSV.bind(this);
    this.renderBatchFilterFields = this.renderBatchFilterFields.bind(this);
    this.sortTable = this.sortTable.bind(this);
    this.changePage = this.changePage.bind(this);
  }

  componentDidMount() {
    this.setState({ showLoading: true }, async () => {
      let testResponse = await TestsAPI.getAllTestDetails();
      this.setState({
        testTypeOptions: testResponse.tests.map((test) => {
          return { label: test.Name, value: test.ID };
        }),
        showLoading: false,
      });
    });
  }

  queryBatchData(page: number) {
    this.setState({ showLoading: true }, async () => {
      console.log(this.state);
      let results = await BatchesAPI.getFilteredBatches({
        filter: {
          BatchNum: this.state.filteredBatch
            ? this.state.filteredBatch.map((b) => b.value.ID)
            : null,
          TestType: this.state.filter.TestType,
          ResultApprovedDate: this.state.filter.ResultApprovedDate,
        },
      });
      this.setState({ showLoading: false });
      if (!results.success) {
        this.setState({ showLoading: false });
        sweetalert.fire({
          title: "",
          text: "Unable to filter Batches data at this time",
          icon: "error",
        });
        return;
      }

      let itemsPerPage = 25;
      let numberOfPages = getNumPages(results.response, itemsPerPage);
      this.setState({
        showLoading: false,
        tableData: slicePages(results.response, page, itemsPerPage),
        batchQueryResults: results.response,
        page_options: getPageOptions(numberOfPages),
      });
    });
  }

  createOrModifyBatch(batch) {
    this.setState({ showLoading: true }, async () => {
      let response = await (!batch.ID
        ? BatchesAPI.createBatch(batch)
        : BatchesAPI.editBatch(batch));
      if (!response.success) {
        sweetalert.fire({ title: "", text: response.reason, icon: "error" });
      } else {
        this.setState({ selectedBatch: null });
        sweetalert
          .fire({ title: "", text: "Batch saved", icon: "success" })
          .then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
      }
    });
  }

  handleExportToCSV() {
    this.setState({ showLoading: true }, async () => {
      await NetworkUtil.downloadCSV(
        "/api/admin/lab/batch/csvExport",
        "BatchManagementData.xlsx",
        { batchData: this.state.batchQueryResults }
      );
      this.setState({ showLoading: false });
    });
  }

  renderBatchFilterFields() {
    return (
      <React.Fragment>
        <BatchSelect
          finishedLoading={() => this.setState({ showLoading: false })}
          multi={true}
          onSelected={(batch: { label; value: Batch }[]) =>
            this.setState({ filteredBatch: batch })
          }
        />
        <FilterCard
          fields={[
            {
              label: "Test Type",
              key: "TestType",
              type: FIELD_TYPE.SELECT,
              options: this.state.testTypeOptions,
              isMapped: true,
              isMulti: true,
              textType: "text",
            },
            {
              label: "Result Approved Date",
              key: "ResultApprovedDate",
              type: FIELD_TYPE.DATE,
            },
          ]}
          filterChanged={(e) => this.setState({ filter: e })}
        />
      </React.Fragment>
    );
  }

  sortTable(hdr, tableData) {
    let rows = tableData;
    //console.log('rows ', JSON.stringify(rows))
    if (rows === null) return;

    rows.sort((a, b) => {
      let first = typeof a[hdr] === "string" ? a[hdr].toLowerCase() : a[hdr];
      let second = typeof b[hdr] === "string" ? b[hdr].toLowerCase() : b[hdr];
      // equal items sort equally
      if (first === second) {
        return 0;
      }
      // nulls sort after anything else
      else if (first === null || first === "") {
        return 1;
      } else if (second === null || second === "") {
        return -1;
      }
      // otherwise, if we're ascending, lowest sorts first
      else if (this.state.direction === "asc") {
        return first < second ? -1 : 1;
      }
      // if descending, highest sorts first
      else {
        return first < second ? 1 : -1;
      }
    });
    this.setState({
      tableData: rows,
      direction: this.state.direction === "asc" ? "desc" : "asc",
    });
  }

  changePage(page: number) {
    let allBatches = this.state.batchQueryResults;
    let returnData = slicePages(allBatches, page, 25);
    this.setState({ tableData: returnData });
  }

  render() {
    return (
      <React.Fragment>
        <PaginationTool />
        <Overlay show_loading={this.state.showLoading} />
        <BatchManagementModal
          selectedBatch={
            this.state.selectedBatch ? this.state.selectedBatch : ({} as Batch)
          }
          onSubmit={(batch) => {
            hideModal(BatchManagementModal.ID);
            this.createOrModifyBatch(batch);
          }}
        />
        <div className="container-fluid">
          <div className={"row"}>
            <div className="col-12 col-md-12 col-lg-8 col-xl-5 pt-2">
              {/*<div className="col-xl-6 col-lg-6 col-md-8 col-sm-10  col-12 pt-2">*/}
              <div className="card mb-2">
                <div className="card-header verlag-bold">
                  <h3>Batch Management</h3>
                </div>
                <div className="card-body">
                  {this.renderBatchFilterFields()}
                </div>
                <div className="card-footer">
                  <button
                    className={"btn btn-outline-primary "}
                    onClick={() =>
                      this.setState(
                        { selected_page: { label: 1, value: 1 } },
                        () =>
                          this.queryBatchData(this.state.selected_page.value)
                      )
                    }
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-outline-success float-right"
                    onClick={() =>
                      this.setState({ selectedBatch: {} as Batch }, () =>
                        showModalNoOutsideClick(BatchManagementModal.ID)
                      )
                    }
                  >
                    Create New
                  </button>
                </div>
              </div>
            </div>

            {this.state.batchQueryResults &&
              this.state.batchQueryResults.length > 0 && (
                <div className="col-12 col-md-12 col-lg-12 pt-2">
                  <div className="card mt-2 mb-5">
                    <div className="card-header verlag-bold stickToTop">
                      <h3>
                        Batch Data
                        <section className="tableHeaderSection float-md-right ">
                          <h3 className={"float-md-right"}>
                            Total: {this.state.batchQueryResults.length}
                          </h3>
                          <h4 className="d-inline-block float-right align-middle pr-2 ml-5">
                            Page{" "}
                          </h4>
                          <div className=" align-middle float-right pages ">
                            <Select
                              isSearchable={true}
                              placeholder={"1"}
                              noOptionsMessage={() => "No option"}
                              value={this.state.selected_page}
                              onChange={(e: ReactSelect) =>
                                this.setState({ selected_page: e }, () =>
                                  this.changePage(e.value)
                                )
                              }
                              className={"state_select"}
                              options={this.state.page_options}
                            />
                          </div>
                        </section>
                        <button
                          className={"btn btn-outline-primary ml-3"}
                          onClick={() => this.handleExportToCSV()}
                        >
                          Export to CSV
                        </button>
                      </h3>
                    </div>
                    <div className="card-body p-0 m-0 table-responsive-md">
                      <SimpleTable
                        columns={[
                          {
                            label: "Batch Number",
                            key: "BatchNum",
                            rawFormat: (val) => {
                              return (
                                <a
                                  href={"#!"}
                                  onClick={() => {
                                    this.setState({ selectedBatch: val }, () =>
                                    showModalNoOutsideClick(BatchManagementModal.ID)
                                    );
                                  }}
                                >
                                  {val.BatchNum}
                                </a>
                              );
                            },
                            headerOnClick: () =>
                              this.sortTable("BatchNum", this.state.tableData),
                            popoverText: "Click to sort by Batch Number",
                          },
                          {
                            label: "Test Type",
                            key: "TestType",
                            headerOnClick: () =>
                              this.sortTable("TestType", this.state.tableData),
                            popoverText: "Click to sort by Test Type",
                          },
                          {
                            label: "Result Approved Date",
                            key: "ResultApprovedDate",
                            headerOnClick: () =>
                              this.sortTable(
                                "ResultApprovedDate",
                                this.state.tableData
                              ),
                            rawFormat: (val) => {
                              return parseDate(val.ResultApprovedDate);
                            },
                            popoverText:
                              "Click to sort by Result Approved Date",
                          },
                          {
                            label: "Export Uploaded By",
                            key: "ExportUploadedBy",
                            headerOnClick: () =>
                              this.sortTable(
                                "ExportUploadedBy",
                                this.state.tableData
                              ),
                            popoverText: "Click to sort by Result Approved By",
                          },
                          {
                            label: "Notes",
                            key: "Notes",
                            headerOnClick: () =>
                              this.sortTable("Notes", this.state.tableData),
                            popoverText: "Click to sort by Notes",
                          },
                        ]}
                        table_data={this.state.tableData}
                      />
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
