import React from "react";

export default class LoggedOut extends React.Component<any, any>{
    render() {
        return <React.Fragment>
            <div className={"container-fluid p-0 m-0 pt-2 min-vh-100"}>
                <div className={"row"}>
                    <div className={"offset-4 col-4"}>
                        <p>You have been logged out. To log in again, press the button below.</p>
                        <div className={"row"}>
                            <div className={"offset-4 col-4"}>
                                <a href={"/admin"}><button className={"btn btn-outline-primary"}>Log In</button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>;
    }
}