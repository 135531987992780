import FilterCard, {FIELD_TYPE} from "./FilterCard";
import React from "react";

interface GuardianProps {
    age: number
    onChange:(e:GuardianData) => void
    shouldDisplay
}

interface GuardianData{
    GuardianFirstName:string
    GuardianLastName:string
}

interface GuardianState {

}

export class GuardianForm extends React.Component<GuardianProps, GuardianState> {

    constructor(props) {
        super(props);

    }

    render() {
        // console.log('age in guardian', this.props.age)
        if (this.props.age < 18 && this.props.shouldDisplay) {
            return (
                <div className="card mt-2 ">
                    <div className="card-header verlag-bold">
                        <h3>Guardian Information</h3>
                    </div>
                    <div className="card-body">
                        <p>Individuals below the age of 18 must have a guardian present during their appointment.</p>
                        <FilterCard
                            fields={[
                                {label: "Guardian First Name", "key": "GuardianFirstName", type: FIELD_TYPE.TEXT, maxLength: 50},
                                {label: "Guardian Last Name", "key": "GuardianLastName", type: FIELD_TYPE.TEXT, maxLength: 50}
                            ]} filterChanged={(e) => this.props.onChange(e)}
                        />
                    </div>
                </div>
            )
        }
        return null;
    }
}