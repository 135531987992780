import React from "react";
import domain from "../Domain";
import ReleaseConsent from "./modals/ReleaseConsent";
import {sweetalert} from "../App";
import SystemAPI from "../network/SystemAPI";

interface ReleaseFormState{
    release?:any
    entity?:{ID:number,FacilityID:number,Name:string,AccessCode:string}
    consentEntities: {ID:number,FacilityID:number,Name:string,AccessCode:string}[]
    accepted:boolean
}
interface ReleaseProps{
    consenting?:any
    releaseChanged:(release:any)=>void
}

export default class ReleaseForm extends React.Component<ReleaseProps,ReleaseFormState> {
    constructor(props) {
        super(props);
        this.state = {
            consentEntities: [],
            accepted: false
        };
        this.handleBlur = this.handleBlur.bind(this);
        this.onConfirmation = this.onConfirmation.bind(this);
    }

    componentDidMount() {
        // lets grab our Consent Entites to match against AccessCode Input in <Release />
            SystemAPI.getConsentEntities().then(data => {
                this.setState({consentEntities: data})
            })
            .catch(e => { console.log(e) })

        // display readonly AccessCode if user has already submitted consent
        if(this.props.consenting){
            this.setState({
                entity: this.props.consenting,
                accepted: true,
                release: this.props.consenting.AccessCode
            });

            Array.from(document.querySelectorAll('input')).forEach(input => {
                if(input.name == "accessCodeInput") return input.disabled = true;
            });
        }

    }

    handleBlur(e){
        let accessCode =  e.target.value;
        let consentEntity = this.state.consentEntities.find(entity => entity.AccessCode === accessCode)
        if(accessCode && accessCode != "" && consentEntity){
            this.setState({entity: consentEntity}, ()=>{
                if(this.state.entity && this.state.entity.AccessCode && this.state.entity.AccessCode !== ""){
                    // @ts-ignore
                    window.$("#" + ReleaseConsent.ID).modal('show');
                }
            });
        }
        if(accessCode && accessCode != "" && !consentEntity){
            sweetalert.fire({icon: "warning", text: "Invalid Access Code.", title: ""})
        }
    }

    onConfirmation(didAccept){
        if(!didAccept) {
            this.setState({accepted:false})
            Array.from(document.querySelectorAll('input')).forEach(input => {
                if(input.name == "accessCodeInput") return input.value = "";
            });
            this.props.releaseChanged("");
        } else {
            this.setState({accepted:true})
            Array.from(document.querySelectorAll('input')).forEach(input => {
                if(input.name == "accessCodeInput") return input.disabled = true;
            });
            this.props.releaseChanged(this.state.entity.ID);
        }
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <React.Fragment>
                <ReleaseConsent onConfirmation={(didAccept) => this.onConfirmation(didAccept)}
                                entity={this.state.entity ? this.state.entity : {ID:null,FacilityID:null,Name:"",AccessCode:""}}/>
                <div className="card mt-2">
                    <div className="card-header verlag-bold">
                        <h3>Optional Release of Information</h3>
                    </div>
                    <div className="card-body">
                        <p>
                            If you are an Employee and have been given an Access Code by your Employer, you may authorize IMMYTech to disclose your test result directly to your Employer.
                        </p>
                        <p>
                            Authorization is not required.  You cannot be denied testing based on a refusal or failure to sign this authorization form.
                        </p>
                        <b>WHAT INFORMATION CAN BE DISCLOSED?</b>
                        <p>
                            Any lab results related to the novel coronavirus (SARS-CoV-2 or COVID-19).
                        </p>
                        <b>WHAT IS THE PURPOSE FOR DISCLOSING?</b>
                        <p>
                            To notify the Employer that the Individual works at, in order that the Employer and the Oklahoma State Department of Health to take measures that may slow the spread of the novel coronavirus (SARS-CoV-2 or COVID-19).
                        </p>
                        <div className="form-group row">
                            <label  className="col-sm-4 col-form-label">Access Code</label>
                            <div className="col-sm-8  p-0 m-0">
                                <input
                                    type={"search"}
                                    name={"accessCodeInput"}
                                    min={10}
                                    max={10}
                                    className={'form-control'}
                                    value={this.state.release}
                                    autoComplete="off"
                                    onBlur={(e) =>{
                                        this.handleBlur(e)
                                    }}
                                />
                                {
                                    this.state.entity &&
                                    this.state.accepted &&
                                    <div className="alert alert-primary m-0" role="alert">
                                        <p className="primary-black m-0">
                                            You have authorized {this.state.entity.Name} to access to your test results.
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}