import React, {useState, useEffect} from "react";
import ReactToPrint from "react-to-print";
import Overlay from "../Overlay";
import {sweetalert} from "../../App";
import {BatchSelect} from "./selects/BatchSelect";
import Batch from "../../types/Batch";
import BatchesAPI from "../../network/BatchesAPI";
import {WellSlot} from "../../types/WellSlot";
import {WellPlateMap} from "./WellPlateMap";

export interface WellMapData{
    WellSlot,
    SpecimenID,
    InternalSpecimenID,
    BatchNum,
    ProcessedDate,
    FacilityAbbreviation,
    RerunCount
}

interface WellPlateReportState {
    show_loading: boolean
    allData: WellMapData[],
    slotMap: WellSlot[],
    selectedBatch: Batch,
}

export class WellPlateReport extends React.Component<any, WellPlateReportState> {
    private componentRef: any;

    constructor(props) {
        super(props);
        this.state = {
            show_loading: false,
            allData: [],
            slotMap: [],
            selectedBatch: null
        };
    }

    async getData(batchNum) {
        this.setState({show_loading:true})
        try {
            let response = await BatchesAPI.getBatchMap(batchNum);
            this.setState({
                allData: response.data,
                slotMap: response.wellSlots,
                show_loading: false
            })
        } catch (error) {
            console.error('error in getData ', error)
            sweetalert.fire({icon: 'error', title: '', text: "Error retreiving data"});
            this.setState({show_loading: false})
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <Overlay show_loading={this.state.show_loading}/>
                    <div className="container-fluid">
                        <div className={"row"}>
                            <div className="col-12 col-md-12 offset-xl-3 col-xl-6 offset-lg-2 col-lg-8  pt-2">
                                <div className="card mb-2">
                                    <div className="card-header verlag-bold">
                                        <h3>Well Plate</h3>
                                    </div>
                                    <div className="card-body">
                                        <BatchSelect onSelected={(e: { label, value: Batch }) =>
                                            this.setState({show_loading: true, selectedBatch:e.value}, () => this.getData(e.value.ID))}/>
                                    </div>
                                    {this.state.selectedBatch &&
                                    <div className="card-footer">
                                        <ReactToPrint
                                            trigger={() => <button className={"btn btn-outline-success"}
                                                                   style={{float: 'right'}}>Print WellPlate</button>}
                                            content={() => this.componentRef}/>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.selectedBatch &&
                <>
                    <div className={"mt-3"} style={{borderTop: '2px solid black'}}/>
                    <WellPlateMap ref={el => (this.componentRef = el)} plateData={this.state.allData}
                                  batchNum={this.state.selectedBatch ? this.state.selectedBatch.BatchNum : ""}
                                  slotMap={this.state.slotMap}/></>
                }
            </React.Fragment>
        )
    }
}