import NetworkUtil from "./NetworkUtil";
import {ServerResponse} from "../types/ServerResponse";
import {ResultType} from "../types/ResultType";
import Banner from "../types/Banner";
import Facility from "../types/Facility";
import PodSched, { PodSchedForSelect } from "../types/PodSched";

export default class AdminAPI {

    public static async getPermissions():Promise<{isGlobalAdminorLab:boolean, isGlobal:boolean, isAdmin:boolean}>{
        return NetworkUtil.makeGet("/api/admin/permissions");
    }

    public static async getAllResultTypes():Promise<{label:string, value:number, description:string}[]>{
        return NetworkUtil.makeGet("/api/admin/resulttypes");
    }

    public static async getAllFacilities():Promise<{authorizedFacilities:Facility[]}>{
        return NetworkUtil.makeGet("/api/admin/getFacilities")
    }

    public static async getFacilitiesWithAddress():Promise<{label:string, value:number}[]>{
        return NetworkUtil.makeGet("/api/admin/facilities")
    }

    public static async getFacilitiesGroups():Promise<{label:string, value:string}>{
        return NetworkUtil.makeGet("/api/admin/facilityGroups")
    }

    public static async getBarcodeStyle():Promise<{isOneD:boolean, success:boolean, reason:string}>{
        return NetworkUtil.makeGet("/api/admin/settings/tableBarcodeStyle");
    }

    public static async getTablePlatingDirection():Promise<{isHorizontal:boolean, success:boolean, reason:string}>{
        return NetworkUtil.makeGet("/api/admin/settings/tablePlatingDirection");
    }

    public static async getAllPods(includeData:boolean=false, active:boolean=false):Promise<{success:boolean,reason:string,data:{label:string,value:(number | PodSchedForSelect)}[]}>{
        return NetworkUtil.makePost("/api/admin/pods/filter", {includedata: includeData, active: active});
    }

    public static async getSpecimenLabels(body):Promise<{success:boolean, idList:[]}>{
        return NetworkUtil.makePost("/api/admin/specimen", body)
    }

    public static async getAuthenticated(body):Promise<{success:boolean, reason:string, user:any, groups:string[]}>{
        return NetworkUtil.makePost("/api/admin/authenticated", body)
    }

}