import React, {FC} from "react";
import Overlay from "./Overlay";
import FilterCard, {FIELD_TYPE} from "./FilterCard";
import ReactToPrint from "react-to-print";
import domain from "./../Domain";
import moment from "moment";
import Select from "react-select";
import {parseDate} from "./../util/FormatUtil";
import SimpleTable from "./tables/SimpleTable";
import {getToken} from "../util/CaptchaUtil";

interface RetrieveConfirmationState {
    filter: object
    show_loading: boolean
    pCollectDate: ''
    pFirstName: ''
    pLastName: ''
    pDOB: ''
    dataAList: any
}

export class RetrieveConfirmation extends React.Component<any, RetrieveConfirmationState> {
    private componentRef: any;

    constructor(props) {
        super(props);
        this.state = {
            filter: {},
            show_loading: false,
            pCollectDate: '',
            pFirstName: '',
            pLastName: '',
            pDOB: '',
            dataAList: []
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.clear = this.clear.bind(this);
        this.assignClearState = this.assignClearState.bind(this);
    }

    componentDidMount(): void {

    }

    clear() {
        this.setState({
            filter: {pCollectDate: '', pFirstName: '', pLastName: '', pDOB: ''},
            show_loading: false,
            dataAList: []
        });
    }

    clearFilterState: () => void = null;

    assignClearState(func) {
        this.clearFilterState = func;
    }


    private async onSubmit(e) {
        if (this.state.filter['pFirstName'] === '' || this.state.filter['pLastName'] === '' ||
            this.state.filter['pDOB'] === '' || this.state.filter['pCollectDate'] === '') {
            alert('Please fill in all the fields!')
        }
        if (!moment(this.state.filter['pDOB'], ["MM/DD/YYYY", "MM-DD-YYYY"], true).isValid()
            || !moment(this.state.filter['pCollectDate'], ["MM/DD/YYYY", "MM-DD-YYYY"], true).isValid()) {
            alert("Please validate that DOB and Test Date is in MM-DD-YYYY format. Example: 01-01-1990");
            this.setState({show_loading: false})
            return
        }
        let token: string = await getToken();
        this.setState({
            show_loading: true,
            pFirstName: this.state.filter['pFirstName'],
            pLastName: this.state.filter['pLastName'],
            pDOB: this.state.filter['pDOB'],
            pCollectDate: this.state.filter['pCollectDate']
        }, () => {
            console.log("First Name: " + this.state.filter['pFirstName'] + " Last Name: " + this.state.filter['pLastName']
                + " DOB: " + this.state.filter['pDOB'] + " Test Date: " + this.state.filter['pCollectDate']);
            fetch(domain + "/api/submission/retrieveConfirmation", {
                method: "POST",
                body: JSON.stringify({
                    pFirstName: this.state.filter['pFirstName'],
                    pLastName: this.state.filter['pLastName'],
                    pDOB: this.state.filter['pDOB'],
                    pCollectDate: this.state.filter['pCollectDate'],
                    token: token
                }),
                headers: {'Content-Type': 'application/json'}
            }).then(res => res.json()).then(data => {
                this.setState({dataAList: data, show_loading: false});
                console.log(this.state.dataAList);
            });
        })
    }

    render() {
        return (
            <React.Fragment>
                <Overlay show_loading={this.state.show_loading}/>
                <div className="container-fluid  ">
                    <div className={"row"}>
                        <div className="col-12 col-md-12 offset-xl-3 col-xl-6 offset-lg-2 col-lg-8  pt-2">
                            <div className="card mb-2">
                                <div className="card-header verlag-bold">
                                    <h3>Retrieve Confirmation Code</h3>
                                </div>
                                <div className="card-body">

                                    <FilterCard passClearStateFunc={this.assignClearState}
                                                fields={[
                                                    {
                                                        label: "Patient First Name",
                                                        "key": "pFirstName",
                                                        type: FIELD_TYPE.TEXT,
                                                        placeholder: "Type Your First Name",
                                                    },
                                                    {
                                                        label: "Patient Last Name",
                                                        "key": "pLastName",
                                                        type: FIELD_TYPE.TEXT,
                                                        placeholder: "Type Your Last Name",
                                                    },
                                                    {
                                                        label: "Patient DOB",
                                                        "key": "pDOB",
                                                        type: FIELD_TYPE.TEXT,
                                                        placeholder: "MM-DD-YYYY",
                                                    },
                                                    {
                                                        label: "Test Date",
                                                        "key": "pCollectDate",
                                                        type: FIELD_TYPE.TEXT,
                                                        placeholder: "MM-DD-YYYY",
                                                    },
                                                ]} filterChanged={(filter) => this.setState({filter: filter})}/>

                                </div>

                                <div className="card-footer">
                                    <button
                                        className="btn btn-outline-primary float-left"
                                        onClick={(e) => this.onSubmit(e)}>
                                        Submit
                                    </button>

                                    <button className="btn btn-outline-danger float-right" onClick={() => {
                                        this.clear();
                                        this.clearFilterState();
                                    }}>Clear
                                    </button>
                                </div>
                            </div>
                            <div className={"container"} ref={el => (this.componentRef = el)}>
                                <br/><br/>

                                {this.state.dataAList.length > 0 &&
                                <div className="card-body p-0 m-0 text-center" style={{fontSize: '12px'}}>
                                    <SimpleTable ref={el => (this.componentRef = el)} columns={[
                                        {label: "Confirmation Code", key: "ReqNum"},
                                        {label: "Collection Date", key: "CollectionDate", formatFunc:parseDate},
                                        {label: "Test Type", key: "Name"}
                                    ]} table_data={this.state.dataAList}/>
                                    <br/><br/><br/>
                                </div>

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}