import NetworkUtil from "./NetworkUtil"
import {ServerResponse} from "../types/ServerResponse";

export default class UsersAPI {
    public static async getOktaCustomerGroups():Promise<{success:boolean,reason:string,oktaGroups:{label,value,oktaID}[]}>{
        return NetworkUtil.makeGet("/api/admin/okta/customerGroups");
    }

    public static async getValidGroups():Promise<{success:boolean,reason:string,groups:{id,description}[]}>{
        return NetworkUtil.makeGet("/api/admin/okta/validgroups");
    }

    public static async createUserAccount(user:{firstName,lastName,email,login,facilityGroup,groupIds}):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/okta/user/create", user);
    }

    public static async deactivateUserAccount(userid:string):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/okta/user/deactivate",{id:userid})
    }

    public static async adjustUserPermissions(userid:string,permissions:string[]):Promise<{success:boolean,reason:string}>{
        return NetworkUtil.makePost("/api/admin/okta/user/permissions",{id:userid,permissions:permissions})
    }

    public static async reactivateUserAccount(userid:string):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/okta/user/reactivate",{id:userid});
    }

    public static async resetUserPassword(userid:string):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/okta/user/resetPassword",{id:userid});
    }

    public static async filterUsers(filter:{filter:{FirstName?,LastName?,Email?,Permissions?,FacilityGroup?,Status?}}):Promise<{
        success:boolean,
        num_pages:number,
        users:{combinedName,customerID,email,facilityGroupID,id,firstName,lastName,permissions,status}[]
    }>{
        return NetworkUtil.makePost("/api/admin/okta/group/users", filter);
    }
}