import React, {LegacyRef} from 'react';
import domain from "../../Domain";
import FilterCard, {FIELD_TYPE, ReactSelect} from "../FilterCard";
import Form from "../../types/Form";
import {getDateOrNull, getPageOptions, getTwelveHourAMPMTime, parseDate} from "../../util/FormatUtil";
import Overlay from "../Overlay";
import moment from "moment-timezone";
import SimpleTable from "../tables/SimpleTable";
import {sweetalert} from "../../App";
import PodSched from "../../types/PodSched";
import {Column} from "../tables/TableBase";
import {Sorter, TableOrder} from "../../sorting/Sorter";
import PodAPI from "../../network/PodAPI";



interface AdminHomePageState {
    showLoading?:boolean
    tableData: any[]
    totalPods?: number
    direction?: TableOrder;
}

export default class AdminHomePage extends React.Component<any, AdminHomePageState> {

    constructor(props) {
        super(props);
        this.state = {
            showLoading:false,
            tableData: [],
            totalPods: 0,
            direction: 'asc',
        };
    }

    componentDidMount(): void {
        this.setState({showLoading: true}, async () => {
            let response = await PodAPI.searchPodsForHomePage();
            // if(response.table_data.length === 0){
            //     sweetalert.fire({icon: 'error', title: '', text: "No pods were returned"});
            // }
            this.setState({
                showLoading: false,
                tableData: response.table_data,
                totalPods: response.total,
            })
        })
    }

    useSorter(col:Column){
        let sorter = new Sorter<PodSched>()
            .withRawSorterFunc("Facility.FacilityName", (a, b) =>{
                let aFacility = a.Facility.FacilityName;
                let bFacility = b.Facility.FacilityName;
                return aFacility.localeCompare(bFacility);
            })
            .withRawSorterFunc("BreakTimes", (a, b) =>{
                let aBreakTimes = a.PodBreaks.length > 0 ? 1 : 0;
                let bBreakTimes = b.PodBreaks.length > 0 ? 1 : 0;
                return aBreakTimes - bBreakTimes;
            })
            .withRawSorterFunc("Time", (a, b) =>{
                return moment(a.StartTime, 'hh:mm a').diff(moment(b.StartTime, 'hh:mm a'));
            })

        this.setState({tableData: sorter.sortByKey(this.state.tableData, col.key as keyof PodSched,this.state.direction), direction: this.state.direction === 'asc' ? 'desc' : 'asc'});
    }

    getNextWeek(){
        let nextWeek = moment().add(7, 'days').format('MM/DD/YYYY');
        return nextWeek;
    }


    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let today = moment().format('MM/DD/YYYY');
        let nextweek = this.getNextWeek();
        return (<React.Fragment>
            <Overlay show_loading={this.state.showLoading}/>
            <div className="container-fluid">
                <div className={'row'}>
                    {this.state.totalPods > 0 ?
                        <div className="col-12 pt-2">
                            <div className="card mt-2">
                                <div className="card-header verlag-bold">
                                    <div className="row">
                                        <div className="col-4">
                                            <h3>Weekly Pods</h3>
                                        </div>
                                        <div className="col-4 text-center">
                                            <img src={'https://storage.googleapis.com/streamline-bucket/ITGStar.png'}
                                                 style={{ height: '40px'}}
                                                 alt={"logo"}/>
                                        </div>
                                        <div className="col-4">
                                            <h3 className={'float-right'}>{today} - {nextweek}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body p-0 m-0 text-center d-md-block">
                                    <SimpleTable
                                        table_data={this.state.tableData}
                                        columns={[
                                            {label: "Pod #", key: "ID"},
                                            {label: "Date", key: "TestDate", formatFunc: parseDate},
                                            {
                                                label: "Time", key: "Time",
                                                rawFormat: (val) => {
                                                    return <div>{getTwelveHourAMPMTime(val.StartTime)} - {getTwelveHourAMPMTime(val.EndTime)}</div>
                                                }
                                            },
                                            {label: "Service", key: "Service"},
                                            {label: "Facility", key: "Facility.FacilityName"},
                                            {
                                                label: "Available Appts", key: "Available",
                                                rawFormat: (val) => {
                                                    return <div>{val.Available} / {val.Total}</div>
                                                }
                                            },
                                            {
                                                label: 'Checked In/Booked', key: "CheckedIn",
                                                rawFormat: (val)=>{
                                                    return <div>{val.CheckedIn} / {val.Booked}</div>
                                                }
                                            }
                                        ]}
                                        columnClickedCallback={(col => {
                                            this.useSorter(col);
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                        :
                        <div className="col-12 pt-2">
                            <div className="card mt-2">
                                <div className="card-header verlag-bold">
                                    <div className="row">
                                        <div className="col-4">
                                            <h3>No pods showing for this week</h3>
                                        </div>
                                        <div className="col-4 text-center">
                                            <img src={'https://storage.googleapis.com/streamline-bucket/ITGStar.png'}
                                                 style={{ height: '40px'}}
                                                 alt={"logo"}/>
                                        </div>
                                        <div className="col-4">
                                            <h3 className={'float-right'}>{today} - {nextweek}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </React.Fragment>)
    }
}