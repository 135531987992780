import React from "react";
import {buildRow, parseDate, showModalNoOutsideClick} from "../../util/FormatUtil";
import Select from "react-select";
import PodSched, {PodBreak} from "../../types/PodSched";
import {isEmptyObject} from "jquery";
import moment from "moment";
import DatePicker from 'react-datepicker'
import {sweetalert} from "../../App";
import Validator, {ValidationEntry} from "../../validation/Validator";
import {Validators} from "../../validation/Validators";
import {calculateAge} from "../../util/ValidationUtil";
import domain from "../../Domain";
import System from "@zxing/library/es2015/core/util/System";
import SystemAPI from "../../network/SystemAPI";
import AdminAPI from "../../network/AdminAPI";

interface PodModalProps{
    selectedPod
    facilties
    services
    onSubmit:(pod:PodSched, podBreaks:PodBreak[]) => void
}

interface PodModalState{
    podID?
    facility?
    isActive?
    service?
    isPrivate?
    accessCode?
    date?
    startTime?
    endTime?
    nickName?
    breaks:{StartTime:Date, EndTime:Date}[]
    breakStartSelector?
    breakEndSelector?
    factor?
    allPods?:{label:string,value:number}[]
    podLabel?
}

export class PodManagementModal extends React.Component<PodModalProps, PodModalState>{
    public static ID = "podedit"

    constructor(props) {
        super(props);
        this.state = {breaks:[], breakEndSelector:"", breakStartSelector:"", accessCode:"", nickName:""};
        this.deleteBreak = this.deleteBreak.bind(this);
        this.newBreak = this.newBreak.bind(this);
        this.isBreakValid = this.isBreakValid.bind(this);
    }

    getBoolSelect(val){
        return val === 1 ? {label:"Yes", value:true} : {label:"No", value:false}
    }
    minutesOfDay(m){
        return m.minutes() + m.hours() * 60;
    }
    isBreakValid(podBreak:PodBreak){
        console.log('podBreak in isBreakValid', podBreak)
        if(!podBreak.StartTime || !podBreak.EndTime){
            sweetalert.fire({icon: 'error', title: '', text: "Start and end time must be completed"});
            return false;
        }
        let startMoment = this.minutesOfDay(moment(podBreak.StartTime));
        let endMoment = this.minutesOfDay(moment(podBreak.EndTime));

        if(startMoment > endMoment){
            sweetalert.fire({icon: 'error', title: '', text: "Break start time cannot be after break end time"});
            return false;
        }
        let podEndMoment = this.minutesOfDay(moment(this.state.endTime));
        let podStartMoment = this.minutesOfDay(moment(this.state.startTime));
        if(endMoment > podEndMoment){
            sweetalert.fire({icon: 'error', title: '', text: "Breaks must end before pod ends"});
            return false;
        }
        if(startMoment < podStartMoment){
            sweetalert.fire({icon: 'error', title: '', text: "Breaks must start after a pod starts"});
            return false;
        }
        return true;
    }

    componentDidMount() {
        console.log(' PodManagment CDM() ')
        AdminAPI.getAllPods().then(response => {
            if(!response.success){
                console.error(response.reason)
                sweetalert.fire("Error loading Pods.")
            }

            this.setState({allPods: response.data as { label: string; value: number }[]})
        })

    }




    componentWillReceiveProps(nextProps: Readonly<PodModalProps>, nextContext: any) {
        if(!isEmptyObject(nextProps.selectedPod)){
            let pod:PodSched = nextProps.selectedPod;

            console.log('selected pod', pod);
            let service = this.props.services.find(f => f.value === pod.ServiceID);
            let facility = this.props.facilties.find(f => f.value === pod.FacilityID);
            let podLabel = this.state.allPods.find(f => f.value === pod.ID)
            let breaks:PodBreak[] = [];
            for(const podBreak of pod["PodBreaks"] as PodBreak[]){
                breaks.push({StartTime:moment(podBreak.StartTime, "HH:mm:ss").toDate() , EndTime: moment(podBreak.EndTime, "HH:mm:ss").toDate()})
            }
            this.setState({
                podID:pod.ID,
                facility:facility ? facility : "",
                isActive:this.getBoolSelect(pod.Active),
                accessCode:pod.AccessCode,
                service:service ? service : "",
                isPrivate:this.getBoolSelect(pod.Private),
                date:parseDate(pod.TestDate),
                startTime:moment(pod.StartTime, "HH:mm:ss").toDate(),
                endTime:moment(pod.EndTime, "HH:mm:ss").toDate(),
                nickName:pod.Nickname ? pod.Nickname : "",
                breaks:breaks ? breaks : [],
                breakStartSelector:"",
                breakEndSelector:"",
                factor:pod.Factor ? pod.Factor : 0,
                podLabel: podLabel.label
            })
        }else{
            this.setState({
                podID:-1,
                facility:"",
                accessCode:"",
                isActive:"",
                service:"",
                isPrivate:"",
                date:"",
                startTime:"",
                endTime:"",
                nickName:"",
                breaks:[],
                breakStartSelector:"",
                breakEndSelector:"",
                factor:""
            })
        }
    }

    newBreak(){
        let breaks:PodBreak[] = JSON.parse(JSON.stringify(this.state.breaks));
        if(!this.isBreakValid({StartTime:this.state.breakStartSelector, EndTime:this.state.breakEndSelector}))
            return;
        breaks.push({StartTime:JSON.parse(JSON.stringify(this.state.breakStartSelector)), EndTime:JSON.parse(JSON.stringify(this.state.breakEndSelector))})
        this.setState({breaks:breaks});
    }

    deleteBreak(index){
        let breaks:PodBreak[] = JSON.parse(JSON.stringify(this.state.breaks));
        breaks.splice(index, 1);
        this.setState({breaks:breaks});
    }
    public static display(){
        window["$"]("#" + PodManagementModal.ID).modal('show');
    }

    handleDateOnChange(value, state){
        if (value) {
            this.setState({[state]: value } as any);
        }
        else{
            this.setState({[state]: null} as any)
        }
    }

    render() {
        // console.log(this.state);
        return  <div className="modal fade form_modal" id={PodManagementModal.ID} tabIndex={-1} role="dialog"
                     aria-labelledby="result_modal_label"
                     aria-hidden="true">
            <div className="modal-dialog modal-lg modal-xl" role="document" style={{display: 'block'}}>
                <div className="modal-content">
                    {/*<Modal_Header title={title}/>*/}
                    <div className="modal-header">
                        <h5 className="modal-title" id="result_modal_label">Pod Editing</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body my-0 mx-3">
                        {this.state.podID !== -1 &&
                        buildRow("Pod",
                            <input
                            className="form-control"
                            autoComplete="off"
                            disabled={true}
                            value={this.state.podLabel}
                            />)
                        }
                        {buildRow("Facility",
                            <Select
                                isSearchable={true}
                                placeholder={"Please Select..."}
                                noOptionsMessage={() => "No option"}
                                value={this.state.facility}
                                onChange={(e) => this.setState({facility: e})}
                                className={"state_select"}
                                options={this.props.facilties}
                            />)}
                        {buildRow("Is Active?",
                            <Select
                                isSearchable={true}
                                placeholder={"Please Select..."}
                                noOptionsMessage={() => "No option"}
                                value={this.state.isActive}
                                onChange={(e) => this.setState({isActive: e})}
                                className={"state_select"}
                                options={[{label: "Yes", value: true}, {label: "No", value: false}]}
                            />)}
                        {buildRow("Appointments Per 5 Minutes",
                            <input  className="form-control"
                                    autoComplete="off"
                                    onChange={(e) => this.setState({factor:e.target.value})}
                                    value={this.state.factor}
                                    pattern={'[0-9]'}
                                    min={0}
                                    max={100}
                            />)
                        }
                        {buildRow("Service Type",
                            <Select
                                isSearchable={true}
                                placeholder={"Please Select..."}
                                noOptionsMessage={() => "No option"}
                                value={this.state.service}
                                onChange={(e) => this.setState({service: e})}
                                className={"state_select"}
                                options={this.props.services}
                            />)}
                        {buildRow("Is Private?",
                            <Select
                                isSearchable={true}
                                placeholder={"Please Select..."}
                                noOptionsMessage={() => "No option"}
                                value={this.state.isPrivate}
                                onChange={(e) => this.setState({isPrivate: e})}
                                className={"state_select"}
                                options={[{label: "Yes", value: true}, {label: "No", value: false}]}
                            />)}
                        {buildRow("Access Code",
                            <input  className="form-control"
                                    autoComplete="off"
                                    onChange={(e) => this.setState({accessCode:e.target.value})}
                                    value={this.state.accessCode}
                                    maxLength={200}
                            />)
                        }
                        {buildRow("Date",
                            <DatePicker
                                minDate={new Date('01-01-1000')}
                                maxDate={new Date('12-31-9999')}
                                placeholderText="--/--/----"
                                selected={this.state.date ? Date.parse(moment(this.state.date, 'MM-DD-YYYY').toISOString()) : null}
                                onChange={(e) => this.handleDateOnChange(e, 'date')}
                            />
                        )}
                        {buildRow("Start Time",
                            <DatePicker
                                placeholderText="--:-- --"
                                minDate={new Date('01-01-1000')}
                                maxDate={new Date('12-31-9999')}
                                showTimeSelect
                                showTimeSelectOnly
                                selected={this.state.startTime ? Date.parse(moment(this.state.startTime, 'h:mm aa').toISOString()) : null}
                                dateFormat="h:mm a"
                                onChange={(e) => this.handleDateOnChange(e, 'startTime')}
                            />
                        )}
                        {buildRow("End Time",
                            <DatePicker
                                placeholderText="--:-- --"
                                minDate={new Date('01-01-1000')}
                                maxDate={new Date('12-31-9999')}
                                showTimeSelect
                                showTimeSelectOnly
                                selected={this.state.endTime ? Date.parse(moment(this.state.endTime, 'h:mm aa').toISOString()) : null}
                                dateFormat="h:mm a"
                                onChange={(e) => this.handleDateOnChange(e, 'endTime')}
                            />
                        )}
                        {buildRow("Nickname (internal only)",
                            <input  className="form-control"
                                    autoComplete="off"
                                    onChange={(e) => this.setState({nickName:e.target.value})}
                                    value={this.state.nickName}
                                    maxLength={200}
                            />)
                        }
                        <h5>Scheduled Breaks</h5>
                        <div className="row">
                            <div className="col-12 d-none d-md-block">
                                <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th scope="col">Start</th>
                                        <th scope="col">End</th>
                                    </tr>
                                    </thead>
                                    {this.state.breaks.map((b, index) =>{
                                        return (
                                            <tr>
                                                <td>{moment(b.StartTime).format('h:mm a')}</td>
                                                <td>{moment(b.EndTime).format('h:mm a')}</td>
                                                <td>
                                                    <button className={"btn btn-outline-danger"} onClick={() => this.deleteBreak(index)}>Delete</button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td>
                                            <DatePicker
                                                placeholderText="--:-- --"
                                                minDate={new Date('01-01-1000')}
                                                maxDate={new Date('12-31-9999')}
                                                disabled={!this.state.startTime || !this.state.endTime}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                selected={this.state.breakStartSelector ? Date.parse(moment(this.state.breakStartSelector, 'h:mm a').toISOString()) : null}
                                                dateFormat="h:mm a"
                                                onChange={(e) => this.handleDateOnChange(e, 'breakStartSelector')}
                                            />
                                        </td>
                                        <td>
                                            <DatePicker
                                                placeholderText="--:-- --"
                                                minDate={new Date('01-01-1000')}
                                                maxDate={new Date('12-31-9999')}
                                                disabled={!this.state.startTime || !this.state.endTime}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                selected={this.state.breakEndSelector ? Date.parse(moment(this.state.breakEndSelector, 'h:mm a').toISOString()) : null}
                                                dateFormat="h:mm a"
                                                onChange={(e) => this.handleDateOnChange(e, 'breakEndSelector')}
                                            />
                                        </td>
                                        <td>
                                            <button disabled={!this.state.startTime || !this.state.endTime} className={"btn btn-outline-primary "} onClick={this.newBreak}>Add</button>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>



                        <div className="row d-block d-md-none">
                            <div className="row">
                                
                                
                            </div>
                            <div className="row">
                                {this.state.breaks.map((b, index) =>{
                                        return (
                                            <div className="row mt-1">
                                                <div className={"col-5 pl-4"}>{moment(b.StartTime).format('h:mm a')}</div>
                                                <div className={"col-5"}>{moment(b.EndTime).format('h:mm a')}</div>
                                                <div className={"col-2 pr-0"}><button className={"btn btn-outline-danger"} onClick={() => this.deleteBreak(index)}>Delete</button></div>
                                            </div>
                                        )
                                    })}
                            </div>
                            <div className="row mt-2"> 
                                <div className="col-3">
                                    <label htmlFor="Start">Start</label>
                                </div>
                                <div className={"col-9"}>
                                    <DatePicker
                                        placeholderText="--:-- --"
                                        minDate={new Date('01-01-1000')}
                                        maxDate={new Date('12-31-9999')}
                                        disabled={!this.state.startTime || !this.state.endTime}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        selected={this.state.breakStartSelector ? Date.parse(moment(this.state.breakStartSelector, 'h:mm a').toISOString()) : null}
                                        dateFormat="h:mm a"
                                        onChange={(e) => this.handleDateOnChange(e, 'breakStartSelector')}
                                    />
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col-3">
                                    <label htmlFor="End">End</label>
                                </div>
                                <div className={"col-9"}>
                                    <DatePicker
                                        placeholderText="--:-- --"
                                        minDate={new Date('01-01-1000')}
                                        maxDate={new Date('12-31-9999')}
                                        disabled={!this.state.startTime || !this.state.endTime}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        selected={this.state.breakEndSelector ? Date.parse(moment(this.state.breakEndSelector, 'h:mm a').toISOString()) : null}
                                        dateFormat="h:mm a"
                                        onChange={(e) => this.handleDateOnChange(e, 'breakEndSelector')}
                                    />
                                </div>
                            
                                <div className={"col-12"}>
                                    <button disabled={!this.state.startTime || !this.state.endTime} className={"btn btn-outline-primary "} onClick={this.newBreak}>Add</button>
                                </div>
                            </div>
                        </div>
                        
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={() => {
                                let formValidation = {
                                    Facility: this.state.facility,
                                    IsPrivate: this.state.isPrivate,
                                    IsActive: this.state.isActive,
                                    Factor: this.state.factor,
                                    Service: this.state.service,
                                    AccessCode: this.state.accessCode,
                                    Date: this.state.date,
                                    StartTime: this.state.startTime,
                                    EndTime: this.state.endTime,
                                }

                                let validator = new Validator<any>()
                                    .withSimpleValidation("Facility", Validators.requireNonNullValidator())
                                    .withSimpleValidation("IsActive", Validators.requireNonNullValidator("Active"))
                                    .withValidation("Factor", Validators.requireFactor(0, 100, "Appointment Per 5 Mins"))
                                    .withSimpleValidation("Service", Validators.requireNonNullValidator())
                                    .withSimpleValidation("IsPrivate", Validators.requireNonNullValidator("Private"))
                                    .withSimpleValidation("Date", Validators.requireNonNullValidator())
                                    .withSimpleValidation("StartTime", Validators.requireNonNullValidator("Start Time"))
                                    .withSimpleValidation("EndTime", Validators.requireNonNullValidator("End Time"))
                                let validationResponse = validator.validate(formValidation);
                                if(!validationResponse.success) {
                                    return sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
                                }

                                if(this.state.isPrivate.value && !this.state.accessCode.trim()){
                                    return sweetalert.fire({icon: 'error', title: '', text: "Private pods must have an access code"});
                                }
                                if(moment(this.state.startTime, "HH:mm") > moment(this.state.endTime, "HH:mm")){
                                    return sweetalert.fire({icon: 'error', title: '', text: "Start time cannot be before the end time"});
                                }

                                console.log('this.state.breaks after submit', this.state.breaks)
                                for(const podBreak of this.state.breaks){
                                    if(!this.isBreakValid(podBreak))
                                        return;
                                }
                                console.log('state just before onSubmit', this.state)
                                this.props.onSubmit({
                                    AccessCode: this.state.accessCode ? this.state.accessCode.trim() : "",
                                    Active: this.state.isActive.value,
                                    EndTime: moment(this.state.endTime).format("HH:mm:ss"),
                                    FacilityID: this.state.facility.value,
                                    Factor: this.state.factor,
                                    ID: this.state.podID === -1 ? null : this.state.podID, //-1 is no existing pod, so make a new one
                                    Nickname: this.state.nickName ? this.state.nickName.trim() : "",
                                    Private: this.state.isPrivate.value,
                                    ServiceID: this.state.service.value,
                                    StartTime: moment(this.state.startTime).format("HH:mm:ss"),
                                    TestDate: this.state.date
                                }, JSON.parse(JSON.stringify(this.state.breaks.map(b =>{
                                    return {StartTime:moment(b.StartTime).format('HH:mm:ss'), EndTime:moment(b.EndTime).format('HH:mm:ss')}
                                }))))
                            }}>
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}