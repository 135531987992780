import React from "react";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import domain from "../../Domain";
import ReactToPrint from "react-to-print";
import Overlay from "../Overlay";
import moment from "moment";
import QRCode from "react-qr-code";
import Select, { OptionTypeBase } from "react-select";
import {sweetalert} from "../../App";
import SystemAPI from "../../network/SystemAPI";
import Validator, {ValidationEntry} from "../../validation/Validator";
import {Validators} from "../../validation/Validators";
import {BinAPI} from "../../network/BinAPI";
import AdminAPI from "../../network/AdminAPI";

interface BinReceivingState{
    show_loading: boolean
    filter: object
    facilities: any
    submitted: boolean
    deliveredBy: string
    facilityName
    numOfSamples: string
    receivedBy: string
    currentDate: string
    binID: string

    DeliveredBy?
    FacilityID?
    NumberOfSamples?
    ReceivedBy?
}

export class BinReceiving extends React.Component<any,BinReceivingState>{
    private componentRef: any;
    constructor(props) {
        super(props);
        this.state = {
            show_loading: false,
            filter: {},
            facilities: [],
            submitted: false,
            deliveredBy: '',
            facilityName: '',
            numOfSamples: '',
            receivedBy: '',
            currentDate: moment().format('MM/DD/YYYY h:mm:ss a'),
            binID: '',
        };
        this.assignClearState = this.assignClearState.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({show_loading: true}, () => {
            AdminAPI.getAllFacilities().then(data => {
                this.setState({facilities: data.authorizedFacilities.map(fac => {
                        return {
                            label: fac.FacilityName,
                            value: fac.ID
                        }
                    }), show_loading: false })
            })
        })
    }

    clearFilterState:() => void = null;

    assignClearState(func){
        console.log("attached");
        this.clearFilterState = func;
    }

    clearState = () =>{
        console.log("clear form");
        this.setState({facilityName: ''});
        this.clearFilterState();
    }


    onSubmit(e) {

        let formValidation = {
            Facility: this.state.facilityName,
            DeliveredBy: this.state.filter['DeliveredBy'],
            NumberOfSamples: this.state.filter['NumberOfSamples'],
            ReceivedBy: this.state.filter['ReceivedBy'],
        }

        let validator = new Validator<any>()
            .withSimpleValidation("Facility", Validators.requireNonNullValidator())
            .withValidation("DeliveredBy", Validators.requireNotBlankAndLength(200, "Delivered By"))
            .withComposedValidation("NumberOfSamples", new ValidationEntry(Validators.requireNotBlankValidator("Number of Samples")), new ValidationEntry(Validators.requireRange(1,100, "Number of Samples")), new ValidationEntry(Validators.requireIsInt("Number of Samples")))
            .withValidation("ReceivedBy", Validators.requireNotBlankAndLength(200, "Received By"))
        let validationResponse = validator.validate(formValidation);
        if(!validationResponse.success) {
            return sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
        }

        this.setState({show_loading: true})

        this.insertSampleReceiving(this.state.filter['DeliveredBy'], this.state.facilityName.value, this.state.filter['NumberOfSamples'], this.state.filter['ReceivedBy'])

}

    async insertSampleReceiving(droppedBy, facilityID, numSamples, receivedBy){

        let tempBinID = ''
        try {
            let result = await BinAPI.insertBinRec({droppedBy: droppedBy, facilityID: facilityID, numSamples: numSamples, receivedBy: receivedBy})
            if(result.success){
                tempBinID = 'BC' + result.response[0]
                this.setState({binID: tempBinID, submitted: true, show_loading: false})
            }
        }
        catch (e) {
            console.log(e)
        }

    }



    render() {
        return(
            <React.Fragment>
                <div className="container">
                    <Overlay show_loading={this.state.show_loading}/>
                    <div className="container-fluid  ">
                        <div className={"row"}>
                            <div className="col-12 col-md-12 offset-xl-3 col-xl-6 offset-lg-2 col-lg-8  pt-2">
                                <div className="card mb-2">
                                    <div className="card-header verlag-bold">
                                        <h3>Create Receiving Label</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-group row">
                                            <div className={"col-4"}>
                                                <label htmlFor={'Facility'} style={{fontWeight: 'bold', paddingTop: '0.5em'}}>Facility</label>
                                            </div>

                                            <div className={"col-8 pl-0 pr-1"}>
                                                <Select
                                                    id={'Facility'}
                                                    isSearchable={true}
                                                    placeholder={"Please Select..."}
                                                    onChange={(e)=> this.setState({facilityName: e})}
                                                    className={"state_select"}
                                                    options={this.state.facilities}
                                                    value={this.state.facilityName}
                                                    isDisabled={this.state.submitted}
                                                />
                                            </div>
                                        </div>

                                        <FilterCard
                                            passClearStateFunc={this.assignClearState}
                                            fields={[
                                                {
                                                    label: "Delivered By",
                                                    "key": "DeliveredBy",
                                                    type: FIELD_TYPE.TEXT,
                                                    placeholder: "Enter drop-off person name",
                                                    disable: this.state.submitted,
                                                    maxLength: 200
                                                },
                                                {
                                                    label: "# of Samples",
                                                    "key": "NumberOfSamples",
                                                    type: FIELD_TYPE.NUMBER,

                                                    min: 1,
                                                    max: 1000,
                                                    placeholder: "Enter number of samples",
                                                    disable: this.state.submitted
                                                },
                                                {
                                                    label: "Received By",
                                                    "key": "ReceivedBy",
                                                    type: FIELD_TYPE.TEXT,
                                                    placeholder: "Enter receiver's name",
                                                    disable: this.state.submitted,
                                                    maxLength: 200
                                                },
                                            ]} filterChanged={(filter) => this.setState({filter: filter})}/>
                                    </div>
                                    <div className="card-footer">
                                        {this.state.submitted ?
                                        <>
                                            <button className="btn btn-outline-primary d-none" onClick={(e) => this.onSubmit(e)}>Submit
                                            </button>

                                            <ReactToPrint
                                            trigger={() => <button className={"btn btn-outline-success"} style={{float: 'right'}}>Print Label</button>}
                                            // @ts-ignore
                                            content={() => this.componentRef}
                                            onAfterPrint={() => {this.setState({submitted: false}); this.clearState()}}
                                            />
                                        </>:
                                        <>
                                            <button className="btn btn-outline-primary" onClick={(e) => this.onSubmit(e)}>Submit
                                            </button>

                                            <ReactToPrint
                                            trigger={() => <button className={"btn btn-outline-success"} style={{float: 'right', display: 'none'}}>Print Label</button>}
                                            // @ts-ignore
                                            content={() => this.componentRef}
                                            />
                                        </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {this.state.submitted ?
                    <div className={"m-5 "}>
                        <div className={"container"} >
                            <div className="row" style={{marginTop: '1em'}}>
                                <div className="col-2"></div>
                                <div className="col-8">
                                    <table className="table table-borderless" style={{borderBottom: '5px solid black'}}>
                                        <tbody className={"text-center"}>
                                                <tr>
                                                    <td style={{width: '50%', fontWeight: 'bold', fontSize: '1.5em'}}>Dropped By:</td>
                                                    <td style={{width: '50%', fontWeight: 'bold',fontSize: '1.5em'}}>{this.state.filter['DeliveredBy']}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{width: '50%', fontWeight: 'bold', fontSize: '1.5em'}}>Facility:</td>
                                                    <td style={{width: '50%', fontWeight: 'bold',fontSize: '1.5em'}}>{this.state.facilityName ? this.state.facilityName.label: ''}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{width: '50%', fontWeight: 'bold', fontSize: '1.5em'}}># Samples:</td>
                                                    <td style={{width: '50%', fontWeight: 'bold',fontSize: '1.5em'}}>{this.state.filter['NumberOfSamples']}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{width: '50%', fontWeight: 'bold', fontSize: '1.5em'}}>Date Dropped:</td>
                                                    <td style={{width: '50%', fontWeight: 'bold',fontSize: '1.5em'}}>{this.state.currentDate}</td>
                                                </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-2"></div>
                            </div>
                            <div className="row" ></div>
                            <div className="row mt-4">
                                <div className="col-2"></div>
                                <div className="col-8 text-center">
                                    <div className={"mb-3"}>
                                        <QRCode value={ this.state.binID ? this.state.binID : ''} size={150} />
                                    </div>
                                    <div className={"mb-3"} style={{fontWeight: 'bold', fontSize: '2.5em'}}>{this.state.binID ? this.state.binID : ''}</div>
                                </div>
                                <div className="col-2"></div>

                            </div>
                        </div>



                    <div className={"m-5 d-none"}>
                        <div className={"container"} ref={el => (this.componentRef = el)}>
                            <div className="row" style={{marginTop: '1em'}}>
                                {/*<div className="col-2"></div>*/}
                                <div className="col-10">
                                    <table className="table table-borderless" style={{borderBottom: '5px solid black'}}>
                                        <tbody className={"text-center"}>
                                        <tr>
                                            <td style={{width: '50%', fontWeight: 'bold', fontSize: '1.5em'}}>Dropped By:</td>
                                            <td style={{width: '50%', fontWeight: 'bold',fontSize: '1.5em'}}>{this.state.filter['DeliveredBy']}</td>
                                        </tr>
                                        <tr>
                                            <td style={{width: '50%', fontWeight: 'bold', fontSize: '1.5em'}}>Facility:</td>
                                            <td style={{width: '50%', fontWeight: 'bold',fontSize: '1.5em'}}>{this.state.facilityName ? this.state.facilityName.label : ''}</td>
                                        </tr>
                                        <tr>
                                            <td style={{width: '50%', fontWeight: 'bold', fontSize: '1.5em'}}># Samples:</td>
                                            <td style={{width: '50%', fontWeight: 'bold',fontSize: '1.5em'}}>{this.state.filter['NumberOfSamples']}</td>
                                        </tr>
                                        <tr>
                                            <td style={{width: '50%', fontWeight: 'bold', fontSize: '1.5em'}}>Date Dropped:</td>
                                            <td style={{width: '50%', fontWeight: 'bold',fontSize: '1.5em'}}>{this.state.currentDate}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-2"></div>
                            </div>
                            <div className="row" ></div>
                            <div className="row mt-4">
                                {/*<div className="col-2"></div>*/}
                                <div className="col-10 text-center">
                                    <div className={"mb-3"}>
                                        <QRCode value={ this.state.binID ? this.state.binID : ''} size={150} />
                                    </div>
                                    <div className={"mb-3"} style={{fontWeight: 'bold', fontSize: '2.5em'}}>{this.state.binID ? this.state.binID : ''}</div>
                                </div>
                                <div className="col-2"></div>

                            </div>
                        </div>
                    </div>
                </div>: <div style={{display: 'none'}}></div>}


            </React.Fragment>
        )
    }}
