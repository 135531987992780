import * as React from "react";
import $ from "jquery"
import cookie from 'react-cookie';
import domain from "../../Domain";
import SidebarItems from "./SidebarItems";
import {sweetalert} from "../../App";
interface AdminNavState {
    user?
    tabs: string[]
    authPages:string[]
    banner,
    showLoading,
    showSidebar: boolean
}

interface AdminProps {
    onChange: (str: string) => void
}

export enum AdminPages {
    CheckIn="Testing Check-in",
    Samples="Samples",
    PodStats="Pod Management and Stats",
    Alert="Send Alert",
    WalkinForm ="Walk-in Form",
    Accession="Well Plate Map",
    BinReceiving="Create Receiving Label",
    RetrieveReceivingLabel="Retrieve Receiving Label",
    InternalSpecimenLabel="Internal Specimen Label",
    MachineExport="Machine Export",
    Facility="Facility",
    RerunReport = "Rerun List",
    BatchReporting = "Batch Reporting",
    AuditLogs = "Audit Logs",
    UserPermissioning = "User Management",
    ReconcileReport = "Samples Status",
    ReconcileLab = "Reconcile Lab",
    AccessionApp = "Accessioning",
    RapidTestManagement ="Rapid Test Management",
    TestManagement ="Test Management",
    BatchManagement="Batch Management",
    ServicesManagement="Services Management",
    CommunicationTemplates="Communication Templates",
    SettingsManagement="Settings"
}

class AdminNav extends React.Component<AdminProps, AdminNavState> {


    constructor(props) {
        super(props);
        this.state = {
            tabs: Object.values(AdminPages),
            user: {userinfo: {}},
            authPages:[],
            banner:"",
            showLoading:false,
            showSidebar: false
        }
        this.checkAuth = this.checkAuth.bind(this);
        this.handleShowSidebar = this.handleShowSidebar.bind(this);
    }

    setupHooks(){
        $(document).ready(function () {
            $('#dismiss, .overlay').on('click', function () {
                // hide sidebar
                $('#sidebar, #main').removeClass('active');
                // hide overlay
                $('.overlay').removeClass('active');
            });

            $('.navSelect').on('click', function () {
                // hide sidebar
                $('#sidebar, #main').removeClass('active');
                // hide overlay
                $('.overlay').removeClass('active');
            });

            $('#sidebarCollapse').on('click', function () {
                // open sidebar
                $('#sidebar, #main').addClass('active');
                // fade in the overlay
                $('.overlay').addClass('active');
                $('.collapse.in').toggleClass('in');
                $('a[aria-expanded=true]').attr('aria-expanded', 'false');
            });

            $('#menu ul').hide();
            $('#menu ul').children('.current').parent().show();
            $('#menu li a').on('click',function() {
                let checkElement = $(this).next(); // looking for UL elements
                let clickedCaret = $(this).find(".nav-caret");

                if (
                    (checkElement.is('ul')) &&
                    (checkElement.is(':visible'))
                ) {
                    $('#menu ul:visible').slideDown('normal');
                    checkElement.slideUp('normal');

                    clickedCaret
                        .removeClass("fa-caret-up")
                        .addClass("fa-caret-down");

                    $('.nav-caret').not(clickedCaret).each(
                        function( index, el ) {
                            if($(el).hasClass('fa-caret-up')){
                                $( el ).removeClass('fa-caret-up')
                                $( el ).addClass( 'fa-caret-down' );
                            }
                        });
                    return false;
                }

                if (
                    (checkElement.is('ul')) &&
                    (!checkElement.is(':visible'))
                ) {
                    $('#menu ul:visible').slideUp('normal');
                    checkElement.slideDown('normal');

                    clickedCaret
                        .removeClass("fa-caret-down")
                        .addClass("fa-caret-up");

                    $('.nav-caret').not(clickedCaret).each(
                        function( index, el ) {
                            if($(el).hasClass('fa-caret-up')){
                                $( el ).removeClass('fa-caret-up')
                                $( el ).addClass( 'fa-caret-down' );
                            }
                        });
                    return false;
                }
            });
        });
    }

    checkAuth(){
        console.log("check auth")
        fetch(domain + "/api/admin/authenticated", {
            method: 'POST',
            body: JSON.stringify({}),
            headers: {'Content-Type': 'application/json'}
        }).then(res => res.json())
            .then(async data => {
                console.log(data);
                if (!data.success) {
                    await sweetalert.fire({
                        title: "Error",
                        text: data.reason,
                        icon: "error",
                        confirmButtonText: "OK"
                    });
                    window['location'] = "/" as unknown as Location
                    return;
                }
                this.setState({user: data.user, authPages: data.groups}, () => this.setupHooks())
            }).catch(e => {
            console.log(e);
        }).catch(e =>{
            console.log(e);
        });
    }

    componentDidMount() {
        this.checkAuth();
        this.setState({showSidebar: false}, () => {
            fetch(domain + "/api/system/banner").then(res => res.json()).then(data => {
                if (data.show) {
                    this.setState({banner: data.banner})
                    return;
                }

            }).catch(e => {
                console.log(e)
                alert("Failed")
            });
        });
    }
    getBannerElem() {
        if(!this.state.banner)
            return null;
        return (
            <div className="alert alert-danger m-0" role="alert">
                <p className="primary-black m-0">
                    {this.state.banner.Text}
                </p>
            </div>
        )
    }

    handleShowSidebar(e){
        this.setState({showSidebar: !this.state.showSidebar});
    }

    render() {
        return (
            <React.Fragment>
                {this.getBannerElem()}

                <nav id="sidebar" className={""}>
                    <div id={"sidebarWrapper p-0 m-0"}>
                        <div>
                            <button type="button" className="btn btn-outline-light float-right" id="dismiss">
                                <i className="fa fa-arrow-left"/>
                            </button>

                        <SidebarItems
                            items={this.state.tabs}
                            authPages={this.state.authPages}
                            onChange={this.props.onChange}
                        />
                        </div>

                    </div>
                </nav>

                <div className="container-fluid bg-primary p-1">
                    <div className="row m-0">
                        <div className={"col-xs-8"}>
                            <div className={"row"}>

                                <div className={"col-md-auto"}>
                                    <button type="button" className="btn btn-outline-light float-left"
                                            id="sidebarCollapse"
                                            onClick={this.handleShowSidebar}>
                                        <span className="dark-blue-text"><i className="fa fa-bars fa-1x"/></span>
                                    </button>
                                    <a href={"/admin"} style={{marginLeft: '20px'}}>
                                        <img src={"https://storage.googleapis.com/streamline-bucket/FullLogoWhite.png"} 
                                            className={'admin-nav-logo'} 
                                            alt={"logo"}/></a>
                                </div>
                            </div>
                        </div>

                        <div className={"col"}>
                            <form method="post" id={"logoutForm"} action="/clearSession">
                                <button  type="submit"  className="btn btn-outline-light float-right">
                                    <span className="dark-blue-text"> Log Out</span>

                                </button></form>
                            <span className="white verlag-light mb-0 mt-2 pr-2 float-right d-none d-sm-block" style={{fontSize: '1.25em'}}>Hello, {this.state.user ? this.state.user.userinfo.name : "Dev"}</span>
                        </div>

                    </div>
                </div>
                {/*This is my comment*/}
                <div className="overlay"/>

            </React.Fragment>
        );
    }
}

export default AdminNav;