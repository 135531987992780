import NetworkUtil from "./NetworkUtil";
import {Sample} from "../types/Sample";
import TestType from "../types/TestType"
import {ResultType} from "../types/ResultType";
import {ServerResponse} from "../types/ServerResponse";
import {Service} from "../types/Service";

export default class WalkinAPI {

    public static async walkinForm(patient):Promise<ServerResponse>{
        console.log('patientInfo in walkinForm', patient)
        return NetworkUtil.makePost("/api/admin/form", patient);
    }

}