import React from "react";
import Select from "react-select";
import Batch from "../../../types/Batch";
import BatchesAPI from "../../../network/BatchesAPI";

interface SelectProps{
    multi?:boolean
    onSelected: (value:{label, value:Batch} | {label, value:Batch}[])=>void
    filterTests?:(batches:Batch[])=>Batch[]
    disabled?
    batches?:Batch[]
    selectedBatch?
    finishedLoading?:() => void
}

interface SelectState{
    value:any
    batches:Batch[]
}

export class BatchSelect extends React.Component<SelectProps, SelectState>{

    constructor(props:any){
        super(props);
        this.state = {
            value:null,
            batches:[]
        }
    }

    async componentDidMount() {
        if(!this.props.batches){
            let batches = await BatchesAPI.getAllBatches();
            this.setState({batches:batches});
            if(this.props.finishedLoading){
                this.props.finishedLoading();
            }
        }
    }

    componentWillReceiveProps(nextProps: Readonly<SelectProps>, nextContext: any) {
        if(nextProps.batches){
            this.setState({batches:nextProps.batches});
        }
        if(nextProps.selectedBatch){
            let foundBatch = this.state.batches.find(batch=>batch.ID === nextProps.selectedBatch.ID);
            if(foundBatch){
                this.setState({value: this.props.multi ? [{label:foundBatch.BatchNum, value:foundBatch}] : {label:foundBatch.BatchNum, value:foundBatch}});
            }
        }
    }

    render() {
        let allowedBatches = this.props.filterTests ? this.props.filterTests(this.state.batches) : this.state.batches;
        let mappedBatches = allowedBatches.map((batch:Batch)=>{
            return {
                value:batch,
                label:batch.BatchNum
            }
        });
        return(
            <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={'Lists the batches by batch number'}>
                <label id={'Batch'}  htmlFor={"Batch"} className="col-sm-4 col-form-label">Batch</label>
                <div className="col-sm-8  p-0 m-0">
                    <Select
                        id={"Batch"}
                        isSearchable={ true}
                        placeholder={"Please Select..."}
                        noOptionsMessage={()=>  "No option"}
                        isMulti={this.props.multi}
                        isDisabled={this.props.disabled}
                        value={this.state.value}
                        onChange={(e)=>{
                            this.setState({value:e}, () =>{
                                this.props.onSelected(e as any);
                            })
                        }}
                        className={"state_select"}
                        options={mappedBatches}
                    />
                </div>
            </div>
        );
    }
}