import React, {Component} from 'react';
import './App.css';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Home from "./components/Home";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import ResultsForm from "./components/ResultsForm";
import PatientForm from "./components/PatientForm";
import AdminPanel from "./components/admin/AdminPanel";
import Confirmation from "./components/Confirmation";
import {MachineExport} from "./components/admin/MachineExport";
import PrivatePod from "./components/PrivatePod";
import {Login} from "./components/admin/Login";
import PatientCheckIn from "./components/PatientCheckIn";
import {RetrieveConfirmation} from "./components/RetrieveConfirmation";
import {TestManagement} from "./components/admin/TestManagement";
import Alert from "./../src/components/admin/Alert";
import CheckIn from "./components/admin/CheckIn";
import SamplesReport from "./components/admin/SamplesReport";
import WalkinForm from "./components/admin/WalkinForm";
import {WellPlateReport} from "./components/admin/WellPlateReport";
import {BinReceiving} from "./components/admin/BinReceiving";
import {InternalSpecimen} from "./components/admin/InternalSpecimen";
import {Facility} from "./components/admin/Facility";
import {RerunReport} from "./components/admin/RerunReport";
import {BatchReporting} from "./components/admin/BatchReporting";
import {SamplesStatusReport} from "./components/admin/SamplesStatusReport";
import {AccessionApp} from "./components/admin/AccessionApp";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DoggedOut from "./components/admin/DoggedOut";
import LoggedOut from "./components/admin/LoggedOut";
import RapidTestManagement from "./components/admin/RapidTestManagement";
import PodStats from "./components/admin/PodStats";
import {BatchManagement} from "./components/admin/BatchManagement";
import {AuditLogs} from "./components/admin/AuditLogs";
import {ReconcileLab} from "./components/admin/ReconcileLab";
import {RetrieveReceivingLabel} from "./components/admin/RetrieveReceivingLabel";
import {UserPermissioning} from "./components/admin/UserPermissioning";
import {ServicesManagement} from "./components/admin/ServicesManagement";
import {CommunicationTemplates} from "./components/admin/CommunicationTemplates";
import {SettingsManagement} from "./components/admin/SettingsManagement";
import AdminHomePage from "./components/admin/AdminHomePage";
const swal = withReactContent(Swal);

export let CAPTCHA = "6Lc4JUAcAAAAAGPcDlen-26x_KpyZUjY7acuP4Al";
//setting defaults for sweetalert
export let sweetalert = swal.mixin({
    confirmButtonColor: '#00539B',      // immy-blue
    cancelButtonText: 'Cancel',
    cancelButtonColor: '#A7A9AC',       // immy-gray
    allowOutsideClick: false,           // enforce user interact with modal buttons
    allowEscapeKey: false,              // enforce user interact with modal buttons
    reverseButtons: true,               // cancel left, confirm right
    showClass: {
        backdrop: 'swal2-noanimation', // disable backdrop animation
        popup: '',                     // disable popup animation
        icon: '',                       // disable icon animation
    },
    hideClass: {
        popup: '',                     // disable popup fade-out animation
    },
    customClass: {
        container: 'swal-on-top swal2-html-container'
    }
});

class App extends Component<{}, {}> {

    componentDidMount() {
        document.title = "Streamline"
    }


    getNavRoute(comp:any){
        return(
            <React.Fragment>
                <Nav/>
                {comp}
                <Footer/>
            </React.Fragment>
        )
    }

    getAdminNavRoute(comp:any) {
        return (
            <React.Fragment>
                <AdminPanel/>
                {comp}
            </React.Fragment>
        )
    }




    render() {
        return (
            <BrowserRouter>
                <React.Fragment>
                    <Switch>
                        <Route path="/" render={(props) =>this.getNavRoute(<Home/>)} exact/>
                        <Route path="/private" render={(props) =>this.getNavRoute(<PrivatePod/>)} exact/>
                        <Route path="/appointment" render={(props) => this.getNavRoute(<PatientForm/>)} exact/>
                        <Route path="/results" render={(props) =>  this.getNavRoute(<ResultsForm/>)} exact/>
                        <Route path="/admin" render={(props) =>this.getAdminNavRoute(<AdminHomePage />)} exact/>
                        <Route path="/admin/CheckIn" render={(props) =>  this.getAdminNavRoute(<CheckIn/>)} exact/>
                        <Route path="/admin/WalkinForm" render={(props) =>  this.getAdminNavRoute(<WalkinForm/>)} exact/>
                        <Route path="/admin/RapidTestManagement" render={(props) =>  this.getAdminNavRoute(<RapidTestManagement/>)} exact/>
                        <Route path="/admin/PodStats" render={(props) =>  this.getAdminNavRoute(<PodStats/>)} exact/>
                        <Route path="/admin/Alert" render={(props) =>  this.getAdminNavRoute(<Alert/>)} exact/>
                        <Route path="/admin/Samples" render={(props) =>  this.getAdminNavRoute(<SamplesReport/>)} exact/>
                        <Route path="/admin/Accession" render={(props) =>  this.getAdminNavRoute(<WellPlateReport/>)} exact/>
                        <Route path="/admin/InternalSpecimenLabel" render={(props) =>  this.getAdminNavRoute(<InternalSpecimen/>)} exact/>
                        <Route path="/admin/MachineExport" render={(props) =>  this.getAdminNavRoute(<MachineExport/>)} exact/>
                        <Route path="/admin/RerunReport" render={(props) =>  this.getAdminNavRoute(<RerunReport/>)} exact/>
                        <Route path="/admin/BatchReporting" render={(props) =>  this.getAdminNavRoute(<BatchReporting/>)} exact/>
                        <Route path="/admin/AccessionApp" render={(props) =>  this.getAdminNavRoute(<AccessionApp/>)} exact/>
                        <Route path="/admin/BatchManagement" render={(props) =>  this.getAdminNavRoute(<BatchManagement/>)} exact/>
                        <Route path="/admin/AuditLogs" render={(props) =>  this.getAdminNavRoute(<AuditLogs/>)} exact/>
                        <Route path="/admin/ReconcileReport" render={(props) =>  this.getAdminNavRoute(<SamplesStatusReport/>)} exact/>
                        <Route path="/admin/ReconcileLab" render={(props) =>  this.getAdminNavRoute(<ReconcileLab/>)} exact/>
                        <Route path="/admin/BinReceiving" render={(props) =>  this.getAdminNavRoute(<BinReceiving/>)} exact/>
                        <Route path="/admin/RetrieveReceivingLabel" render={(props) =>  this.getAdminNavRoute(<RetrieveReceivingLabel/>)} exact/>
                        <Route path="/admin/Facility" render={(props) =>  this.getAdminNavRoute(<Facility/>)} exact/>
                        <Route path="/admin/UserPermissioning" render={(props) =>  this.getAdminNavRoute(<UserPermissioning/>)} exact/>
                        <Route path="/admin/TestManagement" render={(props) =>  this.getAdminNavRoute(<TestManagement/>)} exact/>
                        <Route path="/admin/ServicesManagement" render={(props) =>  this.getAdminNavRoute(<ServicesManagement/>)} exact/>
                        <Route path="/admin/CommunicationTemplates" render={(props) =>  this.getAdminNavRoute(<CommunicationTemplates/>)} exact/>
                        <Route path="/admin/Settings" render={(props)=>this.getAdminNavRoute(<SettingsManagement />)} exact />
                        <Route path="/confirmation" render={(props) =>  this.getNavRoute(<Confirmation/>)} exact/>
                        <Route path="/checkin" render={(props) =>  <PatientCheckIn/>} exact/>
                        <Route path="/retrieve" render={(props) =>  this.getNavRoute(<RetrieveConfirmation/>)} exact/>
                        <Route path="/loggedout" render={(props) => this.getNavRoute(<LoggedOut/>)} exact/>
                    </Switch>

                </React.Fragment>
            </BrowserRouter>);
    }
}

export default App;
