import React from "react";
import CancelAppointment from "./modals/CancelAppointment";
import Overlay from "./Overlay";
import {AppointmentSelectionModal} from "./modals/AppointmentSelectionModal";
import domain from "../Domain";
import Banner from "../types/Banner";
import {getBrandingImage} from "../util/FormatUtil";
import {ImageType} from "../types/Branding";
import SystemAPI from "../network/SystemAPI";

interface NavState{
    showLoading:boolean
    banner?:Banner
    logo:string
}
class Nav extends React.Component<{}, NavState>{

    constructor(props) {
        super(props);
        this.state = {
            showLoading:false,
            logo:""
        }
        this.getBannerElem = this.getBannerElem.bind(this);
    }

    componentDidMount() {
          this.setState({showLoading: true}, () => {
              getBrandingImage(ImageType.LOGO).then(data => {
                  this.setState({logo:data})
              })
              SystemAPI.getBanner().then(data => {
                  if (data.show) {
                      this.setState({banner: data.banner, showLoading: false})
                  }else{
                      this.setState({showLoading:false})
                  }
              })
          })
    }

    getBannerElem() {
        if(!this.state.banner)
            return null;
        return (
            <div className="alert alert-danger m-0" role="alert">
                <p className="primary-black m-0">
                    {this.state.banner.Text}
                </p>
            </div>
        )
    }


    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        return (
            <React.Fragment>
                <AppointmentSelectionModal/>
                <Overlay show_loading={this.state.showLoading}/>
                <CancelAppointment/>

                <div className={"p-0 m-0 nav-wrapper"}>

                    <div className ="p-0 m-0 container-fluid">
                        {this.getBannerElem()}
                        <nav className="navbar navbar-expand-lg navbar-light ">

                            <a className="navbar-brand verlag-bold nav-href" href="/">
                              <span><img src={this.state.logo} height={60}  width={180}
                                         className="d-inline-block align-top nav-href pr-2" alt=""/></span>
                                {/*<span className={"navspan-text"}>Streamline</span>*/}
                            </a>

                            <button className="navbar-toggler" type="button" data-toggle="collapse"
                                    data-target="#navbarText"
                                    aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse float-right " id="navbarText">

                                <ul className="navbar-nav  immy-blue mr-auto immy-blue">
                                    <li className={"nav-item active "}>

                                        <a className="nav-link   immy-blue" href="/appointment" >
                                            Create Appointment
                                        </a>

                                    </li>

                                    <li className={"nav-item active "}>

                                        <a className="nav-link   immy-blue" href="#"  onClick={() =>  // @ts-ignore
                                            window.$("#" + CancelAppointment.ID).modal('show')}>
                                            Cancel Appointment
                                        </a>

                                    </li>

                                    <li className={"nav-item active"}>
                                        <a className="nav-link   immy-blue" href={"/results"}>
                                            Get Testing Results
                                        </a>
                                    </li>

                                    {/*  <li className={"nav-item active"}>
                                      <a className="nav-link   immy-blue" href={"/retrieve"}>
                                          Retrieve Confirmation Code
                                      </a>
                                  </li>*/}

                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Nav;