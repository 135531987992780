import React from "react";
import FilterCard, { FIELD_TYPE, ReactSelect } from "../FilterCard";
import domain from "../../Domain";
import Overlay from "../Overlay";
import SimpleTable from "../tables/SimpleTable";
import {RetrieveReceivingLabelModal} from "../modals/RetrieveReceivingLabelModal";
import {getPageOptions, parseDate, showModalNoOutsideClick, toLocalDateTime} from "../../util/FormatUtil";
import {sweetalert} from "../../App";
import {Column} from "../tables/TableBase";
import {Sorter, TableOrder} from "../../sorting/Sorter";
import SystemAPI from "../../network/SystemAPI";
import { BinAPI } from "../../network/BinAPI";
import AdminAPI from "../../network/AdminAPI";
import { Sample } from "../../types/Sample";
import Select from "react-select";
import PaginationTool from "../PaginationTool";

interface RetrieveReceivingLabelState {
  showLoading: boolean;
  filter?;
  facilities: any;
  submitted: boolean;
  labels?: {
    BinID: string;
    DroppedBy: string;
    DroppedDate: Date;
    NumberSamples: number;
    ReceivedBy: string;
    FacilityName: string;
  }[];
  totalLabels?: number;
  selectedResult?;
  displayLabelBool;
  direction?: TableOrder;
  page_options: ReactSelect[];
  selected_page?;
}

export class RetrieveReceivingLabel extends React.Component<
  any,
  RetrieveReceivingLabelState
> {
  private componentRef: any;
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      facilities: [],
      submitted: false,
      totalLabels: 0,
      displayLabelBool: false,
      direction: "asc",
      page_options: [{ value: "1", label: "1" }],
      selected_page: { value: 1, label: 1 },
    } as any;
    this.onSubmit = this.onSubmit.bind(this);
    this.assignClearState = this.assignClearState.bind(this);
  }

  componentDidMount() {
    this.setState({ showLoading: true }, () => {
      AdminAPI.getFacilitiesWithAddress().then((data) => {
        this.setState({ facilities: data, showLoading: false });
      });
    });
  }

  clearFilterState: () => void = null;

  assignClearState(func) {
    this.clearFilterState = func;
  }

  getFilterObj() {
    return {
      Facilities:
        this.state.filter.FacilityID && this.state.filter.FacilityID.length > 0
          ? this.state.filter.FacilityID
          : null,
      BinID: this.state.filter.BinID ? this.state.filter.BinID : null,
      DroppedDate: this.state.filter.DroppedDate
        ? parseDate(this.state.filter.DroppedDate)
        : null,
      DroppedBy: this.state.filter.DroppedBy
        ? this.state.filter.DroppedBy
        : null,
      ReceivedBy: this.state.filter.ReceivedBy
        ? this.state.filter.ReceivedBy
        : null,
    };
  }

  onSubmit(page) {
    let hasValue = false;

    let filterKeys = Object.keys(this.state.filter);
    for (const fil of filterKeys) {
      console.log("this.state.filter[fil]", this.state.filter[fil]);
      if (fil === "FacilityID") {
        if (this.state.filter.FacilityID.length > 0) {
          hasValue = true;
          break;
        }
      } else if (this.state.filter[fil]) {
        hasValue = true;
        break;
      }
    }

    if (!hasValue) {
      return sweetalert.fire({
        icon: "error",
        title: "",
        text: "At least one field must be used to search",
      });
    }
    this.setState({ showLoading: true }, async () => {
      try {
        let result = await BinAPI.searchReceivingLabels(page, {
          filter: this.getFilterObj(),
        });
        if (result.labels.length < 1) {
          sweetalert.fire({
            icon: "error",
            title: "",
            text: "No labels found",
          });
          this.setState({ showLoading: false, labels: [], totalLabels: 0 });
          return;
        }
        this.setState({
          labels: result.table_data,
          totalLabels: result.labels.length,
          page_options: getPageOptions(result.num_pages),
          showLoading: false,
        });
      } catch (e) {
        console.log(e);
        sweetalert.fire({ icon: "error", title: "", text: "Search Failed" });
        this.setState({ labels: [], showLoading: false });
      }
    });
  }

  useSorter(col: Column) {
    let sorter = new Sorter<any>();
    this.setState({
      labels: sorter.sortByKey(
        this.state.labels,
        col.key as keyof any,
        this.state.direction
      ),
      direction: this.state.direction === "asc" ? "desc" : "asc",
    });
  }

  render() {
    console.log("state ", this.state);
    console.log();
    return (
      <React.Fragment>
        <PaginationTool />
        <Overlay show_loading={this.state.showLoading} />
        <RetrieveReceivingLabelModal result={this.state.selectedResult} />
        <div className="container-fluid min-vh-100">
          <div className={"row"}>
            <div className="col-6 col-md-6 col-lg-6 col-xl-6 pt-2">
              <div className="card mb-2">
                <div className="card-header verlag-bold">
                  <h3>Retrieve Receiving Label</h3>
                </div>
                <div className="card-body">
                  <FilterCard
                    passClearStateFunc={this.assignClearState}
                    fields={[
                      {
                        label: "Facility",
                        key: "FacilityID",
                        type: FIELD_TYPE.SELECT,
                        options: this.state.facilities,
                        isMapped: true,
                        isMulti: true,
                        textType: "text",
                      },
                      { label: "Bin ID", key: "BinID", type: FIELD_TYPE.TEXT },
                      {
                        label: " Dropped Date",
                        key: "DroppedDate",
                        type: FIELD_TYPE.DATE,
                      },
                      {
                        label: "Dropped By",
                        key: "DroppedBy",
                        type: FIELD_TYPE.TEXT,
                      },
                      {
                        label: "Received By",
                        key: "ReceivedBy",
                        type: FIELD_TYPE.TEXT,
                      },
                    ]}
                    filterChanged={(e) => this.setState({ filter: e })}
                  />
                </div>
                <div className="card-footer">
                  <>
                    <button
                      className="btn btn-outline-primary"
                      onClick={() =>
                        this.setState(
                          {
                            selected_page: {
                              label: 1,
                              value: 1,
                            },
                          },
                          () => this.onSubmit(1)
                        )
                      }
                    >
                      Search
                    </button>
                  </>
                </div>
              </div>
            </div>
          </div>

          {this.state.totalLabels > 0 ? (
            <div className="col-12 col-md-12 col-lg-12 pt-2 pl-0">
              <div className="card mt-2 mb-5">
                <div className="card-header verlag-bold stickToTop">
                  <h3>
                    Receiving Labels
                    <section className="tableHeaderSection float-md-right ">
                      <h3 style={{ float: "right" }}>
                        Total: {this.state.totalLabels}
                      </h3>
                      <h4 className="d-inline-block float-right align-middle pr-2 ml-5">
                        Page{" "}
                      </h4>
                      <div className=" align-middle float-right pages">
                        <Select
                          isSearchable={true}
                          placeholder={"1"}
                          noOptionsMessage={() => "No option"}
                          value={this.state.selected_page}
                          onChange={(e: ReactSelect) =>
                            this.setState({ selected_page: e }, () =>
                              this.onSubmit(e.value)
                            )
                          }
                          className={"state_select page-num-select"}
                          options={this.state.page_options}
                        />
                      </div>
                    </section>
                  </h3>
                </div>
                <SimpleTable
                  columns={[
                    {
                      label: "BinID",
                      key: "BinID",
                      rawFormat: (val) => {
                        return (
                          <a
                            href={"#!"}
                            onClick={() =>
                              this.setState(
                                {
                                  selectedResult: JSON.parse(
                                    JSON.stringify(val)
                                  ),
                                },
                                () => showModalNoOutsideClick(RetrieveReceivingLabelModal.ID)
                              )
                            }
                          >
                            {val.BinID}
                          </a>
                        );
                      },
                    },
                    {
                      label: "Dropped Date",
                      key: "DroppedDate",
                      formatFunc: toLocalDateTime,
                    },
                    { label: "Dropped By", key: "DroppedBy" },
                    { label: "Received By", key: "ReceivedBy" },
                    { label: "# of Samples", key: "NumberSamples" },
                    { label: "Facility", key: "FacilityName" },
                  ]}
                  table_data={this.state.labels}
                  columnClickedCallback={(col) => {
                    this.useSorter(col);
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}
