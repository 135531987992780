import React from "react";
import FilterCard, { FIELD_TYPE, ReactSelect } from "../FilterCard";
import domain from "../../Domain";
// import {Sample} from "../../types/Sample";
import moment from "moment";
import * as EmailValidator from "email-validator";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import Overlay from "../Overlay";
import { SamplesModal } from "../modals/SamplesModal";
import PodSelect from "../PodSelect";
import ReactToPrint from "react-to-print";
import SimpleTable from "../tables/SimpleTable";
import {getPageOptions, parseDate, showModalNoOutsideClick} from "../../util/FormatUtil";
import {ManageFacilityModal} from "../modals/ManageFacilityModal";
import {hideModal} from "../../util/FormatUtil";
import {sweetalert} from "../../App";
import NetworkUtil from "../../network/NetworkUtil";
import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import FacilityType, { FacilitySearch } from "../../types/Facility";
import Select from "react-select";
import SystemAPI from "../../network/SystemAPI";
import { FacilityAPI } from "../../network/FacilityAPI";
import AdminAPI from "../../network/AdminAPI";
import PaginationTool from "../PaginationTool";

interface FacilityState {
  states: any;
  showLoading: boolean;
  facilities: any;
  facilityNames: any;
  facilityGroupsforModal: any;
  groups: any;
  sequence: any;
  abbreviation: any;
  cities: any;
  counties: any;
  tableData;
  filter?;
  facilityResults?;
  selectedResult?;
  totalFac?: number;

  FacilityID?;
  FacilityGroup?;
  FacilitySeq?;
  FacilityAbbreviation?;
  FacilityCity?;
  FacilityCounty?;

  direction: TableOrder;

  page_options: ReactSelect[];
  selected_page?;

  isGlobal?: boolean;
}

export class Facility extends React.Component<any, FacilityState> {
  constructor(props) {
    super(props);
    this.state = {
      states: [],
      facilities: [],
      facilityNames: [],
      facilityGroupsforModal: [],
      groups: [],
      sequence: [],
      abbreviation: [],
      cities: [],
      counties: [],
      tableData: null,
      showLoading: false,
      facilityResults: [],
      totalFac: 0,
      direction: "asc",
      page_options: [{ value: "1", label: "1" }],
      selected_page: { value: 1, label: 1 },
    };
    this.handleExportToCSV = this.handleExportToCSV.bind(this);
  }

  fromSet(a) {
    return Array.from(new Set(a)).filter((a) => a);
  }

  componentDidMount() {
    this.setState({ showLoading: true }, () => {
      SystemAPI.getAllStates().then((data) => {
        this.setState({ states: data });
      });

      AdminAPI.getPermissions().then((data) => {
        this.setState({ isGlobal: data.isGlobal });
      });

      AdminAPI.getAllFacilities().then((data) => {
        let facs = data.authorizedFacilities;
        let facilityNamesMapped = this.fromSet(
          facs
            .filter((f) => f.FacilityName)
            .map((obj) => {
              return { label: obj.FacilityName, value: obj.ID };
            })
        );
        let facilityGroupsMapped = this.fromSet(
          facs.map((obj) => {
            return obj.FacilityGroup;
          })
        );
        let facilitySeqMapped = this.fromSet(
          facs.map((obj) => {
            return obj.FacilitySeq;
          })
        );
        let facilityAbbrMapped = this.fromSet(
          facs.map((obj) => {
            return obj.FacilityAbbreviation;
          })
        );
        let facilityCityMapped = this.fromSet(
          facs.map((obj) => {
            return obj.FacilityCity;
          })
        );
        let facilityCountyMapped = this.fromSet(
          facs.map((obj) => {
            return obj.FacilityCounty;
          })
        );
        let facilityGroupsForModal = facilityGroupsMapped.map((obj) => {
          return { label: obj, value: obj };
        });

        this.setState({
          facilityNames: facilityNamesMapped.sort(),
          facilityGroupsforModal: facilityGroupsForModal.sort(function (a, b) {
            return a.label.localeCompare(b.label);
          } as any),
          groups: facilityGroupsMapped.sort(),
          sequence: facilitySeqMapped.sort(function (a, b) {
            return a - b;
          } as any),
          abbreviation: facilityAbbrMapped.sort(),
          cities: facilityCityMapped.sort(),
          counties: facilityCountyMapped.sort(),
          showLoading: false,
        });
      });
    });
  }

  getFilterObj() {
    return {
      FacilityName: this.state.filter["FacilityID"],
      FacilityGroup: this.state.filter["FacilityGroup"],
      FacilitySeq: this.state.filter["FacilitySeq"],
      FacilityAbbreviation: this.state.filter["FacilityAbbreviation"],
      FacilityCity: this.state.filter["FacilityCity"],
      FacilityCounty: this.state.filter["FacilityCounty"],
    };
  }

  search(page) {
    this.setState({ showLoading: true }, async () => {
      try {
        let result = await FacilityAPI.facilitySearch(
          this.getFilterObj(),
          page
        );
        if (result.data.length < 1) {
          sweetalert.fire({
            icon: "error",
            title: "",
            text: "No data returned.  Please refine search.",
          });
          this.setState({
            tableData: [],
            totalFac: 0,
            showLoading: false,
          });
          return;
        }
        this.setState({
          tableData: result.table_data,
          totalFac: result.data.length,
          showLoading: false,
          page_options: getPageOptions(result.num_pages),
        });
      } catch (e) {
        console.log(e);
        sweetalert.fire({ icon: "error", title: "", text: "Search failed" });
        this.setState({ showLoading: false });
      }
    });
  }

  onSubmit(facility) {
    this.setState({ showLoading: true }, async () => {
      try {
        let result = await FacilityAPI.facilityUpsert(facility);
        if (!result.success) {
          sweetalert.fire({ icon: "error", title: "", text: result.reason });
          this.setState({ showLoading: false });
          return;
        } else {
          sweetalert.fire({
            icon: "success",
            title: "",
            text: "Facility saved successfully",
          });

          AdminAPI.getAllFacilities().then((data) => {
            let facs = data.authorizedFacilities;
            let facilityNamesMapped = this.fromSet(
              facs
                .filter((f) => f.FacilityName)
                .map((obj) => {
                  return { label: obj.FacilityName, value: obj.ID };
                })
            );
            let facilityGroupsMapped = this.fromSet(
              facs.map((obj) => {
                return obj.FacilityGroup;
              })
            );
            let facilitySeqMapped = this.fromSet(
              facs.map((obj) => {
                return obj.FacilitySeq;
              })
            );
            let facilityAbbrMapped = this.fromSet(
              facs.map((obj) => {
                return obj.FacilityAbbreviation;
              })
            );
            let facilityCityMapped = this.fromSet(
              facs.map((obj) => {
                return obj.FacilityCity;
              })
            );
            let facilityCountyMapped = this.fromSet(
              facs.map((obj) => {
                return obj.FacilityCounty;
              })
            );
            let facilityGroupsForModal = facilityGroupsMapped.map((obj) => {
              return { label: obj, value: obj };
            });

            this.setState({
              facilityNames: facilityNamesMapped.sort(),
              facilityGroupsforModal: facilityGroupsForModal.sort(function (
                a,
                b
              ) {
                return a.label.localeCompare(b.label);
              } as any),
              groups: facilityGroupsMapped.sort(),
              sequence: facilitySeqMapped.sort(function (a, b) {
                return a - b;
              } as any),
              abbreviation: facilityAbbrMapped.sort(),
              cities: facilityCityMapped.sort(),
              counties: facilityCountyMapped.sort(),
              showLoading: false,
            });
          });

          this.search(1);
        }
      } catch (e) {
        console.log(e);
        sweetalert.fire({ icon: "error", title: "", text: "Failed" });
        this.setState({ showLoading: false });
        this.search(1);
      }
    });
  }

  handleExportToCSV() {
    let filter = this.getFilterObj();
    this.setState({ showLoading: true }, async () => {
      await NetworkUtil.downloadCSV(
        "/api/admin/facilities/csvExport",
        "Facilities.xlsx",
        { filter: filter }
      );
      this.setState({ showLoading: false });
    });
  }

  useSorter(col: Column) {
    let sorter = new Sorter<FacilityType>();
    this.setState({
      tableData: sorter.sortByKey(
        this.state.tableData,
        col.key as keyof FacilityType,
        this.state.direction
      ),
      direction: this.state.direction === "asc" ? "desc" : "asc",
    });
  }

  render() {
    return (
      <React.Fragment>
        <PaginationTool />
        <div className="container-fluid min-vh-100">
          <a id={"top"}>{}</a>
          <Overlay show_loading={this.state.showLoading} />
          <ManageFacilityModal
            facility={this.state.selectedResult}
            facilityGroups={this.state.facilityGroupsforModal}
            states={this.state.states}
            isGlobal={this.state.isGlobal}
            onSubmit={(facility) => {
              hideModal(ManageFacilityModal.ID);
              this.onSubmit(facility);
            }}
          />
          <div className="container-fluid  min-vh-100 ">
            <div className={"row"}>
              <div className="col-12 col-md-12 col-lg-8 col-xl-5 pt-2">
                <div className="card mb-2">
                  <div className="card-header verlag-bold">
                    <h3>Facility Lookup</h3>
                  </div>
                  <div className="card-body">
                    <FilterCard
                      fields={[
                        {
                          label: "Name",
                          key: "FacilityID",
                          type: FIELD_TYPE.SELECT,
                          options: this.state.facilityNames,
                          isMapped: true,
                          isMulti: true,
                          textType: "text",
                        },
                        {
                          label: "Group",
                          key: "FacilityGroup",
                          type: FIELD_TYPE.SELECT,
                          options: this.state.groups,
                          isMulti: true,
                          textType: "text",
                        },
                        {
                          label: "Sequence",
                          key: "FacilitySeq",
                          type: FIELD_TYPE.SELECT,
                          options: this.state.sequence,
                          isMulti: true,
                          textType: "text",
                        },
                        {
                          label: "Abbreviation",
                          key: "FacilityAbbreviation",
                          type: FIELD_TYPE.SELECT,
                          options: this.state.abbreviation,
                          isMulti: true,
                          textType: "text",
                        },
                        {
                          label: "City",
                          key: "FacilityCity",
                          type: FIELD_TYPE.SELECT,
                          options: this.state.cities,
                          isMulti: true,
                          textType: "text",
                        },
                        {
                          label: "County",
                          key: "FacilityCounty",
                          type: FIELD_TYPE.SELECT,
                          options: this.state.counties,
                          isMulti: true,
                          textType: "text",
                        },
                      ]}
                      filterChanged={(filter) =>
                        this.setState({ filter: filter })
                      }
                    />
                  </div>
                  <div className="card-footer">
                    <button
                      className={"btn btn-outline-primary verlag-bold"}
                      onClick={() =>
                        this.setState(
                          {
                            selected_page: {
                              label: 1,
                              value: 1,
                            },
                          },
                          () => this.search(1)
                        )
                      }
                    >
                      Search
                    </button>
                    <button
                      className={"btn btn-outline-primary verlag-bold"}
                      style={{ float: "right" }}
                      onClick={() =>
                        this.setState({ selectedResult: null }, () =>
                        showModalNoOutsideClick(ManageFacilityModal.ID)
                        )
                      }
                    >
                      Create Facility
                    </button>
                  </div>
                </div>
              </div>

              {this.state.totalFac > 0 && (
                <div className="col-12 col-md-12 col-lg-12 pt-2">
                  <div className="card mt-2 mb-5">
                    <div className="card-header verlag-bold stickToTop">
                      <h3>
                        Facilities
                        <button
                          className={"btn btn-outline-primary ml-2"}
                          onClick={() => this.handleExportToCSV()}
                        >
                          Export to CSV
                        </button>
                        <section className="tableHeaderSection float-md-right ">
                          <h3 style={{ float: "right" }}>
                            Total: {this.state.totalFac}
                          </h3>
                          <h4 className="d-inline-block float-right align-middle pr-2 ml-5">
                            Page{" "}
                          </h4>
                          <div className=" align-middle float-right pages">
                            <Select
                              isSearchable={true}
                              placeholder={"1"}
                              noOptionsMessage={() => "Failed to Load"}
                              value={this.state.selected_page}
                              onChange={(e: ReactSelect) =>
                                this.setState({ selected_page: e }, () =>
                                  this.search(e.value)
                                )
                              }
                              className={"state_select page-num-select"}
                              options={this.state.page_options}
                            />
                          </div>
                        </section>
                      </h3>
                    </div>
                    <div className="card-body p-0 m-0 table-responsive-md">
                    <SimpleTable
                       columns={[
                        {
                          label: "Name",
                          key: "FacilityName",
                          rawFormat: (val) => {
                            return (
                              <a
                                href={"#top"}
                                onClick={() =>
                                  this.setState(
                                    {
                                      selectedResult: JSON.parse(
                                        JSON.stringify(val)
                                      ),
                                    },
                                    () => showModalNoOutsideClick(ManageFacilityModal.ID)
                                  )
                                }
                              >
                                {val.FacilityName}
                              </a>
                            );
                          },
                        },
                        { label: "Group", key: "FacilityGroup" },
                        { label: "Sequence", key: "FacilitySeq" },
                        { label: "Abbreviation", key: "FacilityAbbreviation" },
                        { label: "City", key: "FacilityCity" },
                        { label: "County", key: "FacilityCounty" },
                      ]}
                      table_data={this.state.tableData}
                      columnClickedCallback={(col) => {
                        this.useSorter(col);
                      }}
                    />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
