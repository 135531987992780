import React, { Component } from "react";
import { FixedSizeList as List } from "react-window";

interface PodListProps {
    options
    children
    maxHeight
    getValue
    clearValue
    cx
    getStyles
    hasValue
    isMulti
    innerRef
    focusedOption
    innerProps
    className
    getClassNames
    isRtl
    selectOption
    selectProps
    setValue
    theme
}
interface PodListState { }

class FixedSizeList extends Component<PodListProps, PodListState> {
    static height = 35;

    render() {
        const { options, children, maxHeight, getValue } = this.props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * FixedSizeList.height;

        return (
            <List
                height={maxHeight}
                itemCount={children.length}
                itemSize={FixedSizeList.height}
                initialScrollOffset={initialOffset}
            >
                {({ index, style }) => <div style={style}>{children[index]}</div>}
            </List>
        );
    }
}
export default FixedSizeList;