import React from "react";
import Overlay from "../Overlay";
import {sweetalert} from "../../App";
import SimpleTable from "../tables/SimpleTable";
import BatchesAPI, {SampleBatchInfo} from "../../network/BatchesAPI";
import Batch from "../../types/Batch";
import {BatchSelect} from "./selects/BatchSelect";
import {Column} from "../tables/TableBase";
import {Sorter, TableOrder} from "../../sorting/Sorter";

interface BatchState{
    batchID?:number
    showLoading?
    batches:{label, value}[] // value is batchID
    samples:SampleBatchInfo[]
    direction: TableOrder
}
export class BatchReporting extends React.Component<{ }, BatchState>{

    constructor(props) {
        super(props);
        this.state = {
            showLoading:true,
            batches:[],
            samples:[],
            direction: 'asc',
        };
        this.submit = this.submit.bind(this);
    }

    onBatchChange = async (batchID:number) => {
        this.setState({batchID:batchID, showLoading:true}, async() =>{
            let response = await BatchesAPI.getSamplesByBatchID(batchID);
            console.log(response);
            if(!response.success){
                await sweetalert.fire({icon: 'error', title: '', text: response.reason});
            }else if(response.samples.length === 0){
                await sweetalert.fire({icon: 'error', title: '', text: "No samples found in batch"});
            }else {
                this.setState({samples:response.samples});
            }
            this.setState({showLoading:false});
        });
    }

    submit(){
        if( !this.state.batchID){
            return sweetalert.fire({icon: 'error', title: '', text: "Please select a batch"});
        }
        this.setState({showLoading:true,}, async()=>{
            let response = await BatchesAPI.approveBatch(this.state.batchID);
            if(response.success){
                await sweetalert.fire({icon: 'success', title: '', text: "Batch reported successfully"});
            }else{
                await sweetalert.fire({icon: 'error', title: '', text: response.reason});
            }
            this.setState({showLoading:false});
        })
    }

    useSorter(col:Column){
        let sorter = new Sorter<any>()
        this.setState({samples: sorter.sortByKey(this.state.samples, col.key as keyof any,this.state.direction), direction: this.state.direction === 'asc' ? 'desc' : 'asc'});
    }

    render() {
        return <React.Fragment>
            <Overlay show_loading={this.state.showLoading}/>
            <div className="container-fluid  min-vh-100 ">
                <div className={"row"}>
                    <div className="offset-xl-3 col-xl-6 offset-lg-2 col-lg-6 col-md-6 col-12 pt-2">
                        <div className="card mb-2">
                            <div className="card-header verlag-bold">
                                <h3>Batch Reporting</h3>
                            </div>
                            <div className={"card-body"}>
                                <BatchSelect onSelected={(e:{label, value:Batch}) => this.setState({batchID:e.value.ID}, async () =>{
                                    await this.onBatchChange(this.state.batchID)
                                })} finishedLoading={() => this.setState({showLoading:false})}/>
                                <br/>
                                {this.state.samples.length > 0 &&
                                    <SimpleTable columns={[
                                        {label:"Specimen ID", key:"SpecimenID"},
                                        {label:"Internal Specimen ID", key:"InternalSpecimenID"},
                                        {label:"Well Slot", key:"WellSlot"},
                                        {label:"Result", key:"ResultDescription"}]} table_data={this.state.samples} columnClickedCallback={(col =>{
                                        this.useSorter(col);
                                    })}/>
                                }
                             </div>
                            <div className={this.state.samples.length > 0 ? "card-footer" : "invisible"}> <button className={"btn btn-outline-primary"} onClick={this.submit}>Send Results</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>;
    }
}