import React from "react";
import ModalForm from "./ModalForm";
import {showModalNoOutsideClick, toLocalDateTime} from "../../util/FormatUtil";
import QRCode from "react-qr-code";
import ReactToPrint from "react-to-print";

interface LabelProps{
    result?
}


export class RetrieveReceivingLabelModal extends React.Component<LabelProps, any>{
    public formRef: React.RefObject<ModalForm>;
    private componentRef: any;
    constructor(props) {
        super(props);


    }

    public static display(){
        //console.log("Display called")
        window["$"]("#" + RetrieveReceivingLabelModal.ID).modal('show');

    }

    public static readonly ID = "sample"
    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        console.log(this.props)
        return (
            <React.Fragment>

                <div className="modal fade form_modal" id={RetrieveReceivingLabelModal.ID} tabIndex={-1} role="dialog"
                     aria-labelledby="result_modal_label"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-body p-0 m-0">
                                <div className="container-fluid">
                                    <div className={"row"}>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-12 pt-2">
                                            <div className="card mb-2">
                                                <div className="modal-header" style={{borderBottom: '1px solid grey'}}>
                                                    <h3 className="modal-title">Receiving Label</h3>
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                {/*<div className="card-header verlag-bold">*/}
                                                {/*    <h3>Receiving Label</h3>*/}
                                                {/*    <button type="button" className="close" data-dismiss="modal" aria-label="Close">*/}
                                                {/*        <span aria-hidden="true">&times;</span>*/}
                                                {/*    </button>*/}
                                                {/*</div>*/}
                                                <div className="card-body">
                                                    <table className="table table-borderless" style={{borderBottom: '5px solid black'}}>
                                                        <tbody className={"text-center"}>
                                                        <tr>
                                                            <td style={{width: '50%', fontWeight: 'bold', fontSize: '1.5em'}}>Dropped
                                                                By:
                                                            </td>
                                                            <td style={{
                                                                width: '50%',
                                                                fontWeight: 'bold',
                                                                fontSize: '1.5em'
                                                            }}>{this.props.result ? this.props.result['DroppedBy'] : ''}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{
                                                                width: '50%',
                                                                fontWeight: 'bold',
                                                                fontSize: '1.5em'
                                                            }}>Facility:
                                                            </td>
                                                            <td style={{
                                                                width: '50%',
                                                                fontWeight: 'bold',
                                                                fontSize: '1.5em'
                                                            }}>{this.props.result ? this.props.result['FacilityName'] : ''}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{width: '50%', fontWeight: 'bold', fontSize: '1.5em'}}>#
                                                                Samples:
                                                            </td>
                                                            <td style={{
                                                                width: '50%',
                                                                fontWeight: 'bold',
                                                                fontSize: '1.5em'
                                                            }}>{this.props.result ? this.props.result['NumberSamples'] : ''}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{width: '50%', fontWeight: 'bold', fontSize: '1.5em'}}>Date
                                                                Dropped:
                                                            </td>
                                                            <td style={{
                                                                width: '50%',
                                                                fontWeight: 'bold',
                                                                fontSize: '1.5em'
                                                            }}>{this.props.result ? toLocalDateTime(this.props.result['DroppedDate']) : ''}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    <div className="row"></div>
                                                    <div className="row mt-4">
                                                        <div className="col-2"></div>
                                                        <div className="col-8 text-center">
                                                            <div className={"mb-3"}>
                                                                <QRCode
                                                                    value={this.props.result ? this.props.result['BinID'] : ''}
                                                                    size={150}/>
                                                            </div>
                                                            <div className={"mb-3"} style={{
                                                                fontWeight: 'bold',
                                                                fontSize: '2.5em'
                                                            }}>{this.props.result ? this.props.result['BinID'] : ''}</div>
                                                        </div>
                                                        <div className="col-2"></div>
                                                    </div>

                                                </div>

                                                <div className="card-footer mb-2 mr-2 ml-0" style={{backgroundColor: 'transparent', borderTop: 'none'}}>
                                                    <ReactToPrint
                                                        trigger={() => <button className={this.props.result ? "btn btn-primary" : "d-none"} style={{float: 'right'}}>Print Label</button>}
                                                        // @ts-ignore
                                                        content={() => this.componentRef}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"m-5 d-none"}>
                    <div className={"container"} ref={el => (this.componentRef = el)}>
                        <div className="row" style={{marginTop: '1em'}}>
                            {/*<div className="col-2"></div>*/}
                            <div className="col-10">
                                <table className="table table-borderless" style={{borderBottom: '5px solid black'}}>
                                    <tbody className={"text-center"}>
                                    <tr>
                                        <td style={{width: '50%', fontWeight: 'bold', fontSize: '1.5em'}}>Dropped By:</td>
                                        <td style={{width: '50%', fontWeight: 'bold',fontSize: '1.5em'}}>{this.props.result ? this.props.result['DroppedBy'] : ''}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width: '50%', fontWeight: 'bold', fontSize: '1.5em'}}>Facility:</td>
                                        <td style={{width: '50%', fontWeight: 'bold',fontSize: '1.5em'}}>{this.props.result ? this.props.result['FacilityName'] : ''}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width: '50%', fontWeight: 'bold', fontSize: '1.5em'}}># Samples:</td>
                                        <td style={{width: '50%', fontWeight: 'bold',fontSize: '1.5em'}}>{this.props.result ? this.props.result['NumberSamples'] : ''}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width: '50%', fontWeight: 'bold', fontSize: '1.5em'}}>Date Dropped:</td>
                                        <td style={{width: '50%', fontWeight: 'bold',fontSize: '1.5em'}}>{this.props.result ? toLocalDateTime(this.props.result['DroppedDate']) : ''}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-2"></div>
                        </div>
                        <div className="row" ></div>
                        <div className="row mt-4">
                            {/*<div className="col-2"></div>*/}
                            <div className="col-10 text-center">
                                <div className={"mb-3"}>
                                    <QRCode value={this.props.result ? this.props.result['BinID'] : ''} size={150} />
                                </div>
                                <div className={"mb-3"} style={{fontWeight: 'bold', fontSize: '2.5em'}}>{this.props.result ? this.props.result['BinID'] : ''}</div>
                            </div>
                            <div className="col-2"></div>

                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

