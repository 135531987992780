import React from "react";
import FilterCard, {FIELD_TYPE} from "./FilterCard";
import domain from "../Domain";
import {Sample} from "../types/Sample";
import moment from "moment";
import * as EmailValidator from "email-validator";
import {isPossiblePhoneNumber} from 'react-phone-number-input'
import {calculateAge, isBlank, isOnlyWhitespace} from "../util/ValidationUtil";
import {sweetalert} from "../App";
import SystemAPI from "../network/SystemAPI";
import Validator, {ValidationEntry} from "../validation/Validator";
import {Validators} from "../validation/Validators";
import {SampleCheckinBody} from "../network/SamplesAPI";
import TestType from "../types/TestType";

interface InformationProps{
    onChange:(e) => void,
    attachClearStateFunc?:(func) => void
}

interface InformationState{
    states:any
    gender:any
    ethnicity:any
    race:any

}
export class PatientInformation extends React.Component<InformationProps, InformationState>{

    constructor(props) {
        super(props);
        this.state ={
            states:[],
            gender:[],
            ethnicity:[],
            race:[],
        }
        this.assignClearState = this.assignClearState.bind(this);
    }

    componentDidMount() {
        SystemAPI.getAllStates().then(data => {
            this.setState({states: data})
        })
        SystemAPI.getAllRaces().then(data => {
            this.setState({race: data})
        })
        SystemAPI.getAllGenders().then(data => {
            this.setState({gender: data})
        })
        SystemAPI.getAllEthnicities().then(data => {
            this.setState({ethnicity: data})
        })
    }

    getYesNoOptions(){
        return ["Yes", "No", "Unknown"];
    }

    processChange(e){
        let keys = Object.keys(e);
        for(let i = 0; i < keys.length; i++){
            let key = keys[i];
            if(e[key])
                e[key] = e[key].value ? e[key].value : e[key]
        }
        this.props.onChange(e)
    }


    public static validatePatient(s:Sample):boolean{
        let patientValidation = {
            PatientFirstName: s.PatientFirstName,
            PatientMiddleName: s.PatientMiddleName,
            PatientLastName: s.PatientLastName,
            PatientDOB: s.PatientDOB,
            PatientEmail: s.PatientEmail,
            ConfirmEmail: s['confirmEmail'],
            PatientGender: s.PatientGenderID,
            PatientAddress: s.PatientAddress,
            PatientCity: s.PatientCity,
            PatientZip: s.PatientZip,
            PatientState: s.PatientState,
            PatientCounty: s.PatientCounty,
            PatientPhone: s.PatientPhone,
            PatientEthnicity: s.PatientEthnicityID,
            PatientRace: s.PatientRaceID,
            FirstTest: s.FirstTest,
            EmployedHealthcare: s.EmployedHealthcare,
            Symptomatic: s.Symptomatic,
            OnsetSymptomsDate: s.OnsetSymptomsDate,
            Hospitalized: s.Hospitalized,
            ICU: s.ICU,
            ResidentCongregateCare: s.ResidentCongregateCare,
            Pregnant: s.Pregnant,
        }

        let validator = new Validator<any>()
            .withValidation("PatientFirstName", Validators.requireNotBlankAndLength(50, "Patient First Name"))
        if(s.PatientMiddleName && s.PatientMiddleName.trim().length > 0){
            validator = validator.withValidation("PatientMiddleName", Validators.requireNotBlankAndLength(50, "Patient Middle Name"))
        }
        validator = validator.withValidation("PatientLastName", Validators.requireNotBlankAndLength(50, "Patient Last Name"))
            .withValidation("PatientDOB", Validators.requireDOB(150, "Patient Date of Birth"))
            .withComposedValidation("PatientEmail", new ValidationEntry(Validators.requireValidEmail("Patient Email")), Validators.requireNotBlankAndLength(100, "Patient Email"), new ValidationEntry<any>((key, attr, obj) =>{
                if (obj.PatientEmail !== s['confirmEmail']) {
                    return {success:false, error:"Patient Email and Confirmation Email do not match"};
                }
                return {success:true, error:null}
            }))
            .withSimpleValidation("PatientGender", Validators.requireNonNullValidator("Patient Gender"))
            .withValidation("PatientAddress", Validators.requireNotBlankAndLength(500, "Patient Address"))
            .withValidation("PatientCity", Validators.requireNotBlankAndLength(100, "Patient City"))
            .withComposedValidation("PatientZip", new ValidationEntry(Validators.requireNotBlankValidator("Patient Zipcode")), new ValidationEntry(Validators.requireZip("Patient Zipcode")))
            .withSimpleValidation("PatientState", Validators.requireNonNullValidator("Patient State"))
            .withValidation("PatientCounty", Validators.requireNotBlankAndLength(100, "Patient County"))
            .withComposedValidation("PatientPhone", new ValidationEntry(Validators.requireNonNullValidator("Patient Phone")), new ValidationEntry(Validators.requirePhone("Patient Phone")))
            .withSimpleValidation("PatientEthnicity", Validators.requireNonNullValidator("Patient Ethnicity"))
            .withSimpleValidation("PatientRace", Validators.requireNonNullValidator("Patient Race"))
            .withSimpleValidation("FirstTest", Validators.requireNonNullValidator("First Test"))
            .withSimpleValidation("EmployedHealthcare", Validators.requireNonNullValidator("Employed Healthcare"))
            .withSimpleValidation("Symptomatic", Validators.requireNonNullValidator("Symptomatic"))
        console.log('s.Symptomatic', s.Symptomatic)
            if(s.Symptomatic === "Yes"){
                validator = validator.withComposedValidation("OnsetSymptomsDate", new ValidationEntry(Validators.requireNotBeforePatientDOB(s.PatientDOB, "Onset Symptoms Date")), new ValidationEntry(Validators.requireNonNullValidator("Onset Symptoms Date")), new ValidationEntry(Validators.requireNotFutureDate("Onset Symptoms Date")))
            }
            if(s.Symptomatic !== "Yes"){
                validator.withSimpleValidation("OnsetSymptomsDate", Validators.requireBlankValidator("Onset Symptoms Date", "Symptomatic"))
            }
            validator = validator.withSimpleValidation("Hospitalized", Validators.requireNonNullValidator("Hospitalized"))
                .withSimpleValidation("ICU", Validators.requireNonNullValidator("ICU"))
                .withSimpleValidation("ResidentCongregateCare", Validators.requireNonNullValidator("Resident Congregate Care"))
                .withSimpleValidation("Pregnant", Validators.requireNonNullValidator("Pregnant"))
        let validationResponse = validator.validate(patientValidation);
        if(!validationResponse.success) {
            sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
            return false
        }

        return true;
    }
    clearFilterState:() => void = null;

    assignClearState(func){
        this.clearFilterState = func;
    }

    clearState = () =>{
        this.clearFilterState();
    }

    shouldComponentUpdate(nextProps: Readonly<InformationProps>, nextState: Readonly<InformationState>, nextContext: any): boolean {
        if(this.props.attachClearStateFunc)
            this.props.attachClearStateFunc(this.clearState)
        return true;
    }


    render() {
        return  <div className="card mt-2">

            <div className="card-header verlag-bold">
                <h3>Patient Information</h3>
            </div>
            <div className="card-body">
                <FilterCard passClearStateFunc={this.assignClearState} fields={[
                        {label:"First Name", "key":"PatientFirstName", type:FIELD_TYPE.TEXT, textType: "text", maxLength: 50},
                        {label:"Middle Initial", "key":"PatientMiddleName", type:FIELD_TYPE.TEXT, textType: "text", maxLength: 50},
                        {label:"Last Name", "key":"PatientLastName", type:FIELD_TYPE.TEXT, textType: "text", maxLength: 50},
                        {label:"Email to Receive Results", "key":"PatientEmail", type:FIELD_TYPE.TEXT, textType:"email", maxLength: 100},
                        {label:"Confirm Email", "key":"confirmEmail", type:FIELD_TYPE.TEXT, textType:"email", maxLength: 100},
                        {label:"Gender", "key":"PatientGenderID", type:FIELD_TYPE.SELECT, options:this.state.gender, isMapped:true},
                        {label:"Date of Birth", "key":"PatientDOB", type:FIELD_TYPE.DATE, dateWarning:true },
                        {label:"Street Address", "key":"PatientAddress", type:FIELD_TYPE.TEXT, maxLength: 500},
                        {label:"City", "key":"PatientCity", type:FIELD_TYPE.TEXT, maxLength: 100},
                        {label:"Zip", "key":"PatientZip", type:FIELD_TYPE.TEXT, maxLength: 50},
                        {label:"State", "key":"PatientState", type:FIELD_TYPE.SELECT, options:this.state.states, isMapped:true},
                        {label:"County", "key":"PatientCounty", type:FIELD_TYPE.TEXT, maxLength: 100},
                        {label:"Cell Phone", "key":"PatientPhone", type:FIELD_TYPE.PHONE, textType:"tel", pattern:"[0-9]{3}-[0-9]{3}-[0-9]{4}"},
                        {label:"Ethnicity", "key":"PatientEthnicityID", type:FIELD_TYPE.SELECT, options:this.state.ethnicity, isMapped:true},
                        {label:"Race", "key":"PatientRaceID", type:FIELD_TYPE.SELECT, options:this.state.race, isMapped:true},
                        {label:"Is this your first test?", "key":"FirstTest", type:FIELD_TYPE.SELECT, options:this.getYesNoOptions()},
                        {label:"Are you employed in healthcare?", "key":"EmployedHealthcare", type:FIELD_TYPE.SELECT, options:this.getYesNoOptions()},
                        {label:<React.Fragment>Are you symptomatic as defined by the <a target={"_blank"} href='https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/symptoms.html'>CDC</a>?</React.Fragment>, "key":"Symptomatic", type:FIELD_TYPE.SELECT, options:this.getYesNoOptions()},
                        {label:"If yes, what was the date of symptom onset?", "key":"OnsetSymptomsDate", type:FIELD_TYPE.DATE, dateWarning:true},
                        {label:"Are you currently hospitalized?", "key":"Hospitalized", type:FIELD_TYPE.SELECT, options:this.getYesNoOptions(), default:{label:"No", value:"No"}},
                        {label:"Are you currently in an intensive care unit?", "key":"ICU", type:FIELD_TYPE.SELECT, options:this.getYesNoOptions(), default:{label:"No", value:"No"}},
                        {label:"Are you a resident in a Congregate Care setting?", "key":"ResidentCongregateCare", type:FIELD_TYPE.SELECT, options:this.getYesNoOptions(), default:{label:"No", value:"No"}},
                        {label:"Are you pregnant?", "key":"Pregnant", type:FIELD_TYPE.SELECT, options:this.getYesNoOptions(), default:{label:"No", value:"No"}},
                    ]} filterChanged={(e)=>this.processChange(e)}/>
            </div>
        </div>;
    }
}