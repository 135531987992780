import NetworkUtil from "./NetworkUtil";
import {Sample} from "../types/Sample";
import TestType from "../types/TestType"
import TestReport from "../types/TestReport"
import {ResultType} from "../types/ResultType";
import {ServerResponse} from "../types/ServerResponse";
import {Service} from "../types/Service";

export default class SubmissionAPI {

    public static async getEvents():Promise<any>{
        return NetworkUtil.makeGet("/api/events");
    }

    public static async patientForm(patient):Promise<{success:boolean, ReqNum:string, timeTaken:boolean, reason:string}>{
        return NetworkUtil.makePost("/api/submission", patient);
    }

    public static async getApptInfoForCancel(appointment):Promise<{apptInfo}>{
        return NetworkUtil.makePost("/api/submission/getApptInfoForCancel", appointment);
    }

    public static async appointmentCancel(appointment):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/submission/cancel", appointment);
    }

    public static async submitResults(filterObj, token):Promise<{success:boolean, reason:string, result:TestReport}>{
        return NetworkUtil.makePost("/api/submission/results", {filter: filterObj, token: token});
    }

    public static async release(entity, reqNum, token):Promise<{success:boolean, reason:string, result:TestReport}>{
        return NetworkUtil.makePost("/api/submission/release", {entity: entity, reqNum: reqNum, token: token});
    }

    //route not complete
    public static async consent(entity):Promise<{success:boolean, reason:string, result:TestReport}>{
        return NetworkUtil.makePost("/api/submission/consent", entity);
    }

}