import React from "react";
import Select from "react-select";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
// import TimePicker from 'react-time-picker';
// import DatePicker from "react-date-picker";
import DatePicker from 'react-datepicker'
import {BsCheck} from "react-icons/bs";
import moment from "moment";
import {sweetalert} from "../App";
export const FIELD_TYPE = {
    SELECT:"select",
    DATE:"date",
    TEXT:"text",
    TEXT_AREA:"text_area",
    PHONE: "PHONE",
    TIME:"TIME",
    NUMBER: "number",
}
export interface ReactSelect{
    label:string
    value:any
}

interface FilterPart{
    textType?: string;
    pattern?:string
    onInput?: any;
    key:string
    label:string | Element | any
    default?:any
    options?:ReactSelect[] | any[]
    isMapped?:boolean
    placeholder?:string
    isSearchable?:boolean
    isMulti?:boolean
    noOpMessage?:string
    result_types?:string[]
    rows?:number
    readOnly? :boolean
    type:string
    callback?:(any)=>void
    min?: number
    max?: number
    disable?: boolean
    popoverText?:string
    maxLength?:number,
    canUpdate?:(value) => boolean
    isActive?: boolean
    dateWarning?:boolean
}

interface FilterProps{
    fields:FilterPart[]
    filterChanged:(FilterState:any)=>void
    labelStyle?
    fieldStyle?
    passClearStateFunc?
}
interface FilterState{
}
export default class FilterCard extends React.Component<FilterProps,FilterState>{

    constructor(props){
        super(props)
        this.state = {};
        let fields = this.props.fields ? this.props.fields : [];
        let state = {};

        fields.forEach(val =>{
            state[val.key] = val.default ? val.default : "";

        });
        this.state = state;
        this.props.filterChanged(this.state)
        this.clearState = this.clearState.bind(this);
        this.filterChanged = this.filterChanged.bind(this);
    }

    shouldComponentUpdate(nextProps: Readonly<FilterProps>, nextState: Readonly<FilterState>, nextContext: any): boolean {
        if(this.props.passClearStateFunc) {
            this.props.passClearStateFunc(this.clearState);
        }
        return true;
    }

    clearState = () =>{
        let keys = Object.keys(this.state);
        let propFields = this.props.fields;
        this.setState(this.getInitialState(), () =>{
            for(let i = 0; i < keys.length; i++){
                let key = keys[i];
                let propField = propFields.find(f => f.key === key);

                if(propField && propField.default) {
                    this.filterChanged(key, propField.default)
                }else if(key.includes("_selectstate")){
                    let selectPropField = propFields.find(f => f.key === key.replace("_selectstate", ""));
                    if(selectPropField && selectPropField.default){
                        this.filterChanged(key, selectPropField.default);
                    }else {
                        // on CheckIn page after setting the Facility filter, on clear the dropdown
                        // would populate with an empty string as a selection - ah
                        // this.filterChanged(key, {label: "", value: null});
                        this.filterChanged(key,null) 
                    }
                }else {
                    this.filterChanged(key, '');
                }
            }
        })
    }
    getInitialState = () => {
        let fields = this.props.fields ? this.props.fields : [];
        let state = {};

        fields.forEach(val =>{
            state[val.key] = val.default ? val.default : "";
        });
        return state;
    }
    select_changed(data, e){
        let arg = data.key + "_selectstate";
        let obj = {};
        obj[arg] = JSON.parse(JSON.stringify(e));
        this.setState(obj, () =>{
            if(!e) {
                e = [];
                this.filterChanged(data.key, e);
                return;
            }
            e = Array.isArray(e) ? e.map(b => b.value) : e.value;
            this.filterChanged(data.key, e);
        })

    }

    filterChanged(key, e){

        let state = {}
        state[key] = e;

        this.setState(state,() =>{
            let obj = {};
            let keys = Object.keys(JSON.parse(JSON.stringify(this.state)));
            for(let i = 0; i < keys.length; i++){
                let key = keys[i];
                if(!key.includes("_selectstate")){
                    obj[key] = JSON.parse(JSON.stringify(this.state[key]));
                }
            }
            this.props.filterChanged(obj);
            this.props.fields.forEach(f =>{
                if(f.key === key && f.callback)
                    f.callback(JSON.parse(JSON.stringify(e)));
            })
        })
    }

    getLabelStyle(){
        return this.props.labelStyle ? this.props.labelStyle  : "col-sm-4 col-form-label";
    }

    getFieldStyle(){
        return this.props.fieldStyle ? this.props.fieldStyle : "col-sm-8 p-0 m-0 ";
    }

    buildText(data:FilterPart){
        return(
            <div className="form-group row"  data-toggle={'tooltip'} data-placement={'top'} title={data.popoverText}>
                <label htmlFor={data.key} className={this.getLabelStyle()} >{data.label}
                {data.isActive ? (<BsCheck className={'float-right'} color={'blue'} size={20}  />) : null}
                </label>
                <div className={this.getFieldStyle()}>
                    <input type={data.textType ? data.textType : "search"} id={data.key} placeholder={data.placeholder}
                           className={'form-control'}
                           value={this.state[data.key]}
                           readOnly={data.readOnly}
                           autoComplete="off"
                           pattern={data.pattern ? data.pattern : null}
                           onInput={data.onInput}
                           disabled={data.disable}
                           maxLength={data.maxLength ? data.maxLength : null}
                    onChange={(e) =>{
                        if(!data.canUpdate || data.canUpdate(e.target.value)){
                            this.filterChanged(data.key, e.target.value)
                        }
                    }}/>
                </div>
            </div>
        )
    }

    buildNumber(data:FilterPart){
        return(
            <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={data.popoverText}>
                <label htmlFor={data.key} className={this.getLabelStyle()}>{data.label}
                {data.isActive ? (<BsCheck className={'float-right'} color={'blue'} size={20}  />) : null}
                </label>
                <div className={this.getFieldStyle()}>
                    <input type={"number"}  id={data.key} placeholder={data.placeholder}
                           className={'form-control'}
                           value={this.state[data.key]}
                           readOnly={data.readOnly}
                           autoComplete="off"
                           pattern={data.pattern ? data.pattern : null}
                           onInput={data.onInput}
                           min={data.min}
                           max={data.max}
                           disabled={data.disable}
                           onChange={(e) =>{
                               if(!data.canUpdate || data.canUpdate(e.target.value)) {
                                   this.filterChanged(data.key, e.target.value)
                               }
                           }}/>
                </div>
            </div>
        )
    }

    buildTextArea(data:FilterPart){
        return (
            <div className={"form-group row"} data-toggle={'tooltip'} data-placement={'top'} title={data.popoverText}>
            <label htmlFor={data.key} className={this.getLabelStyle()}>{data.label}
            {data.isActive ? (<BsCheck className={'float-right'} color={'blue'} size={20}  />) : null}
            </label>
            <div className={this.getFieldStyle()}>
                <textarea rows={data.rows} id={data.key} placeholder={data.placeholder}
                          className={'form-control'}
                       value={this.state[data.key]}
                          readOnly={data.readOnly}
                       onChange={(e) => this.filterChanged(data.key, e.target.value)}
                          maxLength={data.maxLength ? data.maxLength : null}
                />

            </div>

        </div>
        )
    }

    buildTime(data:FilterPart){
        return <div className={"form-group row"} data-toggle={'tooltip'} data-placement={'top'} title={data.popoverText}>
            <label htmlFor={data.key} className="col-sm-4 col-form-label">{data.label}
            {data.isActive ? (<BsCheck className={'float-right'} color={'blue'} size={20}  />) : null}
            </label>
            <div className="col-sm-8 p-0 m-0">
                <DatePicker
                    placeholderText="--:-- --"
                    minDate={new Date('01-01-1000')}
                    maxDate={new Date('12-31-9999')}
                    disabled={data.disable}
                    showTimeSelect
                    showTimeSelectOnly
                    selected={Date.parse(moment(this.state[data.key], 'h:mm aa').toISOString())}
                    dateFormat="h:mm aa"
                    onChange={(e) =>  this.filterChanged(data.key, e)}
                />

            </div>

        </div>
    }


    buildDate(data:FilterPart){
        return <><div className={data.dateWarning ? "row" : "form-group row"} data-toggle={'tooltip'} data-placement={'top'} title={data.popoverText}>
            <label htmlFor={data.key} className="col-sm-4 col-form-label">{data.label}
            {data.isActive ? (<BsCheck className={'float-right'} color={'blue'} size={20}  />) : null}
            </label>
            <div className="col-sm-3 p-0 m-0" id={data.key}>
                <DatePicker
                    minDate={new Date('01-01-1000')}
                    maxDate={new Date('12-31-9999')}
                    disabled={data.disable}
                    placeholderText="--/--/----"
                    selected={Date.parse(moment(this.state[data.key], 'MM-DD-YYYY').toISOString())}
                    onChange={(e) => this.filterChanged(data.key, e)}
                />
            </div>
        </div>
            {data.dateWarning &&
                <div className="row mb-2">
            <div className="col-sm-4"></div>
            <div className="col-12 col-sm-8 p-0 m-0 text-danger" >*Please include '/' when entering date</div>
        </div>}
        </>
    }


    buildSelect(data:FilterPart){
        let options = data.options ? (data.isMapped ? data.options :  data.options.map((value, index) => {
            return (
                {label:value, value:value});
        })) : [];

        return(
            <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={data.popoverText}>
                <label htmlFor={data.key} className={this.getLabelStyle()}>{data.label}
                {data.isActive ? (<BsCheck className={'float-right'} color={'blue'} size={20}  />) : null}
                </label>
                <div className={this.getFieldStyle()}>
                    <Select
                        id={data.key}
                        isSearchable={data.isSearchable ? data.isSearchable : true}
                        placeholder={"Please Select..."}
                        noOptionsMessage={()=> data.noOpMessage ? data.noOpMessage : "No option"}
                        defaultValue={data.default}
                        isMulti={data.isMulti}
                        value={this.state[data.key + "_selectstate"]}
                        onChange={(e)=>{
                            this.select_changed(data, e)
                        }}
                        className={'state_select'}
                        options={options}
                        isDisabled={data.disable}
                    />

                </div>
            </div>
        )
    }

    buildPhone(data:FilterPart){
        return (<div className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={data.popoverText}>
            <label htmlFor={data.key} className={this.getLabelStyle()}>{data.label}
            {data.isActive ? (<BsCheck className={'float-right'} color={'blue'} size={20}  />) : null}
            </label>
            <div className={this.getFieldStyle()}>
                <PhoneInput
                    id={data.key}
                placeholder={data.placeholder ? data.placeholder : "Enter phone number"}
                onChange={(e) => this.filterChanged(data.key, e)}
                defaultCountry="US"
             value={this.state[data.key]}/>

            </div>
        </div>)
    }

    handleSubmit(event){
        event.preventDefault();
    }

    render() {
        let fields = this.props.fields ? this.props.fields : [];
        return (
            <form id={'filterForm'} onSubmit={this.handleSubmit}>
                {fields.map((val, index) =>{
                    if(val.type === FIELD_TYPE.SELECT){
                        return this.buildSelect(val)
                    }else if(val.type === FIELD_TYPE.TEXT){
                        return this.buildText(val)
                    }else if(val.type === FIELD_TYPE.TEXT_AREA){
                        return this.buildTextArea(val);
                    }else if(val.type === FIELD_TYPE.PHONE){
                        return this.buildPhone(val)
                    }else if(val.type === FIELD_TYPE.TIME){
                        return this.buildTime(val);
                    }else if(val.type === FIELD_TYPE.NUMBER){
                        return this.buildNumber(val);
                    }
                    else if(val.type === FIELD_TYPE.DATE){
                        return this.buildDate(val);
                    }
                })}
            </form>
        )
    }
}


