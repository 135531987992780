import NetworkUtil from "./NetworkUtil";
import {ServerResponse} from "../types/ServerResponse";
import {PodStat} from "../types/PodSched";

export default class RapidAPI{

    public static async rapidSearch(page, filter):Promise<{success: boolean, reason: string, patients:[], table_data?:PodStat[], num_pages:number}>{
        return NetworkUtil.makePost("/api/admin/rapid/search?page=" + page, filter);
    }

    public static async rapidEdit(body):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/rapid/edit", body);
    }

}