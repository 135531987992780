import React from "react";
import Select from "react-select";
import {Sample, SampleEdit} from "../../types/Sample";
import {parseDate, showModalNoOutsideClick} from "../../util/FormatUtil";
import domain from "../../Domain";
import {ResultType} from "../../types/ResultType";
import {sweetalert} from "../../App";
import moment from "moment";
import DatePicker from 'react-datepicker'
import TestType from "../../types/TestType";
import Validator, {ValidationEntry} from "../../validation/Validator";
import {Validators} from "../../validation/Validators";
import SystemAPI from "../../network/SystemAPI";
import AdminAPI from "../../network/AdminAPI";


interface RapidTestState{
    selectedResult?
    results:{value, label}[]
    selectedResultID?
    PatientInformed?
    PatientDOB?
}

interface RapidTestProps{
    tests?:TestType[]
    selectedPatient:Sample
    onSubmit
}

export class RapidTestModal extends React.Component<RapidTestProps, RapidTestState>{

    constructor(props) {
        super(props);
        this.state={
            results:[],
        }
    }

    public static display(){
        window["$"]("#" + RapidTestModal.ID).modal('show');
    }

    public submit(){
        console.log('props', this.props)

        let formValidation = {
            SelectedResultID: this.state.selectedResultID,
            PatientInformed: this.state.PatientInformed
        }
        let validator = new Validator<any>()
            .withSimpleValidation("SelectedResultID", Validators.requireNonNullValidator("Test Result"))
            .withSimpleValidation("PatientInformed", Validators.requireNonNullValidator("Patient Informed"))

        let validationResponse = validator.validate(formValidation);
        if(!validationResponse.success) {
            return sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
        }
        this.props.onSubmit({
            resultID: this.state.selectedResultID.value,
        PatientInformed: this.state.PatientInformed ? this.state.PatientInformed.value : false
        });
    }

    componentDidMount() {
        AdminAPI.getAllResultTypes().then(data => {
            this.setState({results: data} as any)
        })
    }

    componentWillReceiveProps(nextProps: Readonly<RapidTestProps>, nextContext: any) {
        if(nextProps.selectedPatient.ResultID){
            this.setState({selectedResultID:this.state.results.find(f => f.value === nextProps.selectedPatient.ResultID), PatientInformed:""})
        }else{
            this.setState({selectedResultID:"", PatientInformed:""})
        }

        if(nextProps.selectedPatient.PatientDOB){
            this.setState({PatientDOB: parseDate(nextProps.selectedPatient.PatientDOB)})
        }
    }

    handleDateOnChange(value, state){
        if (value) {
            this.setState({[state]: value } as any);
        }
        else{
            this.setState({[state]: null} as any)
        }
    }

    public static ID = "rapidTest"
    render() {

        let foundTest:TestType = this.props.tests.find(t => t.ID === this.props.selectedPatient.TestID);
        let validResults = [];
        if(foundTest){
            validResults = this.state.results.filter(r => foundTest.ValidResults.find(v => v.ID === r.value));
        }
      
        return <React.Fragment>
            <div className="modal fade form_modal" id={RapidTestModal.ID} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                        {/*<Modal_Header title={title}/>*/}
                        <div className="modal-header">
                            <h5 className="modal-title" id="result_modal_label">Test Completion</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body mr-3">
                            <div className="form-group row">
                                <label htmlFor={"FirstName"} className={"col-sm-4 col-form-label"}>First Name</label>
                                <div className={"col-sm-8 p-0 m-0"}>
                                    <input type={"search"} className="form-control" readOnly={true} id={"FirstName"}
                                           value={this.props.selectedPatient.PatientFirstName}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor={"LastName"} className={"col-sm-4 col-form-label"}>Last Name</label>
                                <div className={"col-sm-8 p-0 m-0"}>
                                    <input type={"search"} className="form-control" readOnly={true} id={"LastName"}
                                           value={this.props.selectedPatient.PatientLastName}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor={"DOB"} className={"col-sm-4 col-form-label"}>DOB</label>
                                <div className={"col-sm-8 p-0 m-0"}>
                                    {/*<input type={"search"} className="form-control" readOnly={true} id={"DOB"}*/}
                                    {/*       value={parseDate(this.props.selectedPatient.PatientDOB)}/>*/}

                                    <DatePicker
                                        id={"DOB"}
                                        // disabled
                                        readOnly
                                        minDate={new Date('01-01-1000')}
                                        maxDate={new Date('12-31-9999')}
                                        placeholderText="--/--/----"
                                        selected={this.state.PatientDOB ? Date.parse(moment(this.state.PatientDOB, 'MM-DD-YYYY').toISOString()) : null}
                                    />

                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor={"SpecimenID"} className={"col-sm-4 col-form-label"}>Specimen ID</label>
                                <div className={"col-sm-8 p-0 m-0"}>
                                    <input type={"search"} className="form-control" readOnly={true} id={"SpecimenID"}
                                           value={this.props.selectedPatient.SpecimenID}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor={"Test Result"} className={ "col-sm-4 col-form-label"}>Test Result</label>
                                    <div className={"col-sm-8 p-0 m-0 "} id={"Test Result"}>

                                    <Select
                                        placeholder={"Please Select..."}
                                        value={this.state.selectedResultID}
                                        onChange={(e)=>{
                                            this.setState({selectedResultID:e})
                                        }}
                                        className={"state_select"}
                                        options={validResults}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor={"Patient Informed"} className={ "col-sm-4 col-form-label"}>Patient Informed?</label>
                                    <div className={"col-sm-8 p-0 m-0 "} id={"Patient Informed"}>

                                    <Select
                                        placeholder={"Please Select..."}
                                        value={this.state.PatientInformed}
                                        onChange={(e)=>{
                                            this.setState({PatientInformed:e})
                                        }}
                                        className={"state_select"}
                                        options={[{label:"Yes", value:true}, {label:"No", value:false}]}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => this.submit()}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>;
    }
}