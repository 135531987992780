import NetworkUtil from "./NetworkUtil";
import {AlertAudit} from "../types/AlertAudit";
import {Batches} from "../types/Batches";
import {CommunicationTemplate} from "../types/CommunicationTemplate";
import Facility from "../types/Facility";
import {PodStat} from "../types/PodSched";
import {Sample} from "../types/Sample";
import {Service} from "../types/Service";
import LabInformation from "../types/LabInfo";

export default class AuditLogsAPI {
    public static async alertAuditLogs(filter: {filter:{Name?}}):Promise<{success:boolean, data:AlertAudit[]}>{
        return NetworkUtil.makePost(`/api/admin/audit/alertAuditLogs`, filter);
    }

    public static async batchAuditLogs(filter: {filter:{Name?}}):Promise<{success:boolean, data:Batches[]}>{
        return NetworkUtil.makePost(`/api/admin/audit/batchAuditLogs`, filter);
    }

    public static async commTemplateAuditLogs(filter: {filter:{Name?}}):Promise<{success:boolean, data:CommunicationTemplate[]}>{
        return NetworkUtil.makePost(`/api/admin/audit/commTemplateAuditLogs`, filter);
    }

    public static async facilityAuditLogs(filter: {filter:{Name?}}):Promise<{success:boolean, data:Facility[]}>{
        return NetworkUtil.makePost(`/api/admin/audit/facilityAuditLogs`, filter);
    }

    public static async podAuditLogs(filter: {filter:{Name?}}):Promise<{success:boolean, data:PodStat[]}>{
        return NetworkUtil.makePost(`/api/admin/audit/podAuditLogs`, filter);
    }

    public static async sampleAuditLogs(filter: {filter:{Name?}}):Promise<{success:boolean, data:Sample[]}>{
        return NetworkUtil.makePost(`/api/admin/audit/sampleAuditLogs`, filter);
    }

    public static async serviceAuditLogs(filter: {filter:{Name?}}):Promise<{success:boolean, data:Service[]}>{
        return NetworkUtil.makePost(`/api/admin/audit/serviceAuditLogs`, filter);
    }

    public static async labInfoAuditLogs(filter: {filter:{Name?}}):Promise<{success:boolean,data:LabInformation[]}>{
        return NetworkUtil.makePost(`/api/admin/audit/labInformationAuditLogs`, filter);
    }
}