import React from 'react';
import {FaMicroscope} from "react-icons/fa";
import {AdminPages} from "./AdminNav";

interface SidebarProps {
    onChange: (str: string) => void
}

class SidebarItems extends React.Component<any, SidebarItems> {

    private routes = [
        {label: "Testing Check-in", value: "CheckIn"},
        {label: "Samples", value: "Samples"},
        {label: "Pod Management and Stats", value: "PodStats"},
        {label: "Send Alert", value: "Alert"},
        {label: "Walk-in Form", value: "WalkinForm"},
        {label: "Well Plate Map", value: "Accession"},
        {label: "Create Receiving Label", value: "BinReceiving"},
        {label: "Retrieve Receiving Label", value: "RetrieveReceivingLabel"},
        {label: "Internal Specimen Label", value: "InternalSpecimenLabel"},
        {label: "Machine Export", value: "MachineExport"},
        {label: "Facility", value: "Facility"},
        {label: "Rerun List", value: "RerunReport"},
        {label: "Batch Reporting", value: "BatchReporting"},
        {label: "Audit Logs", value: "AuditLogs"},
        {label: "User Management", value: "UserPermissioning"},
        {label: "Samples Status", value: "ReconcileReport"},
        {label: "Reconcile Lab", value: "ReconcileLab"},
        {label: "Accessioning", value: "AccessionApp"},
        {label: "Rapid Test Management", value: "RapidTestManagement"},
        {label: "Test Management", value: "TestManagement"},
        {label: "Batch Management", value: "BatchManagement"},
        {label: "Services Management", value: "ServicesManagement"},
        {label: "Communication Templates", value: "CommunicationTemplates"},
        {label: "Settings", value: "Settings"}
    ]

    private patientPages = [AdminPages.CheckIn, AdminPages.WalkinForm, AdminPages.RapidTestManagement];
    private podPages = [AdminPages.PodStats, AdminPages.Alert];
    private samplePages = [AdminPages.Samples];
    private labPages = [AdminPages.Accession, AdminPages.BatchReporting, AdminPages.InternalSpecimenLabel, AdminPages.MachineExport, AdminPages.AccessionApp, AdminPages.RerunReport, AdminPages.BatchManagement];
    private reportPages = [AdminPages.AuditLogs, AdminPages.ReconcileReport, AdminPages.ReconcileLab];
    private receivingPages = [AdminPages.BinReceiving, AdminPages.RetrieveReceivingLabel];
    private userManagementPages = [AdminPages.UserPermissioning, AdminPages.Facility, AdminPages.TestManagement,
        AdminPages.ServicesManagement, AdminPages.CommunicationTemplates, AdminPages.SettingsManagement];

    itemBelongsUnderTab(pageName, tabName){
        if(
            this.props.authPages.includes(pageName) &&
            tabName.includes(pageName)
        ){
            let route = this.routes.find(f => f.label === pageName)
            return (
                <li className={"sidebar-list-item"}>
                    <a className={'navSelect'} href={`/admin/${route.value}`}
                       onClick={() => this.props.onChange(pageName)}>
                        {pageName}
                    </a>
                </li>
            );
        }

        return (<React.Fragment />);
    }

    render(){
        const buildAppointmentsTab = this.props.authPages.some(authPage => this.patientPages.includes(authPage));
        const buildPodsTab = this.props.authPages.some(authPage => this.podPages.includes(authPage));
        const buildLabsTab = this.props.authPages.some(authPage => this.labPages.includes(authPage));
        const buildReportsTab = this.props.authPages.some(authPage => this.reportPages.includes(authPage));
        const buildReceivingTab = this.props.authPages.some(authPage => this.receivingPages.includes(authPage));
        const buildUserMgmtTab = this.props.authPages.some(authPage => this.userManagementPages.includes(authPage));
        const buildSamplesTab = this.props.authPages.some(authPage => this.samplePages.includes(authPage));

        return (
            <div className={"sidebar-wrapper"}>
                <a data-toggle="collapse" aria-expanded="false" className={"navTag primary-black-bg"}>
                    <img src={"https://storage.googleapis.com/streamline-bucket/FullLogoWhite.png"} 
                        className={"sidebar-logo"} 
                        alt={"logo"}/>
                </a>

                <ul className={"list-unstyled"} id={"menu"}>
                    { buildAppointmentsTab ?
                        <li className={"sidebar-header"} id={"patients-list"}>
                            <a href={'#!'} className={"navTag primary-black-bg"} id={"patients-tab"}>
                                <span className={"fa-stack fa-lg"}>
                                    <i className={"fa fa-clipboard fa-stack-1x"}/>
                                </span>
                                Appointments
                                <i id={"patients-caret"} className="fa fa-xs fa-caret-down nav-caret"/>
                            </a>
                            <ul className={"list-unstyled"}>
                                {this.props.items.map( t => {
                                    return this.itemBelongsUnderTab(t, this.patientPages)
                                })}
                            </ul>
                        </li> :
                        <React.Fragment />
                    }
                    { buildPodsTab ?
                        <li className={"sidebar-header"} id={"pods-list"}>
                            <a href={'#!'} className={"navTag primary-black-bg"} id={"pods-tab"}>
                                <span className={"fa-stack fa-lg"}>
                                    <i className={"fa fa-hospital fa-stack-1x"}/>
                                </span>
                                Pods
                                <i id={"pods-caret"} className="fa fa-xs fa-caret-down nav-caret"/>
                            </a>
                            <ul className={"list-unstyled"}>
                                {this.props.items.map( t => {
                                    return this.itemBelongsUnderTab(t, this.podPages)
                                })}
                            </ul>
                        </li> :
                        <React.Fragment />
                    }
                    { buildSamplesTab ?
                        <li className={"sidebar-header"} id={"samples-list"}>
                            <a href={"#!"} className={"navTag primary-black-bg"} id={"samples-tab"}>
                                <span className={"fa-stack fa-lg"}>
                                    <i className={"fa fa-vial fa-stack-1x"}/>
                                </span>
                                Samples
                                <i id={"samples-caret"} className="fa fa-xs fa-caret-down nav-caret"/>
                            </a>
                            <ul className={"list-unstyled"}>
                                {this.props.items.map( t => {
                                    return this.itemBelongsUnderTab(t, this.samplePages)
                                })}
                            </ul>
                        </li> :
                        <React.Fragment />
                    }
                    { buildLabsTab ?
                        <li className={"sidebar-header"} id={"lab-list"}>
                            <a href={'#!'} className={"navTag primary-black-bg"} id={"lab-tab"}>
                                <span className={"fa-stack fa-lg"}>
                                    <FaMicroscope className={"fa fa-stack-1x"} style={{marginTop: "15px"}}/>
                                </span>
                                Lab
                                <i id={"lab-caret"} className="fa fa-xs fa-caret-down nav-caret"/>
                            </a>
                            <ul className={"list-unstyled"}>
                                {this.props.items.map( t => {
                                    return this.itemBelongsUnderTab(t, this.labPages)
                                })}
                            </ul>
                        </li> :
                        <React.Fragment />
                    }
                    { buildReportsTab ?
                        <li className={"sidebar-header"} id={"reports-list"}>
                            <a href={'#!'} className={"navTag primary-black-bg"} id={"reports-tab"}>
                                <span className={"fa-stack fa-lg"}>
                                    <i className={"fa fa-area-chart fa-stack-1x"}/>
                                </span>
                                Reports
                                <i id={"reports-caret"} className="fa fa-xs fa-caret-down nav-caret"/>
                            </a>
                            <ul className={"list-unstyled"}>
                                {this.props.items.map( t => {
                                    return this.itemBelongsUnderTab(t, this.reportPages)
                                })}
                            </ul>
                        </li> :
                        <React.Fragment />
                    }
                    { buildReceivingTab ?
                        <li className={"sidebar-header"} id={"receiving-list"}>
                            <a href={'#!'} className={"navTag primary-black-bg"} id={"receiving-tab"}>
                                <span className={"fa-stack fa-lg"}>
                                   <i className={"fa fa-truck fa-stack-1x"}/>
                                </span>
                                Receiving
                                <i id={"receiving-caret"} className="fa fa-xs fa-caret-down nav-caret"/>
                            </a>
                            <ul className={"list-unstyled"}>
                                {this.props.items.map( t => {
                                    return this.itemBelongsUnderTab(t, this.receivingPages)
                                })}
                            </ul>
                        </li> :
                        <React.Fragment />
                    }
                    { buildUserMgmtTab ?
                        <li className={"sidebar-header"} id={"user-mgmt-list"}>
                            <a href={'#!'} className={"navTag primary-black-bg"} id={"user-mgmt-tab"}>
                                <span className={"fa-stack fa-lg"}>
                                    <i className={"fa fa-gears fa-stack-1x"}/>
                                </span>
                                Setup
                                <i id={"user-mgmt-caret"} className="fa fa-xs fa-caret-down nav-caret"/>
                            </a>
                            <ul className={"list-unstyled"}>
                                {this.props.items.map( t => {
                                    return this.itemBelongsUnderTab(t, this.userManagementPages)
                                })}
                            </ul>
                        </li> :
                        <React.Fragment />
                    }
                </ul>
            </div>
        );
    }
}

export default SidebarItems