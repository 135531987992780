import React from "react";
import PodSelect from "../PodSelect";
import ModalMessage from "../modals/ModalMessage";
import domain from "../../Domain";
import Overlay from "../Overlay";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import Select from "react-select";
import {sweetalert} from "../../App";
import SystemAPI from "../../network/SystemAPI";
import Validator, {ValidationEntry} from "../../validation/Validator";
import {Validators} from "../../validation/Validators";
import AlertAPI from "../../network/AlertAPI";
import AdminAPI from "../../network/AdminAPI";

interface AlertState{
    // text:string
    selectedPod?:string
    showLoading:boolean
    filter?:{text?: string}
    filterTwo?:{email?:string, cell?:string}
    podLabel?
    pods?
    isSubmitting?:boolean
}

class Alert extends React.Component<any, AlertState>{
    constructor(props) {
        super(props);
        this.state = {
            // text:"",
            showLoading:false,
            filter:{},
            filterTwo:{},
            isSubmitting: false
        }
        this.assignClearState = this.assignClearState.bind(this);
        this.assignClear2State = this.assignClear2State.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.clear = this.clear.bind(this);
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            AdminAPI.getAllPods().then(response => {
                if(!response.success){
                    console.error(response.reason)
                    sweetalert.fire("Error loading Pods")
                }

                this.setState({pods: response.data, showLoading: false})
            })
        })
    }

    clearFilterState:() => void = null;


    assignClearState(func){
        console.log("attached");
        this.clearFilterState = func;
    }

    clearState = () =>{
        console.log("clear form");
        this.clearFilterState();
    }

    clearFilter2State:() => void = null;

    assignClear2State(func){
        console.log("attached");
        this.clearFilter2State = func;
    }

    clear2State = () =>{
        console.log("clear form");
        this.clearFilter2State();
    }

    clear() {
        this.clearState()
        this.clear2State()
    }

    onSubmit(){
        let formValidation = {
            Text: this.state.filter.text,
            SelectedPod: this.state.selectedPod,
            Email: this.state.filterTwo.email,
            Cell: this.state.filterTwo.cell,
        }

        let validator = new Validator<any>()
            .withSimpleValidation("SelectedPod", Validators.requireNonNullValidator("Pod"))
            .withSimpleValidation("Text", Validators.requireNotBlankValidator("Message"))
            if(this.state.filterTwo.email){
                validator = validator.withSimpleValidation("Email", Validators.requireValidEmail())
            }
            if(this.state.filterTwo.cell){
                validator = validator.withSimpleValidation("Cell", Validators.requirePhone("Cell Number"))
            }

        let validationResponse = validator.validate(formValidation);
        if(!validationResponse.success) {
            return sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
        }
        window['$']("#alertID").modal('show')
    }

    async onConfirm(){
        this.setState({showLoading:true, isSubmitting:true}, async()=>{
            try{
                let result = await AlertAPI.sendAlert({text:this.state.filter.text, pod:this.state.selectedPod, email:this.state.filterTwo.email, cell:this.state.filterTwo.cell})
                if(result.success){
                    sweetalert.fire({icon: 'success', title: '', text: `${result.numberSent} alerts sent. Not everyone will receive an alert at the same time`});
                    this.clear()
                }else{
                    sweetalert.fire({icon: 'error', title: '', text: result.reason})
                }
            }
            catch (e) {
                sweetalert.fire({icon: 'error', title: '', text: 'Failed sending alert'})
                console.error(e)
            }
            window['$']("#alertID").modal('hide')
            this.clear()
            this.clearState()
            this.clear2State()
            this.setState({showLoading:false});
    })
    }

    getPodLabel(id){
        let podLabel = this.state.pods.find(p => p.value === id)
        if(!podLabel){
            return null;
        }
        return {label: podLabel.label, value: id}
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <React.Fragment>
                <Overlay show_loading={this.state.showLoading}/>
            <ModalMessage cancelButton={true} title={"BEFORE ALERT CONFIRMATION"} ID={"alertID"} buttonText={"Send Alert"} message={
                <p>Are you sure?</p>
            } onSubmit={this.onConfirm} isSubmitting={this.state.isSubmitting} />
            <div className="container-fluid  min-vh-100 ">
                <div className={"row"}>
                    <div className="col-xxl-4 col-lg-12 pt-2">
                        <div className="card mb-2">
                            <div className="card-header verlag-bold">
                                <h3>Send Alert</h3>
                            </div>
                            <div className={"card-body"}>
                                <p className={"responsive-p verlag-light mb-4"}>This will send an email and text message to all individuals signed up for the pod. Please use extreme caution.</p>
                                {/*<PodSelect onChange={(e) => this.setState({selectedPod:e.pod})}/>*/}
                                <div className="form-group row">
                                    <div className={"col-4"}>
                                        <label htmlFor={'Pod'} style={{fontWeight: 'bold', paddingTop: '0.5em'}}>Pod</label>
                                    </div>
                                    <div className={"col-8 pl-0 pr-1"} id={'Pod'}>
                                        <Select
                                            isSearchable={true}
                                            placeholder={"Please select..."}
                                            onChange={(e)=> {this.getPodLabel(e.value); this.setState({podLabel: e, selectedPod: e.value}) } }
                                            className={"state_select"}
                                            options={this.state.pods}
                                            value={this.state.podLabel}
                                        />
                                    </div>
                                </div>
                                <FilterCard passClearStateFunc={this.assignClearState} fields={[
                                    {label:"Message", key:"text", rows: 5, type:FIELD_TYPE.TEXT_AREA, maxLength: 1000},
                                    ]} filterChanged={f =>this.setState({filter:f})}/>
                                <h5 className={'mb-3 mt-5'}>Additional Alert Recipient</h5>
                                <FilterCard passClearStateFunc={this.assignClear2State} fields={[
                                    {label:"Email", key:"email", placeholder: 'optional', type:FIELD_TYPE.TEXT, textType:"email", maxLength: 100},
                                    {label:"Cell Number", key:"cell", placeholder: 'optional', type:FIELD_TYPE.PHONE, textType:"tel", pattern:"[0-9]{3}-[0-9]{3}-[0-9]{4}"}]} filterChanged={f =>this.setState({filterTwo:f})}/>
                            </div>
                            <div className={"card-footer"}> <button className={"btn btn-outline-primary"} onClick={this.onSubmit}> Send</button></div>
                        </div>
                    </div>
                </div>
            </div>
            </React.Fragment>
        )
    }
}
export  default Alert